import React, {useState} from 'react';
// @ts-ignore
import styles from "../../../css/admin/MailPanel.module.css";
import PromptIcon from "../../icons/admin/PromptIcon";
import SimpleBar from "simplebar-react";
import {variables} from "../../../types/admin/http/mailListsRequests";

const VarContainer = (props: {variables?: variables, height?: number}) => {
    const [currentDescription, setCurrentDescription] = useState('')
    const [isHintOpened, setIsHintOpened] = useState(false)

    return (
        <>
            {currentDescription && <div className={styles.hintContainer}>
                <div className={styles.hint}>
                    <span>{currentDescription}</span>
                </div>
            </div>}

            {isHintOpened && <div className={styles.mainHintContainer}>
                <div className={styles.mainHint}>
                    <p>В дальнейшем вы можете использовать переменные</p>
                    <p style={{fontWeight: "bold"}}>Что это такое?</p>
                    <p>Например, вы делаете описание этапа и хотите написать что-то (число, имя, количество заданий или дней), значение чего непостоянно и может меняться. В этом случае вы можете ввести специальную переменную.</p>
                    <p>Вы всегда можете подсмотреть значение переменной при наведении мышкой на ее название</p>
                </div>
            </div>}

            <div className={styles.varContainer} style={{ height: props.height }}>
                <div className={styles.headerWithPrompt}>
                    <h4>Переменные</h4>
                    <PromptIcon setIsHintOpened={setIsHintOpened}/>
                </div>
                <SimpleBar style={{maxHeight: props.height ? props.height - 78 : 192}} autoHide={false}>
                    <div className={styles.innerVarContainer} style={{ height: props.height ? props.height - 78 : undefined}}>
                        {props.variables?.map(variable => {
                            return <div
                                key={variable.description}
                                className={styles.variable}
                                onMouseOver={() => setCurrentDescription(variable.description)}
                                onMouseLeave={() => setCurrentDescription('')}
                                onClick={() => {
                                    navigator.clipboard.writeText('{' + variable.name + '}').then(() => {
                                        setCurrentDescription('Скопировано в буфер обмена')
                                    })
                                }}
                            >
                                <span>{'{' + variable.name + '}'}</span>
                            </div>
                        })}
                    </div>
                </SimpleBar>
                <p className={styles.varHint}>Кликните по переменной, чтобы скопировать</p>
            </div>
        </>
    );
};

export default VarContainer;