import {initialStatus, IStatus, WizardStages} from "../../types/entities/status";
import {StatusAction, StatusActionTypes} from "../../types/reducers/status";

const initialState: IStatus = initialStatus

export const statusReducer = (state=initialState, action: StatusAction): IStatus => {
    switch (action.type) {
        case StatusActionTypes.SET_NEXT_WIZARD_STAGE:
            switch (state.current_wizard_stage) {
                case WizardStages.PROJECT_DESCRIPTION:
                    return {...state, current_wizard_stage: WizardStages.REGISTER}
                case WizardStages.REGISTER:
                    return {...state, current_wizard_stage: WizardStages.FIRST_STEP}
                case WizardStages.FIRST_STEP:
                    return {...state, current_wizard_stage: WizardStages.WAITING_FOR_SECOND_STEP}
                case WizardStages.WAITING_FOR_SECOND_STEP:
                    return {...state, current_wizard_stage: WizardStages.SECOND_STEP}
                case WizardStages.SECOND_STEP:
                    return {...state, current_wizard_stage: WizardStages.THIRD_STEP}
                case WizardStages.THIRD_STEP:
                    return {...state, current_wizard_stage: WizardStages.WAITING_FOR_SECOND_STEP, wizard_completed: true}
            }
        case StatusActionTypes.SET_STATUS:
            return action.payload
        default:
            return state
    }
}