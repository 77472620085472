import React from 'react';
// @ts-ignore
import styles from "../../../css/admin/ThemePanel.module.css"

const EyeIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles.eyeIcon}>
            <path d="M11.9992 9.59922C11.9992 10.2357 11.7464 10.8462 11.2963 11.2963C10.8462 11.7464 10.2357 11.9992 9.59922 11.9992C8.9627 11.9992 8.35225 11.7464 7.90216 11.2963C7.45207 10.8462 7.19922 10.2357 7.19922 9.59922C7.19922 8.9627 7.45207 8.35225 7.90216 7.90216C8.35225 7.45207 8.9627 7.19922 9.59922 7.19922C10.2357 7.19922 10.8462 7.45207 11.2963 7.90216C11.7464 8.35225 11.9992 8.9627 11.9992 9.59922V9.59922Z" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M1.96484 9.59805C2.98404 6.35245 6.01684 3.99805 9.59844 3.99805C13.1808 3.99805 16.2128 6.35245 17.232 9.59805C16.2128 12.8436 13.1808 15.198 9.59844 15.198C6.01684 15.198 3.01764 12.8436 1.99844 9.59805H1.96484Z" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};

export default EyeIcon;