import React from 'react';
// @ts-ignore
import styles from "../../../css/admin/ThemePanel.module.css"

const EditIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles.editIcon}>
            <path d="M8.79922 4.00052H4.79922C4.37487 4.00052 3.96791 4.16909 3.66785 4.46915C3.36779 4.76921 3.19922 5.17617 3.19922 5.60052V14.4005C3.19922 14.8249 3.36779 15.2318 3.66785 15.5319C3.96791 15.8319 4.37487 16.0005 4.79922 16.0005H13.5992C14.0236 16.0005 14.4305 15.8319 14.7306 15.5319C15.0306 15.2318 15.1992 14.8249 15.1992 14.4005V10.4005M14.068 2.86932C14.2156 2.7165 14.3922 2.59461 14.5874 2.51076C14.7826 2.4269 14.9925 2.38277 15.205 2.38092C15.4174 2.37907 15.6281 2.41956 15.8247 2.50001C16.0214 2.58046 16.2 2.69926 16.3503 2.84949C16.5005 2.99972 16.6193 3.17836 16.6997 3.37499C16.7802 3.57163 16.8207 3.78232 16.8188 3.99476C16.817 4.20721 16.7728 4.41716 16.689 4.61237C16.6051 4.80757 16.4832 4.98413 16.3304 5.13172L9.46162 12.0005H7.19922V9.73812L14.068 2.86932Z" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};

export default EditIcon;