import React from 'react';
// @ts-ignore
import styles from "../../../../css/admin/Script.module.css";
import StarIcon from "./StarIcon";
import BlackStarIcon from "./BlackStarIcon";
import WizardInput from "./WizardInput";
import {useDispatch} from "react-redux";
import {WizardActionTypes} from "../../../../types/reducers/wizard";
import {useTypedSelector} from "../../../../hooks/useTypedSelector";

interface QualityGradeFormProps {
    gradeCount: 3 | 5 | number
    grade: 'quality' | 'actuality' | string
}

const GradeForm = (props: QualityGradeFormProps) => {
    const { gradeCount, grade } = props
    const dispatch = useDispatch()
    const gradeData = useTypedSelector(state => grade === 'actuality' ? state.wizard.third_step.originality : state.wizard.third_step.quality)

    const forms = new Array(gradeCount).fill('')
    const grades = new Array(gradeCount).fill('')

    const setGrade = (grade: 'quality' | 'actuality' | string, index: number, e: any) => {
        dispatch({
            type: grade === 'actuality' ?
                WizardActionTypes.SET_GRADE_ACTUALITY_DESCRIPTION_BY_INDEX
                :
                WizardActionTypes.SET_GRADE_QUALITY_DESCRIPTION_BY_INDEX,
            payload: {
                index,
                description: e.target.value,
            }
        })
    }

    return (
        <div className={styles.gradeFormContainer}>
            {forms.map((_, outerIndex) => {
                return <div className={styles.oneGradeInput} key={outerIndex}>
                    <div className={styles.gradeIcons}>
                        {grades.map((_, innerIndex) => {
                            return innerIndex <= outerIndex ? <BlackStarIcon key={innerIndex}/> : <StarIcon key={innerIndex}/>
                        })}
                        <p>{outerIndex+1} балл{outerIndex === 0 ? '' : outerIndex === 4 ? 'ов' : 'а'}</p>
                    </div>
                    <WizardInput
                        onChange={(e) => setGrade(grade, outerIndex, e)}
                        placeholder="Текст..."
                        value={gradeData[outerIndex]}
                        label="Расшифровка"
                        width={350}
                        isTextarea={true}
                        height={100}
                    />
                </div>
            })}
        </div>
    );
};

export default GradeForm;