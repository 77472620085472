import React from 'react';
// @ts-ignore
import styles from "../../../css/admin/ThemePanel.module.css"

const ThemeTrashIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles.trashIcon}>
            <path d="M7.99922 8.80039V13.6004M11.1992 8.80039V13.6004M3.19922 5.60039H15.9992M15.1992 5.60039L14.5056 15.314C14.4769 15.7177 14.2963 16.0954 14.0001 16.3712C13.704 16.6471 13.3143 16.8004 12.9096 16.8004H6.28882C5.88413 16.8004 5.49447 16.6471 5.19833 16.3712C4.90218 16.0954 4.72155 15.7177 4.69282 15.314L3.99922 5.60039H15.1992ZM11.9992 5.60039V3.20039C11.9992 2.98822 11.9149 2.78473 11.7649 2.63471C11.6149 2.48468 11.4114 2.40039 11.1992 2.40039H7.99922C7.78705 2.40039 7.58356 2.48468 7.43353 2.63471C7.2835 2.78473 7.19922 2.98822 7.19922 3.20039V5.60039H11.9992Z" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};

export default ThemeTrashIcon;