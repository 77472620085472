import React, {FC, useEffect} from 'react';
import {Routes, Route, useNavigate} from "react-router-dom";
import {publicRoutes} from "./publicRoutes";
import {useTypedSelector} from "../hooks/useTypedSelector";
import AuthPage from "../components/auth/AuthPage";
import {getStatus} from "../http/authRequests";
import {useDispatch} from "react-redux";
import {StatusActionTypes} from "../types/reducers/status";
import TaskEditor from "../components/editors/TaskEditor";
import ExpertisePlayer from "../components/editors/expertise/ExpertisePlayer";
import SecondStep from "../components/steps/SecondStep";
import FirstStepForm from "../components/steps/FirstStepForm";
import FirstStepWaiting from "../components/steps/FirstStepWaiting";
import ThirdStep from "../components/steps/ThirdStep";
import ErrorPage from "../components/common/ErrorPage";
import {timestempToDate} from "../utils/dateFuncs";
import Admin from "../components/admin/Admin";
import EndExpertisePage from "../components/infoPages/EndExpertisePage";
import DashboardPage from "../components/admin/user/dashboard/userDashboard/DashboardPage";
import DemoOverPage from "../components/infoPages/DemoOverPage";
import WizardPage from "../components/admin/wizard/WizardPage";

const AppRouter:FC = () => {
    const isAuth = useTypedSelector(state => state.user.isAuth)
    const role = useTypedSelector(state => state.user.role)
    const status = useTypedSelector(state => state.status)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        getStatus().then(data => {
            dispatch({type: StatusActionTypes.SET_STATUS, payload: {
                ...data,
                stage_deadline: timestempToDate(data.stage_deadline),
                first_stage_deadline: timestempToDate(data.first_stage_deadline),
                second_stage_deadline: timestempToDate(data.second_stage_deadline),
                third_stage_deadline: timestempToDate(data.third_stage_deadline),
            }})
        })
    }, [])

    useEffect(() => {
        if (role === 1 || role === 2) {
            !status.wizard_completed ? navigate('/admin/wizard/player') : navigate('/admin/script')
        }
    }, [status.wizard_completed, role])

    return (
        <Routes>
            {!isAuth && publicRoutes.map(({path, element}) =>
                <Route key={path} path={path} element={React.createElement(element)}/>
            )}

            {!isAuth && <Route path="*" element={<AuthPage />} />}

            {isAuth && (role === 1 || role === 2) && <>
                {status.wizard_completed === false && <Route path="/admin/wizard/:stage" element={<WizardPage />}/>}
                    :
                {status.wizard_completed && <>
                    <Route path="/admin/:panel_type" element={<Admin />}/>
                    <Route path="/dashboard/:user_id" element={<DashboardPage />}/>
                    <Route path="/admin/:panel_type/:mail_list_group" element={<Admin />}/>
                    <Route path="/admin/wizard/:stage" element={<WizardPage />}/>
                </>}

            </>}

            {isAuth && role === 3 && status.stage_number === 1 && status.max_experts_on_subtopic && <>
                <Route path="*" element={<FirstStepForm />} />
            </>}

            {isAuth && role === 3 && status.stage_number === 1 && !status.max_experts_on_subtopic && <>
                <Route path="*" element={<FirstStepWaiting />} />
            </>}

            {isAuth && role === 3 && status.stage_number === 2 && <>
                <Route path="/set_tasks/:subtopic_id" element={<TaskEditor />} />
                <Route path="*" element={<SecondStep />} />
            </>}

            {isAuth && role === 3 && status.stage_number === 3 && <>
                <Route path="/expertise/:subtopic_id" element={<ExpertisePlayer />} />
                <Route path="/expertise_end" element={<EndExpertisePage />} />
                <Route path="//demo_over" element={<DemoOverPage />} />
                <Route path="/dashboard/:user_id" element={<DashboardPage />}/>
                <Route path="*" element={<ThirdStep />} />
            </>}

            <Route path="/error/:error_type" element={<ErrorPage />} />
        </Routes>
    );
};

export default AppRouter;