import React, {useEffect, useState} from 'react';
// @ts-ignore
import styles from "../../../css/admin/MailPanel.module.css";
import ClockIcon from "../../icons/admin/ClockIcon";
import 'simplebar/dist/simplebar.min.css';
import Button from "../../common/Button";
import MailListInfo from "./MailListInfo";
import TextEditor from "./TextEditor";
import RecipientsBlock from "./RecipientsBlock";
import {IMailList} from "../../../types/admin/http/mailListsRequests";
import SuccessModal from "../common/SuccessModal";
import {deleteMailList, getRecipientsByFilter} from "../../../http/admin/mailListsRequests";
import ConfirmModal from "../common/ConfirmModal";
import ArrowIcon from "../../icons/ArrowIcon";
import {isCustomMailSent} from "../../../utils/dateFuncs";
import DeliveredIcon from "../../icons/admin/DeliveredIcon";
import ModalEditor from "./ModalEditor";
import {MailEditorActionTypes} from "../../../types/reducers/mailEditor";
import {RecipientsActionTypes} from "../../../types/reducers/recipients";
import {useDispatch} from "react-redux";

const MailList = (props: {mailList: IMailList}) => {
    const dispatch = useDispatch()
    const [text, setText] = useState('')
    const [isModalOpened, setIsModalOpened] = useState(false)
    const [isConfirmModalOpened, setIsConfirmModalOpened] = useState(false)
    const [isMailListDeleted, setIsMailListDeleted] = useState(false)
    const [isHidden, setIsHidden] = useState(true)
    const [isMailListEditorOpened, setIsMailEditorOpened] = useState(false)

    useEffect(() => {
        setText(props.mailList.text)
    }, [props.mailList.text])

    const closeModal = () => {
        setIsModalOpened(false)
    }

    const closeConfirmModal = () => {
        setIsConfirmModalOpened(false)
    }

    const closeEditor = () => {
        setIsMailEditorOpened(false)
    }

    const confirmDelete = () => {
        deleteMailList(props.mailList.id).then(() => console.log('Deleted mail list id=' + props.mailList.id))
        setIsMailListDeleted(true)
        setIsConfirmModalOpened(false)
    }

    return (<>
            {!isMailListDeleted && <div className={styles.mailList} style={{height: isHidden ? 65 : 'auto'}}>
                <div className={styles.mailListName} onClick={() => setIsHidden(!isHidden)}>
                    <div className={styles.headerWithIcon}>
                        {isCustomMailSent(props.mailList.date) ? <DeliveredIcon /> : <ClockIcon />}
                        <h2 className={styles.mailListHeader}>Тема: {props.mailList.name}</h2>
                    </div>
                    <div className={styles.hideMailListArrowContainer}>
                        <ArrowIcon isOpened={!isHidden}/>
                    </div>
                </div>

                <TextEditor setText={setText} text={text} isForEdit={false}/>

                {isModalOpened && <SuccessModal closeModal={closeModal} text={"Сохранение прошло успешно"}/>}

                {isConfirmModalOpened && <ConfirmModal
                    submit={() => confirmDelete()}
                    closeModal={closeConfirmModal}
                    text={"Уверены, что хотите отменить рассылку?"}
                />}

                {isMailListEditorOpened && <ModalEditor
                    name={props.mailList.name}
                    id={props.mailList.id}
                    text={props.mailList.text}
                    closeModal={closeEditor}
                />}

                {!isCustomMailSent(props.mailList.date, false) && <Button
                    width={"170px"}
                    height={"45px"}
                    fontSize={"16px"}
                    variant={"primary"}
                    margin={"20px 0 0 975px"}
                    submit={() => {
                        if (props.mailList.date) {
                            dispatch({type: MailEditorActionTypes.OPEN_EDITOR})
                            document.getElementById('admin-panel')?.scrollTo({
                                top: 0,
                                behavior: 'smooth'
                            });
                            getRecipientsByFilter('all', props.mailList.id).then(data => {
                                dispatch({type: MailEditorActionTypes.SET_FULL_MAIL_LIST, payload: {
                                        id: props.mailList.id,
                                        mailListName: props.mailList.name,
                                        text: props.mailList.text,
                                        date: props.mailList.date,
                                        isNowChecked: false,
                                        isEditorOpened: true
                                    }})
                                dispatch({type: RecipientsActionTypes.ADD_RECIPIENTS, payload: data.message_statuses})
                            })
                        } else {
                            setIsMailEditorOpened(true)
                        }
                    }}
                >
                    Редактировать
                </Button>}

                <MailListInfo date={props.mailList.date} opened={props.mailList.opened} delivered={props.mailList.delivered} total={props.mailList.total}/>

                <RecipientsBlock
                  mailListId={props.mailList.id}
                  date={props.mailList.date}
                  mailListName={props.mailList.name}
                  mailListText={props.mailList.text}
                  isHidden={isHidden}
                />

                {!isCustomMailSent(props.mailList.date) && <Button
                    width={"180px"}
                    height={"45px"}
                    fontSize={"16px"}
                    variant={"primary"}
                    margin={"20px 0 0 965px"}
                    submit={() => setIsConfirmModalOpened(true)}
                >
                    Отменить рассылку
                </Button>}
            </div>}
    </>
    );
};

export default MailList;