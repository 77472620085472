import React from 'react';
// @ts-ignore
import styles from "../../../../../../css/admin/UserPanel.module.css";

const Toggler = (props: {isChecked: boolean}) => {
    return (
        <div className={styles.toggler} style={{
            backgroundColor: props.isChecked ? "#FFC000" : "rgba(229, 229, 229, 1)",
        }}>
            <div className={styles.check} style={{marginLeft: props.isChecked ? 25 : 0}}/>
        </div>
    );
};

export default Toggler;