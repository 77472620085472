import React, {Dispatch, SetStateAction, useEffect, useRef} from 'react';
// @ts-ignore
import styles from "../../../css/admin/MailPanel.module.css";
import SimpleBar from "simplebar-react";
import VarContainer from "./VarContainer";
import {useTypedSelector} from "../../../hooks/useTypedSelector";

const TextEditor = (props: {setText: Dispatch<SetStateAction<string>> | ((text: string) => void), text: string, isForEdit: boolean}) => {
    const variables = useTypedSelector(state => state.variables)
    const textareaRef = useRef<HTMLTextAreaElement>(null)

    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 255 + 'px'
            textareaRef.current.style.height = textareaRef.current.scrollHeight + 'px'
        }
    }, [])

    return (
        <div className={styles.textEditor}>
            <div className={styles.customTextarea} style={{width: !props.isForEdit ? '100%' : '720px'}}>
                <SimpleBar style={{maxHeight: 260}} autoHide={false}>
                    {props.isForEdit && <textarea
                        ref={textareaRef}
                        placeholder={"Текст рассылки"}
                        value={props.text}
                        className={styles.textarea}
                        onChange={(e) => {
                            props.setText(e.target.value)
                            //autoHeight(e)
                        }}
                    />}

                  {!props.isForEdit && <textarea className={styles.textarea} value={props.text} readOnly/>}
                </SimpleBar>
            </div>

            {props.isForEdit && <VarContainer variables={variables}/>}
        </div>
    );
};

export default TextEditor;