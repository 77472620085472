import React from 'react';
import Modal from "./Modal";
// @ts-ignore
import commonStyles from "../../../css/admin/Admin.module.css";
import BigReadyIcon from "../../icons/BigReadyIcon";
import Button from "../../common/Button";

const SuccessModal = (props: {closeModal: () => void, text: string}) => {
    return (
        <Modal>
            <div className={commonStyles.successModalContainer}>
                <BigReadyIcon />
                <p className={commonStyles.successModalText}>{props.text}</p>
                <Button
                    width={"100px"}
                    height={"50px"}
                    fontSize={"20px"}
                    variant={"secondary"}
                    submit={props.closeModal}
                >
                    Хорошо
                </Button>
            </div>
        </Modal>
    );
};

export default SuccessModal;