import {ITask} from "../types/entities/task";
import {errorType} from "../types/components/editor";

export const isTaskNotEmpty = (task: ITask) => {
    if (task.task_content.question || (task.answers !== '[]' && task.task_type !== 'sequence')) {
        return true
    }

    for (let i = 0; i < task.task_content.options.length; i++) {
        if (task.task_content.options[i].text) {
            return true
        }
    }

    return false
}

export const checkErrors = (currentTasks: ITask[], max_symbols_for_option: number, max_symbols_for_question: number): errorType[] => {
    let currentErrors: any = {}

    currentTasks.forEach((task: ITask) => {
        if (task.task_content.question === '') {
            if (task.id in currentErrors) {
                currentErrors[task.id].push('no question')
            } else {
                currentErrors[task.id] = ['no question']
            }
        }

        if (task.task_content.question.length > max_symbols_for_question) {
            if (task.id in currentErrors) {
                currentErrors[task.id].push('too long question')
            } else {
                currentErrors[task.id] = ['too long question']
            }
        }

        if (task.answers === '[]') {
            if (task.id in currentErrors) {
                currentErrors[task.id].push('no answers')
            } else {
                currentErrors[task.id] = ['no answers']
            }
        }

        task.task_content.options.forEach(option => {
            if (option.text === '') {
                if (task.id in currentErrors) {
                    currentErrors[task.id].push(option.id)
                } else {
                    currentErrors[task.id] = [option.id]
                }
            }

            if (option.text.length > max_symbols_for_option) {
                if (task.id in currentErrors) {
                    currentErrors[task.id].push(option.id + ' too long')
                } else {
                    currentErrors[task.id] = [option.id + ' too long']
                }
            }
        })
    })

    return currentErrors
}