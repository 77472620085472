import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import Header from "../../../../common/Header/Header";
// @ts-ignore
import commonStyles from "../../../../../css/Common.module.css";
// @ts-ignore
import styles from "../../../../../css/admin/UserPanel.module.css";
import DashboardShape from "../../../../icons/admin/DashboardShape";
import GrayDownloadIcon from "../../../../icons/admin/GrayDownloadIcon";
import CustomRadio from "../../../../common/CustomRadio";
import classNames from "classnames";
import QuestionIcon from "../../../../icons/admin/QuestionIcon";
import {getDashboardData, getFeedback} from "../../../../../http/admin/userRequests";
import {IDashboard} from "../../../../../types/admin/http/userRequests";
import Preloader from "../../../../common/Preloader";
import {useDispatch} from "react-redux";
import {MouseHintActionTypes} from "../../../../../types/reducers/mouseHint";
import DownloadCertificateButton from "./components/DownloadCertificateButton";

// TODO Сделать рефактор (разбить на компоненты, посмотреть на вложенность (в тасках можно вынести флаги отображения на уровень выше)
// TODO Разбить на компоненты эту страницу
// TODO Вывод айди для кеев (у заданий, опций и комментов)
// TODO Глянуть возможность применения string

const rated_hint = 'Сколько всего человек выполнило и оценило данное задание. Чем больше выборка, тем более информативны статистические коэффициенты. Так, коэффициент трудности имеет смысл, если выборка больше 10 человек, а коэффициент дискриминативности, если выборка больше 30.'
const difficult_hint = 'Процент правильных ответов на задание. Если этот коэффициент ниже 40% задание считается трудным, от 40 до 70% - средним,  свыше 70% - легким. Значения ниже 10% и выше 95% говорят, что задание слишком трудное или легкое. Такое задание будет плохо работать в тесте, его лучше заменить.'
const discrimination_hint = 'Основной индикатор качества тестового задания. Он показывает статистическую связь правильности ответов на данное задание с общим баллом по тесту. Чем он выше, тем лучше. Задание считается качественным при КД > 0,3. Если КД от 0,2 до 0,3, задание нужно немного переформулировать: незначительно уточнить формулировку или скорректировать один из вариантов ответа. КД ниже 0,2 говорит о том, что задание слишком отличается от остальных и должно быть удалено из теста.'
const originality_hint = 'Экспертная оценка оригинальности задания.'
const quality_hint = ' Экспертная оценка качества задания.'

const DashboardPage = () => {
    const id = useParams().user_id
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [isDataLoaded, setIsDataLoaded] = useState(false)
    const [dashboardData, setDashboardData] = useState<IDashboard>()
    const [feedbackButtonText, setFeedbackButtonText] = useState('Обратная связь')

    useEffect(() => {
        setIsDataLoaded(false)
        getDashboardData(Number(id)).then(data => {
            setIsDataLoaded(true)
            setDashboardData(data)
        }).catch(error => {
            if (error.message === "Request failed with status code 403") {
                navigate('/error/closed')
            }
        })
    }, [])

    const show = (param: number | null | undefined) => {
        if (param === 0) {
            return param.toString()
        } else return param
    }

    const getBlackLineWidth = (current: number, total: number) => {
        if (total === 0) {
            return 50
        } else {
            return Math.round(current / total * 250) + 50
        }
    }

    const getBlackLinePercent = (current: number, total: number) => {
        if (total === 0) return 0
        return Math.round(current / total * 100)
    }

    const getColoredLineWidth = (current: number, total: number) => {
        return (current / total * 100).toString() + '%'
    }

    const showHint = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, text: string) => {
        dispatch({type: MouseHintActionTypes.SHOW_HINT, payload: {
            x: e.clientX + 15,
            y: e.pageY,
            isShown: true,
            hint_text: text
        }})
    }

    const hideHint = () => {
        dispatch({type: MouseHintActionTypes.HIDE_HINT})
    }

    const exportFeedback = () => {
        if (feedbackButtonText !== "Подождите...") {
            setFeedbackButtonText("Подождите...")
            getFeedback(id).then(data => {
                const downloadUrl = window.URL.createObjectURL(data)
                const link = document.createElement('a')
                link.href = downloadUrl
                link.download = `${dashboardData?.user_name}_id${id}.xls`
                document.body.appendChild(link)
                link.click()
                link.remove()
                setFeedbackButtonText("Скачать статистику")
            })
        }
    }

    return (
        <>
            <Header/>
            {isDataLoaded && dashboardData ? <div className={styles.dashboardContainer}>
                <h2>Результаты</h2>
                <div className={styles.mainDashboardBlocks}>
                    {show(dashboardData?.author_rating) && <div className={styles.totalScore}>
                        <p>Место в рейтинге авторов заданий</p>
                        <span>{dashboardData?.author_rating}</span>
                        <p className={styles.qualityCounter}>
                            Сделано <span>{dashboardData.quality_task_counter}</span> качественных заданий
                        </p>
                    </div>}

                    <div className={styles.minorDashboardBlocks}>
                        {show(dashboardData?.total_score) && <div className={styles.minorBlockScore}>
                            <p>Общий балл</p>
                            <span>{dashboardData?.total_score}</span>
                            <p className={styles.rightAnswersStat}>
                                Сделано <span>{dashboardData.quality_task_counter}</span> качественных заданий
                            </p>
                        </div>}

                        {show(dashboardData?.expert_rating) && <div className={styles.minorBlockScore}>
                            <p>Место в рейтинге экспертов</p>
                            <span>{dashboardData?.expert_rating}</span>
                        </div>}

                        {show(dashboardData?.scholar_rating_place) && <div className={styles.minorBlockScore}>
                            <p>Место в рейтинге знатоков</p>
                            <span>{dashboardData?.scholar_rating_place}</span>

                            {show(dashboardData?.scholar_rating_correct) && show(dashboardData?.scholar_rating_total) &&
                                <p className={styles.rightAnswersStat}>
                                    Правильные ответы на <span>{dashboardData?.scholar_rating_correct}/{dashboardData?.scholar_rating_total}</span> заданий
                                </p>}
                        </div>}
                    </div>
                </div>
                <div className={styles.dashboardShape}>
                    <DashboardShape />
                </div>

                {dashboardData.download_certificate && <DownloadCertificateButton
                    name={dashboardData.download_certificate.name}
                    author_rating={dashboardData.download_certificate.author_rating}
                    quality_task_counter={dashboardData.download_certificate.quality_task_counter}
                    cluster={dashboardData.download_certificate.cluster}
                />}

                {(dashboardData.show_tasks && dashboardData.tasks.length !== 0) && <div className={styles.feedbackHeader}>
                    <h2>Обратная связь к вашим заданиям</h2>
                    {dashboardData?.download_xlsx && <div className={styles.feedbackDownload} onClick={exportFeedback}>
                        <GrayDownloadIcon />
                        <span>{feedbackButtonText}</span>
                    </div>}
                </div>}

                {(dashboardData.show_tasks && dashboardData.tasks.length !== 0) && dashboardData.tasks.map(task => {
                    return <div className={styles.task} key={task.question}>
                        <div className={styles.taskContent}>
                            <div className={styles.taskBlock}>
                                <p>{task.question}</p>

                                {task.options.map(option => {
                                    return <div className={styles.taskOption} key={option.text}>
                                        <CustomRadio isChecked={option.is_correct}/>
                                        <div className={styles.optionWithStat}>
                                            <p>{option.text}</p>
                                            <div className={styles.blackStatLine}>
                                                <div className={styles.blackLine} style={{width: getBlackLineWidth(option.current_picked_counter, option.total_picked_counter)}}>
                                                    <span>{option.current_picked_counter}/{option.total_picked_counter}</span>
                                                </div>
                                                <span className={styles.blackLinePercent}>
                                                    {getBlackLinePercent(option.current_picked_counter, option.total_picked_counter)}%
                                                </span>
                                            </div>
                                            {(show(option.high_group_counter) || show(option.medium_group_counter) || show(option.weak_group_counter)) && <div className={styles.coloredLine}>
                                                <div className={styles.redLine} style={{width: getColoredLineWidth(Number(option.weak_group_counter), option.total_picked_counter)}}/>
                                                <div className={styles.grayLine} style={{width: getColoredLineWidth(Number(option.medium_group_counter), option.total_picked_counter)}}/>
                                                <div className={styles.greenLine} style={{width: getColoredLineWidth(Number(option.high_group_counter), option.total_picked_counter)}}/>
                                            </div>}
                                        </div>
                                    </div>
                                })}
                            </div>

                             <div className={styles.legend}>
                                <div className={styles.coloredLegend}>
                                    <div className={classNames(styles.circle, styles.black)}/>
                                    <p>Количество/процент участников, выбравших данный вариант ответа</p>
                                </div>

                                 {show(task.discrimination) && <><div className={styles.coloredLegend}>
                                    <div className={classNames(styles.circle, styles.red)}/>
                                    <p>Участники из “слабой” группы</p>
                                 </div>

                                 <div className={styles.coloredLegend}>
                                     <div className={classNames(styles.circle, styles.gray)}/>
                                     <p>Участники из “средней” группы</p>
                                 </div>

                                 <div className={styles.coloredLegend}>
                                     <div className={classNames(styles.circle, styles.green)}/>
                                     <p>Участники из “высокой” группы</p>
                                 </div></>}
                            </div>
                        </div>

                        <div className={styles.taskInfo}>
                            <div className={styles.taskInfoBlocks}>
                                {show(task.rated_counter) && <div className={styles.taskInfoBlock} style={{width: 90}}>
                                    <div className={styles.infoBlockHeader}>
                                        <span>Оценило</span>
                                        <div
                                            onMouseMove={(e) => {showHint(e, rated_hint)}}
                                            onMouseLeave={hideHint
                                        }>
                                            <QuestionIcon />
                                        </div>
                                    </div>
                                    <p>{task.rated_counter}</p>
                                </div>}
                                {show(task.difficult) && <div className={styles.taskInfoBlock} style={{width: 115}}>
                                    <div className={styles.infoBlockHeader}>
                                        <span style={{width: 70}}>Коэфицент трудности</span>
                                        <div
                                            onMouseMove={(e) => {showHint(e, difficult_hint)}}
                                            onMouseLeave={hideHint}
                                        >
                                            <QuestionIcon />
                                        </div>
                                    </div>
                                    <p>{task.difficult}</p>
                                </div>}
                                {show(task.discrimination) && <div className={styles.taskInfoBlock} style={{width: 165}}>
                                    <div className={styles.infoBlockHeader}>
                                        <span style={{width: 125}}>Коэфицент дискриминативности</span>
                                        <div
                                            onMouseMove={(e) => {showHint(e, discrimination_hint)}}
                                            onMouseLeave={hideHint}
                                        >
                                            <QuestionIcon />
                                        </div>
                                    </div>
                                    <p>{task.discrimination}</p>
                                </div>}
                                {show(task.originality) && <div className={styles.taskInfoBlock} style={{width: 135}}>
                                    <div className={styles.infoBlockHeader}>
                                        <span style={{width: 95}}>Оригинальность</span>
                                        <div
                                            onMouseMove={(e) => {showHint(e, originality_hint)}}
                                            onMouseLeave={hideHint}
                                        >
                                            <QuestionIcon />
                                        </div>
                                    </div>
                                    <p>{task.originality}</p>
                                </div>}
                                {show(task.quality) && <div className={styles.taskInfoBlock} style={{width: 110}}>
                                    <div className={styles.infoBlockHeader}>
                                        <span>Качество</span>
                                        <div
                                            onMouseMove={(e) => {showHint(e, quality_hint)}}
                                            onMouseLeave={hideHint}
                                        >
                                            <QuestionIcon />
                                        </div>
                                    </div>
                                    <p>{task.quality}</p>
                                </div>}
                            </div>

                            {task.comments && task.comments.length !== 0 && <div className={styles.comments}>
                                <h3>Комментарии</h3>
                                {task.comments.map(comment => {
                                    return <p>{comment}</p>
                                })}
                            </div>}
                        </div>
                    </div>
                })}
            </div> : <Preloader />}
        </>
    );
};

export default DashboardPage;