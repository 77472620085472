import {RefObject} from "react";
import {IExercise, initialExercise} from "../types/entities/exercise";
import {ExerciseActionTypes} from "../types/reducers/exercise";
import {Dispatch} from "redux"
import {getExercise, saveExercise} from "../http/editorRequests";

export const saveAndChangeExercise = (taskContainer: RefObject<HTMLDivElement>, dispatch: Dispatch, exercise: IExercise, id: number) => {
    const exerciseData = createExerciseDataObject(exercise)

    let timer = setInterval(function() {
        if (taskContainer.current && taskContainer.current.style.right === "100px") {
            clearInterval(timer)
            saveExercise(exerciseData).then(() => {
                getExercise(id).then(data => {
                    if (data.answer === "") {
                        data.answer = "[]"
                    }

                    if (!data.comment) {
                        data.comment = ''
                    }

                    dispatch({type: ExerciseActionTypes.SET_EXERCISE, payload: data})

                    document.querySelector("#taskContainer")?.scrollIntoView({
                        behavior: "smooth",
                        block: "center"
                    })

                    fadeInAnimation(taskContainer)
                })
            })
        } else {
            if (taskContainer.current) {
                taskContainer.current.style.right = (parseInt(taskContainer.current.style.right) + 5).toString() + 'px'
            }
        }
    }, 1);
}

const createExerciseDataObject = (exercise: IExercise) => {
    return {
        answer_id: exercise.answer_id,
        answer: exercise.answer === '[]' ? "" : exercise.answer,
        comment: exercise.comment,
        quality_assessment: exercise.quality_assessment,
        actuality_assessment: exercise.actuality_assessment
    }
}

export const fadeInAnimation = (block: RefObject<HTMLDivElement>) => {
    if (block.current) {
        block.current.style.right = "-100px"
        block.current.style.opacity = "1"
    }

    let anotherTimer = setInterval(function() {
        if (block.current && block.current.style.right === "0px") {
            clearInterval(anotherTimer)
        } else {
            if (block.current) {
                block.current.style.right = (parseInt(block.current.style.right) + 5).toString() + 'px'
            }
        }
    }, 1);
}