enum ModalActionTypes {
  SET_MODAL_STATUS = 'SET_MODAL_STATUS',
}

type IModalConfig = 'logout' | 'save' | "await" | false

interface ISetLogoutModalStatusAction {
  type: ModalActionTypes.SET_MODAL_STATUS
  payload: IModalConfig
}

type ModalAction = ISetLogoutModalStatusAction

export {ModalActionTypes}
export type {ModalAction, IModalConfig}