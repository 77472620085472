import React, {useEffect, useState} from 'react';
import LogoIcon from "../icons/LogoIcon";
import {useTypedSelector} from "../../hooks/useTypedSelector";
// @ts-ignore
import styles from "../../css/Common.module.css"
import {useParams} from "react-router-dom";

const ErrorPage = () => {
    const mail = useTypedSelector(state => state.app.support_mail)
    const errorType = useParams().error_type
    const [errorMessage, setErrorMessage] = useState(errorType)

    useEffect(() => {
        if (errorType === "wrong_token") {
            setErrorMessage('Ссылка сломана.')
        } else if (errorType === 'forbidden') {
            setErrorMessage('Ссылка недействительна. Убедитесь, что вам не пришла ссылка новее.')
        } else if (errorType === 'inspired') {
            setErrorMessage('Ссылка недействительна. Отправьте запрос на получение новой ссылки.')
        } else if (errorType === 'confirmed') {
            setErrorMessage('Такой пользователь уже зарегистрирован в системе.')
        } else if (errorType === 'closed') {
            setErrorMessage('Вам сюда нельзя.')
        } else if (errorType === 'user_doesnt_exist') {
            setErrorMessage('Пользователя с данной ссылкой-токеном не существует.')
        } else if (errorType === 'register_closed') {
            setErrorMessage('К сожалению, регистрация на конкурс уже закрыта. Ждем вас в следующем году!')
        } else if (errorType === 'something_wrong') {
            setErrorMessage('К сожалению, сервер спит.')
        }
    }, [])

    return (
        <div>
            <div className={styles.errorPage}>
                <div className={styles.logoContainer}>
                    <LogoIcon color={"#323232"}/>
                </div>
                <div>
                    <p className={styles.errorType}>{errorMessage} Хотите вернуться <a className={styles.errorText} href="/">на главную?</a></p>
                    {errorType !== 'register_closed' && <svg className={styles.sad} width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20.4998 37.5827C29.9347 37.5827 37.5832 29.9342 37.5832 20.4993C37.5832 11.0645 29.9347 3.41602 20.4998 3.41602C11.065 3.41602 3.4165 11.0645 3.4165 20.4993C3.4165 29.9342 11.065 37.5827 20.4998 37.5827Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M27.3332 27.3327C27.3332 27.3327 24.7707 23.916 20.4998 23.916C16.229 23.916 13.6665 27.3327 13.6665 27.3327" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M15.375 15.375H15.3931" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M25.625 15.375H25.6432" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>}
                </div>
                <p className={styles.errorText}>
                    Вы можете написать <a className={styles.supportError} href={"mailto:" + mail}>сюда</a>, чтобы получить помощь
                </p>
            </div>
        </div>
    );
};

export default ErrorPage;