import React from 'react';
// @ts-ignore
import styles from "../../css/Common.module.css"
import {restartDemo} from "../../utils/demo/demo";

const RestartDemoButton = (props: {isMobile: boolean}) => {
  return (
    <div className={styles.restartDemoButton} style={{position: props.isMobile ? "relative" : "fixed"}} onClick={restartDemo}>
      <p className={styles.restartDemoButtonText}>Перезапустить демо</p>
    </div>
  );
};

export default RestartDemoButton;