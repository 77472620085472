import React, {Dispatch, SetStateAction} from 'react';
// @ts-ignore
import styles from "../../../css/admin/MailPanel.module.css";

const Filters = (props: {currentFilter: string, setCurrentFilter: Dispatch<SetStateAction<string>>}) => {
    return (
        <div className={styles.filters}>
            <div
                className={styles.filter}
                onClick={() => props.setCurrentFilter('all')}
                style={{background: props.currentFilter === 'all' ? "#FFD556" : "#F3F8FB"}}
            >
                <span>Все</span>
            </div>
            <div
                className={styles.filter}
                onClick={() => props.setCurrentFilter('delivered')}
                style={{background: props.currentFilter === 'delivered' ? "#FFD556" : "#F3F8FB"}}
            >
                <span>Доставлено</span>
            </div>
            <div
                className={styles.filter}
                onClick={() => props.setCurrentFilter('opened')}
                style={{background: props.currentFilter === 'opened' ? "#FFD556" : "#F3F8FB"}}
            >
                <span>Просмотрено</span>
            </div>
            <div
                className={styles.filter}
                onClick={() => props.setCurrentFilter('not_delivered')}
                style={{background: props.currentFilter === 'not_delivered' ? "#FFD556" : "#F3F8FB"}}
            >
                <span>Недоставлено</span>
            </div>
        </div>
    );
};

export default Filters;