import React, {useState} from 'react';
import Toggler from "./Toggler";
// @ts-ignore
import styles from "../../../../../../css/admin/UserPanel.module.css";

const ToggleSetting = (props: {isChecked: boolean, settingName: string}) => {
    const [isChecked, setIsChecked] = useState(false)

    return (
        <div className={styles.toggleSetting} onClick={() => setIsChecked(!isChecked)}>
            <Toggler isChecked={isChecked}/>
            <p>{props.settingName}</p>
        </div>
    );
};

export default ToggleSetting;