import React, {useState} from 'react';
// @ts-ignore
import styles from "../../../css/Player.module.css";
import ControlLink from "./ControlLink";
import {ISubtopic, IThemeListVariant} from "../../../types/components/themes";
import {useDispatch} from "react-redux";
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import {ThemeActionTypes} from "../../../types/reducers/theme";
import classNames from "classnames";
import ProgressBar from "./ProgressBar";
import CustomCheckbox from "../../common/CustomCheckbox";

const Subtopic = (props: {themeListVariant: IThemeListVariant, subtopic: ISubtopic}) => {
    const dispatch = useDispatch()
    const isMobile = useState(document.documentElement.clientWidth <= 900)[0]
    const set = useTypedSelector(state => state.theme)
    const maxSubtopics = useTypedSelector(state => state.status.max_picked_subtopics)

    const togglePickSubtopic = (id: number) => {
        if (set.has(id)) {
            dispatch({type: ThemeActionTypes.DELETE_THEME, payload: id})
        } else if (set.size < Number(maxSubtopics)) {
            dispatch({type: ThemeActionTypes.ADD_THEME, payload: id})
        }
    }

    return (
        <div className={(isMobile && props.themeListVariant !== "chooseThemes") ?
            classNames(styles.subtopic, styles.subtopicMobile)
            :
            styles.subtopic
        } style={{display: isMobile ? "block" : 'flex', opacity: props.subtopic.is_locked ? 0.5 : 1}}>
            <div
                className={(isMobile && props.themeListVariant !== "chooseThemes") ?
                    classNames(styles.optionTheme, styles.optionThemeMobile)
                    :
                    styles.optionTheme}
                style={{cursor: props.themeListVariant === "chooseThemes" ? props.subtopic.is_locked ? "default" : "pointer" : "text"}}
                onClick={props.themeListVariant === "chooseThemes" && !props.subtopic.is_locked ? () => togglePickSubtopic(props.subtopic.id) : () => {}}
            >
                {props.themeListVariant === "chooseThemes" && <CustomCheckbox isChecked={set.has(props.subtopic.id)}/>}
                <p className={(isMobile && props.themeListVariant !== "chooseThemes") ?
                    classNames(styles.subtopicP, styles.subtopicPMobile)
                    :
                    styles.subtopicP}
                >
                    {props.subtopic.value}
                </p>
            </div>
            {props.themeListVariant !== "chooseThemes" && <div className={styles.listControls}>
                <ProgressBar done={props.subtopic.done} total={props.subtopic.total}/>
                <ControlLink id={props.subtopic.id} variant={props.themeListVariant === 'setTasks' ? props.subtopic.done === 0 ?
                        "Добавить задания"
                        :
                        "Изменить"
                :
                    props.subtopic.done === 0 ? "Начать экспертизу" :
                        props.subtopic.done === props.subtopic.total && props.subtopic.total !== undefined ? "Экспертиза завершена" : "Продолжить экспертизу"
                }/>
            </div>}
        </div>
    );
};

export default Subtopic;