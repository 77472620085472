import {IMailEditorData, MailEditorAction, MailEditorActionTypes} from "../../types/reducers/mailEditor";

const initialState: IMailEditorData = {
    mailListName: '',
    text: '',
    date: '',
    isNowChecked: false,
    isEditorOpened: false,
}

export const mailEditorReducer = (state=initialState, action: MailEditorAction): IMailEditorData => {
    switch (action.type) {
        case MailEditorActionTypes.SET_TEXT:
            return {...state, text: action.payload}
        case MailEditorActionTypes.SET_MAIL_LIST_NAME:
            return {...state, mailListName: action.payload}
        case MailEditorActionTypes.SET_DATE:
            return {...state, date: action.payload}
        case MailEditorActionTypes.TOGGLE_IS_NOW_CHECKED:
            return {...state, isNowChecked: !state.isNowChecked}
        case MailEditorActionTypes.SET_DEFAULT_DATA:
            return {...initialState, isEditorOpened: state.isEditorOpened}
        case MailEditorActionTypes.SET_FULL_MAIL_LIST:
            return action.payload
        case MailEditorActionTypes.CLOSE_EDITOR:
            return {...state, isEditorOpened: false}
        case MailEditorActionTypes.OPEN_EDITOR:
            return {...state, isEditorOpened: true}
        default:
            return state
    }
}