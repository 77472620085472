import React, { useEffect, useState } from "react";
// @ts-ignore
import styles from "../../../css/Editor.module.css";
import { useDispatch } from "react-redux";
import { ExerciseActionTypes } from "../../../types/reducers/exercise";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { MarkDescriptionActionTypes } from "../../../types/reducers/markDescription";
import { getTexts } from "../../../http/admin/wizardRequest";

const QualityEditor = () => {
  const dispatch = useDispatch();

  const currentGrade = useTypedSelector(
    (state) => state.exercise.quality_assessment
  );
  const [hoveredGrade, setHoveredGrade] = useState(0);
  const [grades, setGrades] = useState<
    { grade: number; description: string }[]
  >([]);

  const checkOption = (id: number) => {
    dispatch({ type: ExerciseActionTypes.SET_QUALITY_ASSESSMENT, payload: id });
  };

  useEffect(() => {
    getTexts("quality_texts").then((data) => {
      // @ts-ignore
      setGrades(data);
    });
  }, []);

  return (
    <div className={styles.taskGradeEditor}>
      {grades.map((grade, index) => {
        return (
          <svg
            key={index}
            width="30"
            height="28"
            viewBox="0 0 30 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={
              Number(currentGrade) >= index + 1 || hoveredGrade >= index + 1
                ? styles.hoveredStar
                : styles.star
            }
            onMouseOver={() => {
              setHoveredGrade(grade.grade);
              dispatch({
                type: MarkDescriptionActionTypes.SET_QUALITY_DESCRIPTION,
                payload: grade.description,
              });
            }}
            onMouseLeave={() => {
              setHoveredGrade(0);
              dispatch({
                type: MarkDescriptionActionTypes.SET_QUALITY_DESCRIPTION,
                payload: "",
              });
            }}
            onClick={() => {
              checkOption(grade.grade);
              dispatch({
                type: MarkDescriptionActionTypes.SET_PICKED_QUALITY_DESCRIPTION,
                payload: grade.description,
              });
            }}
          >
            <path
              d="M14.9974 1.66602L19.1174 10.0127L28.3307 11.3593L21.6641 17.8527L23.2374 27.026L14.9974 22.6927L6.7574 27.026L8.33073 17.8527L1.66406 11.3593L10.8774 10.0127L14.9974 1.66602Z"
              strokeWidth="1.6"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      })}
    </div>
  );
};

export default QualityEditor;
