import React from 'react';

const BigReadyIcon = () => {
    return (
        <svg width="75" height="75" viewBox="0 0 75 75" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="37.5" cy="37.5" r="37.5" fill="#02D589"/>
            <path d="M17.7656 36.0701L33.0617 49.8348L57.2393 25.6562" stroke="white" strokeWidth="8.19737" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};

export default BigReadyIcon;