import React, {ChangeEvent} from 'react';
// @ts-ignore
import styles from "../../../../css/admin/Script.module.css";
import VarContainer from "../../mailLists/VarContainer";
import {useTypedSelector} from "../../../../hooks/useTypedSelector";

export interface WizardInputProps {
    onChange: (e: any) => void
    value: string
    placeholder: string
    label?: string
    width?: number | '100%'
    height?: number
    isTextarea?: boolean
    margin?: string
    isWithVars?: boolean
}

const WizardInput = (props: WizardInputProps) => {
    const variables = useTypedSelector(state => state.wizard.variables)

    const {
        onChange,
        value,
        placeholder,
        label,
        width = '100%',
        height = 44,
        isTextarea = false,
        isWithVars = false,
        margin
    } = props

    return (
        <div className={styles.wizardInputContainer} style={{ margin }}>
            {label && <span className={styles.wizardInputLabel}>{label}</span>}
            {!isTextarea && <input
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                className={styles.wizardInput}
                style={{width, height}}
            />}

            {isTextarea && <div className={styles.textWithVars}>
                <textarea
                    value={value}
                    onChange={onChange}
                    placeholder={placeholder}
                    className={styles.wizardInput}
                    style={{width: isWithVars ? 550 : width, height}}
                />
                {isWithVars && <VarContainer height={height} variables={variables}/>}
            </div>}
        </div>
    );
};

export default WizardInput;