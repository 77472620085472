import React, {useEffect, useState} from 'react';
// @ts-ignore
import styles from "../../../css/admin/MailPanel.module.css";
import Filters from "./Filters";
import CustomCheckbox from "../../common/CustomCheckbox";
import Button from "../../common/Button";
import {getRecipientsByFilter} from "../../../http/admin/mailListsRequests";
import {IRecipient, RecipientStatuses} from "../../../types/admin/http/mailListsRequests";
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import {isAllRecipientsChecked, isRecipientIn} from "../../../utils/isRecipientIn";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import Preloader from "../../common/Preloader";
import {addOrDeleteAllRecipients, addOrDeleteRecipient} from "../../../utils/removeOrAddRecipients";
import {isCustomMailSent} from "../../../utils/dateFuncs";
import {MailEditorActionTypes} from "../../../types/reducers/mailEditor";
import {RecipientsActionTypes} from "../../../types/reducers/recipients";

const RecipientsBlock = (props: {
    mailListId: number,
    date: string | undefined,
    isHidden: boolean,
    mailListText: string,
    mailListName: string
}) => {
    const [currentFilter, setCurrentFilter] = useState('all')
    const [recipients, setRecipients] = useState<IRecipient[]>([])
    const [isRecipientsLoad, setIsRecipientsLoad] = useState(false)
    const [currentRecipients, setCurrentRecipients] = useState<IRecipient[]>([])
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        setIsRecipientsLoad(false)
        if (currentFilter && !props.isHidden) {
            getRecipientsByFilter(currentFilter, props.mailListId).then(data => {
                console.log(data)
                setRecipients(data.message_statuses)
                setIsRecipientsLoad(true)
            })
        }
    }, [currentFilter, props.isHidden])

    const createMessageForPickedRecipients = () => {
        dispatch({type: MailEditorActionTypes.SET_FULL_MAIL_LIST, payload: {
            mailListName: props.mailListName,
            text: props.mailListText,
            date: props.date ? props.date : '',
            isNowChecked: !props.date
        }})
        dispatch({type: RecipientsActionTypes.ADD_RECIPIENTS, payload: currentRecipients})
        dispatch({type: MailEditorActionTypes.OPEN_EDITOR})
        navigate('/admin/mail_lists/custom')
        document.getElementById('admin-panel')?.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    return (
        <div className={styles.recipientsBlock}>
            <div className={styles.recipientBlockHeader}>
                <h2>Получатели</h2>
            </div>

          {isCustomMailSent(props.date, false) && <Filters currentFilter={currentFilter} setCurrentFilter={setCurrentFilter}/>}

            {currentFilter === "" && <div className={styles.startContainer}>
                <p>Выберите категорию</p>
            </div>}

            {currentFilter !== "" && <div>
                <div className={styles.filterAndLegend}>
                    {recipients.length !== 0 && <div className={styles.subfilter} onClick={() => addOrDeleteAllRecipients(recipients, currentRecipients, setCurrentRecipients)}>
                        <CustomCheckbox isChecked={isAllRecipientsChecked(recipients, currentRecipients)} isMinimize={true}/>
                        <span>Выбрать всех</span>
                    </div>}

                    {isCustomMailSent(props.date) && <div className={styles.legend}>
                        <div className={styles.legendEl}>
                            <div className={styles.circle} style={{background: "#FFDDDD"}}/>
                            <span>Не доставлено</span>
                        </div>
                        <div className={styles.legendEl}>
                            <div className={styles.circle} style={{background: "rgba(255, 213, 86, 1)"}}/>
                            <span>Доставлено</span>
                        </div>
                        <div className={styles.legendEl}>
                            <div className={styles.circle} style={{background: "#B4F9E0"}}/>
                            <span>Посмотрено</span>
                        </div>
                    </div>}
                </div>

                <div className={styles.recipientsContainer}>
                    <div className={styles.recipientInnerContainer}>
                        {isRecipientsLoad ? <>
                            {recipients.length !== 0 && recipients.map(recipient => {
                                return <div
                                    onClick={() => addOrDeleteRecipient(recipient, currentRecipients, setCurrentRecipients)}
                                    key={recipient.id}
                                    className={styles.recipientEl}
                                    style={{background: recipient.status === RecipientStatuses.opened ? "rgba(180, 249, 224, 1)" :
                                            recipient.status === RecipientStatuses.delivered ? "#FFF6DB" : "#FFDDDD"
                                    }}
                                >
                                    <span>{recipient.email}</span>
                                    <CustomCheckbox isChecked={isRecipientIn(recipient.id, currentRecipients)} isMinimize={true}/>
                                </div>
                            })}
                            {recipients.length === 0 && <p style={{textAlign: 'center', width: '100%'}}>Нет получателей...</p>}
                        </> : <Preloader />}
                    </div>
                </div>
            </div>}

            <Button
                width={"645px"}
                height={"45px"}
                fontSize={"16px"}
                variant={"primary"}
                margin={"30px 0 0 500px"}
                submit={createMessageForPickedRecipients}
                disabled={currentRecipients.length === 0}
            >
                Отправить выбранным пользователям новое сообщение по текущему шаблону
            </Button>
        </div>
    );
};

export default RecipientsBlock;