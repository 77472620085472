import React from 'react';
// @ts-ignore
import styles from "../../../css/Player.module.css";

const ProgressBar = (props: {done: number | undefined, total: number | undefined}) => {
    return (
        <div className={styles.progressBar}>
            <span className={styles.progress}>{props.done} из {props.total} заданий</span>
            <div className={styles.outerProgressBar}>
                <div className={styles.innerProgressBar} style={{
                    width: props.done === 0 ? "0" : (Number(props.done) / Number(props.total) * 100).toString() + "%",
                    background: props.done === props.total && props.total !== 0 && props.total !== undefined ? "#02D589" : "#323232"
                }}/>
            </div>
        </div>
    );
};

export default ProgressBar;