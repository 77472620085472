import React from 'react';
import {PDFDownloadLink} from "@react-pdf/renderer";
import PDFCertificate from "./PDFCertificate";
import button from "../../../../../common/Button";
// @ts-ignore
import commonStyles from "../../../../../../css/Common.module.css";

const buttonStyles = {
    width: 220,
    height: 50,
    fontSize: 17,
    textDecoration: 'none',
    margin: '0',
}

const DownloadCertificateButton = (props: {name: string, author_rating: number, quality_task_counter: number, cluster: string}) => {
    return (
        <div style={{marginLeft: 880}}>
            <PDFDownloadLink
                document={<PDFCertificate
                    name={props.name ? props.name : 'Имя'}
                    place={props.author_rating ? props.author_rating : 0}
                    qualityCounter={props.quality_task_counter}
                    cluster={props.cluster}
                />}
                fileName={"certificate"}
            >
                {({loading}) => (loading ?
                    <button className={commonStyles.button} style={buttonStyles}>
                        Подождите...
                    </button>
                    :
                    <button className={commonStyles.button} style={buttonStyles}>
                        Скачать сертификат
                    </button>)}
            </PDFDownloadLink>
        </div>
    );
};

export default DownloadCertificateButton;