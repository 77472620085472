import {IThemeList} from "../types/components/themes";

export const isExpertiseComplete = (themes: IThemeList) => {
    let isComplete = true

    themes.forEach(theme => {
        theme.values.forEach(subtopic => {
            if (subtopic.done !== subtopic.total) {
                isComplete = false
            }
        })
    })

    return isComplete
}