import {initialThemeValue, IThemeList} from "../../types/components/themes";
import {ThemeListAction, ThemeListActionTypes} from "../../types/reducers/themeList";

const initialState: IThemeList = initialThemeValue

export const themeListReducer = (state=initialState, action: ThemeListAction): IThemeList => {
    switch (action.type) {
        case ThemeListActionTypes.SET_THEME_LIST:
            return action.payload
        default:
            return state
    }
}