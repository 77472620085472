import React, {useState} from 'react';
// @ts-ignore
import styles from "../../../css/Player.module.css"
import {IThemeListComponent} from "../../../types/components/themes";
import Theme from "./Theme";
import Button from "../../common/Button";
import {ModalActionTypes} from "../../../types/reducers/modal";
import {useDispatch} from "react-redux";

const ThemeList = (props: IThemeListComponent) => {
    const isMobile = useState(document.documentElement.clientWidth <= 900)[0]
    const dispatch = useDispatch()

    const saveTopics = () => {
        dispatch({type: ModalActionTypes.SET_MODAL_STATUS, payload: 'save'})
    }

    return (
        <div className={styles.themeList} style={{
            width:
                isMobile ? 320 : props.themeListVariant === "chooseThemes" ? "44.5vw" : "62.8vw"
        }}>
            {props.themes.map((theme, index) => {
                return <Theme key={index} themeListVariant={props.themeListVariant} theme={theme} themes={props.themes}/>
            })}
            {props.themeListVariant === "chooseThemes" && <Button
                width={isMobile ? "181px" : "12vw"}
                height={isMobile ? "54px" : "3vw"}
                fontSize={isMobile ? "18px" : "1.2vw"}
                variant="primary"
                margin={isMobile ? "40px auto 0 auto" : "2vw auto 0 auto"}
                submit={saveTopics}
                display={isMobile ? "block" : "none"}
            >
                Сохранить
            </Button>}
        </div>
    );
};

export default ThemeList;