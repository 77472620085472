import {IUser, UserAction, UserActionTypes} from "../../types/reducers/user";

const initialState: IUser = {
    isAuth: null,
    id: null,
    name: null,
    role: 3
}

export const userReducer = (state=initialState, action: UserAction): IUser => {
    switch (action.type) {
        case UserActionTypes.SET_USER:
            return action.payload
        case UserActionTypes.UNSET_USER:
            return {
                isAuth: false,
                id: null,
                name: null,
                role: 3
            }
        default:
            return state
    }
}