import {IAppInfo, initialAppInfoState} from "../../types/entities/appInfo";
import {AppAction, AppActionTypes} from "../../types/reducers/app";

const initialState: IAppInfo = initialAppInfoState

export const appReducer = (state=initialState, action: AppAction): IAppInfo => {
    switch (action.type) {
        case AppActionTypes.SET_APP_INFO:
            return action.payload
        default:
            return state
    }
}