import {IRecipient} from "../admin/http/mailListsRequests";

enum RecipientsActionTypes {
    REMOVE_RECIPIENT = 'REMOVE_RECIPIENT',
    CLEAR_RECIPIENTS = 'CLEAR_RECIPIENTS',
    REMOVE_RECIPIENTS = 'REMOVE_RECIPIENTS',
    ADD_RECIPIENTS = 'ADD_RECIPIENTS'
}

interface IAddRecipientsAction {
    type: RecipientsActionTypes.ADD_RECIPIENTS
    payload: IRecipient[]
}

interface IRemoveRecipientAction {
    type: RecipientsActionTypes.REMOVE_RECIPIENT
    payload: number
}

interface IRemoveRecipientsAction {
    type: RecipientsActionTypes.REMOVE_RECIPIENTS
    payload: IRecipient[]
}

interface IClearRecipients {
    type: RecipientsActionTypes.CLEAR_RECIPIENTS
}

type RecipientsAction = IRemoveRecipientAction | IClearRecipients | IRemoveRecipientsAction | IAddRecipientsAction

export {RecipientsActionTypes}
export type {RecipientsAction}