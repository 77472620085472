import {IStatus} from "../entities/status";

export enum StatusActionTypes {
    SET_STATUS = "SET_STATUS",
    SET_NEXT_WIZARD_STAGE = 'SET_NEXT_WIZARD_STAGE'
}

interface ISetStatusAction {
    type: StatusActionTypes.SET_STATUS
    payload: IStatus
}

interface ISetNextWizardStageAction {
    type: StatusActionTypes.SET_NEXT_WIZARD_STAGE
}

export type StatusAction = ISetStatusAction | ISetNextWizardStageAction