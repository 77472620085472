import React from 'react';

const EditIcon = (props: {isHovered: boolean}) => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" style={{stroke: props.isHovered ? "#FFD556" : "#323232", transition: "stroke 0.3s"}}>
            <path d="M7.57872 2.90273H2.87964C2.38113 2.90273 1.90303 3.10077 1.55053 3.45327C1.19803 3.80577 1 4.28386 1 4.78237V15.1204C1 15.6189 1.19803 16.097 1.55053 16.4495C1.90303 16.802 2.38113 17 2.87964 17H13.2176C13.7161 17 14.1942 16.802 14.5467 16.4495C14.8992 16.097 15.0973 15.6189 15.0973 15.1204V10.4213M13.7684 1.57383C13.9418 1.39431 14.1492 1.25111 14.3785 1.1526C14.6078 1.05409 14.8545 1.00224 15.104 1.00007C15.3536 0.997902 15.6011 1.04546 15.8321 1.13997C16.0631 1.23448 16.273 1.37405 16.4495 1.55053C16.6259 1.72702 16.7655 1.93688 16.86 2.16788C16.9545 2.39888 17.0021 2.64639 16.9999 2.89597C16.9978 3.14555 16.9459 3.39219 16.8474 3.62151C16.7489 3.85084 16.6057 4.05824 16.4262 4.23164L8.35689 12.3009H5.69909V9.64311L13.7684 1.57383Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};

export default EditIcon;