import React from 'react';

const CustomCheckbox = (props: {isChecked: boolean, isMinimize?: boolean}) => {
    return (
        <>
            {props.isChecked ? <svg width={props.isMinimize ? "16" : "22"} height={props.isMinimize ? "16" : "22"} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="20" height="20" transform="translate(1 1)" fill="#323232"/>
                <rect x="1" y="1" width="20" height="20" rx="2" stroke="#323232" strokeWidth="2"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M17.707 6.32208C17.8946 6.52837 18 6.80812 18 7.09981C18 7.39151 17.8946 7.67126 17.707 7.87754L9.6998 16.6779C9.51211 16.8841 9.25757 17 8.99217 17C8.72677 17 8.47223 16.8841 8.28454 16.6779L4.28096 12.2777C4.09864 12.0703 3.99776 11.7924 4.00004 11.504C4.00232 11.2155 4.10758 10.9396 4.29316 10.7357C4.47873 10.5317 4.72977 10.416 4.9922 10.4135C5.25463 10.411 5.50746 10.5219 5.69623 10.7223L8.99217 14.3447L16.2917 6.32208C16.4794 6.11585 16.7339 6 16.9993 6C17.2647 6 17.5193 6.11585 17.707 6.32208Z" fill="white"/>
            </svg>
                :
                <svg width={props.isMinimize ? "16" : "22"} height={props.isMinimize ? "16" : "22"} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="1" y="1" width="20" height="20" rx="2" stroke="#323232" strokeWidth="2"/>
                </svg>
            }
        </>
    );
};

export default CustomCheckbox;