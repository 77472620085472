interface IDescriptionMetaData {
    isPicked: boolean
    description: string
    picked_description: string
}

export interface IDescriptionData {
    actuality: IDescriptionMetaData
    quality: IDescriptionMetaData
}

enum MarkDescriptionActionTypes {
    SET_PICKED_ACTUALITY_DESCRIPTION = 'SET_PICKED_ACTUALITY_DESCRIPTION',
    SET_PICKED_QUALITY_DESCRIPTION = 'SET_PICKED_QUALITY_DESCRIPTION',
    SET_ACTUALITY_DESCRIPTION = 'SET_ACTUALITY_DESCRIPTION',
    SET_QUALITY_DESCRIPTION = 'SET_QUALITY_DESCRIPTION',
}

interface ISetActualityDescriptionAction {
    type: MarkDescriptionActionTypes.SET_ACTUALITY_DESCRIPTION
    payload: string
}

interface ISetQualityDescriptionAction {
    type: MarkDescriptionActionTypes.SET_QUALITY_DESCRIPTION
    payload: string
}

interface ISetPickedActualityDescriptionAction {
    type: MarkDescriptionActionTypes.SET_PICKED_ACTUALITY_DESCRIPTION
    payload: string
}

interface ISetPickedQualityDescriptionAction {
    type: MarkDescriptionActionTypes.SET_PICKED_QUALITY_DESCRIPTION
    payload: string
}

type MarkDescriptionAction =
    ISetActualityDescriptionAction |
    ISetQualityDescriptionAction |
    ISetPickedActualityDescriptionAction |
    ISetPickedQualityDescriptionAction

export {MarkDescriptionActionTypes}
export type {MarkDescriptionAction}