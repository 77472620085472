import React, {useState} from 'react';
import {ISelectOptionsProps} from "../../types/components/common";

const SelectOption = ({id, setCurrentOption, setValue, setIsOpened, i, index, value}: ISelectOptionsProps) => {
    const [isHovered, setIsHovered] = useState(false)

    return (
        <div
            className="option-container"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={() => {
                setCurrentOption(i)
                setValue(index, id)
                setIsOpened(false)
            }}
        >
            <p className="option" style={{
                maxWidth: isHovered ? 1500 : "100%",
                background: isHovered ? "#323232" : "white",
                color: isHovered ? "white" : "#323232",
            }}>
                {value}
            </p>
        </div>
    );
};

export default SelectOption;