import React, {useEffect, useState} from 'react';
import StepNav from "./StepNav";
// @ts-ignore
import styles from "../../css/Player.module.css";
import InfoBlock from "./InfoBlock/InfoBlock";
import ThemeList from "./themeList/ThemeList";
import Header from "../common/Header/Header";
import Footer from "../common/Footer/Footer";
import {getPickedThemes} from "../../http/playerRequests";
import {useTypedSelector} from "../../hooks/useTypedSelector";
import AnotherButton from "../common/AnotherButton";
import Button from "../common/Button";
import {isClientDemo, setNextStage, unsetCompleteCounter} from "../../utils/demo/demo";
import {getTexts} from "../../http/admin/wizardRequest";

const SecondStep = () => {
    const [text, setText] = useState('Загрузка...')
    const [themes, setThemes] = useState([
        {
            "name": "Загрузка...",
            "values": [
                {
                    "value": "Загрузка...",
                    "id": 1,
                    "subtopic_name_id": 1
                },
                {
                    "value": "Загрузка...",
                    "id": 2,
                    "subtopic_name_id": 1
                },
                {
                    "value": "Загрузка...",
                    "id": 3,
                    "subtopic_name_id": 1
                }
            ]
        },
    ])

    const demoButtonHandler = () => {
        unsetCompleteCounter()
        setNextStage()
        window.location.reload()
    }

    useEffect(() => {
        getTexts('second_stage_instruction').then(data => {
            // @ts-ignore
            setText(data)
        })
        getPickedThemes().then(data => {
            setThemes(data)
        })
    }, [])

    return (
        <>
            <Header />
            <div className={styles.playerWindow}>
                <div>
                    <StepNav steps={3} currentStep={2}/>
                    <h1 className={styles.stepHeader}>2 этап</h1>
                    <InfoBlock>
                        {text}
                    </InfoBlock>
                    <ThemeList themeListVariant="setTasks" themes={themes}/>
                    {isClientDemo() && <Button
                      width="240px"
                      height="50px"
                      fontSize="17px"
                      variant="primary"
                      margin="30px auto 0 auto"
                      submit={demoButtonHandler}
                    >
                        Сохранить и продолжить
                    </Button>}
                </div>
            </div>
            <Footer />
        </>
    );
};

export default SecondStep;