import {WizardStages} from "../types/entities/status";

export const getWizardStageOrderNumber = (stage: WizardStages) => {
    switch (stage) {
        case WizardStages.PROJECT_DESCRIPTION:
            return 1
        case WizardStages.REGISTER:
            return 2
        case WizardStages.FIRST_STEP:
            return 3
        case WizardStages.WAITING_FOR_SECOND_STEP:
            return 4
        case WizardStages.SECOND_STEP:
            return 5
        case WizardStages.THIRD_STEP:
            return 6
    }
}