import {ScriptCardProps} from "../../components/admin/wizard/ScriptPanel/ScriptCard";
import {$authHost} from "../index";
import {variables} from "../../types/admin/http/mailListsRequests";
import {
    FirstStepData,
    ProjectDescription,
    Register,
    SecondStepData,
    SubtopicWizardData, Texts,
    ThirdStepData,
    WaitingStage
} from "../../types/reducers/wizard";
import {timestempToDate} from "../../utils/dateFuncs";
import {isClientDemo} from "../../utils/demo/demo";
import {getDashboardDataDemo, getTextsDemo} from "../../utils/demo/mocks";

export const getScriptData = async (): Promise<ScriptCardProps[]> => {
    let response: { data: ScriptCardProps[] } = await $authHost.get(`wizard/script`)

    response.data = response.data.map(scriptCard => ({
        ...scriptCard,
        start_date: scriptCard.start_date ? timestempToDate(scriptCard.start_date, true) : undefined,
        end_date: scriptCard.end_date ? timestempToDate(scriptCard.end_date, true) : undefined
    }))

    return response.data
}

export const getWizardVariables = async (): Promise<variables> => {
    let response = await $authHost.get(`wizard/variables`)

    return response.data
}

export const getProjectDescriptionData = async (): Promise<ProjectDescription> => {
    let response = await $authHost.get(`wizard/project_description`)

    return response.data
}

export const setProjectDescriptionData = async (data: ProjectDescription) => {
    let response = await $authHost.post(`wizard/project_description`, data)
    return response.data
}

export const getRegistrationFieldsData = async (): Promise<Register> => {
    let response = await $authHost.get(`wizard/register`)

    return response.data
}

export const setRegistrationFieldsData = async (data: Register) => {
    let response = await $authHost.post(`wizard/register`, data)
    return response.data
}

export const getFirstStepWizardData = async (): Promise<FirstStepData> => {
    let response: { data: FirstStepData } = await $authHost.get(`wizard/first_step`)

    response.data = {
        ...response.data,
        start_date: timestempToDate(response.data.start_date, true),
    }

    return response.data
}

export const uploadTopicTemplate = async (data: any): Promise<SubtopicWizardData[]> => {
    const formData = new FormData();
    formData.append('file', new Blob([data], {
        type: ''
    }))

    let response: { data: SubtopicWizardData[] } = await $authHost.post(`wizard/topic_template`, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    })

    return response.data
}

export const uploadRulesFile = async (data: any) => {
    const formData = new FormData();
    formData.append('file', new Blob([data], {
        type: ''
    }))

    let response = await $authHost.post(`wizard/set_rules`, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    })

    return response.data
}

export const setFirstStepWizardData = async (data: FirstStepData) => {
    let response = await $authHost.post(`wizard/first_step`, {
        instruction: data.instruction,
        start_date: data.start_date,
        subtopic_limit: +data.subtopic_limit,
        max_picked_subtopics: +data.max_picked_subtopics
    })

    return response.data
}

export const getWaitingStageWizardData = async (): Promise<WaitingStage> => {
    let response = await $authHost.get(`wizard/waiting`)

    return response.data
}

export const setWaitingStageWizardData = async (data: WaitingStage) => {
    let response = await $authHost.post(`wizard/waiting`, data)
    return response.data
}

export const getSecondStepWizardData = async (): Promise<SecondStepData> => {
    let response: { data: SecondStepData } = await $authHost.get(`wizard/second_step`)

    response.data = {
        ...response.data,
        start_date: timestempToDate(response.data.start_date, true),
    }

    return response.data
}

export const setSecondStepWizardData = async (data: SecondStepData) => {
    let response = await $authHost.post(`wizard/second_step`, {
        ...data,
        option_counter: +data.option_counter,
        task_counter: +data.task_counter,
    })
    return response.data
}

export const getThirdStepWizardData = async (): Promise<ThirdStepData> => {
    let response: { data: ThirdStepData } = await $authHost.get(`wizard/third_step`)

    response.data = {
        ...response.data,
        start_date: timestempToDate(response.data.start_date, true),
        end_date: timestempToDate(response.data.end_date, true),
    }

    return response.data
}

export const setThirdStepWizardData = async ({data, qualityCount, actualityCount}: {
    data: ThirdStepData,
    qualityCount: number,
    actualityCount: number
}) => {
    let response = await $authHost.post(`wizard/third_step`, {
        ...data,
        task_limit_for_expert: +data.task_limit_for_expert,
        quality: data.quality.slice(0, qualityCount),
        originality: data.originality.slice(0, actualityCount)
    })
    return response.data
}

export const getTexts = async (stage: string): Promise<Texts> => {
    let response = isClientDemo() ? getTextsDemo(stage) : await $authHost.get(`services/get_stage_text/${stage}`)
    return response.data
}