import {ISubtopic, IUser} from "../types/admin/http/userRequests";
import {IUserSortType} from "../types/admin/entities/userSortTypes";
import {ISubtopicSortType} from "../types/admin/entities/subtopicSortTypes";

const getSortFunc = (sortType: ISubtopicSortType | IUserSortType) => {
    let parseIfDate = sortType.sortType === 'registration_date' ? Date.parse : (a: number) => a
    let sortFunc: (a: IUser | ISubtopic, b: IUser | ISubtopic) => number

    if (sortType.isDescending) {
        sortFunc = ((a: IUser | ISubtopic, b: IUser | ISubtopic) => {
            // @ts-ignore
            return parseIfDate(b[sortType.sortType]) - parseIfDate(a[sortType.sortType])
        })
    } else {
        sortFunc = ((a: IUser | ISubtopic, b: IUser | ISubtopic) => {
            // @ts-ignore
            return parseIfDate(a[sortType.sortType]) - parseIfDate(b[sortType.sortType])
        })
    }

    return sortFunc
}

export const userSort = (elements: IUser[], sortType: IUserSortType): IUser[] => {
    let sortFunc = getSortFunc(sortType)
    return elements.sort(sortFunc)
}

export const subtopicSort = (elements: ISubtopic[], sortType: ISubtopicSortType): ISubtopic[] => {
    let sortFunc = getSortFunc(sortType)
    return elements.sort(sortFunc)
}