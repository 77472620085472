import React, {ReactElement, useEffect, useMemo} from 'react';
import {createPortal} from "react-dom";
// @ts-ignore
import commonStyles from "../../../css/admin/Admin.module.css";

const modalRootElement = document.querySelector('#modal')

const Modal = (props: {children: ReactElement<any, any>}) => {

    const element = useMemo(() => {
        const element = document.createElement('div')
        return element
    }, [])

    useEffect(() => {
        if (modalRootElement)
            modalRootElement.appendChild(element)

        return () => {
            if (modalRootElement)
                modalRootElement.removeChild(element)
        }
    }, [])

    return createPortal(
        <div className={commonStyles.modalBackground}>
            {props.children}
        </div>,
        element
    );
};

export default Modal;