import {Paths} from "../types/entities/route";
import AuthPage from "../components/auth/AuthPage";
import InstructionPage from "../components/infoPages/InstructionPage";
import RegistrationPage from "../components/auth/RegistrationPage/RegistrationPage";
import RestorePassword from "../components/auth/RestorePassword";
import RestorePasswordInstruction from "../components/infoPages/RestorePasswordInstruction";

export const publicRoutes: Paths = [
    {
        path: '/auth',
        element: AuthPage
    },

    {
        path: '/instruction',
        element: InstructionPage
    },

    {
        path: '/password_instruction',
        element: RestorePasswordInstruction
    },

    {
        path: '/restore_password/:token',
        element: RestorePassword
    },

    {
        path: '/registration/:token',
        element: RegistrationPage
    },
]