import React from 'react';
// @ts-ignore
import styles from "../../../css/admin/MailPanel.module.css";
import CalendarIcon from "../../icons/admin/CalendarIcon";

const InfoBlock = (props: {date: string | undefined, status: string | undefined}) => {
    return (
        <div className={styles.infoBlock}>
            <div className={styles.date}>
                <CalendarIcon />
                <span>до {props.date}</span>
            </div>
            <div className={styles.status}>
                <div
                    className={styles.circle}
                    style={{
                        background: props.status === "Не начался" ? "#FFC000" : props.status === "Идёт" ? "green" : "red"
                }}/>
                <span>{props.status}</span>
            </div>
        </div>
    );
};

export default InfoBlock;