import React from 'react';

const PasswordArrowIcon = () => {
    return (
        <svg className="passwordArrow" width="1vw" height="0.83vw" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg" style={{margin: '0 20px 0 20px'}}>
            <path d="M19 8L0.999999 8M12 1L19 8L12 1ZM19 8L12 15L19 8Z" stroke="#323232" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};

export default PasswordArrowIcon;