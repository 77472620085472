import React, {useEffect, useState} from 'react';
import StepNav from "./StepNav";
// @ts-ignore
import styles from "../../css/Player.module.css";
import InfoBlock from "./InfoBlock/InfoBlock";
import ThemeList from "./themeList/ThemeList";
import Header from "../common/Header/Header";
import Footer from "../common/Footer/Footer";
import {getExpertiseThemes} from "../../http/playerRequests";
import {initialThemeValue} from "../../types/components/themes";
import {useTypedSelector} from "../../hooks/useTypedSelector";
import {isExpertiseComplete} from "../../utils/isExpertiseComplete";
import {useNavigate} from "react-router-dom";
import {isClientDemo, setExpertiseComplete} from "../../utils/demo/demo";
import Button from "../common/Button";
import {getTexts} from "../../http/admin/wizardRequest";

const ThirdStep = () => {
    const [text, setText] = useState('Загрузка...')
    const [themes, setThemes] = useState(initialThemeValue)
    const navigate = useNavigate()
    const status = useTypedSelector(state => state.status)
    const userId = useTypedSelector(state => state.user.id)

    useEffect(() => {
        getTexts('third_stage_instruction').then(data => {
            // @ts-ignore
            setText(data)
        })
        getExpertiseThemes().then(data => {
            if (isExpertiseComplete(data)) {
                if (isClientDemo()) {
                    navigate(`/dashboard/${userId}`)
                } else {
                    const currentDate = new Date()
                    const deadlineDate = new Date(status.third_stage_deadline_ts * 1000)

                    if (currentDate < deadlineDate) {
                        navigate('/expertise_end')
                    } else {
                        if (status.dashboard_allowed_by_admin) {
                            navigate(`/dashboard/${userId}`)
                        } else {
                            navigate('/expertise_end')
                        }
                    }
                }
            } else {
                setThemes(data)
            }
        })
    }, [])

    const demoButtonHandler = () => {
        setExpertiseComplete()
        window.location.reload()
    }

    return (
        <>
            <Header />
            <div className={styles.playerWindow}>
                <div>
                    <StepNav steps={3} currentStep={3}/>
                    <h1 className={styles.stepHeader}>3 этап</h1>
                    <InfoBlock>
                        {text}
                    </InfoBlock>
                    <ThemeList themeListVariant="doTasks" themes={themes}/>

                    {isClientDemo() && <Button
                      width="240px"
                      height="50px"
                      fontSize="17px"
                      variant="primary"
                      margin="30px auto 0 auto"
                      submit={demoButtonHandler}
                    >
                        Сохранить экспертизу
                    </Button>}
                </div>
            </div>
            <Footer />
        </>
    );
};

export default ThirdStep;