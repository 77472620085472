import {IRecipient} from "../types/admin/http/mailListsRequests";

export const isRecipientIn = (id: number, recipients: IRecipient[]) => {
    for (let i = 0; i < recipients.length; i++) {
        if (recipients[i].id === id) {
            return true
        }
    }

    return false
}

export const isAllRecipientsChecked = (recipients: IRecipient[], commonRecipients: IRecipient[]) => {
    let isAllChecked = true

    recipients.forEach(recipient => {
        if (!isRecipientIn(recipient.id, commonRecipients)) {
            isAllChecked = false
        }
    })

    return isAllChecked
}