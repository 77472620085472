import React, {useState} from 'react';
// @ts-ignore
import styles from "../../../css/PersonalArea.module.css";
import QuitIcon from "../../icons/QuitIcon";
import {ModalActionTypes} from "../../../types/reducers/modal";
import {useDispatch} from "react-redux";

const HeaderControls = () => {
    const [isQuitHovered, setIsQuitHovered] = useState(false)
    const dispatch = useDispatch()

    const openLogoutModal = () => {
        dispatch({type: ModalActionTypes.SET_MODAL_STATUS, payload: 'logout'})
    }

    return (
        <>
            <div
                className={styles.quitControl}
                onMouseEnter={() => setIsQuitHovered(true)}
                onMouseLeave={() => setIsQuitHovered(false)}
                onClick={openLogoutModal}
            >
                <QuitIcon isHovered={isQuitHovered}/>
                <p style={{color: isQuitHovered ? "#FFC000" : "#323232"}}>Выйти</p>
            </div>
        </>
    );
};

export default HeaderControls;