import React, {MouseEvent, MouseEventHandler, useEffect, useState} from 'react';
import ErrorIcon from "../icons/ErrorIcon";
import EyeIcon from "../icons/EyeIcon";
import GeneratePassword from "./GeneratePassword";
import {checkValidation, generateComputedPassword} from "./RegistrationPage/passwordFunctions";
import {useNavigate, useParams} from "react-router-dom";
import {resetPassword} from "../../http/authRequests";

const RestorePassword = () => {
    const token = useParams().token
    const navigate = useNavigate()
    const [password, setPassword] = useState('')
    const [repeatedPassword, setRepeatedPassword] = useState('')
    const [isFirstHidden, setIsFirstHidden] = useState(true)
    const [isSecondHidden, setIsSecondHidden] = useState(true)
    const [error, setError] = useState('')

    const [checks, setChecks] = useState({
        length: false,
        letterCase: false,
        hasDigits: false,
        hasSymbol: false
    })

    const generatePassword = () => {
        let finalPassword = generateComputedPassword()

        setPassword(finalPassword)
        setRepeatedPassword(finalPassword)
        setChecks({
            length: true,
            letterCase: true,
            hasDigits: true,
            hasSymbol: true
        })
    }

    const submitHandler = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()

        if (password !== repeatedPassword) {
            setError('Неверно введен повторный пароль')
        } else if (!checks.length || !checks.letterCase || !checks.hasDigits || !checks.hasSymbol) {
            setError('Пароль не соответствует всем требованиям')
        } else {
            resetPassword(password, token).then((data) => {
                alert('Пароль успешно изменен')
                navigate('/auth')
            }).catch(() => {
                alert("Что-то пошло не так... Кажется, ссылка не действительна")
            })
        }
    }

    return (
        <div className="form-wrapper resetPasswordForm" style={{opacity: 1, border: 'none'}}>
            <GeneratePassword checks={checks} generatePassword={generatePassword} forRestore={true}/>
            <form className="login-form">
                <div className="input password">
                    <input
                        className="input-password"
                        placeholder="Новый пароль"
                        type={isFirstHidden ? "password" : "text"}
                        value={password}
                        onChange={(e) => {
                            setPassword(e.target.value)
                            checkValidation(e.target.value, setChecks)
                        }}
                        id="password"
                    />
                    <div className="eye-container" onMouseDown={() => setIsFirstHidden(false)} onMouseUp={() => setIsFirstHidden(true)}>
                        <EyeIcon />
                    </div>
                </div>
                <div className="error_container">
                    {error && (
                        <label className="error" htmlFor="email"><ErrorIcon /><span>{error}</span></label>
                    )}
                </div>
                <div className="input password">
                    <input
                        className="input-password"
                        placeholder="Повторите пароль"
                        type={isSecondHidden ? "password" : "text"}
                        value={repeatedPassword}
                        onChange={(e) => setRepeatedPassword(e.target.value)}
                        id="repeat_password"
                    />
                    <div className="eye-container" onMouseDown={() => setIsSecondHidden(false)} onMouseUp={() => setIsSecondHidden(true)}>
                        <EyeIcon />
                    </div>
                </div>
                <button className="submitButton signInButton resetButton" type="submit" onClick={submitHandler}>
                    Подтвердить
                </button>
            </form>
        </div>
    );
};

export default RestorePassword;