import React, {FC, MouseEvent, useEffect, useState} from 'react';
import Modal from "../common/Modal";
// @ts-ignore
import styles from "../../../css/admin/UserPanel.module.css";
import {getFields} from "../../../http/authRequests";
import Select from "../../common/Select";
import Button from "../../common/Button";
import {createUser} from "../../../http/admin/userRequests";
import {IUserPopupsField} from "../../../types/admin/http/userRequests";

interface AddUserModalProps {
    onCancel: () => void
    reloadData: () => void
}

export const AddUserModal: FC<AddUserModalProps> = (props) => {
    const { onCancel, reloadData } = props

    const [fieldValues, setFieldValues] = useState([-1, -1])
    const [fields, setFields] = useState<IUserPopupsField[]>([])

    const [name, setName] = useState('')
    const [secondName, setSecondName] = useState('')
    const [surname, setSurname] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [submitButtonText, setSubmitButtonText] = useState('Добавить')

    const setValue = (index: number, newValue: number) => {
        fieldValues[index] = newValue
        setFieldValues(fieldValues)
    }

    useEffect(() => {
        getFields().then(data => {
            setFields(data)
            const startFieldValues = []
            for (let i = 0; i < data.length; i++)
                startFieldValues.push(-1)
            setFieldValues(startFieldValues)
        })
    }, [])

    const submitHandler = (e: MouseEvent<HTMLButtonElement>) => {
        if (submitButtonText !== 'Подождите...') {
            e.preventDefault()

            let isAllFieldsFilled = true
            for (let i = 0; i < fieldValues.length; i++) {
                if (fieldValues[i] === -1)
                    isAllFieldsFilled = false
            }

            if (!password || !name || !surname || !email) {
                alert('Не все поля заполнены')
            } else if (!isAllFieldsFilled) {
                alert('Выбраны не все поля выбраны!')
            } else {
                setSubmitButtonText('Подождите...')
                createUser({
                    first_name: name,
                    second_name: secondName,
                    last_name: surname,
                    email,
                    password,
                    popup_ids: fieldValues
                }).then(() => {
                    onCancel()
                    reloadData()
                }).catch(() => {
                    setSubmitButtonText('Добавить')
                    alert('Ошибка. Скорее всего, такой пользователь уже есть или недопустимый email-домен.')
                })
            }
        }
    }

    return (
        <Modal>
            <div className={styles.addUserModal}>
                <div className="regInputs adminRegInputs">
                    <input
                        className="input regInput adminRegInput"
                        placeholder="Фамилия"
                        type="text"
                        autoComplete="off"
                        value={surname}
                        onChange={(e) => setSurname(e.target.value)}
                    />
                    <input
                        className="input regInput adminRegInput"
                        placeholder="Имя"
                        type="text"
                        autoComplete="off"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <input
                        className="input regInput adminRegInput"
                        placeholder="Отчество (при наличии)"
                        type="text"
                        autoComplete="off"
                        value={secondName}
                        onChange={(e) => setSecondName(e.target.value)}
                    />
                    <input
                        className="input regInput adminRegInput"
                        placeholder="Пароль"
                        type="text"
                        autoComplete="off"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <input
                        className="input regInput adminRegInput"
                        placeholder="Email"
                        type="email"
                        autoComplete="off"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>

                <div className="regSelects adminRegSelects">
                    {fields.map((field, index) => {
                        let width = '100%'

                        return <Select
                            isForAdmin={true}
                            key={field.name}
                            width={width}
                            name={field.name}
                            values={field.values}
                            index={index}
                            setValue={setValue}
                        />
                    })}
                </div>

                <div className={styles.addUserButtons}>
                    <Button
                        width="100px"
                        height="40px"
                        fontSize="16px"
                        variant="primary"
                        submit={onCancel}
                    >Отмена</Button>
                    <Button
                        width="150px"
                        height="40px"
                        fontSize="16px"
                        variant="primary"
                        submit={submitHandler}
                    >
                        {submitButtonText}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};