import React from 'react';

const YellowPlusIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.8 1.5957V8.3757M7.8 8.3757V15.1557M7.8 8.3757H14.6M7.8 8.3757H1" stroke="#FFD556" strokeWidth="1.59529" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};

export default YellowPlusIcon;