import React, {useEffect, useState} from 'react';
// @ts-ignore
import styles from "../../../../css/admin/Script.module.css";
import AlertModal from "../../common/AlertModal";
import {WizardStages, WizardStagesNames} from "../../../../types/entities/status";
import WizardInput from "../UI/WizardInput";
import HeadingDescriptionComponent from "../UI/HeadingDescriptionComponent";
import Button from "../../../common/Button";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {useTypedSelector} from "../../../../hooks/useTypedSelector";
import {SecondStepData, WizardActionTypes} from "../../../../types/reducers/wizard";
import {
    getSecondStepWizardData,
    setSecondStepWizardData,
} from "../../../../http/admin/wizardRequest";
import {StatusActionTypes} from "../../../../types/reducers/status";
import {isValidDate} from "../../../../utils/isValidDate";

const errorHandler = (secondStepData: SecondStepData) => {
    if (secondStepData.instruction === '') return  'Вы не заполнили инструкцию!'
    if (secondStepData.start_date === '') return 'Вы не ввели дату начала этапа!'
    if (!isValidDate(secondStepData.start_date)) return 'Неверный формат даты'
    if (secondStepData.task_counter === '') return  'Вы не ввели количество заданий!'
    if (secondStepData.option_counter === '') return 'Вы не ввели количество опций!'
    return ''
}

const SecondStepComponent = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const isWizardConfigured = useTypedSelector(state => state.status.wizard_completed)
    const secondStepData = useTypedSelector(state => state.wizard.second_step)
    const [buttonText, setButtonText] = useState('Сохранить')
    const [errorText, setErrorText] = useState('')
    const [isModalErrorOpened, setIsModalErrorOpened] = useState(false)

    useEffect(() => {
        if (isWizardConfigured) {
            getSecondStepWizardData().then(data => {
                dispatch({
                    type: WizardActionTypes.SET_SECOND_STEP_INFO,
                    payload: data,
                })
            })
        }
    }, [])

    const setInstruction = (e: any) => {
        dispatch({
            type: WizardActionTypes.SET_SECOND_STEP_INSTRUCTION,
            payload: e.target.value
        })
    }

    const setStartDate = (e: any) => {
        dispatch({
            type: WizardActionTypes.SET_SECOND_STEP_START_DATE,
            payload: e.target.value
        })
    }

    const setTaskCounter = (e: any) => {
        dispatch({
            type: WizardActionTypes.SET_TASK_COUNTER,
            payload: e.target.value
        })
    }

    const setOptionCounter = (e: any) => {
        dispatch({
            type: WizardActionTypes.SET_OPTIONS_COUNTER,
            payload: e.target.value
        })
    }

    const setMaxSymbolsForQuestion = (e: any) => {
        dispatch({
            type: WizardActionTypes.SET_MAX_SYMBOLS_FOR_QUESTION,
            payload: e.target.value
        })
    }

    const setMaxSymbolsForOption = (e: any) => {
        dispatch({
            type: WizardActionTypes.SET_MAX_SYMBOLS_FOR_OPTION,
            payload: e.target.value
        })
    }

    const submit = () => {
        if (buttonText !== 'Подождите...') {
            const error = errorHandler(secondStepData)

            if (error) {
                setErrorText(error)
                setIsModalErrorOpened(true)
            } else {
                setButtonText('Подождите...')
                setSecondStepWizardData(secondStepData).then(() => {
                    if (isWizardConfigured) {
                        navigate('/admin/script')
                    } else {
                        dispatch({ type: StatusActionTypes.SET_NEXT_WIZARD_STAGE })
                    }
                })
            }
        }
    }

    return (
        <div className={styles.wizardStageComponent}>
            {isModalErrorOpened && <AlertModal closeModal={() => setIsModalErrorOpened(false)} text={errorText}/>}
            <h2 className={styles.wizardStageHeader}>{WizardStagesNames[WizardStages.SECOND_STEP]}</h2>
            <div className={styles.stageContent}>
                <WizardInput
                    onChange={setInstruction}
                    placeholder="Текст..."
                    value={secondStepData.instruction}
                    height={200}
                    label="Инструкция"
                    isTextarea={true}
                    isWithVars={true}
                />
                <WizardInput
                    onChange={setStartDate}
                    placeholder="ДД.ММ.ГГГГ"
                    value={secondStepData.start_date}
                    label="Дата начала этапа"
                    width={150}
                />
                <HeadingDescriptionComponent heading="Настройка заданий">
                    Установите количество создаваемых участником заданий и количество опций
                </HeadingDescriptionComponent>
                <div className={styles.inputTaskConfig}>
                    <div className={styles.inputTaskField}>
                        <p className={styles.inputTaskLabel}>Количество заданий</p>
                        <WizardInput
                            onChange={setTaskCounter}
                            placeholder="5"
                            value={secondStepData.task_counter}
                            width={70}
                        />
                    </div>
                    <div className={styles.inputTaskField}>
                        <p className={styles.inputTaskLabel}>Количество ответных опций</p>
                        <WizardInput
                            onChange={setOptionCounter}
                            placeholder="4"
                            value={secondStepData.option_counter}
                            width={70}
                        />
                    </div>
                    <div className={styles.inputTaskField}>
                        <p className={styles.inputTaskLabel}>Максимальное количество символов для вопроса в заданиях</p>
                        <WizardInput
                            onChange={setMaxSymbolsForQuestion}
                            placeholder="350"
                            value={secondStepData.max_symbols_for_question}
                            width={70}
                        />
                    </div>
                    <div className={styles.inputTaskField}>
                        <p className={styles.inputTaskLabel}>Количество символов для ответных опций</p>
                        <WizardInput
                            onChange={setMaxSymbolsForOption}
                            placeholder="150"
                            value={secondStepData.max_symbols_for_option}
                            width={70}
                        />
                    </div>
                </div>
            </div>
            <Button
                width={"150px"}
                height={"45px"}
                fontSize={"16px"}
                variant={"primary"}
                margin="50px 0 0 770px"
                submit={submit}
            >
                {buttonText}
            </Button>
        </div>
    );
};

export default SecondStepComponent;