import {ClustersAction, ClustersActionTypes, IClusters} from "../../types/reducers/clusters";

const initialState: IClusters = {
    current_cluster: {id: 0, name: "Все"},
    clusters: [{id: 0, name: "Все"}]
}

export const clustersReducer = (state=initialState, action: ClustersAction): IClusters => {
    switch (action.type) {
        case ClustersActionTypes.SET_CLUSTERS:
            return {
                ...state,
                clusters: [...initialState.clusters, ...action.payload]}
        case ClustersActionTypes.SET_CURRENT_CLUSTER:
            return {
                ...state,
                current_cluster: action.payload
            }
        default:
            return state
    }
}