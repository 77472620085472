import React, {FC} from 'react';
// @ts-ignore
import styles from "../../../../css/admin/Script.module.css";
import ScriptNavElement from "./ScriptNavElement";
import {PencilIcon} from "../../../icons/admin/PencilIcon";
import CalendarIcon from "../../../icons/admin/CalendarIcon";
import {StageStatus, WizardStages, WizardStagesNames} from "../../../../types/entities/status";
import {useNavigate} from "react-router-dom";
import {useWizardStageStatus} from "../../../../hooks/useWizardStageStatus";

export interface ScriptCardProps {
    href: WizardStages
    start_date?: string
    end_date?: string
    description?: string
}

const ScriptCard:FC<ScriptCardProps> = (props) => {
    const { href, start_date, end_date, description } = props
    const navigate = useNavigate()

    return (
        <div className={styles.scriptCard}>
            <ScriptNavElement currentStageCard={href}/>
            <div className={styles.scriptCardContent}>
                <div className={styles.scriptCardHeading}>
                    <h2 className={styles.scriptCardHeader}>{WizardStagesNames[href]}</h2>
                    <div
                        className={styles.editControl}
                        onClick={() => navigate(`/admin/wizard/${href}`)}
                    >
                        <PencilIcon />
                        <span>Редактировать</span>
                    </div>
                </div>
                <div className={styles.date}>
                    {start_date && <CalendarIcon />}
                    {start_date && <span>{start_date} - {end_date}</span>}
                </div>
                {description && <p className={styles.descriptionHeader}>Описание:</p>}
                {description && <p className={styles.descriptionText}>{description}</p>}
            </div>
        </div>
    );
};

export default ScriptCard;