export interface IAppInfo {
    app_name: string
    current_stage: number
    id: number
    max_experts_on_subtopic: number
    max_items_for_expert: number
    max_picked_subtopics: number
    max_tasks_on_subtopic: number
    subtopic_expertise: boolean
    support_mail: string
    video_link: string
    app_name_description: string
}

export const initialAppInfoState: IAppInfo = {
    app_name: '',
    current_stage: 0,
    id: 0,
    max_experts_on_subtopic: 0,
    max_items_for_expert: 0,
    max_picked_subtopics: 0,
    max_tasks_on_subtopic: 0,
    subtopic_expertise: true,
    support_mail: "support@hl.expert",
    video_link: '',
    app_name_description: ''
}