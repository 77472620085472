import React from 'react';

const ExpertiseIcon = () => {
    return (
        <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg" style={{display: 'block', margin: "0 auto"}}>
            <path d="M4.25959 8.14619C3.98453 10.2518 3.82064 12.3704 3.76859 14.4932C6.64737 15.6957 9.40374 17.1725 11.9996 18.9032C14.5957 17.1725 17.3525 15.6957 20.2316 14.4932C20.1795 12.3704 20.0156 10.2518 19.7406 8.14619M4.25859 8.14619C3.3802 7.85089 2.49387 7.57978 1.60059 7.33319C4.86398 5.0451 8.34758 3.08841 11.9996 1.49219C15.6516 3.08807 19.1352 5.04443 22.3986 7.33219C21.5026 7.58019 20.6156 7.85219 19.7406 8.14619M4.25859 8.14619C6.92724 9.04331 9.51653 10.1612 11.9996 11.4882C14.4823 10.1612 17.0713 9.04338 19.7396 8.14619M6.74959 12.9992V9.32419C8.44632 8.26953 10.1989 7.30745 11.9996 6.44219M4.99259 17.9922C5.55056 17.4356 5.99302 16.7742 6.29455 16.046C6.59608 15.3179 6.75072 14.5373 6.74959 13.7492V12.2492M6.74959 12.9992C6.9485 12.9992 7.13926 12.9202 7.27992 12.7795C7.42057 12.6389 7.49959 12.4481 7.49959 12.2492C7.49959 12.0503 7.42057 11.8595 7.27992 11.7189C7.13926 11.5782 6.9485 11.4992 6.74959 11.4992C6.55067 11.4992 6.35991 11.5782 6.21926 11.7189C6.0786 11.8595 5.99959 12.0503 5.99959 12.2492C5.99959 12.4481 6.0786 12.6389 6.21926 12.7795C6.35991 12.9202 6.55067 12.9992 6.74959 12.9992Z" stroke="#323232" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};

export default ExpertiseIcon;