import React from 'react';
import StepOneSVG from "./vectorImages/StepOneSVG";

const DesktopStepOne = () => {
    return (
        <>
            <p>
                Вам необходимо внимательно <b>прочитать и оценить каждое задание</b>, предложенное по данной теме другими авторами.
            </p>

            <div style={{margin: "50px auto", width: 550}}>
                <StepOneSVG />
            </div>
        </>
    );
};

export default DesktopStepOne;