import React, {useEffect, useState} from 'react';
import Modal from "../common/Modal";
// @ts-ignore
import styles from "../../../css/admin/ThemePanel.module.css"
import Button from "../../common/Button";
import CrossIcon from "../../icons/admin/CrossIcon";
import {addTopic} from "../../../http/admin/themeRequests";

const AddTopicModal = (props: {closeModal: () => void, reload: () => void}) => {
    const [topic, setTopic] = useState('')
    const [buttonText, setButtonText] = useState('Сохранить')

    const submit = () => {
        if (topic === '') {
            alert('Вы не ввели название темы!')
        } else if (buttonText !== 'Подождите...') {
            setButtonText('Подождите...')
            addTopic(topic).then(() => {
                setButtonText('Сохранить')
                props.closeModal()
                props.reload()
            })
        }
    }

    return (
        <Modal>
            <div className={styles.editMenu}>
                <div className={styles.topicModalHeading}>
                    <h2>Добавить тему</h2>
                    <div onClick={props.closeModal}>
                        <CrossIcon />
                    </div>
                </div>
                <div className={styles.editControls}>
                    <div className={styles.subtopicEditControl}>
                        <h3>Название</h3>
                        <textarea
                            name="topic"
                            id="topic"
                            value={topic}
                            onChange={(e) => setTopic(e.target.value)}
                        />
                    </div>
                </div>
                <Button
                    width={"150px"}
                    height={"40px"}
                    variant={"primary"}
                    fontSize={"16px"}
                    margin={"20px 0 0 350px"}
                    submit={submit}
                >
                    {buttonText}
                </Button>
            </div>
        </Modal>
    );
};

export default AddTopicModal;