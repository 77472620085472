import React from 'react';
// @ts-ignore
import styles from "../../css/Player.module.css"

const BookIcon = (props: {isHovered: boolean}) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles.bookIcon}>
            <path d="M2 3H8C9.06087 3 10.0783 3.42143 10.8284 4.17157C11.5786 4.92172 12 5.93913 12 7V21C12 20.2044 11.6839 19.4413 11.1213 18.8787C10.5587 18.3161 9.79565 18 9 18H2V3Z" stroke={props.isHovered ? "#FFC000" : "black"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M22.001 3H16.001C14.9401 3 13.9227 3.42143 13.1725 4.17157C12.4224 4.92172 12.001 5.93913 12.001 7V21C12.001 20.2044 12.317 19.4413 12.8797 18.8787C13.4423 18.3161 14.2053 18 15.001 18H22.001V3Z" stroke={props.isHovered ? "#FFC000" : "black"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};

export default BookIcon;