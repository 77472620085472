import React, {useEffect, useState} from 'react';
// @ts-ignore
import styles from "../../../../css/admin/Script.module.css";
import AlertModal from "../../common/AlertModal";
import {WizardStages, WizardStagesNames} from "../../../../types/entities/status";
import Button from "../../../common/Button";
import WizardInput from "../UI/WizardInput";
import HeadingDescriptionComponent from "../UI/HeadingDescriptionComponent";
import AdminDropDown from "../../common/AdminDropDown";
import UploadIcon from "../../../icons/admin/UploadIcon";
import Preloader from "../../../common/Preloader";
import {
    getFirstStepWizardData, setFirstStepWizardData,
    uploadTopicTemplate
} from "../../../../http/admin/wizardRequest";
import {useTypedSelector} from "../../../../hooks/useTypedSelector";
import {useDispatch} from "react-redux";
import {FirstStepData, ProjectDescription, WizardActionTypes} from "../../../../types/reducers/wizard";
import {IOption} from "../../../../types/admin/props/adminDropDown";
import {StatusActionTypes} from "../../../../types/reducers/status";
import {useNavigate} from "react-router-dom";
import {isValidDate} from "../../../../utils/isValidDate";

const errorHandler = (firstStepData: FirstStepData) => {
    if (firstStepData.instruction === '') return 'Вы не ввели инструкцию!'
    if (firstStepData.start_date === '') return 'Вы не ввели дату начала этапа!'
    if (!isValidDate(firstStepData.start_date)) return 'Неверный формат даты'
    if (firstStepData.subtopic_limit === '') return 'Вы не ввели лимит количества участников!'
    if (!firstStepData.subtopic_data) return 'Вы не загрузили шаблон!'
    return ''
}

const FirstStepComponent = () => {
    const navigate = useNavigate()
    const isWizardConfigured = useTypedSelector(state => state.status.wizard_completed)
    const firstStepData = useTypedSelector(state => state.wizard.first_step)
    const dispatch = useDispatch()
    const [buttonText, setButtonText] = useState('Сохранить')
    const [errorText, setErrorText] = useState('')
    const [isModalErrorOpened, setIsModalErrorOpened] = useState(false)
    const [currentCluster, setCurrentCluster] = useState<IOption>({ id: 0, name: 'Все' })
    const [isFileParsing, setIsFileParsing] = useState(false)

    useEffect(() => {
        if (isWizardConfigured) {
            setIsFileParsing(true)
            getFirstStepWizardData().then(data => {
                setIsFileParsing(false)
                dispatch({
                    type: WizardActionTypes.SET_FIRST_STEP_DATA,
                    payload: data
                })
                setCurrentCluster({ id: 0, name: 'Все' })
            })
        }
    }, [])

    const uploadHandler = (e: any) => {
        setIsFileParsing(true)
        uploadTopicTemplate(e.target.files[0]).then(() => {
            e.target.value = null
            getFirstStepWizardData().then(data => {
                setIsFileParsing(false)
                dispatch({
                    type: WizardActionTypes.SET_SUBTOPICS,
                    payload: {
                        subtopic_data: data.subtopic_data,
                        clusters: data.clusters
                    }
                })
                setCurrentCluster({ id: 0, name: 'Все' })
            })
        }).catch(() => {
            e.target.value = null
            setIsFileParsing(false)
            setErrorText('Кажется, неправильно заполнена таблица. Проверьте правильность написания кластеров')
            setIsModalErrorOpened(true)
        })
    }

    const setSubtopicLimit = (e: any) => {
        dispatch({
            type: WizardActionTypes.SET_DEFAULT_LIMIT,
            payload: e.target.value
        })
    }

    const setMaxPickedSubtopics = (e: any) => {
        dispatch({
            type: WizardActionTypes.SET_MAX_PICKED_SUBTOPICS,
            payload: e.target.value
        })
    }

    const setInstruction = (e: any) => {
        dispatch({
            type: WizardActionTypes.SET_FIRST_STEP_INSTRUCTION,
            payload: e.target.value
        })
    }

    const setDate = (e: any) => {
        dispatch({
            type: WizardActionTypes.SET_FIRST_STEP_DATE,
            payload: e.target.value
        })
    }

    const submit = () => {
        if (buttonText !== 'Подождите...') {
            const error = errorHandler(firstStepData)

            if (error) {
                setErrorText(error)
                setIsModalErrorOpened(true)
            } else {
                setButtonText('Подождите...')
                setFirstStepWizardData(firstStepData).then(() => {
                    if (isWizardConfigured) {
                        navigate('/admin/script')
                    } else {
                        dispatch({ type: StatusActionTypes.SET_NEXT_WIZARD_STAGE })
                    }
                })
            }
        }
    }

    return (
        <div className={styles.wizardStageComponent}>
            {isModalErrorOpened && <AlertModal closeModal={() => setIsModalErrorOpened(false)} text={errorText}/>}
            <h2 className={styles.wizardStageHeader}>{WizardStagesNames[WizardStages.FIRST_STEP]}</h2>
            <div className={styles.firstStepContent}>
                <WizardInput
                    onChange={setInstruction}
                    placeholder="Текст..."
                    value={firstStepData.instruction}
                    height={200}
                    label="Инструкция"
                    isTextarea={true}
                    isWithVars={true}
                />
                <WizardInput
                    onChange={setDate}
                    placeholder="ДД.ММ.ГГГГ"
                    value={firstStepData.start_date}
                    label="Дата начала этапа (ДД.ММ.ГГГГ)"
                    width={150}
                />
                <div className={styles.uploadHeading}>
                    <HeadingDescriptionComponent heading="Темы и подтемы">
                        Вы можете <a href="/template.xlsx" download={true}>скачать шаблон</a>, заполнить его и загрузить обратно в систему. Также вы можете вручную ввести эту информацию в таблицу ниже.
                        Заполненные вами данные не потеряются при закрытии окна.
                    </HeadingDescriptionComponent>
                    <div className={styles.uploadButtonContainer}>
                        <div className={styles.inputWrapper}>
                            <input
                                name="file"
                                type="file"
                                id="input__file"
                                className={styles.inputFile}
                                accept=".xls, .xlsx, .csv"
                                onChange={uploadHandler}
                            />
                                <label htmlFor="input__file" className={styles.inputFileButton}>
                                    <span className={styles.inputIconWrapper}>
                                        <UploadIcon />
                                    </span>
                                    <span className={styles.inputFileButtonText}>Выберите файл</span>
                                </label>
                        </div>
                    </div>
                </div>
                <WizardInput
                    onChange={setSubtopicLimit}
                    placeholder="4"
                    value={firstStepData.subtopic_limit}
                    label="Лимит участников на одну подтему"
                    width={75}
                />

                <WizardInput
                    onChange={setMaxPickedSubtopics}
                    placeholder="3"
                    value={firstStepData.max_picked_subtopics}
                    label="Лимит подтем для участника"
                    width={75}
                />

                {isFileParsing && <Preloader />}

                {firstStepData.subtopic_data && !isFileParsing && <AdminDropDown
                    width={400}
                    defaultValue={{id: 0, name:'Все'}}
                    setter={setCurrentCluster}
                    optionList={[{id: 0, name:'Все'}, ...firstStepData.clusters]}
                    maxHeight={130}
                />}

                {firstStepData.subtopic_data && !isFileParsing && <div>
                    <div className={styles.userTable}>
                        <div className={styles.tableHeader}>
                            <div className={styles.topicHeader}>
                                <p>Тема</p>
                            </div>
                            <div className={styles.subtopicHeader}>
                                <p>Подтема</p>
                            </div>
                        </div>
                    </div>

                        <div className={styles.table}>
                            {firstStepData.subtopic_data.map(topic => {
                                return <div className={styles.topicRow} key={topic.topic_name}>
                                    <div className={styles.topic}>
                                        <p>
                                            {topic.topic_name}
                                        </p>
                                    </div>
                                    <div className={styles.subtopics}>
                                        {topic.subtopics.filter(subtopic => subtopic.cluster_ids.includes(+currentCluster?.id) || +currentCluster?.id === 0).length === 0 && <div className={styles.subtopic}>
                                            <div className={styles.subtopicName}>
                                                <p>По данной теме нет подтем в текущем кластере</p>
                                            </div>
                                        </div>}
                                        {topic.subtopics.filter(subtopic => subtopic.cluster_ids.includes(+currentCluster?.id) || +currentCluster?.id === 0).map(subtopic => {
                                            return <div className={styles.subtopic} key={subtopic.subtopic_name}>
                                                <div className={styles.subtopicName}>
                                                    <p>{subtopic.subtopic_name}</p>
                                                    <p>Описание: {subtopic.description}</p>
                                                </div>
                                            </div>
                                        })}
                                    </div>
                                </div>
                            })}
                        </div>
                </div>}
            </div>

            <Button
                width={"150px"}
                height={"45px"}
                fontSize={"16px"}
                variant={"primary"}
                margin="50px 0 0 770px"
                submit={submit}
            >
                {buttonText}
            </Button>
        </div>
    );
};

export default FirstStepComponent;