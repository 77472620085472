import React, {useEffect, useState} from 'react';
// @ts-ignore
import commonStyles from "../../../../css/admin/Admin.module.css";
// @ts-ignore
import styles from "../../../../css/admin/Script.module.css";
import ScriptCard from "./ScriptCard";
import {getScriptData} from "../../../../http/admin/wizardRequest";
import Preloader from "../../../common/Preloader";
import {useTypedSelector} from "../../../../hooks/useTypedSelector";
import {useDispatch} from "react-redux";
import {WizardActionTypes} from "../../../../types/reducers/wizard";


const ScriptPanel = () => {
    const [isScriptLoaded, setIsScriptLoaded] = useState(true)
    const dispatch = useDispatch()
    const scriptData = useTypedSelector(state => state.wizard.scriptData)

    useEffect(() => {
        setIsScriptLoaded(false)
        getScriptData().then(data => {
            setIsScriptLoaded(true)
            dispatch({
                type: WizardActionTypes.SET_SCRIPT_DATA,
                payload: data
            })
        })
    }, [])

    return (
        <div className={commonStyles.mainPanel}>
            {isScriptLoaded && <>
                <h1 className={commonStyles.panelHeader}>Управление сценарием проекта</h1>
                <div className={styles.scriptCardsContainer}>
                    {scriptData.map(scriptCard => <ScriptCard
                        key={scriptCard.href}
                        description={scriptCard.description}
                        href={scriptCard.href}
                        start_date={scriptCard.start_date}
                        end_date={scriptCard.end_date}
                    />)}
                </div>
            </>}
            {!isScriptLoaded && <Preloader />}
        </div>
    );
};

export default ScriptPanel;