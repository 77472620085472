import React, {useEffect, useState} from 'react';
// @ts-ignore
import styles from "../../../css/admin/MailPanel.module.css";
import {useNavigate, useParams} from "react-router-dom";

//TODO Вынести вкладки в компоненты

const MailNav = () => {
    const [toggledNavElId, setToggledNavElId] = useState('')
    const navigate = useNavigate()
    const mailListGroup = useParams().mail_list_group

    useEffect(() => {
        if (mailListGroup) {
            setToggledNavElId(mailListGroup)
        }
    }, [mailListGroup])

    return (
        <div className={styles.mailNav}>
            <div id={"0"} className={styles.navElement} onClick={() => {navigate('/admin/mail_lists/register')}}>
                <span>Регистрация</span>
                <div className={styles.line} style={{background: toggledNavElId === 'register' ? "#323232" : "#E5E5E5"}} />
            </div>
            <div id={"1"} className={styles.navElement} onClick={() => {navigate('/admin/mail_lists/first')}}>
                <span>1 этап</span>
                <div className={styles.line} style={{background: toggledNavElId === 'first' ? "#323232" : "#E5E5E5"}} />
            </div>
            <div id={"2"} className={styles.navElement} onClick={() => {navigate('/admin/mail_lists/second')}}>
                <span>2 этап</span>
                <div className={styles.line} style={{background: toggledNavElId === 'second' ? "#323232" : "#E5E5E5"}} />
            </div>
            <div id={"3"} className={styles.navElement} onClick={() => {navigate('/admin/mail_lists/third')}}>
                <span>3 этап</span>
                <div className={styles.line} style={{background: toggledNavElId === 'third' ? "#323232" : "#E5E5E5"}} />
            </div>
            <div id={"4"} className={styles.navElement} onClick={() => {navigate('/admin/mail_lists/custom')}}>
                <span>Кастомные рассылки</span>
                <div className={styles.line} style={{background: toggledNavElId === 'custom' ? "#323232" : "#E5E5E5"}} />
            </div>
        </div>
    );
};

export default MailNav;