import {$authHost} from "../http";

export const downloadFileFromServer = async (file_url: string, filename: string) => {
    try {
        const response = await $authHost.get(file_url, { responseType: 'blob' });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (error) {
        alert('Ошибка. Возможно, файл еще не создан');
    }
};