import {$authHost} from "./index";
import {isClientDemo} from "../utils/demo/demo";
import {getPickedThemesDemo, getThemesDemo, saveThemesDemo} from "../utils/demo/mocks";

export const getThemes = async (): Promise<any> => {
    const response = isClientDemo() ? getThemesDemo() : await $authHost.get(`login/auth/topics/subtopics`)
    return response.data
}

export const saveThemes = async (subtopics: number[]): Promise<any> => {
    const response = isClientDemo() ? saveThemesDemo(subtopics) : await $authHost.post(`login/auth/register/topics/subtopics`, {
        value_ids: subtopics
    })
    return response.data
}

export const getPickedThemes = async (): Promise<any> => {
    const response = isClientDemo() ? getPickedThemesDemo() : await $authHost.get(`login/auth/register/topics/subtopics`)
    return response.data
}

export const getExpertiseThemes = async (): Promise<any> => {
    const response = isClientDemo() ? getPickedThemesDemo() : await $authHost.get(`expertise/user/subtopics`)
    return response.data
}

export const clearPickedSubtopics = async (): Promise<any> => {
    const response = await $authHost.post(`login/auth/register/topics/subtopics/clear`)
    return response.data
}
