import React, {FC, PropsWithChildren} from 'react';
// @ts-ignore
import styles from "../../../../css/admin/Script.module.css";

interface HeadingDescriptionProps {
    heading: string,
    children?: any,
    margin?: string,
}

const HeadingDescriptionComponent:FC<PropsWithChildren<HeadingDescriptionProps>> = (props) => {
    const { heading, children, margin } = props

    return (
        <div className={styles.headingDescriptionBlock} style={{ margin }}>
            <h2 className={styles.headingDescription}>{heading}</h2>
            <p className={styles.componentDescription}>{children}</p>
        </div>
    );
};

export default HeadingDescriptionComponent;