import {$authHost, $host} from "./index";
import {isClientDemo} from "../utils/demo/demo";
import {getStatusDemo} from "../utils/demo/mocks";
import {IUserPopupsField} from "../types/admin/http/userRequests";

export const sentEmail = async (email: string): Promise<any> => {
    const response = await $host.post(`login/auth/register/email?email=${email}`)
    return response.data
}

export const sentEmailForRestore = async (email: string): Promise<any> => {
    const response = await $host.post(`login/auth/register/password/reset?email=${email}`)
    return response.data
}

export const resetPassword = async (password: string, token: string | undefined): Promise<any> => {
    const response = await $host.post(`login/auth/register/password/new?token=${token}`, {password})
    return response.data
}

export const getFields = async (): Promise<IUserPopupsField[]> => {
    const response = await $host.get(`login/auth/popups`)
    return response.data
}

export const checkRegisterTokenValid = async (token: string | undefined): Promise<any> => {
    const response = await $host.post(`login/auth/register/user/check?token=${token}`)
    return response.data
}

export const registration = async (
    token: string | undefined,
    first_name: string,
    second_name: string,
    last_name: string,
    password: string,
    popup_ids: number[]
) => {
    const response = await $host.post(`login/auth/register/user?token=${token}`, {
        first_name,
        second_name,
        last_name,
        password,
        popup_ids
    })
    return response.data
}

export const logIn = async (email: string, password: string) => {
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }

    let data = new URLSearchParams()
    data.append('grant_type', 'password')
    data.append('username', email)
    data.append('password', password)

    const response = await $host.post('login/auth/authorize', data, config)
    return response.data
}

export const check = async () => {
    const {data} = await $authHost.get('login/auth/user')
    return data
}

export const getAppInfo = async () => {
    const {data} = await $host.get('services/app_info')
    return data
}

export const getStatus = async () => {
    return isClientDemo() ?
      getStatusDemo((await $authHost.get('services/app_info/state')).data)
      :
      (await $authHost.get('services/app_info/state')).data
}