import React from 'react';

const SortIcon = () => {
    return (
        <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 1L19 5M1 17H14H1ZM1 13H10H1ZM1 9H10H1ZM15 13V1V13ZM15 1L11 5L15 1Z" stroke="#323232" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};

export default SortIcon;