export const defaultUITexts = {
    firstStep: "Дефолтный текст 1 этап",
    waitingForSecondStep: "Этап ожидания дефолт",
    secondStep: "Второй этап",
    thirdStep: "Третий",
    qualityTexts: [
        { grade: 1, description: '1q' },
        { grade: 2, description: '2q' },
        { grade: 3, description: '3q' },
        { grade: 4, description: '4q' },
        { grade: 5, description: '5q' },
    ],
    originalityTexts: [
        { grade: 1, description: '1o' },
        { grade: 2, description: '2o' },
        { grade: 3, description: '3o' },
        { grade: 4, description: '4o' },
        { grade: 5, description: '5o' },
    ]
}