import {IRecipient} from "../types/admin/http/mailListsRequests";
import {isAllRecipientsChecked, isRecipientIn} from "./isRecipientIn";
import {Dispatch, SetStateAction} from "react";

const addRecipient = (recipient: IRecipient, currentRecipients: IRecipient[]): IRecipient[] => {
    let isRecipientsAlreadyIn = false
    currentRecipients.forEach(currentRecipient => {
        if (currentRecipient.id === recipient.id) {
            isRecipientsAlreadyIn = true
        }
    })

    if (isRecipientsAlreadyIn) {
        return currentRecipients
    } else {
        return [...currentRecipients, recipient]
    }
}

const removeRecipients = (recipient: IRecipient, currentRecipients: IRecipient[]): IRecipient[] => {
    return currentRecipients.filter(currentRecipient => {
        return currentRecipient.id !== recipient.id
    })
}

export const addOrDeleteRecipient = (recipient: IRecipient, currentRecipients: IRecipient[], setter: Dispatch<SetStateAction<IRecipient[]>>) => {
    if (isRecipientIn(recipient.id, currentRecipients)) {
        setter(removeRecipients(recipient, currentRecipients))
    } else {
        setter(addRecipient(recipient, currentRecipients))
    }
}

export const addOrDeleteAllRecipients = (recipients: IRecipient[], currentRecipients: IRecipient[], setter: Dispatch<SetStateAction<IRecipient[]>>) => {
    if (isAllRecipientsChecked(recipients, currentRecipients)) {
        setter([])
    } else {
        setter(recipients)
    }
}