import React, {useEffect, useState} from 'react';
// @ts-ignore
import styles from "../../../css/admin/UserPanel.module.css";
import FunctionalHeader from "../common/FunctionalHeader";
// @ts-ignore
import commonStyles from "../../../css/admin/Admin.module.css";
import YellowPlusIcon from "../../icons/admin/YellowPlusIcon";
import CustomCheckbox from "../../common/CustomCheckbox";
import SortIcon from "../../icons/admin/SortIcon";
import classNames from "classnames";
import EditIcon from "../../icons/admin/EditIcon";
import EyeIcon from "../../icons/admin/EyeIcon";
import ThemeTrashIcon from "../../icons/admin/ThemeTrashIcon";
import ConfirmModal from "../common/ConfirmModal";
import SubtopicMenuModal from "./SubtopicMenuModal";
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import {deleteUser, exportAllUsers, getUsersByCluster} from "../../../http/admin/userRequests";
import {IUser, IUserPopup} from "../../../types/admin/http/userRequests";
import Preloader from "../../common/Preloader";
import {timestempToDate} from "../../../utils/dateFuncs";
import {isAllRecipientsChecked, isRecipientIn} from "../../../utils/isRecipientIn";
import {useDispatch} from "react-redux";
import {addOrDeleteAllRecipients, addOrDeleteRecipient} from "../../../utils/removeOrAddRecipients";
import {useNavigate} from "react-router-dom";
import AlertModal from "../common/AlertModal";
import DownloadIcon from "../../icons/admin/DownloadIcon";
import {IUserSortType, userSortType} from "../../../types/admin/entities/userSortTypes";
import {userSort} from "../../../utils/userSort";
import DashboardIcon from "../../icons/admin/DashboardIcon";
import SettingsPanel from "./dashboard/settingsPanel/SettingsPanel";
import {MouseHintActionTypes} from "../../../types/reducers/mouseHint";
import {IRecipient} from "../../../types/admin/http/mailListsRequests";
import {RecipientsActionTypes} from "../../../types/reducers/recipients";
import {AddUserModal} from "./AddUserModal";
import {MailEditorActionTypes} from "../../../types/reducers/mailEditor";
import {UploadUsersModal} from "./UploadUsersModal";

//TODO Вынести таблицу в качестве элемента

const eye_hint = 'Посмотреть дашборд пользователя'
const edit_hint = 'Редактировать выбранные подтемы пользователя'
const delete_hint = 'Удалить пользователя'

const UserPanel = () => {
    const currentCluster = useTypedSelector(state => state.clusters.current_cluster)
    const commonRecipients = useTypedSelector(state => state.recipients)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
    const [isSubtopicListOpen, setIsSubtopicListOpen] = useState(false)
    const [isAlertModalOpen, setIsAlertModalOpen] = useState(false)
    const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false)
    const [isUploadUsersModalOpen, setIsUploadUsersModalOpen] = useState(false)
    const [isDashboardSettingsOpen, setIsDashboardSettingsOpen] = useState(false)
    const [isTableLoaded, setIsTableLoaded] = useState(false)
    const [currentUserId, setCurrentUserId] = useState(0)
    const [controlText, setControlText] = useState('Скачать статистику')
    const [currentRecipients, setCurrentRecipients] = useState<IRecipient[]>(commonRecipients)
    const [currentSort, setCurrentSort] = useState<IUserSortType>({isDescending: true, sortType: "registration_date"})

    const [searchValue, setSearchValue] = useState('')
    const customFilter = (item: IUser) => {
        if (searchValue !== '') {
            let key: keyof typeof item;
            for (key in item) {
                if (key !== 'user_popups' && key !== 'registration_date') {
                    if (item[key].toString().toLowerCase().includes(searchValue.toLowerCase())) {
                        return true
                    }
                } else if (key === 'registration_date') {
                    if (timestempToDate(item[key]).includes(searchValue.toLowerCase())) {
                        return true
                    }
                } else if (key === 'user_popups') {
                    for (let i = 0; i < item[key].length; i++) {
                        if (item[key][i].value.toString().toLowerCase().includes(searchValue.toLowerCase())) {
                            return true
                        }
                    }
                }
            }
            return false
        }
        return true
    }

    const [users, setUsers] = useState<IUser[]>([])

    const closeConfirmModal = () => {
        setIsConfirmModalOpen(false)
    }

    const closeDashboardSettings = () => {
        setIsDashboardSettingsOpen(false)
    }

    const closeSubtopicListModal = () => {
        setIsSubtopicListOpen(false)
    }

    const popupSort = (a: IUserPopup, b: IUserPopup) => {
        return Number(b.id) - Number(a.id)
    }

    const setSort = (sortType: userSortType) => {
        if (currentSort.sortType !== sortType) {
            setCurrentSort({sortType: sortType, isDescending: true})
        } else {
            setCurrentSort({...currentSort, isDescending: !currentSort.isDescending})
        }
    }

    const showHint = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, text: string) => {
        dispatch({type: MouseHintActionTypes.SHOW_HINT, payload: {
                x: e.clientX + 15,
                y: e.pageY,
                isShown: true,
                hint_text: text
            }})
    }

    const hideHint = () => {
        dispatch({type: MouseHintActionTypes.HIDE_HINT})
    }

    const exportUsers = () => {
        if (controlText !== "Подождите...") {
            setControlText("Подождите...")
            exportAllUsers().then(data => {
                const downloadUrl = window.URL.createObjectURL(data)
                const link = document.createElement('a')
                link.href = downloadUrl
                link.download = 'users.xls'
                document.body.appendChild(link)
                link.click()
                link.remove()
                setControlText("Скачать статистику")
            })
        }
    }

    const changeSubtopicCounter = (newCounter: number) => {
        setUsers(users.map(user => {
            if (user.id === currentUserId) {
                return {...user, subtopic_counter: newCounter}
            } else {
                return user
            }
        }))
    }

    const loadData = () => {
        setIsTableLoaded(false)
        getUsersByCluster(currentCluster.id).then(data => {
            setIsTableLoaded(true)
            if (data) setUsers(data)
        })
    }

    useEffect(() => {
        loadData()
    }, [currentCluster])

    return (
        <div className={commonStyles.mainPanel}>
            {isConfirmModalOpen && <ConfirmModal
                closeModal={closeConfirmModal}
                submit={() => {
                    if (currentUserId !== 0) {
                        setCurrentUserId(0)
                        deleteUser(currentUserId).then(() => {
                            setUsers(users.filter(user => user.id !== currentUserId))
                        }).catch(() => {
                            alert('Пользователь не может быть удален на данном этапе!')
                        }).finally(() => closeConfirmModal())
                    }
                }}
                text={"Вы действительно хотите удалить данного пользователя?"}
            />}

            {isSubtopicListOpen && <SubtopicMenuModal
                id={currentUserId}
                closeModal={closeSubtopicListModal}
                changeCounter={changeSubtopicCounter}
            />}

            {isAddUserModalOpen && <AddUserModal
                onCancel={() => setIsAddUserModalOpen(false)}
                reloadData={loadData}
            />}

            {isUploadUsersModalOpen && <UploadUsersModal
                onCancel={() => setIsUploadUsersModalOpen(false)}
            />}

            {isAlertModalOpen && <AlertModal
                closeModal={() => {
                    setIsAlertModalOpen(false)
                    dispatch({type: RecipientsActionTypes.ADD_RECIPIENTS, payload: currentRecipients})
                    dispatch({type: MailEditorActionTypes.OPEN_EDITOR})
                    navigate('/admin/mail_lists/custom')
                }}
                text={'Вы будете перенаправлены на страницу редактирования рассылки. На ней вам нужно будет ввести текст, который вы хотели бы отправить выбранным адресатам, и подтвердить отправку письма.'}
            />}

            {isDashboardSettingsOpen && <SettingsPanel closeModal={closeDashboardSettings}/>}

            <div className={commonStyles.headerControls}>
                <FunctionalHeader header={"Пользователи"} counter={users ? users.filter(customFilter).length : 0} searchValue={searchValue} searchValueSetter={setSearchValue}/>
                <div className={commonStyles.controls}>
                    {/*<div className={commonStyles.control} onClick={() => setIsDashboardSettingsOpen(true)}>
                        <DashboardIcon />
                        <span>Настройки дашборда</span>
                    </div>*/}
                    <div className={commonStyles.control} onClick={exportUsers}>
                        <DownloadIcon/>
                        <span>{controlText}</span>
                    </div>
                    <div className={commonStyles.control} onClick={() => setIsAddUserModalOpen(true)}>
                        <YellowPlusIcon/>
                        <span>Добавить пользователя</span>
                    </div>
                    <div className={commonStyles.control} onClick={() => setIsUploadUsersModalOpen(true)}>
                        <YellowPlusIcon/>
                        <span>Загрузить пользователей</span>
                    </div>
                </div>
            </div>

            {isTableLoaded && users.length !== 0 ? <div className={styles.userTable}>
                <div
                    className={styles.checkAll}
                    onClick={() => addOrDeleteAllRecipients(users.filter(customFilter).map(user => {return {id: user.id, email: user.name}}), currentRecipients, setCurrentRecipients)
                }>
                    <CustomCheckbox
                        isChecked={isAllRecipientsChecked(users.filter(customFilter).map(user => {return {id: user.id, email: user.name}}), currentRecipients)}
                        isMinimize={true}
                    />
                    <span>Выбрать всех</span>
                </div>
                <div className={styles.tableHeader}>
                    <div className={classNames(styles.nameHeader, styles.cellHeader)}>
                        email
                    </div>
                    <div className={classNames(styles.nameHeader, styles.cellHeader)}>
                        Имя
                    </div>
                    <div className={classNames(styles.dateHeader, styles.cellHeader)} onClick={() => setSort('registration_date')}>
                        <div className={styles.cellHeaderRow}>
                            <span>Дата регистрации</span>
                            <SortIcon />
                        </div>
                    </div>
                    <div className={styles.customFieldsHeaders}>
                        {users.length !== 0 && users[0].user_popups.sort(popupSort).map(popup => {
                            return <div className={styles.customFieldHeader} key={popup.popup_name_id}>
                                {popup.name}
                            </div>
                        })}
                    </div>
                    <div className={classNames(styles.counterHeader, styles.cellHeader)} onClick={() => setSort('subtopic_counter')}>
                        <div className={styles.cellHeaderRow}>
                            <span>Выбрано подтем</span>
                            <SortIcon />
                        </div>
                    </div>
                    <div className={classNames(styles.counterHeader, styles.cellHeader)} onClick={() => setSort('task_counter')}>
                        <div className={styles.cellHeaderRow}>
                            <span>Создано заданий</span>
                            <SortIcon />
                        </div>
                    </div>
                    <div className={classNames(styles.counterHeader, styles.cellHeader)} onClick={() => setSort('rated_counter')}>
                        <div className={styles.cellHeaderRow}>
                            <span>Оценено заданий</span>
                            <SortIcon />
                        </div>
                    </div>
                    <div className={classNames(styles.originalHeader, styles.cellHeader)} onClick={() => setSort('avg_actuality')}>
                        <div className={styles.cellHeaderRow}>
                            <span>Актуальность</span>
                            <SortIcon />
                        </div>
                    </div>
                    <div className={classNames(styles.qualityHeader, styles.cellHeader)} onClick={() => setSort('avg_quality')}>
                        <div className={styles.cellHeaderRow}>
                            <span>Качество</span>
                            <SortIcon />
                        </div>
                    </div>
                    <div className={classNames(styles.expertHeader, styles.cellHeader)} onClick={() => setSort('expertness')}>
                        <div className={styles.cellHeaderRow}>
                            <span>Экспертность</span>
                            <SortIcon />
                        </div>
                    </div>
                </div>

                <div className={styles.table}>
                    {userSort(users, currentSort).filter(customFilter).map(user => {
                        return <div className={styles.userRow} key={user.id}>
                            <div
                                className={classNames(styles.name, styles.mail)}
                                onClick={() => addOrDeleteRecipient({id: user.id, email: user.email}, currentRecipients, setCurrentRecipients)}
                            >
                                <CustomCheckbox isChecked={isRecipientIn(user.id, currentRecipients)} isMinimize={true}/>
                                <p>{user.email}</p>
                            </div>
                            {user.user_popups.length !== 0 ? <><div className={styles.name}>
                                <p>{user.name}</p>
                            </div>
                            <div className={classNames(styles.date, styles.cell)}>
                                <p>{timestempToDate(user.registration_date, true)}</p>
                            </div>
                            <div className={styles.customFields}>
                                {user.user_popups.sort(popupSort).map(popup => {
                                    return <div className={styles.customField} key={popup.id}>
                                        <span>{popup.value}</span>
                                    </div>
                                })}
                            </div>
                            <div className={classNames(styles.counter, styles.cell)}>
                                {user.subtopic_counter}
                            </div>
                            <div className={classNames(styles.counter, styles.cell)}>
                                {user.task_counter}
                            </div>
                            <div className={classNames(styles.counter, styles.cell)}>
                                {user.rated_counter}
                            </div>
                            <div className={classNames(styles.original, styles.cell)}>
                                {user.avg_actuality}
                            </div>
                            <div className={classNames(styles.quality, styles.cell)}>
                                {user.avg_quality}
                            </div>
                            <div className={classNames(styles.expert, styles.cell)}>
                                {user.expertness}
                            </div>
                            <div className={classNames(styles.cell, styles.controls)}>
                                <div
                                  onClick={() => {
                                      navigate(`/dashboard/${user.id}`)
                                      hideHint()
                                  }}
                                  onMouseMove={(e) => showHint(e, eye_hint)}
                                  onMouseLeave={() => hideHint()}
                                >
                                    <EyeIcon/>
                                </div>
                                <div
                                  onClick={() => {
                                    setIsSubtopicListOpen(true)
                                    setCurrentUserId(user.id)
                                  }}
                                  onMouseMove={(e) => showHint(e, edit_hint)}
                                  onMouseLeave={() => hideHint()}
                                >
                                    <EditIcon/>
                                </div>
                                <div
                                  onClick={() => {
                                    setIsConfirmModalOpen(true)
                                    setCurrentUserId(user.id)
                                  }}
                                  onMouseMove={(e) => showHint(e, delete_hint)}
                                  onMouseLeave={() => hideHint()}
                                >
                                    <ThemeTrashIcon/>
                                </div>
                            </div></> : <p style={{padding: 15}}>Данный пользователь не прошел второй этап регистрации</p>}
                        </div>
                    })}
                </div>

                {currentRecipients.length !== 0 && <div className={styles.blockControl} onClick={() => setIsAlertModalOpen(true)}>
                    <svg width="27" height="24" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 3.98864L7.312 8.18427C7.57493 8.35917 7.88392 8.45252 8.2 8.45252C8.51608 8.45252 8.82507 8.35917 9.088 8.18427L15.4 3.98864M2.6 12.7628H13.8C14.2243 12.7628 14.6313 12.5947 14.9314 12.2955C15.2314 11.9963 15.4 11.5906 15.4 11.1675V3.191C15.4 2.7679 15.2314 2.36213 14.9314 2.06295C14.6313 1.76378 14.2243 1.5957 13.8 1.5957H2.6C2.17565 1.5957 1.76869 1.76378 1.46863 2.06295C1.16857 2.36213 1 2.7679 1 3.191V11.1675C1 11.5906 1.16857 11.9963 1.46863 12.2955C1.76869 12.5947 2.17565 12.7628 2.6 12.7628Z" stroke="white" strokeWidth="1.59529" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    <p>Отправить выбранным</p>
                </div>}
            </div> : users.length !== 0 || !isTableLoaded ? <Preloader /> : <p>Пусто =)</p>}
        </div>
    );
};

export default UserPanel;