import React from 'react';
import InfoBlock from "../../../InfoBlock/InfoBlock";
import QuestionCard from "../../../../editors/expertise/QuestionCard";
// @ts-ignore
import expertiseStyles from "../../../../../css/Editor.module.css";

const StepThree = () => {
    return (
        <>
            <InfoBlock>
                <p><b>Оцените качество</b> тестового задания и его <b>актуальность</b>.</p>
            </InfoBlock>
            <div style={{width: 320, margin: '23px auto'}}>
                <QuestionCard>
                    <div className={expertiseStyles.setQualityBlock}>
                        <div className={expertiseStyles.setQualityContainer}>
                            <div className={expertiseStyles.headerAndErrorContainer}>
                                <h2 className={expertiseStyles.setQualityHeader}>Качество задания</h2>
                            </div>
                            <div className={expertiseStyles.taskGradeEditor}>
                                {[1, 2, 3, 4, 5].map((grade, index) => {
                                    return <svg key={index} width="30" height="28" viewBox="0 0 30 28" fill="none" xmlns="http://www.w3.org/2000/svg"
                                                className={grade <= 4 ? expertiseStyles.hoveredStar : expertiseStyles.star}
                                    >
                                        <path d="M14.9974 1.66602L19.1174 10.0127L28.3307 11.3593L21.6641 17.8527L23.2374 27.026L14.9974 22.6927L6.7574 27.026L8.33073 17.8527L1.66406 11.3593L10.8774 10.0127L14.9974 1.66602Z" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                })}
                            </div>
                        </div>

                        <br/>
                        <br/>

                        <div className={expertiseStyles.setQualityBlock}>
                            <div className={expertiseStyles.setQualityContainer}>
                                <div className={expertiseStyles.headerAndErrorContainer}>
                                    <h2 className={expertiseStyles.setQualityHeader}>Актуальность задания</h2>
                                </div>

                                <div className={expertiseStyles.taskGradeEditor}>
                                    {<svg className={expertiseStyles.hoveredFace} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16.0052 29.3346C23.369 29.3346 29.3385 23.3651 29.3385 16.0013C29.3385 8.63751 23.369 2.66797 16.0052 2.66797C8.64141 2.66797 2.67188 8.63751 2.67188 16.0013C2.67188 23.3651 8.64141 29.3346 16.0052 29.3346Z" strokeWidth="1.60811" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M21.3385 21.3346C21.3385 21.3346 19.3385 18.668 16.0052 18.668C12.6719 18.668 10.6719 21.3346 10.6719 21.3346" strokeWidth="2.41216" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M12 12H12.0135" strokeWidth="2.41216" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M20 12H20.0135" strokeWidth="2.41216" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>}

                                    {<svg className={expertiseStyles.hoveredFace} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15.9974 29.3346C23.3612 29.3346 29.3307 23.3651 29.3307 16.0013C29.3307 8.63751 23.3612 2.66797 15.9974 2.66797C8.6336 2.66797 2.66406 8.63751 2.66406 16.0013C2.66406 23.3651 8.6336 29.3346 15.9974 29.3346Z" strokeWidth="1.60811" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M10.6641 21.332L22.6641 18.6654" strokeWidth="2.41216" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M12 12H12.0135" strokeWidth="2.41216" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M20 12H20.0135" strokeWidth="2.41216" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>}

                                    {<svg className={expertiseStyles.hoveredFace} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15.9974 29.3346C23.3612 29.3346 29.3307 23.3651 29.3307 16.0013C29.3307 8.63751 23.3612 2.66797 15.9974 2.66797C8.6336 2.66797 2.66406 8.63751 2.66406 16.0013C2.66406 23.3651 8.6336 29.3346 15.9974 29.3346Z" strokeWidth="1.60811" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M10.6641 20H21.3307" strokeWidth="2.41216" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M12 12H12.0135" strokeWidth="2.41216" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M20 12H20.0135" strokeWidth="2.41216" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>}

                                    {<svg className={expertiseStyles.face} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16.0052 29.3346C23.369 29.3346 29.3385 23.3651 29.3385 16.0013C29.3385 8.63751 23.369 2.66797 16.0052 2.66797C8.64141 2.66797 2.67188 8.63751 2.67188 16.0013C2.67188 23.3651 8.64141 29.3346 16.0052 29.3346Z" strokeWidth="1.60811" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M10.6719 18.668C10.6719 18.668 12.6719 21.3346 16.0052 21.3346C19.3385 21.3346 21.3385 18.668 21.3385 18.668" strokeWidth="2.41216" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M12 12H12.0135" strokeWidth="2.41216" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M20 12H20.0135" strokeWidth="2.41216" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>}

                                    {<svg className={expertiseStyles.face} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16.0052 29.3346C23.369 29.3346 29.3385 23.3651 29.3385 16.0013C29.3385 8.63751 23.369 2.66797 16.0052 2.66797C8.64141 2.66797 2.67188 8.63751 2.67188 16.0013C2.67188 23.3651 8.64141 29.3346 16.0052 29.3346Z" strokeWidth="1.60811" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M10.6719 18.668C10.6719 18.668 12.6719 22.668 16.0052 22.668C19.3385 22.668 21.3385 18.668 21.3385 18.668" strokeWidth="4.02027" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M12 12H12.0135" strokeWidth="2.41216" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M20 12H20.0135" strokeWidth="2.41216" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>}
                                </div>
                            </div>
                        </div>
                    </div>
                </QuestionCard>
            </div>
        </>
    );
};

export default StepThree;