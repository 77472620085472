export interface IMailList {
    id: number
    name: string
    date?: string
    text: string
    delivered: number
    opened: number
    total: number
}

type MailLists = IMailList[]

export interface IMailListData {
    deadline?: string
    status?: string
    mail_list: MailLists
}

type date = string | "now"

export interface ICustomMailListIn {
    name: string
    date: date
    text: string
    recipients: number[]
}

interface AdminSubtopic {
    id: number
    name: string
    user_counter: number
    task_counter: number
}

interface AdminTheme {
    id: number
    name: string
    subtopics: AdminSubtopic[]
}

export type AdminThemes = AdminTheme[]

interface variable {
    name: string
    description: string
}

export type variables = variable[]

export enum RecipientStatuses {
    accepted = 1,
    delivered = 2,
    failed = 3,
    opened = 4,
    rejected = 5,
    unknown = 99,
}

export interface IRecipient {
    id: number
    email: string
    status?: RecipientStatuses
}