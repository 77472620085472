import React, {useEffect, useRef, useState} from 'react';
import ErrorIcon from "../icons/ErrorIcon";
import EyeIcon from "../icons/EyeIcon";
import {ISignInProps} from "../../types/components/auth";
import {check, logIn} from "../../http/authRequests";
import {UserActionTypes} from "../../types/reducers/user";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";

const SignIn = ({getRegistration, getForget}: ISignInProps) => {
    const navigate = useNavigate()
    const current_block = useRef<HTMLDivElement | null>(null)
    const [buttonText, setButtonText] = useState('Войти')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [isHidden, setIsHidden] = useState(true)
    const [errors, setErrors] = useState({
        email: '',
        password: ''
    })

    const dispatch = useDispatch()

    useEffect(() => {
        if (current_block.current)
            current_block.current.style.opacity = "1"
    }, [])

    const submit = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()

        let emailError = ''
        let passwordError = ''

        if (email === '' || password === '') {
            if (password === '') {
                passwordError = 'Не заполнен пароль';
            }

            if (email === '') {
                emailError = 'Не заполнен email'
            }

            setErrors({
                email: emailError,
                password: passwordError
            })
        } else {
            setButtonText('Подождите...')
            logIn(email, password).then(data => {
                localStorage.setItem('token', data.access_token)
                check().then(userData => {
                    dispatch({type: UserActionTypes.SET_USER, payload: {
                        id: userData.id,
                        isAuth: true,
                        name: userData.last_name + ' ' + userData.first_name + ' ' + userData.second_name
                    }})
                }).then(() => {
                    navigate('/')
                    window.location.reload()
                })
            }).catch(error => {
                if (error.message === "Request failed with status code 401") {
                    setErrors({
                        email: "Неверно введен email или пароль",
                        password: ""
                    })
                }
                setButtonText('Войти')
            })
        }
    }

    const eyeHandler = () => {
        setIsHidden(!isHidden)
    }

    return (
        <div className="form-wrapper" ref={current_block}>
            <form className="login-form">
                <input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="input"
                    placeholder="you@example.com"
                    type="email"
                    id="email"
                    autoComplete="on"
                />
                <div className="error_container">
                    {errors.email && (
                        <label className="error" htmlFor="email"><ErrorIcon /><span>{errors.email}</span></label>
                    )}
                </div>
                <div className="input password">
                    <input
                        className="input-password"
                        placeholder="password"
                        type={isHidden ? "password" : "text"}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        id="password"
                    />
                    <div className="eye-container" onClick={eyeHandler}>
                        <EyeIcon />
                    </div>
                </div>
                <div className="error_container">
                    {errors.password && (
                        <label className="error" htmlFor="password"><ErrorIcon /><span>{errors.password}</span></label>
                    )}
                </div>
                <div className="sign_in_line_container">
                    <hr className="sign_in_line"/>
                </div>
                <button className="submitButton signInButton" type="submit" onClick={submit}>
                    {buttonText}
                </button>
            </form>
            <div className="controls controlsSignIn">
                <div
                    className="control controlSignIn"
                    onClick={getRegistration}
                >
                    Зарегистрироваться
                </div>
                <div
                    className="control controlSignIn2"
                    onClick={getForget}
                >
                    Забыли пароль?
                </div>
            </div>
        </div>
    );
};

export default SignIn;