import React from 'react';
// @ts-ignore
import styles from "../../../css/Editor.module.css";
import {IExercise} from "../../../types/entities/exercise";
import QuestionCard from "./QuestionCard";
import RadioExerciseOptions from "./RadioExerciseOptions";
import CheckboxExerciseOptions from "./CheckboxExerciseOptions";
import SequenceExerciseOptions from "./SequenceExerciseOptions";
import RatingExerciseBlock from "./RatingExerciseBlock";
import ErrorIcon from "../../icons/ErrorIcon";
import classNames from "classnames";
import {useTypedSelector} from "../../../hooks/useTypedSelector";

const Exercise = (props: {exercise: IExercise}) => {
    const isError = useTypedSelector(state => state.exercise.errors?.answer_error)

    return (
        <div className={styles.taskContentContainer}>
             <div>
                 <QuestionCard>
                    <span className={styles.exerciseNumber}>
                        {props.exercise.done + 1}
                    </span>
                    <div className={styles.exercise}>
                        <p className={styles.exerciseQuestion}>{props.exercise.task_content.question}</p>
                        {props.exercise.task_type === "radio" && <RadioExerciseOptions options={props.exercise.task_content.options}/>}
                        {props.exercise.task_type === "checkbox" && <CheckboxExerciseOptions options={props.exercise.task_content.options}/>}
                        {props.exercise.task_type === "sequence" && <SequenceExerciseOptions options={props.exercise.task_content.options}/>}
                    </div>
                 </QuestionCard>
                 <div className={classNames(styles.errorContainer, styles.errorContainerQuestion)}>
                     {isError && <div className={styles.error}>
                         <ErrorIcon />
                         <p className={styles.errorText}>Ответьте на задание</p>
                     </div>}
                 </div>
             </div>
            <RatingExerciseBlock />
        </div>
    );
};

export default Exercise;