import {$authHost} from "../index";
import {ICustomMailListIn, IMailListData, IRecipient, variables} from "../../types/admin/http/mailListsRequests";

export const getMailListsData = async (group: string): Promise<IMailListData> => {
    const response = await $authHost.get(`admin/mail/all?mail_group=${group}`)
    return response.data
}

export const getVariable = async (): Promise<variables> => {
    const response = await $authHost.get(`admin/mail/variables`)
    return response.data
}

export const editCommonMailList = async (id: number, name: string, text: string) => {
    const response = await $authHost.post(`admin/mail/common`, { id, name, text })
    return response.data
}

export const saveCustomMailList = async (customMailListIn: ICustomMailListIn): Promise<any> => {
    const response = await $authHost.post(`admin/mail/custom`, customMailListIn)
    return response.data
}

export const getRecipientsByFilter = async (status_filter: string, id: number): Promise<{message_statuses: IRecipient[]}> => {
    const response = await $authHost.get(`admin/mail/status/?status_filter=${status_filter}&message_id=${id}`)
    return response.data
}

export const deleteMailList = async (id: number): Promise<any> => {
    const response = await $authHost.delete(`admin/mail/custom/delete?mail_id=${id}`)
    return response.data
}

