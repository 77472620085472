import React, {FC} from 'react';
import HighlightLogo from "../icons/HighlightLogo";
import MailIcon from "../icons/MailIcon";
import {useTypedSelector} from "../../hooks/useTypedSelector";

const InstructionPage: FC = () => {
    const supportMail = useTypedSelector(state => state.app.support_mail)

    return (
        <>
            <HighlightLogo />
            <div className="instructionBlock">
                <MailIcon />
                <div className="instructionText">
                    <p className="word">Ура!</p>
                    <p className="text">Вы успешно зарегистрировались. Инструкция с описанием дальнейших действий отправлена на вашу почту.</p>
                </div>
            </div>
            <a href={"mailto" + supportMail} className="support instructionSupport">{supportMail}</a>
        </>
    );
};

export default InstructionPage;