import React from 'react';
// @ts-ignore
import styles from "../../../css/admin/Script.module.css";
import {WizardStages} from "../../../types/entities/status";
import WizardNavigationStage from "./WizardNavigationStage";


const WizardNavigation = () => {

    return (
        <div className={styles.wizardNavigationBlockContainer}>
            <div className={styles.wizardNavigationBlock}>
                <WizardNavigationStage stage={WizardStages.PROJECT_DESCRIPTION}/>
                <WizardNavigationStage stage={WizardStages.REGISTER}/>
                <WizardNavigationStage stage={WizardStages.FIRST_STEP}/>
                <WizardNavigationStage stage={WizardStages.WAITING_FOR_SECOND_STEP}/>
                <WizardNavigationStage stage={WizardStages.SECOND_STEP}/>
                <WizardNavigationStage stage={WizardStages.THIRD_STEP}/>
            </div>
        </div>
    );
};

export default WizardNavigation;