import React, {useEffect, useState} from 'react';
// @ts-ignore
import styles from "../../../css/Player.module.css";
import PlusIcon from "../../icons/PlusIcon";
import ReadyIcon from "../../icons/ReadyIcon";
import EditIcon from "../../icons/EditIcon";
import {useNavigate} from "react-router-dom";

const ControlLink = (props: {variant: string, /*"Добавить задания" | "Изменить" | "Начать" | "Продолжить экспертизу" | "Экспертиза завершена"*/ id: number}) => {
    const [isHovered, setIsHovered] = useState(false)
    const navigate = useNavigate()

    return (
        <div
            style={{cursor: props.variant === "Экспертиза завершена" ? "default" : "pointer"}}
            className={styles.addControl}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={() => {
                if (props.variant !== "Экспертиза завершена") {
                    if (props.variant === "Добавить задания" || props.variant === "Изменить") {
                        navigate(`/set_tasks/${props.id}`)
                    } else {
                        navigate(`/expertise/${props.id}`)
                    }
                }
            }}
        >
            {props.variant === "Добавить задания" ?
                <PlusIcon isHovered={isHovered}/>
                :
                props.variant === "Экспертиза завершена" ?
                    <ReadyIcon />
                    :
                    <EditIcon isHovered={isHovered}/>
            }
            <span
                className={styles.addControlSpan}
                style={{
                    color: props.variant === "Экспертиза завершена" ?
                        "#02D589"
                        :
                        isHovered ?
                            "#FFD556"
                            :
                            "black",
                    textDecoration: props.variant === "Экспертиза завершена" ? "none" : "underline",
                    cursor: props.variant === "Экспертиза завершена" ? "default" : "pointer"
                }}
            >{props.variant}</span>
        </div>
    );
};

export default ControlLink;