import {$authHost} from "../index";
import {ICluster, IUpdateOrAddSubtopicIn} from "../../types/admin/http/themeRequests";
import {ITopic} from "../../types/admin/http/userRequests";

export const getClusters = async (): Promise<ICluster[]> => {
    const response = await $authHost.get(`admin/clusters/`)
    return response.data
}

export const getTopicsByClusterId = async (id: number): Promise<ITopic[]> => {
    const response = await $authHost.get(`admin/themes/status/?cluster_id=${id}`)
    return response.data
}

export const updateSubtopic = async (data: IUpdateOrAddSubtopicIn): Promise<any> => {
    const response = await $authHost.put(`admin/subtopic/`, data)
    return response.data
}

export const addSubtopic = async (data: IUpdateOrAddSubtopicIn): Promise<any> => {
    const response = await $authHost.post(`admin/subtopic/`, data)
    return response.data
}

export const addTopic = async (name: string): Promise<any> => {
    const response = await $authHost.post(`admin/topic/`, {name})
    return response.data
}

export const deleteSubtopics = async (ids: number[], target_subtopic_id: number): Promise<any> => {
    const response = await $authHost.delete(`admin/subtopic/`, {data: {ids, target_subtopic_id}})
    return response.data
}