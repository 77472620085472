import React from 'react';
// @ts-ignore
import styles from "../../../css/PersonalArea.module.css";
import FooterLogo from "../../icons/FooterLogo";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerContent}>
        <FooterLogo />
      </div>
    </footer>
  );
};

export default Footer;