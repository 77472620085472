import {IExercise, initialExercise} from "../../types/entities/exercise";
import {ExerciseAction, ExerciseActionTypes} from "../../types/reducers/exercise";

const initialState: IExercise = initialExercise

export const exerciseReducer = (state=initialState, action: ExerciseAction): IExercise => {
    switch (action.type) {
        case ExerciseActionTypes.SET_EXERCISE:
            return action.payload
        case ExerciseActionTypes.SET_ANSWERS:
            return {...state, answer: action.payload}
        case ExerciseActionTypes.SET_QUALITY_ASSESSMENT:
            return {...state, quality_assessment: action.payload}
        case ExerciseActionTypes.SET_ACTUALITY_ASSESSMENT:
            return {...state, actuality_assessment: action.payload}
        case ExerciseActionTypes.SET_COMMENT:
            return {...state, comment: action.payload}
        case ExerciseActionTypes.SET_ERRORS:
            return {...state, errors: action.payload}
        default:
            return state
    }
}