import {IRecipient} from "../../types/admin/http/mailListsRequests";
import {RecipientsAction, RecipientsActionTypes} from "../../types/reducers/recipients";
import {isRecipientIn} from "../../utils/isRecipientIn";

const initialState: IRecipient[] = []

export const recipientsReducer = (state=initialState, action: RecipientsAction): IRecipient[] => {
    switch (action.type) {
        case RecipientsActionTypes.REMOVE_RECIPIENT:
            return state.filter(recipient => {
                return recipient.id !== action.payload
            })
        case RecipientsActionTypes.ADD_RECIPIENTS:
            return [...action.payload]
        case RecipientsActionTypes.REMOVE_RECIPIENTS:
            return state.filter(recipient => {
                if (!isRecipientIn(recipient.id, action.payload)) {
                    return recipient
                }
            })
        case RecipientsActionTypes.CLEAR_RECIPIENTS:
            return initialState
        default:
            return state
    }
}