import React, {useEffect, useRef, useState} from 'react';
import ErrorIcon from "../icons/ErrorIcon";
import Button from "../common/Button";
import {ISignUpEmailProps} from "../../types/components/auth";
import {sentEmail} from "../../http/authRequests";
import {useNavigate} from "react-router-dom";
import CustomCheckbox from "../common/CustomCheckbox";
import {downloadFileFromServer} from "../../utils/downloadFileFromServer";

const SignUpEmail = ({getLogin}: ISignUpEmailProps) => {
    const current_block= useRef<HTMLDivElement | null>(null)
    const [buttonText, setButtonText] = useState('Зарегистрироваться')
    const [email, setEmail] = useState('')
    const [emailErrors, setEmailErrors] = useState('')
    const [checkError, setCheckError] = useState('')
    const [isRulesChecked, toggleRulesCheck] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        if (current_block.current)
            current_block.current.style.opacity = "1"
    }, [])

    const validateEmail = (email: string) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const submit = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()

        if (!validateEmail(email) || !isRulesChecked) {
            if (!validateEmail(email)) {
                setEmailErrors('Введен некорректный email')
            } else {
                setEmailErrors('')
            }

            if (!isRulesChecked) {
                setCheckError('Вы не согласились с правилами конкурса')
            } else {
                setCheckError('')
            }
        } else {
            setButtonText('Подождите...')
            sentEmail(email).then(() => {
                navigate('/instruction')
            }).catch(error => {
                setButtonText('Зарегистрироваться')
                if (error.message === 'Request failed with status code 403') {
                    setEmailErrors('Такой email уже зарегистрирован')
                    setCheckError('')
                } else if (error.message === 'Request failed with status code 406') {
                    setEmailErrors('Недопустимый email')
                    setCheckError('')
                }
            })
        }
    }

    return (
        <div className="form-wrapper" ref={current_block}>
            <form className="login-form">
                <input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="input"
                    placeholder="you@example.com"
                    type="email"
                    id="email"
                    autoComplete="off"
                />
                <div className="error_container">
                    {emailErrors && (
                        <label className="error" htmlFor="email"><ErrorIcon /><span>{emailErrors}</span></label>
                    )}
                </div>
                <div className="rules-checkbox">
                    <div onClick={() => toggleRulesCheck(!isRulesChecked)} className="checkboxArea">
                        <CustomCheckbox isChecked={isRulesChecked}/>
                        <span className="labelText">Я принимаю</span>
                    </div>
                    <a
                        href="#"
                        onClick={() => downloadFileFromServer(
                        'wizard/get_rules',
                        'rules.pdf'
                        )}
                        className="rules"
                    >
                        правила конкурса
                    </a>
                </div>
                <div className="error_container">
                    {checkError && (
                        <label className="error" htmlFor="email"><ErrorIcon /><span>{checkError}</span></label>
                    )}
                </div>
                <hr className="sign_up_line"/>
                <Button width="300px" height="50px" fontSize="20px" variant={"primary"} margin="0 auto" submit={submit}>
                    {buttonText}
                </Button>
            </form>
            <div className="controls">
                <div
                    className="control controlOne"
                    onClick={getLogin}
                >
                    Уже зарегистрированы?
                </div>
            </div>
        </div>
    );
};

export default SignUpEmail;