import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import Header from "../common/Header/Header";
// @ts-ignore
import styles from "../../css/Editor.module.css";
import Button from "../common/Button";
import {clearTask, getTasks, saveTasks} from "../../http/editorRequests";
import {ITask} from "../../types/entities/task";
import Task from "./Task";
import {checkErrors, isTaskNotEmpty} from "../../utils/checkErrorsFuncs";
import InfoBlock from "../steps/InfoBlock/InfoBlock";
import Modal from "./Modal";
import AnotherButton from "../common/AnotherButton";
import BigReadyIcon from "../icons/BigReadyIcon";
import {useTypedSelector} from "../../hooks/useTypedSelector";
import Preloader from "../common/Preloader";

const TaskEditor = () => {
    const isMobile = useState(document.documentElement.clientWidth <= 900)[0]
    const subtopic_id = Number(useParams().subtopic_id)
    const [tasks, setTasks] = useState<ITask[]>([])
    const [errors, setErrors] = useState<any>({})
    const [isModalOpen, setIsModalOpen] = useState(false)
    const navigate = useNavigate()
    const [buttonWord, setButtonWord] = useState('Сохранить')
    const [subtopic, setSubtopic] = useState('')
    const [topic, setTopic] = useState('')
    const [subtopicDescription, setSubtopicDescription] = useState('')
    const [isTasksLoaded, setIsTasksLoaded] = useState(false)
    const [maxQuestionSymbols, setMaxQuestionSymbols] = useState(350)
    const [maxOptionSymbols, setMaxOptionSymbols] = useState(150)

    const maxItems = useTypedSelector(state => state.app.max_tasks_on_subtopic)
    const deadline = useTypedSelector(state => state.status.stage_deadline)

    useEffect(() => {
        setIsTasksLoaded(false)
        getTasks(subtopic_id).then(data => {
            setIsTasksLoaded(true)
            setTasks(data.tasks)
            setTopic(data.subtopic_data.topic)
            setSubtopic(data.subtopic_data.subtopic)
            setSubtopicDescription(data.subtopic_data.description)
            setMaxOptionSymbols(data.max_symbols_for_option)
            setMaxQuestionSymbols(data.max_symbols_for_question)
        })
    }, [])

    const setQuestion = (taskId: number, question: string) => {
        let newTasksObject = JSON.parse(JSON.stringify(tasks));

        newTasksObject = newTasksObject.map((task: ITask) => {
            if (task.id === taskId) {
                return {...task, task_content: {...task.task_content, question: question}}
            } else {
                return task
            }
        })

        setTasks(newTasksObject)
    }

    const clearCurrentTask = async (taskId: number) => {
        let newTasksObject = JSON.parse(JSON.stringify(tasks));

        newTasksObject = newTasksObject.map((task: ITask) => {
            if (task.id === taskId) {
                let newOptions = task.task_content.options.map(option => {
                    return {...option, text: ''}
                })

                return {...task, answers: '[]', task_content: {options: newOptions, question: ''}}
            } else {
                return task
            }
        })

        await clearTask(taskId)
        setTasks(newTasksObject)
    }

    const setOption = (taskId: number, optionId: number, optionText: string) => {
        let newTasksObject = JSON.parse(JSON.stringify(tasks));

        let currentTask = newTasksObject.find((task: ITask) => {
            return task.id === taskId
        })

        currentTask.task_content.options = currentTask.task_content.options.map((option: {id: number, text: string}) => {
            if (option.id === optionId) {
                return {...option, text: optionText}
            } else {
                return option
            }
        })

        setTasks(newTasksObject)
    }

    const setAnswers = (taskId: number, answers: number[]) => {
        let newTasksObject = JSON.parse(JSON.stringify(tasks));

        newTasksObject = newTasksObject.map((task: ITask) => {
            if (task.id === taskId) {
                return {...task, answers: JSON.stringify(answers)}
            } else {
                return task
            }
        })

        setTasks(newTasksObject)
        saveCurrentTasks(false, newTasksObject)
    }

    const saveCurrentTasks = (isSubmit=false, newTaskObject?: ITask[]) => {
        let tasksForSave: ITask[]

        if (newTaskObject) {
            tasksForSave = newTaskObject
        } else {
            tasksForSave = tasks
        }

        let finalTasksForSave = {
            tasks: tasksForSave,
            save_on_button: isSubmit
        }

        saveTasks(finalTasksForSave).then(() => {
            if (isSubmit) {
                setButtonWord('Сохранить')
                setIsModalOpen(true)
            }
        }).catch((error) => {
            if (isSubmit) {
                if (error.message === 'Request failed with status code 406') {
                    alert(`Длина заданий не должна превышать ${maxQuestionSymbols}, а вариантов ответа - ${maxOptionSymbols} символов.`)
                } else {
                    alert(error.message)
                }
                setButtonWord('Сохранить')
            }
        })
    }

    const submit = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()

        let currentTasks = JSON.parse(JSON.stringify(tasks)).filter((task: ITask) => {
            return isTaskNotEmpty(task)
        })

        let currentErrors = checkErrors(currentTasks, maxOptionSymbols, maxQuestionSymbols)
        let keys = Object.keys(currentErrors)
        setErrors(currentErrors)

        if (keys.length !== 0) {
            let keysNumber = keys.map((key) => Number(key))
            let firstError = Math.min(...keysNumber)

            document.querySelector("#task" + firstError)?.scrollIntoView({
                behavior: "smooth",
                block: "center"
            })
        } else {
            setButtonWord('Подождите...')
            saveCurrentTasks(true)
        }

        setErrors(currentErrors)
    }

    return (
        <>
            {isModalOpen && <Modal>
                <div className={styles.readyModalContent}>
                    <BigReadyIcon />
                    <h2 className={styles.modalHeader}>Ваши задания успешно сохранены</h2>
                    <AnotherButton width={181} height={54} onClick={() => navigate('/second_step')}>К списку тем</AnotherButton>
                </div>
            </Modal>}
            <Header />
            <div className={styles.playerWindow} id="playerWindow">
                {isMobile ? <InfoBlock>
                    <p className={styles.theme}>Придумайте и введите в форму задания по теме <b>“{subtopic}”</b> из раздела "{topic}".</p>
                    <p className={styles.theme} dangerouslySetInnerHTML={{__html: subtopicDescription}} />
                    <p className={styles.theme}>Вы можете ввести от 1 до {maxItems} заданий, но чем больше их будет, тем выше ваши шансы на победу. Вы можете возвращаться к редактированию заданий до завершения 2 этапа конкурса ({deadline}). Чтобы система сохранила задание, введите его целиком: вопрос, варианты ответа, укажите правильный ответ.</p>
                    <p className={styles.theme}>Частично введенные задания не сохраняются.</p>
                </InfoBlock>
                :
                <div className={styles.themeBlockContainer}>
                    <div className={styles.themeBlock}>
                        <p className={styles.theme}>Придумайте и введите в форму задания по теме <b>“{subtopic}”</b> из раздела "{topic}".</p>
                        <p className={styles.theme} dangerouslySetInnerHTML={{__html: subtopicDescription}} />
                        <p className={styles.theme}>Вы можете ввести от 1 до {maxItems} заданий, но чем больше их будет, тем выше ваши шансы на победу. Вы можете возвращаться к редактированию заданий до завершения 2 этапа конкурса ({deadline}). Чтобы система сохранила задание, введите его целиком: вопрос, варианты ответа, укажите правильный ответ.</p>
                        <p className={styles.theme}><span style={{fontWeight: "bold"}}>Частично введенные задания не сохраняются.</span></p>
                    </div>
                </div>
                }

                {isTasksLoaded ? <form className={styles.setTaskForm}>
                    {tasks.map((task, index) => {
                        return <Task
                            key={index}
                            task={task}
                            number={index+1}
                            setQuestion={setQuestion}
                            setOption={setOption}
                            setAnswers={setAnswers}
                            saveCurrentTasks={saveCurrentTasks}
                            clearTask={clearCurrentTask}
                            errors={task.id in errors ? errors[task.id] : []}
                            max_symbols_for_question={maxQuestionSymbols}
                            max_symbols_for_option={maxOptionSymbols}
                        />
                    })}

                    <Button
                        submit={submit}
                        width="181px"
                        height="54px"
                        variant="primary"
                        fontSize="18px"
                        margin="80px auto 0 auto"
                    >
                        {buttonWord}
                    </Button>
                </form>
                :
                <Preloader/>
                }
            </div>
        </>
    );
};

export default TaskEditor;