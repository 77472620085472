import React from 'react';

const StarIcon = () => {
    return (
        <svg width="30" height="28" viewBox="0 0 30 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.9974 1.66406L19.1174 10.0107L28.3307 11.3574L21.6641 17.8507L23.2374 27.0241L14.9974 22.6907L6.7574 27.0241L8.33073 17.8507L1.66406 11.3574L10.8774 10.0107L14.9974 1.66406Z" stroke="black" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};

export default StarIcon;