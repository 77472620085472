import {variables} from "../admin/http/mailListsRequests";

enum VariablesActionTypes {
    SET_VARS = 'SET_VARS',
    UNSET_VARS = 'UNSET_VARS'
}

interface ISetVarsAction {
    type: VariablesActionTypes.SET_VARS
    payload: variables
}

interface IUnsetVarsAction {
    type: VariablesActionTypes.UNSET_VARS
}

type VariablesAction = ISetVarsAction | IUnsetVarsAction

export {VariablesActionTypes}
export type {VariablesAction}