import React from 'react';

const UserpickIcon = (props: {width: string}) => {
    return (
        <svg width={props.width} height={props.width} viewBox="0 0 45 46" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.4654 45.284C17.4965 45.284 12.5976 43.4956 8.74841 40.3478C3.63954 36.0554 0.700195 29.7599 0.700195 22.9637C0.700195 10.7304 10.498 0.714844 22.4654 0.714844C26.7344 0.714844 30.9335 2.00256 34.4327 4.36337C37.8619 6.65264 40.5213 9.87192 42.271 13.6635C43.6007 16.5966 44.2305 19.6729 44.2305 22.9637C44.2305 25.9683 43.6706 28.9015 42.4809 31.6915C42.4109 31.9061 42.3409 32.0492 42.201 32.2638C41.0112 34.9108 39.3316 37.2716 37.3021 39.2032C33.3129 43.1379 28.0641 45.284 22.4654 45.284Z" fill="url(#paint0_linear_2892_7539)"/>
            <path d="M22.465 1.43079C26.804 1.43079 30.7932 2.71851 34.0824 5.00778C37.3717 7.2255 40.0311 10.3733 41.6407 14.0218C42.9005 16.7403 43.5303 19.8165 43.5303 22.9642C43.5303 25.9689 42.9704 28.8305 41.8507 31.4059C41.7807 31.6205 41.7107 31.7636 41.6407 31.9782C40.521 34.5537 38.8414 36.8429 36.8818 38.7029C33.1026 42.2799 28.0638 44.4977 22.465 44.4977C17.4261 44.4977 12.8072 42.7092 9.16796 39.7045C4.479 35.7698 1.39969 29.7605 1.39969 22.9642C1.39969 11.0886 10.8476 1.43079 22.465 1.43079ZM22.465 0C10.0778 0 0 10.3017 0 22.9642C0 29.9036 3.00933 36.4137 8.32815 40.8491C12.3173 44.14 17.3561 46 22.465 46C28.2037 46 33.6625 43.7823 37.8616 39.776C40.0311 37.7014 41.7107 35.3406 42.9704 32.5505C43.0404 32.3359 43.1804 32.1213 43.2504 31.9782C44.4401 29.1166 45 26.112 45 22.9642C45 19.6019 44.3002 16.3826 42.9704 13.3779C41.2208 9.44323 38.4215 6.15241 34.9222 3.7916C31.2131 1.28771 26.944 0 22.465 0Z" fill="#010202"/>
            <path d="M13.9971 39.4189V4.29297H34.2926L34.5025 4.43605C37.9318 6.72532 40.5912 9.9446 42.3408 13.7362L42.4108 13.8793V32.1219L42.3408 32.265C41.151 34.9119 39.4714 37.2727 37.4419 39.2043L37.2319 39.4189H13.9971Z" fill="white"/>
            <path d="M34.0823 5.00892C37.3715 7.22665 40.0309 10.3744 41.6406 14.0229V31.9794C40.5208 34.5548 38.8412 36.8441 36.8816 38.7041H14.6966V5.00892H34.0823ZM34.5022 3.57812H34.0823H14.6966H13.2969V5.00892V38.7041V40.1349H14.6966H36.8816H37.4415L37.8614 39.7772C40.0309 37.7025 41.7106 35.3417 42.9703 32.5517L43.1103 32.2655V31.9794V14.0229V13.7368L42.9703 13.4506C41.2207 9.51592 38.4213 6.22509 34.9221 3.86428L34.5022 3.57812Z" fill="black"/>
            <path d="M21.9049 27.9017C22.7552 27.9017 23.4445 27.197 23.4445 26.3278C23.4445 25.4586 22.7552 24.7539 21.9049 24.7539C21.0546 24.7539 20.3652 25.4586 20.3652 26.3278C20.3652 27.197 21.0546 27.9017 21.9049 27.9017Z" fill="black"/>
            <path d="M33.4527 27.9017C34.3031 27.9017 34.9924 27.197 34.9924 26.3278C34.9924 25.4586 34.3031 24.7539 33.4527 24.7539C32.6024 24.7539 31.9131 25.4586 31.9131 26.3278C31.9131 27.197 32.6024 27.9017 33.4527 27.9017Z" fill="black"/>
            <path d="M31.003 29.6172C31.003 31.5488 29.4633 33.1226 27.5738 33.1226C25.6842 33.1226 24.1445 31.5488 24.1445 29.6172H31.003Z" fill="black"/>
            <defs>
                <linearGradient id="paint0_linear_2892_7539" x1="0.700265" y1="22.9853" x2="44.2724" y2="22.9853" gradientUnits="userSpaceOnUse">
                    <stop stopColor="white"/>
                    <stop offset="0.4086" stopColor="#B3B3B2"/>
                    <stop offset="1"/>
                </linearGradient>
            </defs>
        </svg>

    );
};

export default UserpickIcon;