import {$authHost} from "./index";
import {ITask} from "../types/entities/task";
import {IExercise, IExerciseDataObject} from "../types/entities/exercise";
import {isClientDemo} from "../utils/demo/demo";
import {
    cleatTaskDemo,
    getExerciseDemo,
    getSubtopicDataDemo,
    getTasksDemo, saveExerciseDemo,
    saveTasksDemo,
} from "../utils/demo/mocks";

export const getTasks = async (subject_id: number | undefined): Promise<any> => {
    const response = isClientDemo() ? getTasksDemo(Number(subject_id)) : await $authHost.get(`tasks/${subject_id}`)
    return response.data
}

export const clearTask = async (id: number): Promise<any> => {
    const response = isClientDemo() ? cleatTaskDemo() : await $authHost.delete(`tasks/?id=${id}`)
    return response.data
}

export const saveTasks = async (tasksObject: {tasks: ITask[], save_on_button: boolean}): Promise<any> => {
    const response = isClientDemo() ? saveTasksDemo(tasksObject.tasks) : await $authHost.post(`tasks/`, tasksObject)
    return response.data
}

export const getExercise = async (id: number): Promise<IExercise> => {
    const response = isClientDemo() ? getExerciseDemo() : await $authHost.get(`expertise/${id}`)
    return response.data
}

export const saveExercise = async (exercise: IExerciseDataObject): Promise<any> => {
    const response = isClientDemo() ? saveExerciseDemo(exercise) : await $authHost.post(`expertise/`, exercise)
    return response.data
}

export const getSubtopicData = async (id: number): Promise<any> => {
    const response = isClientDemo() ? getSubtopicDataDemo(id) : await $authHost.get(`services/topic/subtopic/${id}`)
    return response.data
}