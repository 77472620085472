import React from 'react';

const GenerateIcon = () => {
    return (
        <svg className="generate-icon" width="17" height="21" viewBox="0 0 17 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 1.98242V5.98242V1.98242ZM1 3.98242H5H1ZM4 15.9824V19.9824V15.9824ZM2 17.9824H6H2ZM9.00958 7.9528C9.64146 6.05745 12.3585 6.05745 12.9904 7.9528V7.9528C13.1757 8.50852 13.6046 8.95893 14.1531 9.16463V9.16463C15.8327 9.79454 15.8327 12.1703 14.1531 12.8002V12.8002C13.6046 13.0059 13.1757 13.4563 12.9904 14.012V14.012C12.3585 15.9074 9.64146 15.9074 9.00958 14.012V14.012C8.82432 13.4563 8.39535 13.0059 7.84687 12.8002V12.8002C6.1673 12.1703 6.1673 9.79454 7.84687 9.16463V9.16463C8.39535 8.95893 8.82432 8.50852 9.00958 7.9528V7.9528Z" stroke="#323232" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};

export default GenerateIcon;