import {variables} from "../../types/admin/http/mailListsRequests";
import {VariablesAction, VariablesActionTypes} from "../../types/reducers/variables";

const initialState: variables = []

export const variablesReducer = (state=initialState, action: VariablesAction): variables => {
    switch (action.type) {
        case VariablesActionTypes.SET_VARS:
            return action.payload
        case VariablesActionTypes.UNSET_VARS:
            return initialState
        default:
            return state
    }
}