const validatePassword = (password: string) => {
    let isUpperCaseSymbol = false
    let isLowerCaseSymbol = false
    let isDigit = false
    let isSpecialSymbol = false
    let isLengthCorrect = password.length > 7

    for (let symbol of password) {
        if (symbol === symbol.toUpperCase() && !"0123456789!№;%:?*()_+=-".includes(symbol)) {
            isUpperCaseSymbol = true
        } else if (symbol === symbol.toLowerCase() && !"0123456789!№;%:?*()_+=-".includes(symbol)) {
            isLowerCaseSymbol = true
        } else if (!isNaN(Number(symbol))) {
            isDigit = true
        } else if ("!№;%:?*()_+=-".includes(symbol)) {
            isSpecialSymbol = true
        }

        if (isUpperCaseSymbol && isLowerCaseSymbol && isDigit && isSpecialSymbol && isLengthCorrect) {
            return true
        }
    }

    return {
        isUpperCaseSymbol,
        isLowerCaseSymbol,
        isDigit,
        isSpecialSymbol,
        isLengthCorrect
    }
}

export const checkValidation = (password: string, setChecks: any) => {
    const isValidate = validatePassword(password)
    if (isValidate === true) {
        setChecks({
            length: true,
            letterCase: true,
            hasDigits: true,
            hasSymbol: true
        })
    } else {
        setChecks({
            length: isValidate.isLengthCorrect,
            letterCase: isValidate.isLowerCaseSymbol && isValidate.isUpperCaseSymbol,
            hasDigits: isValidate.isDigit,
            hasSymbol: isValidate.isSpecialSymbol
        })
    }
}

export const generateComputedPassword = () => {
    let passwordIncorrect = true
    let password = "";
    while (passwordIncorrect) {
        let symbols = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!№;%:?*()_=";
        for (let i = 0; i < 16; i++) {
            password += symbols.charAt(Math.floor(Math.random() * symbols.length));
        }

        if (validatePassword(password)) {
            passwordIncorrect = false
        }
    }

    return password
}