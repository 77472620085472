import React, {Dispatch, SetStateAction} from 'react';
// @ts-ignore
import styles from "../../../css/admin/Admin.module.css"
import AdminDropDown from "./AdminDropDown";
import SearchIcon from "../../icons/admin/SearchIcon";
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import {useDispatch} from "react-redux";
import {ClustersActionTypes} from "../../../types/reducers/clusters";
import {IOption} from "../../../types/admin/props/adminDropDown";

const FunctionalHeader = (props: {header: string, counter?: number, searchValue?: string, searchValueSetter?: Dispatch<SetStateAction<string>>}) => {
    const options = useTypedSelector(state => state.clusters)
    const dispatch = useDispatch()

    const clusterSetter = (option: IOption) => {
        dispatch({type: ClustersActionTypes.SET_CURRENT_CLUSTER, payload: option})
    }

    return (
        <div className={styles.functionalHeader}>
            <h1>{props.header}{props.counter !== 0 && props.counter !== undefined && ` (${props.counter})`}</h1>
            <AdminDropDown
                maxHeight={150}
                defaultValue={options.current_cluster}
                width={150}
                optionList={options.clusters}
                setter={clusterSetter}
            />
            <div className={styles.search}>
                <SearchIcon />
                <input
                    type="text"
                    placeholder={"Найти в таблице"}
                    value={props.searchValue ? props.searchValue : ''}
                    onChange={(e) => {
                        if (props.searchValueSetter)
                            props.searchValueSetter(e.target.value)
                    }}
                />
            </div>
        </div>
    );
};

export default FunctionalHeader;