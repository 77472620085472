import React from 'react';

const ReadyIcon = () => {
    return (
        <svg width="20" height="20" style={{cursor: "default"}} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 14.6673L16 18.6673L29.3333 5.33398" stroke="#02D589" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M28 16V25.3333C28 26.0406 27.719 26.7189 27.219 27.219C26.7189 27.719 26.0406 28 25.3333 28H6.66667C5.95942 28 5.28115 27.719 4.78105 27.219C4.28095 26.7189 4 26.0406 4 25.3333V6.66667C4 5.95942 4.28095 5.28115 4.78105 4.78105C5.28115 4.28095 5.95942 4 6.66667 4H21.3333" stroke="#02D589" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};

export default ReadyIcon;