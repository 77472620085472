import React from 'react';
// @ts-ignore
import styles from "../../../css/Editor.module.css";
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import ErrorIcon from "../../icons/ErrorIcon";
import ActualityEditor from "./ActualityEditor";

const SetActualityBlock = () => {
    const isError = useTypedSelector(state => state.exercise.errors?.actuality_error)
    const description = useTypedSelector(state => state.markDescription)


    return (
        <div className={styles.setQualityBlock}>
            <div className={styles.setQualityContainer}>
                <div className={styles.headerAndErrorContainer}>
                    <h2 className={styles.setQualityHeader}>Актуальность задания</h2>
                    <div className={styles.errorContainer}>
                        {isError && <div className={styles.error}>
                            <ErrorIcon />
                            <p className={styles.errorText}>Выберите степень актуальности</p>
                        </div>}
                    </div>
                </div>
                <ActualityEditor />
                <p className={styles.markDescription}>
                    {description.actuality.picked_description !== '' ?
                        description.actuality.picked_description
                        :
                        description.actuality.description
                    }
                </p>
            </div>
        </div>
    );
};

export default SetActualityBlock;