import React from 'react';
// @ts-ignore
import styles from "../../css/PersonalArea.module.css";

const LogoIcon = (props: { color: string }) => {
    return (
        <svg className={styles.logoIcon} viewBox="0 0 94 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.7768 9.69474V16.7307H8.33843V10.1976C8.36738 9.92453 8.33646 9.64843 8.24783 9.38852C8.15921 9.12862 8.01498 8.89114 7.82523 8.69264C7.63549 8.49414 7.40473 8.33937 7.14908 8.23912C6.89343 8.13886 6.61901 8.09554 6.34491 8.11215C5.08396 8.11215 4.21309 8.84534 4.21309 10.4729V16.7293H0.775391V0.6875H4.21309V6.34832C4.61012 5.87303 5.11414 5.49856 5.68379 5.25557C6.25343 5.01259 6.87252 4.90801 7.49036 4.95041C9.80616 4.95041 11.7768 6.60079 11.7768 9.69474Z" fill={props.color}/>
            <path d="M13.793 2.19935C13.793 1.7914 13.9139 1.39262 14.1406 1.05342C14.3672 0.714221 14.6894 0.449844 15.0663 0.293728C15.4432 0.137612 15.8579 0.0967654 16.2581 0.176353C16.6582 0.25594 17.0256 0.452386 17.3141 0.74085C17.6026 1.02931 17.799 1.39685 17.8786 1.79696C17.9582 2.19707 17.9174 2.61179 17.7613 2.98869C17.6051 3.36558 17.3408 3.68772 17.0016 3.91437C16.6624 4.14101 16.2636 4.26199 15.8556 4.26199C15.3103 4.25658 14.7888 4.03753 14.4031 3.65188C14.0174 3.26623 13.7984 2.74472 13.793 2.19935ZM14.1388 5.27048H17.5766V16.7298H14.1388V5.27048Z" fill={props.color}/>
            <path d="M31.8511 5.2716V16.1575C31.8511 20.0081 28.8492 21.635 25.8009 21.635C23.4173 21.635 21.4231 20.7413 20.369 18.862L23.3025 17.166C23.5392 17.644 23.9161 18.0383 24.383 18.2963C24.8498 18.5542 25.3843 18.6635 25.915 18.6095C27.519 18.6095 28.5047 17.785 28.5047 16.1568V15.1033C28.102 15.5957 27.5895 15.9869 27.0084 16.2455C26.4273 16.5041 25.7936 16.623 25.1583 16.5926C23.6454 16.5459 22.2102 15.9121 21.1565 14.8255C20.1029 13.7389 19.5137 12.2848 19.5137 10.7713C19.5137 9.25769 20.1029 7.8036 21.1565 6.71698C22.2102 5.63037 23.6454 4.9966 25.1583 4.94995C25.7937 4.91959 26.4274 5.03856 27.0085 5.2973C27.5896 5.55604 28.1021 5.94735 28.5047 6.43987V5.2709L31.8511 5.2716ZM28.5054 10.7719C28.5288 10.2185 28.3862 9.67061 28.0957 9.19889C27.8052 8.72716 27.3802 8.35315 26.8754 8.12497C26.3706 7.8968 25.8091 7.8249 25.263 7.91852C24.717 8.01214 24.2115 8.267 23.8115 8.65031C23.4115 9.03362 23.1354 9.52785 23.0187 10.0694C22.9019 10.6109 22.9499 11.175 23.1564 11.6891C23.3629 12.2031 23.7185 12.6437 24.1775 12.9539C24.6364 13.2642 25.1777 13.43 25.7317 13.4301C26.0926 13.4552 26.4548 13.4046 26.795 13.2815C27.1351 13.1583 27.4457 12.9654 27.7069 12.715C27.9681 12.4647 28.1742 12.1626 28.3117 11.8279C28.4492 11.4933 28.5151 11.1336 28.5054 10.7719Z" fill={props.color}/>
            <path d="M45.3725 9.69474V16.7307H41.9348V10.1976C41.9638 9.92447 41.9328 9.64831 41.8441 9.38834C41.7555 9.12838 41.6113 8.89087 41.4214 8.69236C41.2316 8.49384 41.0008 8.3391 40.745 8.23889C40.4893 8.13869 40.2148 8.09544 39.9406 8.11215C38.6797 8.11215 37.8089 8.84534 37.8089 10.4729V16.7293H34.3711V0.6875H37.8089V6.34832C38.2059 5.87303 38.7098 5.49856 39.2795 5.25557C39.8491 5.01259 40.4682 4.90801 41.0861 4.95041C43.4012 4.95041 45.3725 6.60079 45.3725 9.69474Z" fill={props.color}/>
            <path d="M47.7305 0H51.1682V16.7307H47.7305V0Z" fill={props.color}/>
            <path d="M53.3457 2.19935C53.3457 1.7914 53.4667 1.39262 53.6933 1.05342C53.92 0.714221 54.2421 0.449844 54.619 0.293728C54.9959 0.137612 55.4107 0.0967654 55.8108 0.176353C56.2109 0.25594 56.5784 0.452386 56.8668 0.74085C57.1553 1.02931 57.3518 1.39685 57.4314 1.79696C57.5109 2.19707 57.4701 2.61179 57.314 2.98869C57.1579 3.36558 56.8935 3.68772 56.5543 3.91437C56.2151 4.14101 55.8163 4.26199 55.4084 4.26199C54.863 4.25658 54.3415 4.03753 53.9558 3.65188C53.5702 3.26623 53.3511 2.74472 53.3457 2.19935ZM53.6916 5.27048H57.1293V16.7298H53.6916V5.27048Z" fill={props.color}/>
            <path d="M71.4038 5.2716V16.1575C71.4038 20.0081 68.4018 21.635 65.3535 21.635C62.97 21.635 60.9765 20.7413 59.9217 18.862L62.8551 17.166C63.0917 17.644 63.4687 18.0384 63.9356 18.2964C64.4025 18.5544 64.937 18.6636 65.4676 18.6095C67.0717 18.6095 68.0573 17.785 68.0573 16.1568V15.1033C67.6546 15.5957 67.1422 15.9868 66.5611 16.2454C65.9799 16.5041 65.3463 16.6229 64.7109 16.5926C63.1981 16.5459 61.7628 15.9121 60.7092 14.8255C59.6556 13.7389 59.0664 12.2848 59.0664 10.7713C59.0664 9.25769 59.6556 7.8036 60.7092 6.71698C61.7628 5.63037 63.1981 4.9966 64.7109 4.94995C65.3463 4.91959 65.98 5.03856 66.5611 5.2973C67.1423 5.55604 67.6547 5.94735 68.0573 6.43987V5.2709L71.4038 5.2716ZM68.058 10.7719C68.0815 10.2184 67.9388 9.67055 67.6483 9.19881C67.3578 8.72707 66.9328 8.35306 66.4279 8.1249C65.923 7.89674 65.3615 7.82488 64.8154 7.91856C64.2694 8.01224 63.7639 8.26717 63.3639 8.65055C62.964 9.03393 62.6879 9.52822 62.5712 10.0698C62.4546 10.6114 62.5027 11.1755 62.7093 11.6896C62.9159 12.2036 63.2716 12.6441 63.7306 12.9543C64.1897 13.2645 64.7311 13.4302 65.2851 13.4301C65.6459 13.4551 66.008 13.4044 66.3481 13.2812C66.6882 13.158 66.9987 12.9651 67.2598 12.7148C67.521 12.4645 67.7269 12.1623 67.8643 11.8277C68.0018 11.4932 68.0677 11.1335 68.058 10.7719Z" fill={props.color}/>
            <path d="M84.9252 9.69474V16.7307H81.4875V10.1976C81.5165 9.92447 81.4855 9.64831 81.3969 9.38834C81.3082 9.12838 81.164 8.89087 80.9742 8.69236C80.7844 8.49384 80.5535 8.3391 80.2978 8.23889C80.042 8.13869 79.7675 8.09544 79.4934 8.11215C78.2324 8.11215 77.3616 8.84534 77.3616 10.4729V16.7293H73.9238V0.6875H77.3616V6.34832C77.7586 5.87295 78.2625 5.49841 78.8321 5.25542C79.4018 5.01243 80.0209 4.9079 80.6388 4.95041C82.9546 4.95041 84.9252 6.60079 84.9252 9.69474Z" fill={props.color}/>
            <path d="M91.6386 8.5709V12.6277C91.6386 13.6133 92.4866 13.7046 93.9994 13.6133V16.7301C89.5075 17.188 88.2002 15.8364 88.2002 12.6277V8.5709H86.3672V5.26736H88.2002V3.08991L91.6379 2.05859V5.26736H93.9986V8.56743L91.6386 8.5709Z" fill={props.color}/>
        </svg>
    );
};

export default LogoIcon;