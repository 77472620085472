import {IThemes, ThemeAction, ThemeActionTypes} from "../../types/reducers/theme";

const initialState: IThemes = new Set()

export const themeReducer = (state=initialState, action: ThemeAction): IThemes => {
    switch (action.type) {
        case ThemeActionTypes.SET_THEMES:
            return action.payload
        case ThemeActionTypes.ADD_THEME:
            state.add(action.payload)
            return new Set(Array.from(state))
        case ThemeActionTypes.DELETE_THEME:
            state.delete(action.payload)
            return new Set(Array.from(state))
        default:
            return state
    }
}