import React from 'react';

interface IArrowIconProps {
    isOpened: boolean
}

const ArrowIcon = ({isOpened}: IArrowIconProps) => {
    return (
        <svg style={{transform: isOpened ? "rotate(0deg)" : "rotate(180deg)", transition: '0.3s'}} width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 7L7 0.999999L13 7" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};

export default ArrowIcon;