import React, {useEffect, useState} from 'react';
// @ts-ignore
import styles from "../../../css/Editor.module.css";
import {IOption} from "../../../types/entities/task";
import {useDispatch} from "react-redux";
import {ExerciseActionTypes} from "../../../types/reducers/exercise";
import {dragEnd, dragMove} from "../../../utils/dragMobileFuncs";

const SequenceExerciseOptions = (props: {options: IOption[]}) => {
    const [isDraggable, setIsDraggable] = useState(false)
    const [order, setOrder] = useState<{ order: number; option: IOption; }[]>()
    const [currentOption, setCurrentOption] = useState<{ order: number; option: IOption; }>()
    const dispatch = useDispatch()

    useEffect(() => {
        let newOrder: { order: number; option: IOption }[] = []

        for (let i = 0; i < props.options.length; i++) {
            newOrder.push({order: i+1, option: props.options[i]})
        }

        setOrder(newOrder)
    }, [])

    const dragStart = (e: React.DragEvent<HTMLDivElement>) => {
        order?.forEach((option) => {
            if (option.option.id === Number(e.currentTarget.id)) {
                setCurrentOption(option)
            }
        })
    }

    const dragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        e.currentTarget.style.filter = 'brightness(0.8)'
    }

    const dragLeave = (e: React.DragEvent<HTMLDivElement>) => {
        e.currentTarget.style.filter = 'brightness(1)'
    }

    const drop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        e.currentTarget.style.filter = 'brightness(1)'

        let finalOption: { order: number; option: IOption; }
        order?.forEach((option) => {
            if (option.option.id === Number(e.currentTarget.id)) {
                finalOption = option
            }
        })

        let newOrder = order?.map((option) => {
            if (currentOption && option.option.id === finalOption.option.id) {
                return {...option, order: currentOption.order}
            }

            if (currentOption && option.option.id === currentOption.option.id) {
                return {...option, order: finalOption.order}
            }

            return option
        })
        let newAnswers: number[] = []
        newOrder?.forEach((option) => {
            newAnswers[option.order - 1] = option.option.id
        })

        dispatch({type: ExerciseActionTypes.SET_ANSWERS, payload: JSON.stringify(newAnswers)})
        setOrder(newOrder)
    }

    const sortByOrder = (oneOption: { order: number; option: IOption; }, twoOption: { order: number; option: IOption; }) => {
        return oneOption.order - twoOption.order
    }

    const setAnswer = (smth: any, order: number[]) => {
        dispatch({type: ExerciseActionTypes.SET_ANSWERS, payload: JSON.stringify(order)})
    }

    return (
        <div className={styles.exerciseOptions + ' dragOptionsContainer'}>
            {order?.sort(sortByOrder).map((ordered => {
                return <div className={styles.dropContainer} key={ordered.option.id}>
                    <div
                        onDragStart={(e) => dragStart(e)}
                        onDragOver={(e) => dragOver(e)}
                        onDragLeave={(e) => dragLeave(e)}
                        onDrop={(e) => drop(e)}
                        className={styles.exerciseOption + ' dragElement forExercise'}
                        draggable={isDraggable}
                        id={ordered.option.id.toString()}
                    >
                        <p className={styles.dragExerciseOption}>{ordered.option.text}</p>
                        <div
                            className={styles.exerciseDragControl}
                            onMouseDown={() => setIsDraggable(true)}
                            onMouseUp={() => setIsDraggable(false)}
                            onTouchMove={(e) => dragMove(e)}
                            onTouchEnd={() => dragEnd(ordered.option.id, setAnswer)}
                        >
                            <span className={styles.dot}/>
                            <span className={styles.dot}/>
                            <span className={styles.dot}/>
                        </div>
                    </div>
                </div>
            }))}
        </div>
    );
};

export default SequenceExerciseOptions;