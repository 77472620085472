import React, {MouseEventHandler, useState} from 'react';
// @ts-ignore
import styles from "../../css/Editor.module.css";

const Arrow = (props: {isComplete: boolean, word: string, direction: "left" | "right", onClick: MouseEventHandler<HTMLDivElement>}) => {
    const [isWordHidden, setIsWordHidden] = useState(true)
    const isMobile = useState(document.documentElement.clientWidth <= 900)[0]

    return (
        <div
            className={styles.arrowContent}
            onMouseOver={() => setIsWordHidden(false)}
            onMouseLeave={() => setIsWordHidden(true)}
            onClick={props.onClick}
        >
            {props.direction === "left" && !props.isComplete && <>
                <svg className={styles.arrowBack} style={{marginRight: 17}} viewBox="0 0 26 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.5556 19L2 10.5M2 10.5L10.5556 2M2 10.5H24" stroke="#323232" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                <span className={styles.arrowSpan} style={{opacity: isWordHidden ? "0" : "1"}}>{isMobile ? '' : props.word}</span>
            </>}

            {props.direction === "right" && <>
                {props.isComplete ?
                    <span className={styles.arrowSpan} style={{opacity: isWordHidden ? "0" : "1"}}>Завершить</span>
                    :
                    <span className={styles.arrowSpan} style={{opacity: isWordHidden ? "0" : "1"}}>{isMobile ? '' : props.word}</span>
                }
                <svg className={styles.arrowBack} style={{marginLeft: 17, transform: "rotate(180deg)"}} viewBox="0 0 26 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.5556 19L2 10.5M2 10.5L10.5556 2M2 10.5H24" stroke="#323232" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </>}
        </div>
    );
};

export default Arrow;