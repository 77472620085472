import {IOption, ITask, TaskTypes} from "./task";

export interface IExercise {
    actuality_assessment: number | null
    quality_assessment: number | null
    answer_id: number
    task_type : TaskTypes
    task_content: {
        question: string
        options: IOption[]
    }
    comment: string
    subtopic: number
    user_id: number
    task_id: -1
    answer: string
    done: number
    total: number
    errors?: IExerciseErrors
}

export interface IExerciseErrors {
    answer_error: boolean
    quality_error: boolean
    actuality_error: boolean
}

export interface IExerciseDataObject {
    answer_id: number
    answer: string
    comment: string
    quality_assessment: number | null
    actuality_assessment: number | null
}

export const initialExercise: IExercise = {
    "answer_id": -1,
    "user_id": -1,
    "task_id": -1,
    "task_type": "radio",
    "task_content": {
        "question": "Загрузка...",
        "options": [
            {
                "id": -1,
                "text": "Загрузка..."
            },
            {
                "id": -2,
                "text": "Загрузка..."
            },
            {
                "id": -3,
                "text": "Загрузка..."
            },
            {
                "id": -4,
                "text": "Загрузка..."
            }
        ]
    },
    "subtopic": -1,
    "answer": "[]",
    "comment": '',
    "quality_assessment": null,
    "actuality_assessment": null,
    "done": 0,
    "total": 0,
    "errors": {
        answer_error: false,
        quality_error: false,
        actuality_error: false,
    }
}