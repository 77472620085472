import React from 'react';

const CustomRadio = (props: {isChecked: boolean}) => {
    return (
        <>
            {props.isChecked ? <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="8" cy="8" r="7" stroke="#323232" strokeWidth="2"/>
                    <circle cx="8" cy="8" r="5" fill="#323232"/>
                </svg>
                :
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="8" cy="8" r="7" stroke="#323232" strokeWidth="2"/>
                </svg>
            }
        </>
    );
};

export default CustomRadio;