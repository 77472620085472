import axios from "axios"

let api: string | undefined
api = process.env.REACT_APP_API_URL || 'http://localhost:5000/'

const $host = axios.create({
    baseURL: api
})

const $authHost = axios.create({
    baseURL: api
})

const authInterceptor = (config: any) => {
    config.headers.authorization = `Bearer ${localStorage.getItem('token')}`
    return config
}

$authHost.interceptors.request.use(authInterceptor)

export {
    $host,
    $authHost
}