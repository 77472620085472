import React, {useState} from 'react';
// @ts-ignore
import styles from "../../../css/Player.module.css";
import Arrow from "../../common/Arrow";
import {IChangeTaskControlProps} from "../../../types/admin/props/changeTaskControl";

const ChangeTaskControl = (props: IChangeTaskControlProps) => {
    const isMobile = useState(document.documentElement.clientWidth <= 900)[0]

    return (
        <div className={styles.changeTaskControl}>
            <div style={{width: isMobile ? 42 : 86}}>
                {props.step !== 1 && <Arrow
                    word={"Назад"}
                    direction={"left"}
                    isComplete={false}
                    onClick={() => {
                        props.getPrev()
                    }}
                />}
            </div>

            <div className={styles.dots}>
                <div
                    className={styles.dot}
                    style={{background: props.step === 1 ? "black" : "silver"}}
                    onClick={() => {
                        props.setStep(1)
                    }}
                />
                <div
                    className={styles.dot}
                    style={{background: props.step === 2 ? "black" : "silver"}}
                    onClick={() => {
                        props.setStep(2)
                    }}
                />
                <div
                    className={styles.dot}
                    style={{background: props.step === 3 ? "black" : "silver"}}
                    onClick={() => {
                        props.setStep(3)
                    }}
                />
                <div
                    className={styles.dot}
                    style={{background: props.step === 4 ? "black" : "silver"}}
                    onClick={() => {
                        props.setStep(4)
                    }}
                />
                <div
                    className={styles.dot}
                    style={{background: props.step === 5 ? "black" : "silver"}}
                    onClick={() => {
                        props.setStep(5)
                    }}
                />
            </div>

            <Arrow
                word={"Далее"}
                direction={"right"}
                isComplete={false}
                onClick={() => {
                    props.getNext()
                }}
            />
        </div>
    );
};

export default ChangeTaskControl;