import React, {PropsWithChildren} from 'react';
// @ts-ignore
import styles from '../../css/Editor.module.css'

const Modal = (props: PropsWithChildren<any>) => {
    return (
        <div className={styles.modal}>
            <div className={styles.modalContent}>
                {props.children}
            </div>
        </div>
    );
};

export default Modal;