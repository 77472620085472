import React, {useEffect, useState} from 'react';
// @ts-ignore
import styles from "../../css/Editor.module.css";
import classNames from "classnames";
import {errorType, IDefaultTaskProps} from "../../types/components/editor";
import SequenceDraggableOptions from "./SequenceDraggableOptions";
import CustomRadio from "../common/CustomRadio";
import CustomCheckbox from "../common/CustomCheckbox";
import ClearTaskIcon from "../icons/ClearTaskIcon";

const Task = (props: IDefaultTaskProps) => {
    const [fixedErrors, setFixedErrors] = useState<errorType[]>([])

    const isChecked = (optionId: number) => {
        return new Set(JSON.parse(props.task.answers)).has(optionId);
    }

    useEffect(() => {
        setFixedErrors([])
    }, [props.errors, props.task.answers])

    const isErrorInQuestion = (props.errors.includes("no question") && !fixedErrors.includes("no question")) ||
        (props.errors.includes("too long question") && !fixedErrors.includes("too long question"))

    const getQuestionErrorText = () => {
        if (props.errors.includes("no question")) return "Введите текст вопроса."
        if (props.errors.includes("too long question"))
            return `Количество символов в вопросе не должно превышать ${props.max_symbols_for_question}`
    }

    const isErrorInOption = (id: number) => {
        return (props.errors.includes(id) && !fixedErrors.includes(id)) ||
            (props.errors.includes(id + ' too long') && !fixedErrors.includes(id + ' too long'))
    }

    const getOptionErrorText = (id: number) => {
        if (props.errors.includes(id)) return "Напишите вариант ответа."
        if (props.errors.includes(id + ' too long')) return `Превышен максимум символов (${props.max_symbols_for_option})`
    }

    return (
        <div className={styles.task} id={"task" + props.task.id.toString()}>
            <div className={styles.taskContainer}>
                <div className={styles.taskHeader}>
                    <div className={styles.taskNumberContainer}>
                        <span className={styles.taskNumber}>{props.number}</span>
                    </div>
                    <p className={styles.taskHeaderText}>
                        {isErrorInQuestion && <span className={styles.questionError}>{getQuestionErrorText()}</span>}
                        {!isErrorInQuestion && <>
                            {props.task.task_type === 'checkbox' && "Задание с несколькими верными вариантами ответа"}
                            {props.task.task_type === 'radio' && "Задание с единственным верным вариантом ответа"}
                            {props.task.task_type === 'sequence' && "Задание на ранжирование. Введите текст вопроса и варианты ответа в верной последовательности. При предъявлении респондентам варианты будут автоматически перемешиваться."}
                        </>}
                    </p>
                </div>
                <div className={styles.taskContent}>
                    <textarea
                        onBlur={() => props.saveCurrentTasks()}
                        className={isErrorInQuestion ?
                            classNames(styles.taskQuestion, styles.taskQuestionError)
                            :
                            styles.taskQuestion}
                        placeholder="Введите текст вопроса..."
                        value={props.task.task_content.question}
                        onChange={(e) => {
                            if (!fixedErrors.includes("no question")) {
                                setFixedErrors([...fixedErrors, "no question"])
                            }
                            if (!fixedErrors.includes("too long question")) {
                                setFixedErrors([...fixedErrors, "too long question"])
                            }
                            props.setQuestion(props.task.id, e.target.value)
                        }}
                    />

                    {props.task.task_type !== "sequence" && props.task.task_content.options.map((option) => {
                        return <div className={styles.optionContainer}>
                            {isErrorInOption(option.id) && <div className={styles.optionErrorContainer}>
                                <span className={styles.optionError}>{getOptionErrorText(option.id)}</span>
                            </div>}
                            <div key={option.id} className={styles.option}>
                                <div onClick={props.task.task_type === "radio" ?
                                    () => {
                                        if (!fixedErrors.includes("no answers")) {
                                            setFixedErrors([...fixedErrors, "no answers"])
                                        }
                                        props.setAnswers(props.task.id, Array.from(new Set([option.id])));
                                    }
                                    :
                                    () => {
                                        if (!fixedErrors.includes("no answers")) {
                                            setFixedErrors([...fixedErrors, "no answers"])
                                        }
                                        let currentAnswers = new Set<number>(JSON.parse(props.task.answers))
                                        if (currentAnswers.has(option.id)) {
                                            currentAnswers.delete(option.id)
                                        } else {
                                            currentAnswers.add(option.id)
                                        }
                                        props.setAnswers(props.task.id, Array.from(currentAnswers))
                                    }
                                } style={{paddingTop: 5}}>
                                    {props.task.task_type === 'radio' && <CustomRadio isChecked={isChecked(option.id)}/>}
                                    {props.task.task_type === 'checkbox' && <CustomCheckbox isChecked={isChecked(option.id)} isMinimize={true}/>}
                                </div>
                                <textarea
                                    className={isErrorInOption(option.id) ?
                                        classNames(styles.optionTextarea, styles.optionTextareaError)
                                        :
                                        styles.optionTextarea}
                                    placeholder="Вариант ответа..."
                                    value={option.text}
                                    onChange={(e) => {
                                        if (!fixedErrors.includes(option.id)) {
                                            setFixedErrors([...fixedErrors, option.id])
                                        }
                                        if (!fixedErrors.includes(option.id + ' too long')) {
                                            setFixedErrors([...fixedErrors, option.id + ' too long'])
                                        }
                                        props.setOption(props.task.id, option.id, e.target.value)
                                    }}
                                    onBlur={() => props.saveCurrentTasks()}
                                />
                            </div>
                        </div>
                    })}

                    {props.task.task_type === "sequence" && <SequenceDraggableOptions
                        task={props.task}
                        number={props.number}
                        setAnswers={props.setAnswers}
                        setOption={props.setOption}
                        setQuestion={props.setQuestion}
                        saveCurrentTasks={props.saveCurrentTasks}
                        errors={props.errors}
                        fixedErrors={fixedErrors}
                        setFixedErrors={setFixedErrors}
                    />}
                </div>
            </div>

            <div className={styles.taskInstructions}>
                <svg className={props.errors.includes("no answers") && !fixedErrors.includes("no answers") ? classNames(styles.arrowUpIcon, styles.arrowUpIconError) : styles.arrowUpIcon} width="10" height="20" viewBox="0 0 10 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5 1V19M1 5L5 1L1 5ZM5 1L9 5L5 1Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                <span
                    className={props.errors.includes("no answers") && !fixedErrors.includes("no answers") ?
                        classNames(styles.spanInstruction, styles.spanInstructionError)
                        :
                        styles.spanInstruction}
                >
                    {props.task.task_type === "sequence" && 'Укажите правильную последовательность'}
                    {props.task.task_type === "radio" && 'Укажите правильный вариант ответа'}
                    {props.task.task_type === "checkbox" && 'Укажите правильные варианты ответа'}
                </span>
            </div>

            <div className={styles.clearTaskControl} onClick={() => props.clearTask && props.clearTask(Number(props.task.id))}>
                <ClearTaskIcon />
                <span>Очистить форму</span>
            </div>
        </div>
    );
};

export default Task;