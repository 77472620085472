import React, {useEffect, useState} from 'react';
// @ts-ignore
import styles from "../../../../css/admin/Script.module.css";
import AlertModal from "../../common/AlertModal";
import {WizardStages, WizardStagesNames} from "../../../../types/entities/status";
import Button from "../../../common/Button";
import WizardInput from "../UI/WizardInput";
import HeadingDescriptionComponent from "../UI/HeadingDescriptionComponent";
import {useDispatch} from "react-redux";
import {useTypedSelector} from "../../../../hooks/useTypedSelector";
import {WaitingStage, WizardActionTypes} from "../../../../types/reducers/wizard";
import {
    getWaitingStageWizardData,
    setWaitingStageWizardData
} from "../../../../http/admin/wizardRequest";
import {StatusActionTypes} from "../../../../types/reducers/status";
import {useNavigate} from "react-router-dom";

const errorHandler = (waitingInfo: WaitingStage) => {
    if (waitingInfo.instruction === '') return  'Вы не заполнили инструкцию!'
    if (!waitingInfo.embedded_link.startsWith('https://') && waitingInfo.embedded_link !== '')
        return 'Кажется, вы ввели неверный формат ссылки'
    return ''
}

const WaitingSecondStepComponent = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const isWizardConfigured = useTypedSelector(state => state.status.wizard_completed)
    const waitingInfo = useTypedSelector(state => state.wizard.waiting_stage)
    const [buttonText, setButtonText] = useState('Сохранить')
    const [errorText, setErrorText] = useState('')
    const [isModalErrorOpened, setIsModalErrorOpened] = useState(false)

    useEffect(() => {
        if (isWizardConfigured) {
            getWaitingStageWizardData().then(data => {
                dispatch({
                    type: WizardActionTypes.SET_WAITING_INFO,
                    payload: data,
                })
            })
        }
    }, [])

    const setInstruction = (e: any) => {
        dispatch({
            type: WizardActionTypes.SET_WAITING_INSTRUCTION,
            payload: e.target.value
        })
    }

    const setEmbeddedLink = (e: any) => {
        dispatch({
            type: WizardActionTypes.SET_EMBEDDED_LINK,
            payload: e.target.value
        })
    }

    const submit = () => {
        if (buttonText !== 'Подождите...') {
            const error = errorHandler(waitingInfo)

            if (error) {
                setErrorText(error)
                setIsModalErrorOpened(true)
            } else {
                setButtonText('Подождите...')
                setWaitingStageWizardData(waitingInfo).then(() => {
                    if (isWizardConfigured) {
                        navigate('/admin/script')
                    } else {
                        dispatch({ type: StatusActionTypes.SET_NEXT_WIZARD_STAGE })
                    }
                })
            }
        }
    }

    return (
        <div className={styles.wizardStageComponent}>
            {isModalErrorOpened && <AlertModal closeModal={() => setIsModalErrorOpened(false)} text={errorText}/>}
            <h2 className={styles.wizardStageHeader}>{WizardStagesNames[WizardStages.WAITING_FOR_SECOND_STEP]}</h2>
            <div className={styles.stageContent}>
                <WizardInput
                    onChange={setInstruction}
                    placeholder="Текст..."
                    value={waitingInfo.instruction}
                    height={200}
                    label="Инструкция"
                    isTextarea={true}
                    isWithVars={true}
                />
                <HeadingDescriptionComponent heading="Видео-урок">
                    Введите ссылку на видео или плейлист в формате embedded
                </HeadingDescriptionComponent>
                <WizardInput
                    onChange={setEmbeddedLink}
                    placeholder="https://www.youtube.com/embed/example"
                    value={waitingInfo.embedded_link}
                    label="Ссылка (оставьте поле пустым, если не хотите добавлять видео)"
                />
                <iframe
                    className={styles.iframe}
                    src={waitingInfo.embedded_link.startsWith('https://') ? waitingInfo.embedded_link : ''}
                    title="YouTube video player" frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                >
                </iframe>
            </div>
            <Button
                width={"150px"}
                height={"45px"}
                fontSize={"16px"}
                variant={"primary"}
                margin="50px 0 0 770px"
                submit={submit}
            >
                {buttonText}
            </Button>
        </div>
    );
};

export default WaitingSecondStepComponent;