import React from 'react';
import InfoBlock from "../../../InfoBlock/InfoBlock";
// @ts-ignore
import styles from "../../../../../css/Editor.module.css";
import QuestionCard from "../../../../editors/expertise/QuestionCard";

const StepFour = () => {
    return (
        <>
            <InfoBlock>
                <p>По возможности, <b>поясните свою оценку в письменном комментарии</b>.</p>
                <br/>
                <p>Например, если Вы обнаружили опечатку, то укажите, в каком слове она допущена</p>
            </InfoBlock>

            <div style={{margin: '20px auto', width: 320}}>
                <QuestionCard>
                    <div className={styles.commentBlock} style={{margin: "0 auto", width: 250}}>
                        <h3 className={styles.commentHeader} style={{width: 250}}>Оставьте комментарий:</h3>
                        <textarea
                            style={{margin: "0 auto", width: 250}}
                            className={styles.commentTextarea}
                            placeholder={"Комментарий..."}
                        />
                    </div>
                </QuestionCard>
            </div>
        </>
    );
};

export default StepFour;