import React, {useState} from 'react';
// @ts-ignore
import styles from "../../../css/PersonalArea.module.css";
import LogoIcon from "../../icons/LogoIcon";
import UserpickIcon from "../../icons/UserpickIcon";
import HeaderControls from "./HeaderControls";
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import AdminDropDown from "../../admin/common/AdminDropDown";
import {IOption} from "../../../types/admin/props/adminDropDown";
import RoleSwitcher from "./RoleSwitcher";
import {isClientDemo} from "../../../utils/demo/demo";
import RestartDemoButton from "../RestartDemoButton";

const Header = () => {
  const isMobile = useState(document.documentElement.clientWidth <= 900)[0]
  const { name } = useTypedSelector(state => state.user)
  const logo = process.env.REACT_APP_LOGO
  const logoElement = logo === "highlight" ? <LogoIcon color="#323232"/> : <img src={'/' + logo} alt="logo" height={80}/>

  return (
    <header className={styles.header}>
      <div className={styles.headerContent}>

        {(isMobile && isClientDemo()) ? <RestartDemoButton isMobile={isMobile}/> : logoElement}
        <div className={styles.controls}>
          {localStorage.getItem('currentMode') && <RoleSwitcher />}
          <div className={styles.userInfo}>
            <p className={styles.name}>{name}</p>
            <UserpickIcon width="5.41vh"/>
          </div>
          <HeaderControls />
        </div>
      </div>
    </header>
  );
};

export default Header;