import React from 'react';
import HighlightLogo from "../icons/HighlightLogo";
import MailIcon from "../icons/MailIcon";
import {useTypedSelector} from "../../hooks/useTypedSelector";

const RestorePasswordInstruction = () => {
    const supportMail = useTypedSelector(state => state.app.support_mail)

    return (
        <>
            <HighlightLogo />
            <div className="instructionBlock">
                <MailIcon />
                <div className="instructionText">
                    <p className="word">Успешно!</p>
                    <p className="text">Инструкция с описанием дальнейших действий по восстановлению пароля отправлена на Вашу почту.</p>
                </div>
            </div>
            <a href={"mailto" + supportMail} className="support regSupport instructionSupport">{supportMail}</a>
        </>
    );
};

export default RestorePasswordInstruction;