import React from 'react';
// @ts-ignore
import styles from "../../css/Player.module.css";

const PlusIcon = (props: {isHovered: boolean}) => {
    return (
        <svg className={styles.plusIcon} style={{stroke: props.isHovered ? "#FFD556" : "#323232"}} width="0.88vw" height="0.88vw" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 8.5H1M8.5 1V16V1Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};

export default PlusIcon;