import {ICluster} from "../admin/http/themeRequests";

enum ClustersActionTypes {
    SET_CLUSTERS = 'SET_CLUSTERS',
    SET_CURRENT_CLUSTER = 'SET_CURRENT_CLUSTER'
}

interface IClusters {
    current_cluster: ICluster
    clusters: ICluster[]
}

interface ISetClustersAction {
    type: ClustersActionTypes.SET_CLUSTERS
    payload: ICluster[]
}

interface ISetCurrentClusterAction {
    type: ClustersActionTypes.SET_CURRENT_CLUSTER
    payload: ICluster
}

type ClustersAction = ISetClustersAction | ISetCurrentClusterAction

export {ClustersActionTypes}
export type {ClustersAction, IClusters}