import React, {useEffect, useState} from 'react';
// @ts-ignore
import styles from "../../../css/admin/UserPanel.module.css";
// @ts-ignore
import commonStyles from "../../../css/admin/Admin.module.css";
import SimpleBar from "simplebar-react";
import Modal from "../common/Modal";
import SearchIcon from "../../icons/admin/SearchIcon";
import classNames from "classnames";
import CustomCheckbox from "../../common/CustomCheckbox";
import Button from "../../common/Button";
import {getUserSubtopics, updateUserSubtopics} from "../../../http/admin/userRequests";
import {ITopic, IUserSubtopics} from "../../../types/admin/http/userRequests";
import Preloader from "../../common/Preloader";

const SubtopicMenuModal = (props: {closeModal: () => void, id: number, changeCounter: (newCounter: number) => void}) => {
    const [isDataLoaded, setIsDataLoaded] = useState(false)
    const [button, setButton] = useState('Сохранить')
    const [topics, setTopics] = useState<ITopic[]>([])
    const [pickedSubtopics, setPickedSubtopics] = useState<Set<number>>(new Set())

    useEffect(() => {
        setIsDataLoaded(false)
        getUserSubtopics(props.id).then(data => {
            setIsDataLoaded(true)
            setTopics(data.topics)
            setPickedSubtopics(new Set(data.picked_subtopics))
        })
    }, [])

    const addOrDeletePickedSubtopic = (id: number) => {
        let newSet = new Set<number>(JSON.parse(JSON.stringify(Array.from(pickedSubtopics))))

        if (pickedSubtopics.has(id)) {
            newSet.delete(id)
        } else {
            newSet.add(id)
        }

        setPickedSubtopics(newSet)
    }

    const submit = () => {
        if (button !== 'Подождите...') {
            setButton('Подождите...')
            updateUserSubtopics(props.id, Array.from(pickedSubtopics)).then(() => {
                props.changeCounter(pickedSubtopics.size)
                setButton('Сохранить')
                props.closeModal()
            })
        }
    }

    return (
        <Modal>
            <div className={styles.subtopicMenu}>
                <div className={classNames(commonStyles.search, styles.subtopicMenuSearch)}>
                    <SearchIcon />
                    <input type="text" placeholder={"Найти в таблице"}/>
                </div>
                <div className={styles.topicList}>
                    <SimpleBar autoHide={false} style={{maxHeight: 400}}>
                        {isDataLoaded ? topics.map(topic => {
                            return <div className={styles.topic} key={topic.id}>
                                <h3>{topic.name}</h3>
                                {topic.subtopics.map(subtopic => {
                                    return <div className={styles.subtopic} key={subtopic.id} onClick={() => addOrDeletePickedSubtopic(subtopic.id)}>
                                        <CustomCheckbox isChecked={pickedSubtopics.has(subtopic.id)} isMinimize={true}/>
                                        <p>{subtopic.name}</p>
                                    </div>
                                })}
                            </div>
                        }) : <Preloader />}
                    </SimpleBar>
                </div>

                <div className={styles.buttons}>
                    <Button
                        width={"150px"}
                        height={"40px"}
                        variant={"primary"}
                        fontSize={"16px"}
                        margin={"0 auto"}
                        submit={props.closeModal}
                    >
                        Выйти
                    </Button>

                    <Button
                        width={"150px"}
                        height={"40px"}
                        variant={"primary"}
                        fontSize={"16px"}
                        margin={"0 auto"}
                        submit={submit}
                    >
                        {button}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default SubtopicMenuModal;