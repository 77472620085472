import {
  addSubtopics,
  addTasksTemplate, DemoType,
  getDemo, setCompleteTaskCount,
  setCurrentSubtopicId, setDemo,
  setNewTasksContent,
  setNextStage
} from "./demo";
import {filterPickedThemes} from "../../components/steps/themeList/filterFunction";
import {ITask} from "../../types/entities/task";
import {IThemeList} from "../../types/components/themes";
import {IExerciseDataObject} from "../../types/entities/exercise";
import {defaultUITexts} from "../defaultUITexts";
import {Texts} from "../../types/reducers/wizard";

export const getStatusDemo = (data: any) => {
  const demo = getDemo();

  return {
    ...data,
    stage_number: demo.current_stage,
    max_experts_on_subtopic: demo.max_experts_on_subtopic,
    max_picked_subtopics: demo.max_picked_subtopics
  }
}

export const saveThemesDemo = (pickedSubtopicIds: number[]) => {
  const demo = getDemo()

  const pickedSubtopics = filterPickedThemes(demo.subtopics, new Set(pickedSubtopicIds))
  addSubtopics(pickedSubtopics)

  setNextStage()
  return { data: { status: 'ok' } }
}

export const getThemesDemo = () => {
  const demo = getDemo()

  return { data: demo.subtopics }
}

export const getPickedThemesDemo = () => {
  const demo = getDemo()
  const total = demo.current_stage === 3 ? demo.max_items_for_expert : demo.max_tasks_on_subtopic

  demo.pickedSubtopics.forEach((topic: any) => {
    topic.values.forEach((subtopic: any) => {
      subtopic.total = total
    })
  })

  return { data: demo.pickedSubtopics }
}

export const getTasksDemo = (subtopicId: number) => {
  let demo = JSON.parse(String(localStorage.getItem('demo')))

  demo.defaultDemo.pickedSubtopics.forEach((topic: any) => {
    topic.values.forEach((subtopic: any) => {
      if (subtopic.id === subtopicId) {
        demo.emptyTaskObj.subtopic_data.subtopic = subtopic.value
        demo.emptyTaskObj.subtopic_data.topic = subtopic.topic
        demo.emptyTaskObj.subtopic_data.description = subtopic.description
      }
    })
  })

  localStorage.setItem('demo', JSON.stringify(demo));

  demo = getDemo()
  setCurrentSubtopicId(subtopicId)
  if (!(subtopicId in demo)) {
    addTasksTemplate(subtopicId, JSON.parse(String(localStorage.getItem('demo'))).emptyTaskObj)
  }

  return { data: getDemo()[subtopicId] }
}

export const getSubtopicDataDemo = (subtopicId: number) => {
  const demo = getDemo()
  let currentTopic: string
  let currentSubtopic: string
  let description: string

  demo.pickedSubtopics.forEach((topic: any[]) => {
    // @ts-ignore
    topic['values'].forEach(subtopic => {
      if (subtopic.id === subtopicId) {
        // @ts-ignore
        currentTopic = topic.name
        currentSubtopic = subtopic.value
        description = subtopic.description
      }
    })
  })

  demo.currentExpertiseId = subtopicId
  setDemo(demo)

  // @ts-ignore
  return { data: { topic: currentTopic, subtopic: currentSubtopic, description } }
}

export const getTextsDemo = (stage: string): { data: Texts } => {
  switch (stage) {
    case "first_stage_instruction":
      return { data: defaultUITexts.firstStep }
    case "second_stage_instruction":
      return { data: defaultUITexts.secondStep }
    case "third_stage_instruction":
      return { data: defaultUITexts.thirdStep }
    case "waiting_stage_instruction":
      return { data: defaultUITexts.waitingForSecondStep }
    case "quality_texts":
      return { data: defaultUITexts.qualityTexts }
    case "originality_texts":
      return { data: defaultUITexts.originalityTexts }
    default:
      return { data: 'text' }
  }
}

export const getExerciseDemo = () => {
  const demo = getDemo()
  let task;

  demo.pickedSubtopics.forEach((topic: any[]) => {
    // @ts-ignore
    topic['values'].forEach(subtopic => {
      if (subtopic.id === demo.currentExpertiseId) {
        task = subtopic.tasks[subtopic.done]
      }
    })
  })

  // @ts-ignore
  task.task_content.options.sort(() => Math.random() - 0.5)

  return { data: task }
}

export const saveExerciseDemo = (exercise: IExerciseDataObject) => {
  const demo = getDemo()

  demo.total_rated_exercise_counter++

  if (JSON.parse(exercise.answer).includes(exercise.answer_id)) {
    demo.right_answers_expertise_counter++
  }

  demo.pickedSubtopics.forEach((topic: any[]) => {
    // @ts-ignore
    topic['values'].forEach(subtopic => {
      if (subtopic.id === demo.currentExpertiseId) {
        subtopic.done++;
      }
    })
  })

  setDemo(demo)

  return { data: { status: 'ok' } }
}

export const saveTasksDemo = (tasks: ITask[]) => {
  setNewTasksContent(tasks)
  setCompleteTaskCount()
  return { data: { status: 'ok' } }
}

export const setExpertiseComplete = () => {
  const demo = getDemo()
  const themes: IThemeList = demo.pickedSubtopics

  themes.forEach(theme => {
    theme.values.forEach(subtopic => {
      subtopic.done = subtopic.total
    })
  })

  setDemo(demo)
}

export const cleatTaskDemo = () => {
  return { data: { status: 'ok' } }
}

export const getDashboardDataDemo = () => {
  const demo: DemoType = JSON.parse(String(localStorage.getItem('demo')))
  let userTasks: any = []

  for (let key in demo.defaultDemo) {
    if (!isNaN(Number(key))) {
      demo.defaultDemo[key].tasks.forEach((task: any) => {
        if (task.answers !== '[]') {
          task.task_content.options.forEach((option: any) => {
            option.is_correct = !!JSON.parse(task.answers).includes(option.id);
          })

          task.task_content.options.sort((a: any, b: any) => b.is_correct - a.is_correct)
          userTasks.push(task)
        }
      })
    }
  }

  let authorRating
  let qualityTaskCounter
  switch (userTasks.length) {
    case 0:
      authorRating = 50
      qualityTaskCounter = 0
      break
    case 1:
      authorRating = 1
      qualityTaskCounter = 1
      break
    case 2:
      authorRating = 25
      qualityTaskCounter = 1
      break
    case 3:
      authorRating = 33
      qualityTaskCounter = 1
      break
    case 4:
      authorRating = 38
      qualityTaskCounter = 1
      break
    case 5:
      authorRating = 30
      qualityTaskCounter = 2
      break
    default:
      authorRating = 25
      qualityTaskCounter = 3
  }

  demo.dashboardObj.author_rating = authorRating
  demo.dashboardObj.quality_task_counter = qualityTaskCounter
  demo.dashboardObj.scholar_rating_place = Math.round(51 - demo.defaultDemo.right_answers_expertise_counter / demo.defaultDemo.total_rated_exercise_counter * 50)
  demo.dashboardObj.scholar_rating_total = demo.defaultDemo.total_rated_exercise_counter
  demo.dashboardObj.scholar_rating_correct = demo.defaultDemo.right_answers_expertise_counter

  if (demo.dashboardObj.download_certificate) {
    demo.dashboardObj.download_certificate.author_rating = authorRating
    demo.dashboardObj.download_certificate.quality_task_counter = qualityTaskCounter
  }

  for (let i = 0; i < userTasks.length; i++) {
    demo.dashboardObj.tasks[i].question = userTasks[i].task_content.question
    for (let j = 0; j < userTasks[i].task_content.options.length; j++) {
      demo.dashboardObj.tasks[i].options[j].text = userTasks[i].task_content.options[j].text
      demo.dashboardObj.tasks[i].options[j].is_correct = userTasks[i].task_content.options[j].is_correct
    }
  }

  demo.dashboardObj.tasks.length = userTasks.length

  return { data: demo.dashboardObj }
}