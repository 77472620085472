import React, {useEffect, useState} from 'react';
import InfoBlock from "./InfoBlock/InfoBlock";
import ThemeBlock from "./ThemeBlock";
import {getThemes} from "../../http/playerRequests";
// @ts-ignore
import styles from "../../css/Player.module.css"
import BookIcon from "../icons/BookIcon";
import Header from "../common/Header/Header";
import Footer from "../common/Footer/Footer";
import {useTypedSelector} from "../../hooks/useTypedSelector";
import {getTexts} from "../../http/admin/wizardRequest";
import {WizardStages} from "../../types/entities/status";

const FirstStepWaiting = () => {
    const [isHovered, setIsHovered] = useState(false)
    const appStatus = useTypedSelector(state => state.app)
    const [text, setText] = useState('Загрузка...')

    const [themes, setThemes] = useState([
        {
            "name": "Тема",
            "values": [
                {
                    "value": "Подтема",
                    "id": 1,
                    "subtopic_name_id": 1
                },
                {
                    "value": "Подтема",
                    "id": 2,
                    "subtopic_name_id": 1
                },
                {
                    "value": "Подтема",
                    "id": 3,
                    "subtopic_name_id": 1
                }
            ]
        },
    ])

    useEffect(() => {
        getTexts('waiting_stage_instruction').then(data => {
            // @ts-ignore
            setText(data)
        })
        getThemes().then(data => {
            setThemes(data)
        })
    }, [])

    return (
        <>
            <Header />
            <div className={styles.playerWindow}>
                <div className={styles.waiting}>
                    <InfoBlock>
                        {text}
                        {appStatus.video_link && <iframe className={styles.frame} src={appStatus.video_link}
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen>
                        </iframe>}
                    </InfoBlock>
                    <div
                        className={styles.mobileHint}
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                        style={{display: "none"}}
                    >
                        <BookIcon isHovered={isHovered}/>
                        <span className={styles.waitingReminderMobile}>Памятка</span>
                    </div>
                    <iframe className={styles.frameMobile} src={appStatus.video_link}
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen>
                    </iframe>
                    <ThemeBlock themes={themes} isForWaiting={true}/>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default FirstStepWaiting;