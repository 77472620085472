import React from 'react';
// @ts-ignore
import commonStyles from "../../../css/admin/Admin.module.css";
import Button from "../../common/Button";
import Modal from "./Modal";

const ConfirmModal = (props: {text: string, closeModal: () => void, submit: () => void}) => {
    return (
        <Modal>
            <div className={commonStyles.successModalContainer}>
                <p className={commonStyles.successModalText}>{props.text}</p>
                <div className={commonStyles.confirmControls}>
                    <Button
                        width={"100px"}
                        height={"50px"}
                        fontSize={"20px"}
                        variant={"secondary"}
                        submit={props.closeModal}
                    >
                        Нет
                    </Button>
                    <Button
                        width={"100px"}
                        height={"50px"}
                        fontSize={"20px"}
                        variant={"secondary"}
                        submit={props.submit}
                    >
                        Да
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default ConfirmModal;