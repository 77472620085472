import React, {useEffect, useState} from 'react';
import {BrowserRouter} from "react-router-dom";
import AppRouter from "./router/AppRouter";
import {useTypedSelector} from "./hooks/useTypedSelector";
import LogoutModal from "./components/common/Header/LogoutModal";
import {useDispatch} from "react-redux";
import {check, getAppInfo} from "./http/authRequests";
import {UserActionTypes} from "./types/reducers/user";
import {AppActionTypes} from "./types/reducers/app";
import "../src/css/scrollbars.css"
import MouseHint from "./components/common/MouseHint";
import {
  checkUserChanged,
  isClientDemo,
  setUpDemo,
} from "./utils/demo/demo";
import RestartDemoButton from "./components/common/RestartDemoButton";
import {checkAdminMode} from "./utils/checkAdminMode";

//TODO Изменить папочную структуру (чтобы в каждой папке находится корневой элемент сущности и папка components)
//TODO Пофиксить мемори лики

function App() {
  const isMobile = useState(document.documentElement.clientWidth <= 900)[0]
  const modalConfig = useTypedSelector(state => state.modal)
  const isAuth = useTypedSelector(state => state.user.isAuth)
  const hintData = useTypedSelector(state => state.mouseHint)
  const dispatch = useDispatch()

  useEffect(() => {
      getAppInfo().then((data) => {
          if (data.is_demo) {
            setUpDemo(dispatch)
          } else {
            localStorage.removeItem('demo')
            dispatch({type: AppActionTypes.SET_APP_INFO, payload: data})
          }

          check().then(userData => {
              if (data.is_demo) {
                checkUserChanged(userData)
              }

              let role
              if (userData.user_role === 1) {
                role = checkAdminMode()
              } else {
                role = userData.user_role
                localStorage.removeItem('currentMode')
              }

              dispatch({type: UserActionTypes.SET_USER, payload: {
                id: userData.id,
                isAuth: true,
                name: userData.last_name + ' ' + userData.first_name + ' ' + userData.second_name,
                role
              }})
          }).catch(() => {
              dispatch({type: UserActionTypes.SET_USER, payload: {
                id: null,
                isAuth: false,
                name: null,
                role: 3
              }})
          })
      })
  }, [])

  return (
      <>
        {isAuth !== null && <BrowserRouter>
          <AppRouter />
          {modalConfig && <LogoutModal config={modalConfig} />}
          {hintData.isShown && <MouseHint x={hintData.x} y={hintData.y} text={hintData.hint_text}/>}
        </BrowserRouter>}
        {(isClientDemo() && !isMobile) && <RestartDemoButton isMobile={isMobile} />}
      </>
  );
}

export default App;
