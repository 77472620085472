import React from 'react';
import HighlightLogo from "../icons/HighlightLogo";
import {useTypedSelector} from "../../hooks/useTypedSelector";
import ExpertiseIcon from "../icons/ExpertiseIcon";
import {restartDemo} from "../../utils/demo/demo";

const DemoOverPage = () => {
    const supportMail = useTypedSelector(state => state.app.support_mail)

    return (
        <>
            <HighlightLogo />
            <div className="instructionBlock">
                <ExpertiseIcon />
                <div className="instructionText">
                    <p className="demoText">Вы прошли демо-версию. Если хотите пройти ее еще раз, нажмите <span onClick={restartDemo} className="restartDemo">сюда</span></p>
                </div>
            </div>
            <a href={"mailto" + supportMail} className="support instructionSupport">{supportMail}</a>
        </>
    );
};

export default DemoOverPage;