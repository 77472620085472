import React from 'react';
// @ts-ignore
import styles from '../../../css/Player.module.css'

const Ellipses = () => {
    return (
        <svg className={styles.ellipses} width="7.96vw" height="7.13vw" viewBox="0 0 153 137" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M87.7162 82.9059C71.4972 97.5866 55.553 109.158 42.9016 116.005C36.5689 119.432 31.1104 121.649 26.8814 122.501C22.5668 123.371 19.9081 122.753 18.677 121.126C17.446 119.498 17.3879 116.526 18.9761 112.053C20.5328 107.668 23.5448 102.218 27.8076 96.0548C36.3238 83.7421 49.6985 68.7748 65.9175 54.0941C82.1365 39.4134 98.0808 27.8424 110.732 20.9953C117.065 17.5679 122.523 15.3511 126.752 14.4986C131.067 13.6289 133.726 14.2473 134.957 15.8744C136.188 17.5016 136.246 20.474 134.658 24.9472C133.101 29.3317 130.089 34.782 125.826 40.9452C117.31 53.2579 103.935 68.2252 87.7162 82.9059Z" stroke="#323232" strokeWidth="2"/>
            <path d="M84.0694 83.8436C65.2796 94.2212 47.3897 101.633 33.6696 105.238C26.802 107.043 21.0339 107.879 16.7417 107.684C12.3729 107.486 9.88966 106.244 9.00737 104.332C8.12508 102.421 8.68547 99.498 11.1332 95.5351C13.5381 91.6415 17.5775 87.0609 22.9822 82.0876C33.7798 72.1517 49.8533 60.8054 68.6431 50.4278C87.4329 40.0503 105.323 32.6387 119.043 29.0335C125.911 27.2288 131.679 26.3928 135.971 26.5875C140.34 26.7858 142.823 28.0279 143.705 29.9391C144.587 31.8503 144.027 34.7734 141.579 38.7364C139.174 42.63 135.135 47.2105 129.73 52.1839C118.933 62.1197 102.859 73.466 84.0694 83.8436Z" stroke="#323232" strokeWidth="2"/>
        </svg>

    );
};

export default Ellipses;