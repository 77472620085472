import React from 'react';

const MailIcon = () => {
    return (
        <svg width="53" height="24" viewBox="0 0 53 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{display: 'block', margin: "0 auto"}}>
            <path d="M21 12H3M17 8L21 12L17 8ZM21 12L17 16L21 12Z" stroke="#323232" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M32 19.7268V9.57693C31.9999 9.20278 32.0902 8.8344 32.2627 8.50447C32.4352 8.17455 32.6846 7.89328 32.9889 7.68563L40.7667 2.38224C41.1318 2.13301 41.561 2 42 2C42.439 2 42.8682 2.13301 43.2333 2.38224L51.0111 7.68563C51.3154 7.89328 51.5648 8.17455 51.7373 8.50447C51.9098 8.8344 52.0001 9.20278 52 9.57693V19.7268M32 19.7268C32 20.3297 32.2341 20.9079 32.6509 21.3342C33.0676 21.7605 33.6329 22 34.2222 22H49.7778C50.3671 22 50.9324 21.7605 51.3491 21.3342C51.7659 20.9079 52 20.3297 52 19.7268M32 19.7268L39.5 14.6121M52 19.7268L44.5 14.6121M39.5 14.6121L32 9.49737M39.5 14.6121L40.7667 15.4759C41.1318 15.7251 41.561 15.8581 42 15.8581C42.439 15.8581 42.8682 15.7251 43.2333 15.4759L44.5 14.6121M44.5 14.6121L52 9.49737" stroke="#323232" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};

export default MailIcon;