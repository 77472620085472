import {variables} from "../admin/http/mailListsRequests";
import {IOption} from "../admin/props/adminDropDown";
import {ScriptCardProps} from "../../components/admin/wizard/ScriptPanel/ScriptCard";
import {defaultUITexts} from "../../utils/defaultUITexts";

enum WizardActionTypes {
    SET_SCRIPT_DATA = 'SET_SCRIPT_DATA',

    SET_PROJECT_NAME = 'SET_PROJECT_NAME',
    SET_DESCRIPTION = 'SET_DESCRIPTION',
    ADD_MAIL_DOMAIN = 'ADD_MAIL_DOMAIN',
    DELETE_MAIL_DOMAIN = 'DELETE_MAIL_DOMAIN',
    SET_MAIL_DOMAIN_BY_INDEX = 'SET_MAIL_DOMAIN_BY_INDEX',
    SET_SUPPORT_MAIL = 'SET_SUPPORT_MAIL',
    SET_WIZARD_VARIABLES = 'SET_WIZARD_VARIABLES',
    SET_WIZARD_PROJECT_DESCRIPTION = 'SET_WIZARD_PROJECT_DESCRIPTION',

    SET_REGISTER_FIELD_NAME_BY_INDEX = 'SET_REGISTER_FIELD_NAME_BY_INDEX',
    SET_REGISTER_SUBFIELDS_BY_INDEX = 'SET_REGISTER_SUBFIELDS_BY_INDEX',
    REMOVE_REGISTER_FIELD = 'REMOVE_REGISTER_FIELD',
    ADD_REGISTER_FIELD = 'ADD_REGISTER_FIELD',
    CHECK_REGISTER_FIELD = 'CHECK_REGISTER_FIELD',
    SET_REGISTER_FIELDS = 'SET_REGISTER_FIELDS',

    SET_FIRST_STEP_DATA = 'SET_FIRST_STEP_DATA',
    SET_FIRST_STEP_INSTRUCTION = 'SET_FIRST_STEP_INSTRUCTION',
    SET_FIRST_STEP_DATE = 'SET_FIRST_STEP_DATE',
    SET_MAX_PICKED_SUBTOPICS = 'SET_MAX_PICKED_SUBTOPICS',
    SET_DEFAULT_LIMIT = 'SET_DEFAULT_LIMIT',
    SET_SUBTOPICS = 'SET_SUBTOPICS',

    SET_WAITING_INSTRUCTION = 'SET_WAITING_INSTRUCTION',
    SET_EMBEDDED_LINK = 'SET_EMBEDDED_LINK',
    SET_WAITING_INFO = 'SET_WAITING_INFO',

    SET_SECOND_STEP_INSTRUCTION = 'SET_SECOND_STEP_INSTRUCTION',
    SET_SECOND_STEP_START_DATE = 'SET_SECOND_STEP_START_DATE',
    SET_TASK_COUNTER = 'SET_TASK_COUNTER',
    SET_OPTIONS_COUNTER = 'SET_OPTIONS_COUNTER',
    SET_SECOND_STEP_INFO = 'SET_SECOND_STEP_INFO',
    SET_MAX_SYMBOLS_FOR_QUESTION = 'SET_MAX_SYMBOLS_FOR_QUESTION',
    SET_MAX_SYMBOLS_FOR_OPTION = 'SET_MAX_SYMBOLS_FOR_OPTION',

    SET_THIRD_STEP_INSTRUCTION = 'SET_THIRD_STEP_INSTRUCTION',
    SET_THIRD_STEP_START_DATE = 'SET_THIRD_STEP_START_DATE',
    SET_THIRD_STEP_END_DATE = 'SET_THIRD_STEP_END_DATE',
    SET_EXPERT_TASK_LIMIT = 'SET_EXPERT_TASK_LIMIT',
    SET_COLLECT_WAY = 'SET_COLLECT_WAY',
    SET_GRADE_QUALITY_DESCRIPTION_BY_INDEX = 'SET_GRADE_QUALITY_DESCRIPTION_BY_INDEX',
    SET_GRADE_ACTUALITY_DESCRIPTION_BY_INDEX = 'SET_GRADE_ACTUALITY_DESCRIPTION_BY_INDEX',
    SET_DASHBOARD_FLAG = 'SET_DASHBOARD_FLAG',
    SET_THIRD_STAGE_DATA = 'SET_THIRD_STAGE_DATA',
}

interface ISetScriptDataAction {
    type: WizardActionTypes.SET_SCRIPT_DATA
    payload: ScriptCardProps[]
}

interface ISetThirdStepInstructionAction {
    type: WizardActionTypes.SET_THIRD_STEP_INSTRUCTION
    payload: string
}

interface ISetThirdStepStartDateAction {
    type: WizardActionTypes.SET_THIRD_STEP_START_DATE
    payload: string
}

interface ISetThirdStepEndDateAction {
    type: WizardActionTypes.SET_THIRD_STEP_END_DATE
    payload: string
}

interface ISetExpertTaskLimitAction {
    type: WizardActionTypes.SET_EXPERT_TASK_LIMIT
    payload: string
}


interface ISetMaxSymbolsForQuestionAction {
    type: WizardActionTypes.SET_MAX_SYMBOLS_FOR_QUESTION
    payload: string
}


interface ISetMaxSymbolsForOptionAction {
    type: WizardActionTypes.SET_MAX_SYMBOLS_FOR_OPTION
    payload: string
}


interface ISetCollectWayAction {
    type: WizardActionTypes.SET_COLLECT_WAY
    payload: boolean
}

interface ISetGradeActualityDescriptionByIndexAction {
    type: WizardActionTypes.SET_GRADE_ACTUALITY_DESCRIPTION_BY_INDEX
    payload: {
        index: number,
        description: string
    }
}

interface ISetGradeQualityDescriptionByIndexAction {
    type: WizardActionTypes.SET_GRADE_QUALITY_DESCRIPTION_BY_INDEX
    payload: {
        index: number,
        description: string
    }
}

interface ISetDashboardFlagAction {
    type: WizardActionTypes.SET_DASHBOARD_FLAG
    payload: boolean
}

interface ISetThirdStepDataAction {
    type: WizardActionTypes.SET_THIRD_STAGE_DATA
    payload: ThirdStepData
}

interface ISetFirstStepDataAction {
    type: WizardActionTypes.SET_FIRST_STEP_DATA
    payload: FirstStepData
}

interface ISetFirstStepInstructionAction {
    type: WizardActionTypes.SET_FIRST_STEP_INSTRUCTION
    payload: string
}

interface ISetFirstStepDateAction {
    type: WizardActionTypes.SET_FIRST_STEP_DATE
    payload: string
}

interface ISetDefaultLimitAction {
    type: WizardActionTypes.SET_DEFAULT_LIMIT
    payload: string
}

interface ISetMaxPickedSubtopicsAction {
    type: WizardActionTypes.SET_MAX_PICKED_SUBTOPICS
    payload: string
}

interface ISetSubtopicsAction {
    type: WizardActionTypes.SET_SUBTOPICS
    payload: UploadedSubtopicData
}

interface ISetTaskCounterAction {
    type: WizardActionTypes.SET_TASK_COUNTER
    payload: string
}

interface ISetSecondStepInstructionAction {
    type: WizardActionTypes.SET_SECOND_STEP_INSTRUCTION
    payload: string
}

interface ISetSecondStepStartDateAction {
    type: WizardActionTypes.SET_SECOND_STEP_START_DATE
    payload: string
}

interface ISetSecondStepInfoAction {
    type: WizardActionTypes.SET_SECOND_STEP_INFO
    payload: SecondStepData
}

interface ISetOptionsCounterAction {
    type: WizardActionTypes.SET_OPTIONS_COUNTER
    payload: string
}

interface ISetWaitingInstructionAction {
    type: WizardActionTypes.SET_WAITING_INSTRUCTION
    payload: string
}

interface ISetWaitingInfoAction {
    type: WizardActionTypes.SET_WAITING_INFO
    payload: WaitingStage
}

interface ISetEmbeddedLinkAction {
    type: WizardActionTypes.SET_EMBEDDED_LINK
    payload: string
}

interface ISetRegisterFieldAction {
    type: WizardActionTypes.SET_REGISTER_FIELDS
    payload: Register
}

interface ICheckRegisterFieldAction {
    type: WizardActionTypes.CHECK_REGISTER_FIELD
    payload: number
}

interface IAddRegisterFieldAction {
    type: WizardActionTypes.ADD_REGISTER_FIELD
}

interface IRemoveRegisterFieldAction {
    type: WizardActionTypes.REMOVE_REGISTER_FIELD
}

interface ISetRegisterSubfieldsByIndexAction {
    type: WizardActionTypes.SET_REGISTER_SUBFIELDS_BY_INDEX
    payload: {
        index: number,
        subfields: string
    }
}

interface ISetRegisterFieldNameByIndexAction {
    type: WizardActionTypes.SET_REGISTER_FIELD_NAME_BY_INDEX
    payload: {
        index: number,
        name: string
    }
}


interface ISetProjectNameAction {
    type: WizardActionTypes.SET_PROJECT_NAME
    payload: string
}

interface ISetWizardProjectDescriptionAction {
    type: WizardActionTypes.SET_WIZARD_PROJECT_DESCRIPTION
    payload: ProjectDescription
}

interface ISetWizardVariables {
    type: WizardActionTypes.SET_WIZARD_VARIABLES
    payload: variables
}

interface ISetDescriptionAction {
    type: WizardActionTypes.SET_DESCRIPTION
    payload: string
}

interface ISetSupportMailAction {
    type: WizardActionTypes.SET_SUPPORT_MAIL
    payload: string
}

interface IAddMailDomainAction {
    type: WizardActionTypes.ADD_MAIL_DOMAIN
}

interface IDeleteMailDomainAction {
    type: WizardActionTypes.DELETE_MAIL_DOMAIN
}

interface ISetMailDomainByIndexAction {
    type: WizardActionTypes.SET_MAIL_DOMAIN_BY_INDEX
    payload: {
        index: number
        domain: string
    }
}

type WizardProjectDescriptionAction = ISetProjectNameAction |
    ISetDescriptionAction |
    IAddMailDomainAction |
    IDeleteMailDomainAction |
    ISetMailDomainByIndexAction |
    ISetSupportMailAction |
    ISetWizardVariables |
    ISetWizardProjectDescriptionAction |
    ISetRegisterFieldNameByIndexAction |
    ISetRegisterSubfieldsByIndexAction |
    IRemoveRegisterFieldAction |
    IAddRegisterFieldAction |
    ICheckRegisterFieldAction |
    ISetRegisterFieldAction |
    ISetEmbeddedLinkAction |
    ISetWaitingInfoAction |
    ISetWaitingInstructionAction |
    ISetSecondStepInfoAction |
    ISetSecondStepInstructionAction |
    ISetSecondStepStartDateAction |
    ISetOptionsCounterAction |
    ISetTaskCounterAction |
    ISetSubtopicsAction |
    ISetFirstStepDateAction |
    ISetFirstStepInstructionAction |
    ISetDefaultLimitAction |
    ISetFirstStepDataAction |
    ISetThirdStepDataAction |
    ISetThirdStepStartDateAction |
    ISetThirdStepEndDateAction |
    ISetThirdStepInstructionAction |
    ISetDashboardFlagAction |
    ISetGradeActualityDescriptionByIndexAction |
    ISetGradeQualityDescriptionByIndexAction |
    ISetExpertTaskLimitAction |
    ISetCollectWayAction |
    ISetScriptDataAction |
    ISetMaxPickedSubtopicsAction |
    ISetMaxSymbolsForOptionAction |
    ISetMaxSymbolsForQuestionAction

type WizardAction = WizardProjectDescriptionAction

export {WizardActionTypes}
export type {WizardAction}

export interface Wizard {
    scriptData: ScriptCardProps[]
    variables: variables
    project_description: ProjectDescription
    register: Register
    waiting_stage: WaitingStage
    first_step: FirstStepData
    second_step: SecondStepData
    third_step: ThirdStepData
}

export interface RegisterField {
    name: string
    subfields: string
    isChecked: boolean
}

export type Register = RegisterField[]

export interface ProjectDescription {
    project_name: string
    description: string
    mail_domains: string[]
    support_mail: string
}

export interface WaitingStage {
    instruction: string,
    embedded_link: string
}

export type Texts = string | {grade: number, description: string}[]

export interface SecondStepData {
    instruction: string
    start_date: string
    task_counter: string
    option_counter: string
    max_symbols_for_question: string
    max_symbols_for_option: string
}

export interface FirstStepData {
    instruction: string,
    start_date: string,
    subtopic_limit: string,
    max_picked_subtopics: string,
    subtopic_data?: SubtopicWizardData[]
    clusters: IOption[]
}

export interface UploadedSubtopicData {
    subtopic_data: SubtopicWizardData[]
    clusters: IOption[]
}

export interface SubtopicWizardData {
    topic_name: string
    subtopics: {
        subtopic_name: string
        description: string
        cluster_ids: number[]
    }[]
}

export interface ThirdStepData {
    instruction: string
    start_date: string
    end_date: string
    task_limit_for_expert: string
    is_collection_by_subtopic: boolean
    quality: string[]
    originality: string[]
    is_dashboard_show: boolean
}

export const WizardInitState: Wizard = {
    scriptData: [],

    variables: [],

    project_description: {
        project_name: '',
        description: '',
        mail_domains: [''],
        support_mail: ''
    },

    register: [{
        name: 'Организация',
        subfields: '',
        isChecked: true
    }],

    waiting_stage: {
        instruction: defaultUITexts.waitingForSecondStep,
        embedded_link: ''
    },

    first_step: {
        instruction: defaultUITexts.firstStep,
        start_date: '',
        subtopic_limit: '',
        max_picked_subtopics: '',
        clusters: [],
    },

    second_step: {
        instruction: defaultUITexts.secondStep,
        start_date: '',
        task_counter: '',
        option_counter: '',
        max_symbols_for_option: '',
        max_symbols_for_question: '',
    },

    third_step: {
        instruction: defaultUITexts.thirdStep,
        start_date: '',
        end_date: '',
        task_limit_for_expert: '',
        is_collection_by_subtopic: true,
        quality: ['', '', '', '', ''],
        originality: ['', '', '', '', ''],
        is_dashboard_show: true,
    }
}