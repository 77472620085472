import React, {FC, useMemo} from 'react';
// @ts-ignore
import styles from "../../../../css/admin/Script.module.css";
import {StageStatus, WizardStages} from "../../../../types/entities/status";
import {useWizardStageStatus} from "../../../../hooks/useWizardStageStatus";

interface ScriptNavElementProps {
    currentStageCard: WizardStages
}

const useNavElementStyles = (currentStageCard: WizardStages, currentStageStatus: StageStatus) => useMemo(() => {
    const actualOrPastColor = currentStageStatus === StageStatus.ACTUAL || currentStageStatus === StageStatus.PAST ?
        'rgba(255, 213, 86, 1)'
        :
        'rgba(215, 215, 215, 1)'

    const shortLineStyles = {
        backgroundColor: currentStageCard === WizardStages.PROJECT_DESCRIPTION ? 'rgba(0, 0, 0, 0)' : actualOrPastColor
    }

    const longLineColor = currentStageStatus === StageStatus.ACTUAL ?
        'linear-gradient(rgba(255, 213, 86, 1), rgba(215, 215, 215, 1))'
        :
        currentStageStatus === StageStatus.PAST ? 'rgba(255, 213, 86, 1)' : 'rgba(215, 215, 215, 1)'

    const longLineStyles = {
        background: currentStageCard === WizardStages.THIRD_STEP ? 'rgba(0, 0, 0, 0)' : longLineColor
    }

    const circleStyles = {
        backgroundColor: actualOrPastColor
    }

    return { shortLineStyles, circleStyles, longLineStyles }
}, [currentStageStatus, currentStageCard])

const ScriptNavElement:FC<ScriptNavElementProps> = (props) => {
    const { currentStageCard } = props
    const currentStageStatus = useWizardStageStatus(currentStageCard)

    const { shortLineStyles, circleStyles, longLineStyles } = useNavElementStyles(currentStageCard, currentStageStatus)

    return (
        <div className={styles.navElement}>
            <div className={styles.shortLine} style={shortLineStyles}/>
            <div className={styles.circle} style={circleStyles}/>
            <div className={styles.longLine} style={longLineStyles}/>
        </div>
    );
};

export default ScriptNavElement;