import React from 'react';
import InfoBlock from "../../../InfoBlock/InfoBlock";
import QuestionCard from "../../../../editors/expertise/QuestionCard";
// @ts-ignore
import expertiseStyles from "../../../../../css/Editor.module.css";
import CustomRadio from "../../../../common/CustomRadio";

const StepOne = () => {
    return (
        <>
            <InfoBlock>
                <p>Вам необходимо внимательно <b>прочитать и оценить каждое задание</b>, предложенное по данной теме другими авторами.</p>
            </InfoBlock>
            <p style={{width: 320, margin: '20px auto 20px auto'}}>Пример задания</p>
            <div style={{width: 320, margin: '0 auto'}}>
                <QuestionCard>
                    <span className={expertiseStyles.exerciseNumber}>
                        1
                    </span>
                    <div className={expertiseStyles.exercise}>
                        <p className={expertiseStyles.exerciseQuestion}>Сколько будет 2х2?</p>
                        {[1, 2, 3, 4].map(num => {
                            return <div className={expertiseStyles.exerciseOption} key={num}>
                                <CustomRadio isChecked={false}/>
                                <p className={expertiseStyles.exerciseOptionText}>{num}</p>
                            </div>
                        })}
                    </div>
                </QuestionCard>
            </div>
        </>
    );
};

export default StepOne;