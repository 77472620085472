import React from 'react';

const ClearTaskIcon = () => {
    return (
        <svg width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg" style={{transform: "rotate(90deg)"}}>
            <path d="M10.375 10L12.4583 8L10.375 10ZM12.4583 8L14.5417 6L12.4583 8ZM12.4583 8L10.375 6L12.4583 8ZM12.4583 8L14.5417 10L12.4583 8ZM1 8L7.68125 14.414C8.07186 14.7891 8.60168 14.9999 9.15417 15H17.6667C18.2192 15 18.7491 14.7893 19.1398 14.4142C19.5305 14.0391 19.75 13.5304 19.75 13V3C19.75 2.46957 19.5305 1.96086 19.1398 1.58579C18.7491 1.21071 18.2192 1 17.6667 1H9.15417C8.60168 1.00011 8.07186 1.2109 7.68125 1.586L1 8Z" stroke="#A1A1A1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};

export default ClearTaskIcon;