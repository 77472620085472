export interface ISubtopic {
    value: string
    id: number
    subtopic_name_id: number
    is_locked?: boolean
    done?: number
    total?: number
}

export interface ITheme {
    name: string
    values: ISubtopic[]
}

export type IThemeList = ITheme[]

export const initialThemeValue: IThemeList = [
    {
        "name": "Загрузка...",
        "values": [
            {
                "value": "Загрузка...",
                "is_locked": false,
                "id": 1,
                "subtopic_name_id": 1
            },
        ]
    },
]
export type IThemeListVariant = "chooseThemes" | "setTasks" | "doTasks"

export interface IThemeListComponent {
    themeListVariant: IThemeListVariant,
    themes: IThemeList
}