import React, {useEffect, useMemo} from 'react';
import Header from "../../common/Header/Header";
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import {WizardStages} from "../../../types/entities/status";
import {useNavigate, useParams} from "react-router-dom";
import WizardNavigation from "./WizardNavigation";
import ProjectDescriptionComponent from "./WizardStageComponents/ProjectDescriptionComponent";
// @ts-ignore
import styles from "../../../css/admin/Script.module.css";
// @ts-ignore
import commonStyles from "../../../css/admin/Admin.module.css";
import RegistrationComponent from "./WizardStageComponents/RegistrationComponent";
import FirstStepComponent from "./WizardStageComponents/FirstStepComponent";
import WaitingSecondStepComponent from "./WizardStageComponents/WaitingSecondStepComponent";
import SecondStepComponent from "./WizardStageComponents/SecondStepComponent";
import ThirdStepComponent from "./WizardStageComponents/ThirdStepComponent";
import {getWizardVariables} from "../../../http/admin/wizardRequest";
import {useDispatch} from "react-redux";
import {WizardActionTypes} from "../../../types/reducers/wizard";

const useCurrentStageComponent = (currentStage: string | undefined) => useMemo(() => {
    switch (currentStage) {
        case WizardStages.PROJECT_DESCRIPTION:
            return <ProjectDescriptionComponent />
        case WizardStages.REGISTER:
            return <RegistrationComponent />
        case WizardStages.FIRST_STEP:
            return <FirstStepComponent />
        case WizardStages.WAITING_FOR_SECOND_STEP:
            return <WaitingSecondStepComponent />
        case WizardStages.SECOND_STEP:
            return <SecondStepComponent />
        case WizardStages.THIRD_STEP:
            return <ThirdStepComponent />
        default:
            return <div>404</div>
    }
}, [currentStage])

const WizardPage = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const stage = useParams().stage
    const isWizardConfigured = useTypedSelector(state => state.status.wizard_completed)
    const currentStage = useTypedSelector(state => state.status.current_wizard_stage)
    const variables = useTypedSelector(state => state.wizard.variables)

    useEffect(() => {
        if (variables.length === 0) {
            getWizardVariables().then(data => {
                dispatch({ type: WizardActionTypes.SET_WIZARD_VARIABLES, payload: data })
            })
        }
    })

    const currentStageComponent = useCurrentStageComponent(isWizardConfigured ? stage : currentStage)

    return (
        <>
            <Header />
            <div className={styles.wizardPageLayout}>
                {!isWizardConfigured && <WizardNavigation />}
                {isWizardConfigured && <div className={styles.backControl} onClick={() => navigate('/admin/script')}>
                    Назад к сценарию
                </div>}
                <div>
                    <h2 className={commonStyles.panelHeader}>Создание проекта</h2>
                    {currentStageComponent}
                </div>
            </div>
        </>
    );
};

export default WizardPage;