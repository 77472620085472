import React from 'react';
// @ts-ignore
import styles from "../../../css/admin/MailPanel.module.css";
import DeliveredIcon from "../../icons/admin/DeliveredIcon";
import OpenedIcon from "../../icons/admin/OpenedIcon";
import CalendarIcon from "../../icons/admin/CalendarIcon";
import {isCustomMailSent} from "../../../utils/dateFuncs";

const MailListInfo = (props: {opened: number, delivered: number, total: number, date?: string}) => {
    return (
        <div className={styles.mailListInfo}>
            {props.date && <><div className={styles.delivered}>
                <div className={styles.deliveredHeader}>
                    <CalendarIcon />
                    <div className={styles.counter}>
                        <span className={styles.deliveredSpan}>Дата отправки</span>
                        <p>{props.date}</p>
                    </div>
                </div>
            </div>

            <div className={styles.verticalLine} /></>}

          {isCustomMailSent(props.date) && false && <>
              <div className={styles.delivered}>
                  <div className={styles.deliveredHeader}>
                      <DeliveredIcon />
                      <div className={styles.counter}>
                          <span className={styles.deliveredSpan}>Доставлено</span>
                          <p>{props.delivered}/<span className={styles.total}>{props.total}</span></p>
                      </div>
                  </div>
              </div>

              <div className={styles.verticalLine} />

              <div className={styles.opened}>
                  <div className={styles.deliveredHeader}>
                      <OpenedIcon />
                      <div className={styles.counter}>
                          <span className={styles.deliveredSpan}>Открыто</span>
                          <p>{props.opened}/<span className={styles.total}>{props.total}</span></p>
                      </div>
                  </div>
              </div>
          </>}
        </div>
    );
};

export default MailListInfo;