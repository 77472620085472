import React from 'react';

const DashboardShape = () => {
    return (
        <svg width="98" height="115" viewBox="0 0 98 115" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_f_4266_47580)">
                <path d="M93.6328 44.3239L3.99937 4.61646L3.99937 35.9787L59.7701 57.5602L3.99937 79.1445L3.99937 110.5L93.6328 70.7925L93.6328 44.3239Z" fill="#FFD556"/>
            </g>
            <defs>
                <filter id="filter0_f_4266_47580" x="0" y="0.617188" width="97.6328" height="113.883" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_4266_47580"/>
                </filter>
            </defs>
        </svg>
    );
};

export default DashboardShape;