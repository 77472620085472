import {
    IDescriptionData,
    MarkDescriptionAction,
    MarkDescriptionActionTypes
} from "../../types/reducers/markDescription";

const initialState: IDescriptionData = {
    actuality: {
        isPicked: false,
        description: '',
        picked_description: ''
    },

    quality: {
        isPicked: false,
        description: '',
        picked_description: ''
    }
}

export const markDescriptionReducer = (state=initialState, action: MarkDescriptionAction): IDescriptionData => {
    switch (action.type) {
        case MarkDescriptionActionTypes.SET_ACTUALITY_DESCRIPTION:
            return {...state, actuality: {...state.actuality, description: action.payload}}
        case MarkDescriptionActionTypes.SET_QUALITY_DESCRIPTION:
            return {...state, quality: {...state.quality, description: action.payload}}
        case MarkDescriptionActionTypes.SET_PICKED_QUALITY_DESCRIPTION:
            return {...state, quality: {...state.quality, picked_description: action.payload}}
        case MarkDescriptionActionTypes.SET_PICKED_ACTUALITY_DESCRIPTION:
            return {...state, actuality: {...state.actuality, picked_description: action.payload}}
        default:
            return initialState
    }
}