import React from 'react';
// @ts-ignore
import styles from "../../../css/Editor.module.css";
import {IOption} from "../../../types/entities/task";
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import {useDispatch} from "react-redux";
import {ExerciseActionTypes} from "../../../types/reducers/exercise";
import CustomCheckbox from "../../common/CustomCheckbox";

const CheckboxExerciseOptions = (props: {options: IOption[]}) => {
    const checkedOptions = useTypedSelector(state => new Set(JSON.parse(state.exercise.answer)))
    const dispatch = useDispatch()

    const checkOption = (id: number) => {
        const newCheckedOptions = new Set(checkedOptions)

        if (newCheckedOptions.has(id)) {
            newCheckedOptions.delete(id)
            dispatch({type: ExerciseActionTypes.SET_ANSWERS, payload: JSON.stringify(Array.from(newCheckedOptions))})
        } else {
            newCheckedOptions.add(id)
            dispatch({type: ExerciseActionTypes.SET_ANSWERS, payload: JSON.stringify(Array.from(newCheckedOptions))})
        }
    }

    return (
        <div className={styles.exerciseOptions}>
            {props.options.map((option) => {
                return <div key={option.id} className={styles.exerciseOption} onClick={() => checkOption(option.id)}>
                    <CustomCheckbox isChecked={checkedOptions.has(option.id)} isMinimize={true}/>
                    <p className={styles.exerciseOptionText}>{option.text}</p>
                </div>
            })}
        </div>
    );
};

export default CheckboxExerciseOptions;