export const dragMove = (e) => {
    document.querySelector('#playerWindow')?.setAttribute('style', 'overflow: hidden')
    const draggableElement = e.currentTarget.closest('.dragElement')
    const dragOptionsContainers = document.querySelector('.dragOptionsContainer')
    const dragContainers = dragOptionsContainers.childNodes
    dragOptionsContainers.append(draggableElement)

    let cursor = e.targetTouches[0]
    draggableElement.setAttribute("data-isDragged", `true`)
    if (draggableElement.classList.contains('forExercise')) {
        draggableElement.setAttribute("style", `position: absolute; top: ${cursor.screenY - 250}px`)
    } else {
        draggableElement.setAttribute("style", `position: absolute; top: ${cursor.pageY - 50}px`)
    }

    dragContainers.forEach(container => {
        if (
            draggableElement.getBoundingClientRect().top + draggableElement.offsetHeight / 2 < container.getBoundingClientRect().bottom &&
            draggableElement.getBoundingClientRect().right - draggableElement.offsetWidth / 2 > container.getBoundingClientRect().left &&
            draggableElement.getBoundingClientRect().bottom - draggableElement.offsetHeight / 2 > container.getBoundingClientRect().top &&
            draggableElement.getBoundingClientRect().left + draggableElement.offsetWidth / 2 < container.getBoundingClientRect().right
        ) {
            let emptyContainer = findEmptyContainer(dragContainers)
            let option = container.querySelector('.dragElement')
            if (container.innerHTML !== '' && option) {
                emptyContainer.append(container.querySelector('.dragElement'))
            }
        }
    })
}

export const dragEnd = (taskId, setAnswers) => {
    const draggableElement = document.querySelector('[data-isdragged="true"]')
    const emptyContainer = findEmptyContainer(document.querySelector('.dragOptionsContainer').childNodes)
    emptyContainer.append(draggableElement)

    document.querySelector('#playerWindow')?.setAttribute('style', 'overflow: scrollable')
    draggableElement.setAttribute("data-isDragged", `false`)
    draggableElement.setAttribute("style", `position: static;`)

    const dragOptionsContainer = document.querySelector('.dragOptionsContainer')

    const newOrder = []
    dragOptionsContainer.childNodes.forEach((container) => {
        newOrder.push(Number(container.childNodes[0].getAttribute('id')))
    })

    setAnswers(taskId, newOrder)
}

const findEmptyContainer = (containers) => {
    for (let i = 0; i < containers.length; i++) {
        if (containers[i].innerHTML === '') {
            return containers[i]
        }
    }
}