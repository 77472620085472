import React, {useEffect, useRef, useState} from 'react';
import ArrowIcon from "../../icons/ArrowIcon";
import {IAdminDropDownProps} from "../../../types/admin/props/adminDropDown";
// @ts-ignore
import styles from "../../../css/admin/Admin.module.css";
import SimpleBar from 'simplebar-react';

const AdminDropDown = (props: IAdminDropDownProps) => {
    const [currentOption, setCurrentOption] = useState(props.defaultValue.name)
    const [isOpened, setIsOpened] = useState(false)

    const optionsContainer = useRef<HTMLDivElement>(null)
    const [defaultHeight, setDefaultHeight] = useState(0)

    useEffect(() => {
        if (optionsContainer?.current !== null) {
            setDefaultHeight(optionsContainer.current?.offsetHeight + 180)
        }
    }, [])

    return (
        <div className={styles.dropDown + " classForScrollDropDown"} style={{width: props.width}}>
            <div className={styles.value} onClick={() => {setIsOpened(!isOpened)}}>
                <p style={{width: props.width - 55}}>{currentOption}</p>
                <ArrowIcon isOpened={isOpened}/>
            </div>

            <div className={styles.dropOptions} style={{height: isOpened ? defaultHeight : 0, maxHeight: props.maxHeight}}>
                <SimpleBar style={{maxHeight: props.maxHeight - 9}} autoHide={false}>
                    <div className={styles.dropOptionsContainer} ref={optionsContainer}>
                        {props.optionList.map(option => {
                            return <p key={option.id} className={styles.dropOption} onClick={() => {
                                props.setter && props.setter(option)
                                setCurrentOption(option.name)
                                setIsOpened(false)
                            }}>
                                {option.name}
                            </p>
                        })}
                    </div>
                </SimpleBar>
            </div>
        </div>
    );
};

export default AdminDropDown;