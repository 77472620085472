import {IAppInfo} from "../entities/appInfo";

export enum AppActionTypes {
    SET_APP_INFO = "SET_APP_INFO"
}

interface ISetAppInfoAction {
    type: AppActionTypes.SET_APP_INFO
    payload: IAppInfo
}

export type AppAction = ISetAppInfoAction