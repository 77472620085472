import React from 'react';
import {IOption} from "../../../types/admin/props/adminDropDown";
import AdminDropDown from "../../admin/common/AdminDropDown";

const options = [{
  id: 1,
  name: 'Админ'
}, {
  id: 3,
  name: 'Пользователь'
}]

const RoleSwitcher = () => {
  const setMode = (option: IOption) => {
    if (option.name === 'Админ') {
      localStorage.setItem('currentMode', '1')
    } else {
      localStorage.setItem('currentMode', '3')
    }

    window.location.reload()
  }

  return (
    <AdminDropDown
      width={200}
      maxHeight={100}
      optionList={options}
      defaultValue={localStorage.getItem('currentMode') === '3' ? options[1] : options[0]}
      setter={setMode}
    />
  );
};

export default RoleSwitcher;