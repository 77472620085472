import React from 'react';
import StepTwoSVG from "./vectorImages/StepTwoSVG";

const DesktopStepTwo = () => {
    return (
        <>
            <p>
                Для каждого задания <b>укажите ответ</b>, который вы считаете верным.
            </p>

            <br/>

            <p>
                Это нужно не для оценки Ваших знаний, а для того, чтобы убедится, что задание имеет однозначный ответ.
            </p>

            <div style={{margin: "50px auto", width: 430}}>
                <StepTwoSVG />
            </div>
        </>
    );
};

export default DesktopStepTwo;