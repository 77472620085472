import React, {useEffect, useState} from 'react';
// @ts-ignore
import styles from "../../../../css/admin/Script.module.css";
import AlertModal from "../../common/AlertModal";
import {WizardStages, WizardStagesNames} from "../../../../types/entities/status";
import WizardInput from "../UI/WizardInput";
import Button from "../../../common/Button";
import {useTypedSelector} from "../../../../hooks/useTypedSelector";
import {useDispatch} from "react-redux";
import HeadingDescriptionComponent from "../UI/HeadingDescriptionComponent";
import CustomRadio from "../../../common/CustomRadio";
import AdminDropDown from "../../common/AdminDropDown";
import {IOption} from "../../../../types/admin/props/adminDropDown";
import {SecondStepData, ThirdStepData, WizardActionTypes} from "../../../../types/reducers/wizard";
import GradeForm from "../UI/GradeForm";
import {
    getThirdStepWizardData,
    setSecondStepWizardData,
    setThirdStepWizardData
} from "../../../../http/admin/wizardRequest";
import {StatusActionTypes} from "../../../../types/reducers/status";
import {useNavigate} from "react-router-dom";
import {isValidDate} from "../../../../utils/isValidDate";
import Preloader from "../../../common/Preloader";

const errorHandler = (thirdStepData: ThirdStepData, qualityCount: number, actualityCount: number) => {
    if (thirdStepData.instruction === '') return  'Вы не заполнили инструкцию!'
    if (thirdStepData.start_date === '') return 'Вы не ввели дату начала этапа!'
    if (!isValidDate(thirdStepData.start_date)) return 'Неверный формат даты начала этапа'
    if (thirdStepData.end_date === '') return 'Вы не ввели дату конца этапа!'
    if (!isValidDate(thirdStepData.end_date)) return 'Неверный формат даты конца этапа'
    if (thirdStepData.task_limit_for_expert === '') return  'Вы не ввели лимит заданий для эксперта!'

    let isQualityCounterNotFill = ''
    for (let i = 0; i < qualityCount; i++) {
        if (thirdStepData.quality[i] === '') isQualityCounterNotFill = 'Вы заполнили не все оценки качества'
    }

    let isActualityCounterNotFill = ''
    for (let i = 0; i < actualityCount; i++) {
        if (thirdStepData.originality[i] === '') isActualityCounterNotFill = 'Вы заполнили не все оценки актуальности'
    }

    return (isQualityCounterNotFill || isActualityCounterNotFill || '')
}

const ThirdStepComponent = () => {
    const navigate = useNavigate()
    const thirdStepData = useTypedSelector(state => state.wizard.third_step)
    const isWizardConfigured = useTypedSelector(state => state.status.wizard_completed)
    const dispatch = useDispatch()
    const [currentQualityOption, setCurrentQualityOption] = useState<IOption>({id: 2, name: '5'})
    const [currentActualityOption, setCurrentActualityOption] = useState<IOption>({id: 2, name: '5'})
    const [buttonText, setButtonText] = useState('Сохранить')
    const [errorText, setErrorText] = useState('')
    const [isModalErrorOpened, setIsModalErrorOpened] = useState(false)
    const [isDataPending, setIsDataPending] = useState(false)

    useEffect(() => {
        if (isWizardConfigured) {
            setIsDataPending(true)
            getThirdStepWizardData().then(data => {
                dispatch({
                    type: WizardActionTypes.SET_THIRD_STAGE_DATA,
                    payload: data
                })

                if (data.quality.length === 3) setCurrentQualityOption({id: 1, name: '3'})
                if (data.originality.length === 3) setCurrentActualityOption({id: 1, name: '3'})
                setIsDataPending(false)
            })
        }
    }, [])

    const setInstruction = (e: any) => {
        dispatch({
            type: WizardActionTypes.SET_THIRD_STEP_INSTRUCTION,
            payload: e.target.value
        })
    }

    const setStartDate = (e: any) => {
        dispatch({
            type: WizardActionTypes.SET_THIRD_STEP_START_DATE,
            payload: e.target.value
        })
    }

    const setEndDate = (e: any) => {
        dispatch({
            type: WizardActionTypes.SET_THIRD_STEP_END_DATE,
            payload: e.target.value
        })
    }

    const setLimit = (e: any) => {
        dispatch({
            type: WizardActionTypes.SET_EXPERT_TASK_LIMIT,
            payload: e.target.value
        })
    }

    const setCollectWay = (is_subtopic_way: boolean) => {
        dispatch({
            type: WizardActionTypes.SET_COLLECT_WAY,
            payload: is_subtopic_way
        })
    }

    const setDashboardShown = (is_dashboard_shown: boolean) => {
        dispatch({
            type: WizardActionTypes.SET_DASHBOARD_FLAG,
            payload: is_dashboard_shown
        })
    }

    const submit = () => {
        if (buttonText !== 'Подождите...') {
            const error = errorHandler(thirdStepData, +currentQualityOption.name, +currentActualityOption.name)

            if (error) {
                setErrorText(error)
                setIsModalErrorOpened(true)
            } else {
                setButtonText('Подождите...')
                setThirdStepWizardData({
                    data: thirdStepData,
                    actualityCount: +currentActualityOption.name,
                    qualityCount: +currentQualityOption.name
                }).then(() => {
                    if (isWizardConfigured) {
                        navigate('/admin/script')
                    } else {
                        dispatch({ type: StatusActionTypes.SET_NEXT_WIZARD_STAGE })
                    }
                })
            }
        }
    }

    return (
        <div className={styles.wizardStageComponent}>
            {isModalErrorOpened && <AlertModal closeModal={() => setIsModalErrorOpened(false)} text={errorText}/>}
            <h2 className={styles.wizardStageHeader}>{WizardStagesNames[WizardStages.THIRD_STEP]}</h2>
            <div className={styles.firstStepContent}>
                <WizardInput
                    onChange={setInstruction}
                    placeholder="Текст..."
                    value={thirdStepData.instruction}
                    height={200}
                    label="Инструкция"
                    isTextarea={true}
                    isWithVars={true}
                />
                <div className={styles.dates}>
                    <WizardInput
                        onChange={setStartDate}
                        placeholder="ДД.ММ.ГГГГ"
                        value={thirdStepData.start_date}
                        label="Дата начала этапа (ДД.ММ.ГГГГ)"
                        width={150}
                    />
                    <WizardInput
                        onChange={setEndDate}
                        placeholder="ДД.ММ.ГГГГ"
                        value={thirdStepData.end_date}
                        label="Дата конца этапа (ДД.ММ.ГГГГ)"
                        width={150}
                    />
                </div>
                <HeadingDescriptionComponent heading="Настройка заданий">
                    Настройте максимум заданий и принцип сборки
                </HeadingDescriptionComponent>
                <WizardInput
                    onChange={setLimit}
                    placeholder="5"
                    value={thirdStepData.task_limit_for_expert}
                    label="Лимит заданий на одного эксперта"
                    width={70}
                />
                <div className={styles.checkField}>
                    <p>
                        Принцип сборки заданий
                    </p>
                    <div className={styles.checkboxRow} onClick={() => setCollectWay(false)}>
                        <CustomRadio isChecked={!thirdStepData.is_collection_by_subtopic}/>
                        <p>По своим темам</p>
                    </div>
                    <div className={styles.checkboxRow} onClick={() => setCollectWay(true)}>
                        <CustomRadio isChecked={thirdStepData.is_collection_by_subtopic}/>
                        <p>По своим подтемам</p>
                    </div>
                </div>
                {isDataPending && <Preloader />}
                {!isDataPending && <div className={styles.formsContainer}>
                    <div>
                        <p className={styles.gradeHeader}>Оценка качества</p>
                        <div className={styles.counterDropDown}>
                            <p>Количество баллов</p>
                            <AdminDropDown
                                width={70}
                                optionList={[{id: 1, name: '3'}, {id: 2, name: '5'}]}
                                setter={setCurrentQualityOption}
                                defaultValue={currentQualityOption}
                                maxHeight={100}
                            />
                        </div>
                        <GradeForm gradeCount={+currentQualityOption.name} grade="quality"/>
                    </div>
                    <div>
                        <p className={styles.gradeHeader}>Оценка оригинальности</p>
                        <div className={styles.counterDropDown}>
                            <p>Количество баллов</p>
                            <AdminDropDown
                                width={70}
                                optionList={[{id: 1, name: '3'}, {id: 2, name: '5'}]}
                                defaultValue={currentActualityOption}
                                setter={setCurrentActualityOption}
                                maxHeight={100}
                            />
                        </div>
                        <GradeForm gradeCount={+currentActualityOption.name} grade="actuality"/>
                    </div>
                </div>}
                <div className={styles.endThirdStepCheckbox}>
                    <HeadingDescriptionComponent heading="Завершение">
                        По окончании третьего этапа появится
                    </HeadingDescriptionComponent>
                    <div className={styles.checkField}>
                        <div className={styles.checkboxRow} onClick={() => setDashboardShown(false)}>
                            <CustomRadio isChecked={!thirdStepData.is_dashboard_show}/>
                            <p>Заглушка</p>
                        </div>
                        <div className={styles.checkboxRow} onClick={() => setDashboardShown(true)}>
                            <CustomRadio isChecked={thirdStepData.is_dashboard_show}/>
                            <p>Дашборд участника с результатом работы</p>
                        </div>
                    </div>
                </div>
            </div>

            <Button
                width={"150px"}
                height={"45px"}
                fontSize={"16px"}
                variant={"primary"}
                margin="50px 0 0 770px"
                submit={submit}
            >
                {buttonText}
            </Button>
        </div>
    );
};

export default ThirdStepComponent;