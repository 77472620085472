import React from 'react';

const DeliveredIcon = () => {
    return (
        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 11H3.586C3.85119 11.0001 4.10551 11.1055 4.293 11.293L6.707 13.707C6.89449 13.8945 7.1488 13.9999 7.414 14H10.586C10.8512 13.9999 11.1055 13.8945 11.293 13.707L13.707 11.293C13.8945 11.1055 14.1488 11.0001 14.414 11H17M5 2H3C2.46957 2 1.96086 2.21071 1.58579 2.58579C1.21071 2.96086 1 3.46957 1 4V16C1 16.5304 1.21071 17.0391 1.58579 17.4142C1.96086 17.7893 2.46957 18 3 18H15C15.5304 18 16.0391 17.7893 16.4142 17.4142C16.7893 17.0391 17 16.5304 17 16V4C17 3.46957 16.7893 2.96086 16.4142 2.58579C16.0391 2.21071 15.5304 2 15 2H13H5ZM9 1V9V1ZM9 9L12 6L9 9ZM9 9L6 6L9 9Z" stroke="#323232" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};

export default DeliveredIcon;