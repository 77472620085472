import React, {useState} from 'react';
// @ts-ignore
import styles from "../../../css/admin/ThemePanel.module.css";
import CrossIcon from "../../icons/admin/CrossIcon";
import Button from "../../common/Button";
import Modal from "../common/Modal";
import {ISubtopic} from "../../../types/admin/http/userRequests";
import SimpleBar from 'simplebar-react';
import classNames from "classnames";
import AdminDropDown from "../common/AdminDropDown";
import {IOption} from "../../../types/admin/props/adminDropDown";
import {deleteSubtopics} from "../../../http/admin/themeRequests";

const DeleteModal = (props: {closeModal: () => void, subtopics: ISubtopic[], reload: () => void, allSubtopics: IOption[], clear: () => void}) => {
    const [buttonText, setButtonText] = useState('Сохранить')
    const [targetSubtopic, setTargetSubtopic] = useState(props.allSubtopics[0])

    const setter = (option: IOption) => {
        setTargetSubtopic(option)
    }

    const submit = () => {
        if (buttonText !== "Подождите...") {
            setButtonText('Подождите...')
            deleteSubtopics(props.subtopics.map(subtopic => subtopic.id), targetSubtopic.id).then(() => {
                setButtonText('Сохранить')
                props.closeModal()
                props.reload()
                props.clear()
            })
        }
    }

    return (
        <Modal>
            <div className={styles.editMenu}>
                <div className={styles.topicModalHeading}>
                    <h2>Удалить подтемы</h2>
                    <div onClick={props.closeModal}>
                        <CrossIcon />
                    </div>
                </div>
                <div className={classNames(styles.editControls, styles.subtopicContainer)}>
                    <SimpleBar style={{maxHeight: 300}}>
                        {props.subtopics.map(subtopic => {
                            return <p className={styles.deletedSubtopic} key={subtopic.id}>
                                {subtopic.name}
                            </p>
                        })}
                    </SimpleBar>
                </div>
                <div className={styles.clusterControl}>
                    <h3>Перенести участников данных подтем в...</h3>
                    <AdminDropDown
                        width={480}
                        maxHeight={150}
                        optionList={props.allSubtopics}
                        defaultValue={props.allSubtopics[0]}
                        setter={setter}
                    />
                </div>
                <Button
                    width={"150px"}
                    height={"40px"}
                    variant={"primary"}
                    fontSize={"16px"}
                    margin={"20px 0 0 350px"}
                    submit={submit}
                >
                    {buttonText}
                </Button>
            </div>
        </Modal>
    );
};

export default DeleteModal;