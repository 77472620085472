import React from 'react';

// @ts-ignore
import styles from '../../../css/Player.module.css'

const Shape = () => {
    return (
        <svg className={styles.shape} width="4.58vw" height="4.21vw" viewBox="0 0 88 81" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.501419 26.4058L59.5521 80.8001L67.8464 56.9117L30.2781 25.4472L79.2624 24.0325L87.555 0.149295L7.50153 6.24492L0.501419 26.4058Z" fill="#FFD556"/>
        </svg>
    );
};

export default Shape;