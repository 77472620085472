import React from 'react';

const OpenedIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 16.9986V8.06864C0.999951 7.73946 1.08116 7.41535 1.23641 7.12508C1.39166 6.8348 1.61617 6.58734 1.89 6.40464L8.89 1.73864C9.21866 1.51937 9.6049 1.40234 10 1.40234C10.3951 1.40234 10.7813 1.51937 11.11 1.73864L18.11 6.40464C18.3838 6.58734 18.6083 6.8348 18.7636 7.12508C18.9188 7.41535 19 7.73946 19 8.06864V16.9986M1 16.9986C1 17.5291 1.21071 18.0378 1.58579 18.4129C1.96086 18.7879 2.46957 18.9986 3 18.9986H17C17.5304 18.9986 18.0391 18.7879 18.4142 18.4129C18.7893 18.0378 19 17.5291 19 16.9986M1 16.9986L7.75 12.4986M19 16.9986L12.25 12.4986M7.75 12.4986L1 7.99864M7.75 12.4986L8.89 13.2586C9.21866 13.4779 9.6049 13.5949 10 13.5949C10.3951 13.5949 10.7813 13.4779 11.11 13.2586L12.25 12.4986M12.25 12.4986L19 7.99864" stroke="#323232" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};

export default OpenedIcon;