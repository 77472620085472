import React from 'react';
// @ts-ignore
import styles from "../../../css/Editor.module.css";
import SetActualityBlock from "./SetActualityBlock";
import SetQualityBlock from "./SetQualityBlock";
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import {useDispatch} from "react-redux";
import {ExerciseActionTypes} from "../../../types/reducers/exercise";

const RatingExerciseBlock = () => {
    const comment = useTypedSelector(state => state.exercise.comment)
    const dispatch = useDispatch()

    const setComment = (comment: string) => {
        dispatch({type: ExerciseActionTypes.SET_COMMENT, payload: comment})
    }

    return (
        <div className={styles.ratingExerciseBlock}>
            <SetQualityBlock />
            <SetActualityBlock />

            <div className={styles.commentBlock}>
                <h3 className={styles.commentHeader}>Оставьте комментарий:</h3>
                <textarea
                    className={styles.commentTextarea}
                    placeholder={"Комментарий..."}
                    value={comment}
                    onChange={e => setComment(e.target.value)}
                />
            </div>
        </div>
    );
};

export default RatingExerciseBlock;