import React from 'react';

const Preloader = () => {
    return (
        <div>
            <div className="loader">loading...</div>
        </div>
    );
};

export default Preloader;