import React, {useState} from 'react';
import GenerateIcon from "../icons/GenerateIcon";
import {IGeneratePasswordProps} from "../../types/components/auth";

const GeneratePassword = ({generatePassword, checks, forRestore}: IGeneratePasswordProps) => {
    const isMobile = useState(document.documentElement.clientWidth <= 900)[0]

    return (
      <div
        className="generate-password-container"
        style={forRestore ? {
            width: 'auto',
            height: 'auto',
            position: "static",
            margin: "0 auto 20px auto"
        } : isMobile ? {
            width: 'auto',
            height: 313,
            position: "static",
            margin: "0 auto 0 auto"
        } : {}}
      >
          <div className="generate-password" style={forRestore ? {
              position: 'static',
              margin: "0 auto",
              border: 'none'
          } : isMobile ? {
              position: 'static',
              margin: '0 auto',
              border: 'none'
          } : {}}>
              <p className="generate-instruction">
                  Пароль должен содержать:
              </p>
              <ul className="checks">
                  <li><CheckIcon isVisible={checks.length}/><p>Не менее 8 символов</p></li>
                  <li><CheckIcon isVisible={checks.letterCase}/><p>Заглавные и строчные буквы</p></li>
                  <li><CheckIcon isVisible={checks.hasDigits}/><p>Цифры</p></li>
                  <li><CheckIcon isVisible={checks.hasSymbol}/><p>Спец. символы: !№;%:?*()_=</p></li>
              </ul>
              <p className="to-generate">Или вы можете просто...</p>
              <div className="submitButton generate" onClick={generatePassword}>
                  <div className="button-content">
                      <GenerateIcon />
                      <span>Сгенерировать пароль</span>
                  </div>
              </div>
          </div>
      </div>
    );
};

const CheckIcon = ({isVisible}: {isVisible: boolean}) => {
    return <svg className={isVisible ? "visible" : "hidden"} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M16.7069 5.29303C16.8944 5.48056 16.9997 5.73487 16.9997 6.00003C16.9997 6.26519 16.8944 6.5195 16.7069 6.70703L8.70692 14.707C8.51939 14.8945 8.26508 14.9998 7.99992 14.9998C7.73475 14.9998 7.48045 14.8945 7.29292 14.707L3.29292 10.707C3.11076 10.5184 3.00997 10.2658 3.01224 10.0036C3.01452 9.74143 3.11969 9.49062 3.3051 9.30521C3.49051 9.1198 3.74132 9.01464 4.00352 9.01236C4.26571 9.01008 4.51832 9.11087 4.70692 9.29303L7.99992 12.586L15.2929 5.29303C15.4804 5.10556 15.7348 5.00024 15.9999 5.00024C16.2651 5.00024 16.5194 5.10556 16.7069 5.29303Z" fill="black"/>
    </svg>
}

export default GeneratePassword;