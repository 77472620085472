import React from 'react';

const GreyPlusIcon = (props: {stroke?: string}) => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.3327 9.0013H1.66602M8.99935 1.66797V16.3346V1.66797Z" stroke={props.stroke ? props.stroke : '#929292'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};

export default GreyPlusIcon;