import React, {FC, PropsWithChildren} from 'react';
// @ts-ignore
import styles from "../../../css/Player.module.css"
import Shape from "./Shape";
import Ellipses from "./Ellipses";

const InfoBlock: FC = (props: PropsWithChildren<any>) => {
    return (
        <div className={styles.infoBlockContainer}>
            <div className={styles.shapeContainer}>
                <div className={styles.circle} />
            </div>
            <div className={styles.shapeContainer}>
                <Shape />
            </div>
            <div className={styles.infoBlock} dangerouslySetInnerHTML={{__html: props.children}} />
            <div className={styles.shapeContainer}>
                <div className={styles.triangle} />
            </div>
            <div className={styles.ellipsesContainer}>
                <Ellipses />
            </div>
        </div>
    );
};

export default InfoBlock;