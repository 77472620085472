import React, {useEffect, useRef, useState} from 'react';
import ArrowIcon from "../icons/ArrowIcon";
import {ISelectProps} from "../../types/components/common";
import SelectOption from "./SelectOption";
import 'simplebar/dist/simplebar.min.css';
import SimpleBar from "simplebar-react";

const Select = ({width, name, values, index, setValue, isForAdmin = false}: ISelectProps) => {
    const isMobile = useState(document.documentElement.clientWidth <= 900)[0]
    const optionsContainer = useRef<HTMLDivElement>(null)
    const [height, setHeight] = useState(0)

    const [options, setOptions] = useState([
        {
            "value": name,
            "id": -1,
            "popup_name_id": -1
        },
        ...values
    ])

    useEffect(() => {
        if (optionsContainer?.current !== null)
            setHeight(optionsContainer.current?.offsetHeight + 35)
    }, [])

    const [currentOption, setCurrentOption] = useState(0)
    const [isOpened, setIsOpened] = useState(false)

    return (
        <div className={isForAdmin ? 'adminSelect' : 'select'}>
            <div className="selectMain" style={{width: isMobile ? 320 : width}} onClick={() => setIsOpened(!isOpened)}>
                <span className="mainOption" style={{width: isMobile ? 260 : width}}>{options[currentOption].value}</span>
                <ArrowIcon isOpened={isOpened}/>
            </div>
            <div className="options" style={{
                width: isMobile ? 320 : width,
                maxHeight: 200,
                height: isOpened ? height : 0,
            }}>
                <SimpleBar style={{
                    maxHeight: 200,
                }} autoHide={false}>
                    <div className="options-container" ref={optionsContainer}>
                        {options.map((option, i) => {
                            if (i > 0)
                                return <SelectOption
                                    key={option.id}
                                    id={option.id}
                                    i={i}
                                    index={index}
                                    setCurrentOption={setCurrentOption}
                                    setValue={setValue}
                                    setIsOpened={setIsOpened}
                                    value={option.value}
                                />
                        })}
                    </div>
                </SimpleBar>
            </div>
        </div>
    );
};

export default Select;