enum MouseHintActionTypes {
    SHOW_HINT = 'SHOW_HINT',
    HIDE_HINT = 'HIDE_HINT'
}

interface IMouseHintState {
    x: number
    y: number
    hint_text: string
    isShown: boolean
}

interface IShowHintAction {
    type: MouseHintActionTypes.SHOW_HINT
    payload: IMouseHintState
}

interface IHideHintAction {
    type: MouseHintActionTypes.HIDE_HINT
}

type MouseHintAction = IShowHintAction | IHideHintAction

export {MouseHintActionTypes}
export type {MouseHintAction, IMouseHintState}