import {ITheme, IThemeList} from "../../types/components/themes";
import {ITask} from "../../types/entities/task";
import {checkErrors} from "../checkErrorsFuncs";
import {IDashboard} from "../../types/admin/http/userRequests";
import {AppActionTypes} from "../../types/reducers/app";
import {Dispatch} from "redux";

export interface DemoType {
  defaultDemo: any,
  emptyTaskObj: any,
  dashboardObj: IDashboard
}

export const demoObj: DemoType = {
  defaultDemo: {
    app_name: "Тест экспертной платформы",
    app_name_description: "Демо-версия прохождения экспертной платформы",
    max_picked_subtopics: 2,
    max_experts_on_subtopic: 5,
    video_link: "https://www.youtube.com/embed/videoseries?list=PLBxbCPLubsISLkNK8E9Q45Hsme_5kUvj1",
    support_mail:"support@hl.expert",
    current_stage: 1,
    max_items_for_expert: 5,
    max_tasks_on_subtopic: 3,
    is_demo: true,
    is_admin: false,
    subtopics: [
      {
        "name": "ТК РФ",
        "values": [
          {"id":1, "value":"Общие вопросы ТК РФ", "topic": "ТК РФ", "subtopic_name_id": 1, "done": 0, "total": 5, "description": "Подтема для демо-версии", "tasks": [
              {
                "answer_id":447,
                "user_id":34,
                "task_id":112,
                "task_type":"radio",
                "task_content":{
                  "question":"Известно, что ТК РФ регламентирует форму и тарификацию оплаты труда работника, согласно нормативам, определяет понятие переработок, труда в дневное и ночное время. Как оплачивается каждый час работы в ночное время, согласно ТК РФ?",
                  "options":[
                    {"id":447,"text":"В повышенном размере по сравнению с работой в нормальных условиях, но не ниже размеров, установленных законами и иными нормативными правовыми актами."},
                    {"id":448,"text":"Оплата работы в ночное время равна оплате этого вида деятельности в дневное время."},
                    {"id":446,"text":"Плата за каждый час работы в ночное время удваивается по сравнению с оплатой каждого часа работы в дневное время."},
                    {"id":445,"text":"Не регламентируется ТК РФ"}]
                },
                "subtopic":53,
                "answer":"[]",
                "comment":null,
                "quality_assessment":null,
                "actuality_assessment":null,
                "done":0,
                "total":5
              },
              {
                "answer_id":447,
                "user_id":34,
                "task_id":112,
                "task_type":"radio",
                "task_content":{
                  "question":"Коллективный договор заключается",
                  "options":[
                    {"id":447,"text":"на срок не более 3 лет."},
                    {"id":448,"text":"на срок не более 2 лет."},
                    {"id":446,"text":"на срок не более 1 года."},
                    {"id":445,"text":"на срок не более 5 лет."}]
                },
                "subtopic":53,
                "answer":"[]",
                "comment":null,
                "quality_assessment":null,
                "actuality_assessment":null,
                "done":1,
                "total":5
              },
              {
                "answer_id":447,
                "user_id":34,
                "task_id":112,
                "task_type":"radio",
                "task_content":{
                  "question":"Премия рассчитывается по формуле:\n" +
                    "Премия = (оклад+надбавка за проф.уровень)*(отработанное\n" +
                    "время, мес)*(целевой\n" +
                    "процент премирования по грейду)*(оценка\n" +
                    "сотрудника)*(оценка подразделения)*(оценка Предприятия)\n" +
                    "\n" +
                    "Выберите вариант, в котором данные для расчета премии Иванова И.И., машиниста\n" +
                    "с окладом 20000руб. , подставлены правильно, если в\n" +
                    "оцениваемом полугодии он не брал отпусков и больничных, получил оценку 95% и\n" +
                    "имеет проф. уровень «развитие», кроме того выполнение функционального контракта\n" +
                    "составило 106%, а Производственный контракт\n" +
                    "предприятия выполнен на 115% (учитывая районный\n" +
                    "коэффициент, но не вычитая налог на доходы)",
                  "options":[
                    {"id":447,"text":"Премия = (20000*1,1*1,3)*6*0,18*0,95*1,06*1,15"},
                    {"id":448,"text":"Премия = (20000+10)*1,3*6*18*95*106*115"},
                    {"id":446,"text":"Премия = (22000*1,1*1,3)*6*0,18*0,95*1,06*1,15"},
                    {"id":445,"text":"Премия = (20000*1,1*1,3)*6*0,18*0,95*1,06*1,15*0,87"}]
                },
                "subtopic":53,
                "answer":"[]",
                "comment":null,
                "quality_assessment":null,
                "actuality_assessment":null,
                "done":2,
                "total":5
              },
              {
                "answer_id":448,
                "user_id":34,
                "task_id":112,
                "task_type":"radio",
                "task_content":{
                  "question":"Из-за ошибки в финансовых документах Вам дали слишком большую зарплату. Вы обрадовались, но в следующем месяце из Вашей заработной платы удержали 52% в качестве компенсации.",
                  "options":[
                    {"id":447,"text":"Вы смиритесь с этим."},
                    {"id":448,"text":"Вы потребуете, чтобы компенсация вычиталась из нескольких Ваших зарплат по частям."},
                    {"id":446,"text":"Вы будете уверять начальство в том, что Вы не должны страдать из-за чужой ошибки."},
                    {"id":445,"text":"Вы обратитесь в HR-службу за разъяснениями."}]
                },
                "subtopic":53,
                "answer":"[]",
                "comment":null,
                "quality_assessment":null,
                "actuality_assessment":null,
                "done":3,
                "total":5
              },
              {
                "answer_id":448,
                "user_id":34,
                "task_id":112,
                "task_type":"radio",
                "task_content":{
                  "question":"Работник имеет право обратиться в суд по спору об увольнении...",
                  "options":[
                    {"id":447,"text":"в течение 2 недель"},
                    {"id":448,"text":"в течение 1 месяца"},
                    {"id":446,"text":"в течение 3 месяцев"},
                    {"id":445,"text":"в течение года"}]
                },
                "subtopic":53,
                "answer":"[]",
                "comment":null,
                "quality_assessment":null,
                "actuality_assessment":null,
                "done":4,
                "total":5
              },
            ]}
        ]
      },
      {
        "name": "Охрана труда",
        "values": [
          {"id":2, "value":"Условия труда", "topic": "Охрана труда", "subtopic_name_id": 2, "done": 0, "total": 5, "description": "Подтема для демо-версии", "tasks": [
              {
                "answer_id":447,
                "user_id":34,
                "task_id":112,
                "task_type":"radio",
                "task_content":{
                  "question":"Кто, в соответствии с требованиями охраны труда, обязан организовать санитарно-бытовое обслуживание и медицинское обеспечение работников организации?",
                  "options":[
                    {"id":447,"text":"Работодатель"},
                    {"id":448,"text":"Учредитель профсоюзной организации"},
                    {"id":446,"text":"Начальник кадрового подразделения"},
                    {"id":445,"text":"Специалист службы охраны труда"}]
                },
                "subtopic":53,
                "answer":"[]",
                "comment":null,
                "quality_assessment":null,
                "actuality_assessment":null,
                "done":0,
                "total":5
              },
              {
                "answer_id":448,
                "user_id":34,
                "task_id":112,
                "task_type":"radio",
                "task_content":{
                  "question":"За счет каких средств в организации производится приобретение средств индивидуальной защиты для работников?",
                  "options":[
                    {"id":447,"text":"За счет средств федерального или регионального бюджета, выделяемых работодателю"},
                    {"id":448,"text":"За счет средств работодателя"},
                    {"id":446,"text":"За счет средств профсоюзной организации при условии, что данный работник является членом профсоюза, в противном случае работник обеспечивает себя самостоятельно"},
                    {"id":445,"text":"За счет средств работника"}]
                },
                "subtopic":53,
                "answer":"[]",
                "comment":null,
                "quality_assessment":null,
                "actuality_assessment":null,
                "done":1,
                "total":5
              },
              {
                "answer_id":447,
                "user_id":34,
                "task_id":112,
                "task_type":"radio",
                "task_content":{
                  "question":"Максимально допустимый груз для женщин при постоянном подъёме и перемещении в течении рабочей смены?",
                  "options":[
                    {"id":447,"text":"7 кг"},
                    {"id":448,"text":"6 кг"},
                    {"id":446,"text":"5 кг"},
                    {"id":445,"text":"8 кг"}]
                },
                "subtopic":53,
                "answer":"[]",
                "comment":null,
                "quality_assessment":null,
                "actuality_assessment":null,
                "done":2,
                "total":5
              },
              {
                "answer_id":447,
                "user_id":34,
                "task_id":112,
                "task_type":"radio",
                "task_content":{
                  "question":"Количество часов работы в неделю допустимое для несовершеннолетних от 16 до 18 лет?",
                  "options":[
                    {"id":447,"text":"36 ч"},
                    {"id":448,"text":"24 ч"},
                    {"id":446,"text":"28 ч"},
                    {"id":445,"text":"32 ч"}]
                },
                "subtopic":53,
                "answer":"[]",
                "comment":null,
                "quality_assessment":null,
                "actuality_assessment":null,
                "done":3,
                "total":5
              },
              {
                "answer_id":445,
                "user_id":34,
                "task_id":112,
                "task_type":"radio",
                "task_content":{
                  "question":"На кого возлагаются обязанности по обеспечению безопасных условий и охраны труда в организации?",
                  "options":[
                    {"id":447,"text":"На руководителя службы охраны труда организации"},
                    {"id":448,"text":"На руководителей подразделений организации"},
                    {"id":446,"text":"На главного инженера (технического руководителя) организации"},
                    {"id":445,"text":"На работодателя"}]
                },
                "subtopic":53,
                "answer":"[]",
                "comment":null,
                "quality_assessment":null,
                "actuality_assessment":null,
                "done":4,
                "total":5
              },
            ]},
          {"id":3, "value":"Безопасное выполнение работ", "topic": "Охрана труда", "subtopic_name_id": 2, "done": 0, "total": 5, "description": "Подтема для демо-версии", "tasks": [
              {
                "answer_id":447,
                "user_id":34,
                "task_id":112,
                "task_type":"radio",
                "task_content":{
                  "question":"Какого вида искусственного освещения нет?",
                  "options":[
                    {"id":447,"text":"целевое"},
                    {"id":448,"text":"рабочее"},
                    {"id":446,"text":"дежурное"},
                    {"id":445,"text":"аварийное"}]
                },
                "subtopic":53,
                "answer":"[]",
                "comment":null,
                "quality_assessment":null,
                "actuality_assessment":null,
                "done":0,
                "total":5
              },
              {
                "answer_id":447,
                "user_id":34,
                "task_id":112,
                "task_type":"radio",
                "task_content":{
                  "question":"К какой степени тяжести относится электрический удар если человек потерял сознание, но с сохранением дыхания?",
                  "options":[
                    {"id":447,"text":"II"},
                    {"id":448,"text":"III"},
                    {"id":446,"text":"IV"},
                    {"id":445,"text":"V"}]
                },
                "subtopic":53,
                "answer":"[]",
                "comment":null,
                "quality_assessment":null,
                "actuality_assessment":null,
                "done":1,
                "total":5
              },
              {
                "answer_id":447,
                "user_id":34,
                "task_id":112,
                "task_type":"radio",
                "task_content":{
                  "question":"Из скольки человек должна состоять бригада, при работе на сетях водоснабжения и канализации, связанной со спуском в колодцы, камеры и резервуары?",
                  "options":[
                    {"id":447,"text":"Не менее чем из трех работников"},
                    {"id":448,"text":"Из двух работников"},
                    {"id":446,"text":"Количество работников зависит от выполняемых работ"},
                    {"id":445,"text":"Не менее пяти работников"}]
                },
                "subtopic":53,
                "answer":"[]",
                "comment":null,
                "quality_assessment":null,
                "actuality_assessment":null,
                "done":2,
                "total":5
              },
              {
                "answer_id":446,
                "user_id":34,
                "task_id":112,
                "task_type":"radio",
                "task_content":{
                  "question":"Что должен сделать работник в случае выявления неисправностей используемых в работе инструментов и оборудования?",
                  "options":[
                    {"id":447,"text":"Заменить вышедший из строя инструмент и оборудование на исправный"},
                    {"id":448,"text":"Сделать перерыв в работе, после которого с осторожностью продолжить выполнение поставленной задачи"},
                    {"id":446,"text":"Незамедлительно поставить в известность своего непосредственного руководителя и приостановить работу до устранения неисправностей"},
                    {"id":445,"text":"Продолжить работу с применением средств индивидуальной защиты"}]
                },
                "subtopic":53,
                "answer":"[]",
                "comment":null,
                "quality_assessment":null,
                "actuality_assessment":null,
                "done":3,
                "total":5
              },
              {
                "answer_id":447,
                "user_id":34,
                "task_id":112,
                "task_type":"radio",
                "task_content":{
                  "question":"Работы с каким инструментом, вне зависимости от условий их проведения, должны проводиться по наряду-допуску на производство работ повышенной опасности?",
                  "options":[
                    {"id":447,"text":"С ручным пиротехническим инструментом"},
                    {"id":448,"text":"С ручным гидравлическим инструментом"},
                    {"id":446,"text":"С ручным пневматическим инструментом"},
                    {"id":445,"text":"С ручным абразивным инструментом"}]
                },
                "subtopic":53,
                "answer":"[]",
                "comment":null,
                "quality_assessment":null,
                "actuality_assessment":null,
                "done":4,
                "total":5
              },
            ]},
          {"id":4, "value":"Расследование несчастных случаев", "topic": "Охрана труда", "subtopic_name_id": 2, "done": 0, "total": 5, "description": "Подтема для демо-версии", "tasks": [
              {
                "answer_id":447,
                "user_id":34,
                "task_id":112,
                "task_type":"radio",
                "task_content":{
                  "question":"Какое из перечисленных обстоятельств классифицирует несчастный случай, как не связанный с производством?",
                  "options":[
                    {"id":447,"text":"Все перечисленные обстоятельства"},
                    {"id":448,"text":"Только смерть вследствие общего заболевания или самоубийства"},
                    {"id":446,"text":"Только смерть или повреждение здоровья вследствие алкогольного, наркотического или иное токсического опьянения (отравление) пострадавшего"},
                    {"id":445,"text":"Только несчастный случай"}]
                },
                "subtopic":53,
                "answer":"[]",
                "comment":null,
                "quality_assessment":null,
                "actuality_assessment":null,
                "done":0,
                "total":5
              },
              {
                "answer_id":447,
                "user_id":34,
                "task_id":112,
                "task_type":"radio",
                "task_content":{
                  "question":"Подлежит ли расследованию и учету несчастный случай, происшедший с работником во время следования на работу на общественном транспорте?",
                  "options":[
                    {"id":447,"text":"Да, подлежит, если несчастный случай произошёл в рабочее время"},
                    {"id":448,"text":"Да, подлежит, если несчастный случай произошёл в нерабочее время"},
                    {"id":446,"text":"Да, подлежит, в любом случае"},
                    {"id":445,"text":"Нет, не подлежит, в любом случае"}]
                },
                "subtopic":53,
                "answer":"[]",
                "comment":null,
                "quality_assessment":null,
                "actuality_assessment":null,
                "done":1,
                "total":5
              },
              {
                "answer_id":447,
                "user_id":34,
                "task_id":112,
                "task_type":"radio",
                "task_content":{
                  "question":"В какой государственный орган следует направлять извещение в установленной форме о групповом несчастном случае, тяжелом несчастном случае или несчастном случае со смертельным исходом?",
                  "options":[
                    {"id":447,"text":"во все перечисленные государственные органы"},
                    {"id":448,"text":"в исполнительный орган страховщика по вопросам обязательного социального страхования"},
                    {"id":446,"text":"в прокуратуру по месту происшедшего несчастного случая"},
                    {"id":445,"text":"в соответствующий федеральный орган исполнительной власти, если несчастный случай произошел в подведомственной ему организации"}]
                },
                "subtopic":53,
                "answer":"[]",
                "comment":null,
                "quality_assessment":null,
                "actuality_assessment":null,
                "done":2,
                "total":5
              },
              {
                "answer_id":447,
                "user_id":34,
                "task_id":112,
                "task_type":"radio",
                "task_content":{
                  "question":"При несчастном случае работодатель (его представитель) обязан:",
                  "options":[
                    {"id":447,"text":"Всё верно"},
                    {"id":448,"text":"Принять неотложные меры по предотвращению развития аварийной ситуации"},
                    {"id":446,"text":"Немедленно организовать первую помощь пострадавшему и при необходимости доставку его в медицинскую организацию"},
                    {"id":445,"text":"Обеспечить своевременное расследование несчастного случая и его учет"}]
                },
                "subtopic":53,
                "answer":"[]",
                "comment":null,
                "quality_assessment":null,
                "actuality_assessment":null,
                "done":3,
                "total":5
              },
              {
                "answer_id":445,
                "user_id":34,
                "task_id":112,
                "task_type":"radio",
                "task_content":{
                  "question":"Какой документ оформляется на каждый несчастный случай, квалифицированный по результатам расследования как несчастный случай на производстве?",
                  "options":[
                    {"id":447,"text":"Протокол несчастного случая на производстве"},
                    {"id":448,"text":"Перечень пострадавших при несчастном случае на производстве"},
                    {"id":446,"text":"Медицинское заключение о характере и степени тяжести повреждения, причиненного здоровью пострадавшего"},
                    {"id":445,"text":"Акт о несчастном случае на производстве формы Н-1"}]
                },
                "subtopic":53,
                "answer":"[]",
                "comment":null,
                "quality_assessment":null,
                "actuality_assessment":null,
                "done":4,
                "total":5
              },
            ]}
        ]
      },
    ],
    total_rated_exercise_counter: 0,
    right_answers_expertise_counter: 0,
    pickedSubtopics: [],
    user: ''
  },
  emptyTaskObj: {
    "tasks": [
      {
        "id":1,
        "task_type":"radio",
        "task_content":
          {
            "question":"",
            "options":[
              {"id":501,"text":""},
              {"id":502,"text":""},
              {"id":503,"text":""},
              {"id":504,"text":""}
            ]
          },
        "subtopic":57,
        "answers":"[]"
      },

      {
        "id":2,
        "task_type":"radio",
        "task_content":
          {
            "question":"",
            "options":[
              {"id":501,"text":""},
              {"id":502,"text":""},
              {"id":503,"text":""},
              {"id":504,"text":""}
            ]
          },
        "subtopic":57,
        "answers":"[]"
      },

      {
        "id":3,
        "task_type":"radio",
        "task_content":
          {
            "question":"",
            "options":[
              {"id":501,"text":""},
              {"id":502,"text":""},
              {"id":503,"text":""},
              {"id":504,"text":""}
            ]
          },
        "subtopic":57,
        "answers":"[]"
      }
    ],

    "subtopic_data":{
      "topic":"Тема 1",
      "subtopic":"Подтема А",
      "description":"Подтема для теста 1А"
    },

    "done":0,
    "total":5,
    "max_symbols_for_option": 150,
    "max_symbols_for_question": 350
  },
  dashboardObj: {
    "author_rating":1.0,
    "expert_rating":9.0,
    "scholar_rating_place":1,
    "scholar_rating_correct":9,
    "scholar_rating_total":9,
    "quality_task_counter":100,
    // @ts-ignore
    "main_info":"author_rating",
    "download_xlsx":false,
    "download_certificate":
      {
        "name":"Фамилия Имя Отчество",
        "author_rating":1,
        "cluster":"Кластер 1",
        "quality_task_counter":100
      },
    "show_tasks":true,
    "tasks":[
      {
        "question":"Арсений 5 подтема 2в. Ответьте правильно",
        "options":[
          {
            "text":"скверно",
            "is_correct":false,
            "current_picked_counter":30,
            "total_picked_counter":50,
            "weak_group_counter":5,
            "medium_group_counter":10,
            "high_group_counter":15
          },
          {
            "text":"серно",
            "is_correct":false,
            "current_picked_counter":5,
            "total_picked_counter":50,
            "weak_group_counter":1,
            "medium_group_counter":2,
            "high_group_counter":2
          },
          {
            "text":"многомерно",
            "is_correct":false,
            "current_picked_counter":5,
            "total_picked_counter":50,
            "weak_group_counter":4,
            "medium_group_counter":1,
            "high_group_counter":0
          },
          {
            "text":"верно",
            "is_correct":true,
            "current_picked_counter":10,
            "total_picked_counter":50,
            "weak_group_counter":6,
            "medium_group_counter":4,
            "high_group_counter":0
          }
        ],
        "comments":['Хорошее простое задание'],
        "difficult":0.6,
        "originality":4,
        "quality":4.8,
        "discrimination":0.57,
        "rated_counter":50
      },
      {
        "question":"Арсений 5 подтема 2в. Ответьте правильно",
        "options":[
          {
            "text":"скверно",
            "is_correct":false,
            "current_picked_counter":20,
            "total_picked_counter":50,
            "weak_group_counter":6,
            "medium_group_counter":8,
            "high_group_counter":6
          },
          {
            "text":"серно",
            "is_correct":false,
            "current_picked_counter":12,
            "total_picked_counter":50,
            "weak_group_counter":3,
            "medium_group_counter":5,
            "high_group_counter":4
          },
          {
            "text":"многомерно",
            "is_correct":false,
            "current_picked_counter":7,
            "total_picked_counter":50,
            "weak_group_counter":4,
            "medium_group_counter":1,
            "high_group_counter":2
          },
          {
            "text":"верно",
            "is_correct":true,
            "current_picked_counter":1,
            "total_picked_counter":50,
            "weak_group_counter":3,
            "medium_group_counter":3,
            "high_group_counter":5
          }
        ],
        "comments":['Кажется, задание немного не в тему', 'Вопрос не относится к заявленной теме'],
        "difficult":0.4,
        "originality":4.2,
        "quality":2.6,
        "discrimination":-0.02,
        "rated_counter":50
      },
      {
        "question":"Арсений 5 подтема 2в. Ответьте правильно",
        "options":[
          {
            "text":"скверно",
            "is_correct":false,
            "current_picked_counter":11,
            "total_picked_counter":50,
            "weak_group_counter":2,
            "medium_group_counter":4,
            "high_group_counter":5
          },
          {
            "text":"серно",
            "is_correct":false,
            "current_picked_counter":14,
            "total_picked_counter":50,
            "weak_group_counter":3,
            "medium_group_counter":5,
            "high_group_counter":6
          },
          {
            "text":"многомерно",
            "is_correct":false,
            "current_picked_counter":15,
            "total_picked_counter":50,
            "weak_group_counter":6,
            "medium_group_counter":5,
            "high_group_counter":4
          },
          {
            "text":"верно",
            "is_correct":true,
            "current_picked_counter":10,
            "total_picked_counter":50,
            "weak_group_counter":5,
            "medium_group_counter":3,
            "high_group_counter":2
          }
        ],
        "comments":['Задание хорошее, но немного запутанное. Стоит более понятно сформулировать верный вариант ответа.'],
        "difficult":0.22,
        "originality":3,
        "quality":3.9,
        "discrimination":0.17,
        "rated_counter":50
      },
      {
        "question":"Арсений 5 подтема 2в. Ответьте правильно",
        "options":[
          {
            "text":"скверно",
            "is_correct":false,
            "current_picked_counter":8,
            "total_picked_counter":50,
            "weak_group_counter":4,
            "medium_group_counter":2,
            "high_group_counter":2
          },
          {
            "text":"серно",
            "is_correct":false,
            "current_picked_counter":13,
            "total_picked_counter":50,
            "weak_group_counter":2,
            "medium_group_counter":4,
            "high_group_counter":7
          },
          {
            "text":"многомерно",
            "is_correct":false,
            "current_picked_counter":5,
            "total_picked_counter":50,
            "weak_group_counter":4,
            "medium_group_counter":1,
            "high_group_counter":0
          },
          {
            "text":"верно",
            "is_correct":true,
            "current_picked_counter":24,
            "total_picked_counter":50,
            "weak_group_counter":6,
            "medium_group_counter":10,
            "high_group_counter":8
          }
        ],
        "comments":['А в этом задании точно только один правильный ответ?', 'Дистрактор 4 также является верным ответом.'],
        "difficult":0.16,
        "originality":3,
        "quality":4,
        "discrimination":-0.13,
        "rated_counter":50
      },
      {
        "question":"Арсений 5 подтема 2в. Ответьте правильно",
        "options":[
          {
            "text":"скверно",
            "is_correct":false,
            "current_picked_counter":16,
            "total_picked_counter":50,
            "weak_group_counter":3,
            "medium_group_counter":4,
            "high_group_counter":9
          },
          {
            "text":"серно",
            "is_correct":false,
            "current_picked_counter":15,
            "total_picked_counter":50,
            "weak_group_counter":5,
            "medium_group_counter":5,
            "high_group_counter":5
          },
          {
            "text":"многомерно",
            "is_correct":false,
            "current_picked_counter":10,
            "total_picked_counter":50,
            "weak_group_counter":4,
            "medium_group_counter":3,
            "high_group_counter":3
          },
          {
            "text":"верно",
            "is_correct":true,
            "current_picked_counter":9,
            "total_picked_counter":50,
            "weak_group_counter":4,
            "medium_group_counter":5,
            "high_group_counter":0
          }
        ],
        "comments":['Исправить опечатку во втором варианте ответа.'],
        "difficult":0.32,
        "originality":5,
        "quality":4.5,
        "discrimination":0.34,
        "rated_counter":50
      },
      {
        "question":"Арсений 5 подтема 2в. Ответьте правильно",
        "options":[
          {
            "text":"скверно",
            "is_correct":false,
            "current_picked_counter":24,
            "total_picked_counter":50,
            "weak_group_counter":7,
            "medium_group_counter":5,
            "high_group_counter":12
          },
          {
            "text":"серно",
            "is_correct":false,
            "current_picked_counter":9,
            "total_picked_counter":50,
            "weak_group_counter":8,
            "medium_group_counter":0,
            "high_group_counter":1
          },
          {
            "text":"многомерно",
            "is_correct":false,
            "current_picked_counter":9,
            "total_picked_counter":50,
            "weak_group_counter":7,
            "medium_group_counter":2,
            "high_group_counter":0
          },
          {
            "text":"верно",
            "is_correct":true,
            "current_picked_counter":8,
            "total_picked_counter":50,
            "weak_group_counter":6,
            "medium_group_counter":2,
            "high_group_counter":0
          }
        ],
        "comments":['Хорошее, в меру трудное задание'],
        "difficult":0.48,
        "originality":4,
        "quality":4.5,
        "discrimination":0.67,
        "rated_counter":50
      },
    ]
  },
}

export const isClientDemo = () => {
  if (!!localStorage.getItem('demo')) {
    const demo = getDemo()
    return !demo.is_admin
  }
  return false
}

export const setUpDemo = (dispatch: Dispatch) => {
  let demoData = localStorage.getItem('demo')
  if (demoData) {
    dispatch({type: AppActionTypes.SET_APP_INFO, payload: JSON.parse(demoData).defaultDemo})
  } else {
    demoData = JSON.stringify(demoObj)
    dispatch({type: AppActionTypes.SET_APP_INFO, payload: JSON.parse(demoData).defaultDemo})
    localStorage.setItem('demo', demoData)
  }
}

export const checkUserChanged = (userData: any) => {
  let demo = getDemo()
  if (!demo.user) {
    demo.user = userData.email
    setUsername(`${userData.last_name} ${userData.first_name} ${userData.second_name}`)
    setDemo(demo)
  } else if (demo.user !== userData.email) {
    localStorage.removeItem('demo')
    window.location.reload()
  }
}

export const setAdminFlag = () => {
  const demo = getDemo()
  demo.is_admin = true
  setDemo(demo)
}

export const removeAdminFlag = () => {
  const demo = getDemo()
  demo.is_admin = false
  setDemo(demo)
}

export const getDemo = () => {
  return JSON.parse(String(localStorage.getItem('demo'))).defaultDemo
}

export const setDemo = (demoObj: any) => {
  let oldDemo = JSON.parse(String(localStorage.getItem('demo')))
  oldDemo.defaultDemo = demoObj
  localStorage.setItem('demo', JSON.stringify(oldDemo))
}

export const addTasksTemplate = (subject_id: number, template: any) => {
  const demo = getDemo()
  demo[subject_id] = template
  setDemo(demo)
}

export const setCompleteTaskCount = () => {
  const demo = getDemo()
  const counter = demo.max_tasks_on_subtopic - Object.keys(
      checkErrors(
        demo[demo.currentSubtopicId].tasks,
        demo.emptyTaskObj.max_symbols_for_option,
        demo.emptyTaskObj.max_symbols_for_question
      )).length

  demo.pickedSubtopics.forEach((topic: any[]) => {
    // @ts-ignore
    topic['values'].forEach(subtopic => {
      if (subtopic.id === demo.currentSubtopicId) {
        subtopic.done = counter
      }
    })
  })

  setDemo(demo)
}

export const unsetCompleteCounter = () => {
  const demo = getDemo()

  demo.pickedSubtopics.forEach((topic: any[]) => {
    // @ts-ignore
    topic['values'].forEach(subtopic => {
      subtopic.done = 0
    })
  })

  setDemo(demo)
}

export const restartDemo = () => {
  localStorage.removeItem('demo')
  window.location.reload()
}

export const setCurrentSubtopicId = (subtopicId: number) => {
  const demo = getDemo()
  demo.currentSubtopicId = subtopicId
  setDemo(demo)
}

export const setUsername = (username: string) => {
  const demo: DemoType = JSON.parse(String(localStorage.getItem('demo')))

  if (demo.dashboardObj.download_certificate) {
    demo.dashboardObj.download_certificate.name = username
  }

  localStorage.setItem('demo', JSON.stringify(demo))
}

export const setNewTasksContent = (tasks: ITask[]) => {
  const demo = getDemo()
  demo[demo.currentSubtopicId].tasks = tasks
  setDemo(demo)
}

export const addSubtopics = (subtopics: ITheme[]) => {
  const demo = getDemo()
  setDemo({...demo, pickedSubtopics: subtopics})
}

export const setNextStage = () => {
  const prev = getDemo()
  setDemo({...prev, current_stage: prev.current_stage + 1})
}

export const setExpertiseComplete = () => {
  const demo = getDemo()
  const themes: IThemeList = demo.pickedSubtopics

  themes.forEach(theme => {
    theme.values.forEach(subtopic => {
      subtopic.done = subtopic.total
    })
  })

  setDemo(demo)
}