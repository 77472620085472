import {combineReducers} from 'redux'
import {userReducer} from "./userReducer";
import {modalReducer} from "./modalReducer";
import {themeReducer} from "./themeReducer";
import {exerciseReducer} from "./exerciseReducer";
import {appReducer} from "./appReducer";
import {statusReducer} from "./statusReducer";
import {themeListReducer} from "./themeListReducer";
import {variablesReducer} from "./variablesReducer";
import {recipientsReducer} from "./recipientsReducer";
import {clustersReducer} from "./clustersReducer";
import {mailEditorReducer} from "./mailEditorReducer";
import {markDescriptionReducer} from "./markDescriptionReducer";
import {mouseHintReducer} from "./mouseHintReducer";
import {wizardReducer} from "./wizardReducer";

export const rootReducer = combineReducers({
    user: userReducer,
    modal: modalReducer,
    theme: themeReducer,
    exercise: exerciseReducer,
    app: appReducer,
    status: statusReducer,
    themeList: themeListReducer,
    variables: variablesReducer,
    recipients: recipientsReducer,
    clusters: clustersReducer,
    mailEditor: mailEditorReducer,
    markDescription: markDescriptionReducer,
    mouseHint: mouseHintReducer,
    wizard: wizardReducer,
})

export type RootState = ReturnType<typeof rootReducer>