import React, {useState, MouseEvent, useEffect} from 'react';
import HighlightLogo from "../../icons/HighlightLogo";
import Select from "../../common/Select";
import EyeIcon from "../../icons/EyeIcon";
import PasswordArrowIcon from "../../icons/PasswordArrowIcon";
import GeneratePassword from "../GeneratePassword";
import {checkValidation, generateComputedPassword} from "./passwordFunctions";
import {check, checkRegisterTokenValid, getFields, registration} from "../../../http/authRequests";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {UserActionTypes} from "../../../types/reducers/user";
import ErrorIcon from "../../icons/ErrorIcon";
import {useTypedSelector} from "../../../hooks/useTypedSelector";

const RegistrationPage: React.FC = () => {
    const token = useParams().token
    const navigate = useNavigate()
    const [buttonWord, setButtonWord] = useState('Начать работу')
    const [error, setError] = useState('')
    const [password, setPassword] = useState('')
    const [email, setEmail] = useState('email')
    const [repeatedPassword, setRepeatedPassword] = useState('')
    const [isFirstHidden, setIsFirstHidden] = useState(true)
    const [isSecondHidden, setIsSecondHidden] = useState(true)

    const appInfo = useTypedSelector(state => state.app)

    const [name, setName] = useState('')
    const [secondName, setSecondName] = useState('')
    const [surname, setSurname] = useState('')

    const [fieldValues, setFieldValues] = useState([-1, -1])
    const [fields, setFields] = useState([{
        "name": "Ваша работа",
        "values": [
            {
                "value": "Загрузка",
                "id": -2,
                "popup_name_id": -1
            },
            {
                "value": "Длинное название компании Длинное название компании Длинное название компании Длинное название компании Длинное название название ",
                "id": -3,
                "popup_name_id": -1
            },
            {
                "value": "Загрузка",
                "id": -4,
                "popup_name_id": -1
            },
            {
                "value": "Загрузка",
                "id": -5,
                "popup_name_id": -1
            },
            {
                "value": "Загрузка",
                "id": -6,
                "popup_name_id": -1
            },
            {
                "value": "Загрузка",
                "id": -7,
                "popup_name_id": -1
            }
        ]
    }])

    const dispatch = useDispatch()

    const currentStage = useTypedSelector(state => state.app.current_stage)

    const [checks, setChecks] = useState({
        length: false,
        letterCase: false,
        hasDigits: false,
        hasSymbol: false
    })

    const generatePassword = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        let finalPassword = generateComputedPassword()

        setPassword(finalPassword)
        setRepeatedPassword(finalPassword)
        setChecks({
            length: true,
            letterCase: true,
            hasDigits: true,
            hasSymbol: true
        })
        setIsFirstHidden(true)
    }

    const submitHandler = (e: MouseEvent<HTMLButtonElement>) => {
        if (buttonWord !== 'Подождите...') {
            e.preventDefault()

            let isAllFieldsFilled = true
            for (let i = 0; i < fieldValues.length; i++) {
                if (fieldValues[i] === -1)
                    isAllFieldsFilled = false
            }

            if (!password || !name || !surname) {
                setError('Не все поля заполнены')
            } else if (password !== repeatedPassword) {
                setError('Неверно введен повторный пароль')
            } else if (!isAllFieldsFilled) {
                setError('Выбраны не все поля выбраны!')
            } else if (!checks.length || !checks.hasSymbol || !checks.hasDigits || !checks.letterCase) {
                setError('Пароль не соответствует требованиям')
            } else {
                setButtonWord('Подождите...')
                registration(token, name, secondName, surname, password, fieldValues).then((data) => {
                    localStorage.setItem('token', data.access_token)
                    check().then(userData => {
                        dispatch({type: UserActionTypes.SET_USER, payload: {
                                id: userData.id,
                                isAuth: true,
                                name: userData.last_name + ' ' + userData.first_name + ' ' + userData.second_name
                            }})
                    }).then(() => {
                        navigate('/')
                        window.location.reload()
                    })
                }).catch(error => {
                    setButtonWord('Начать работу')
                    setError('Слишком длинное поле')
                })
            }
        }
    }

    const setValue = (index: number, newValue: number) => {
        fieldValues[index] = newValue
        setFieldValues(fieldValues)
    }

    useEffect(() => {
        if (currentStage > 5) {
            navigate('/error/register_closed')
        } else {
            checkRegisterTokenValid(token).then((data) => {
                setEmail(data)
                getFields().then(data => {
                    setFields(data)
                    const startFieldValues = []
                    for (let i = 0; i < data.length; i++)
                        startFieldValues.push(-1)
                    setFieldValues(startFieldValues)
                })
            }).catch(error => {
                if (error.message === "Network Error") {
                    navigate('/error/wrong_token')
                } else if (error.message === "Request failed with status code 403") {
                    navigate('/error/forbidden')
                } else if (error.message === "Request failed with status code 401") {
                    navigate('/error/inspired')
                } else if (error.message === "Request failed with status code 406") {
                    navigate('/error/confirmed')
                } if (error.message === "Request failed with status code 404") {
                    navigate('/error/user_doesnt_exist')
                }
            })
        }
    }, [])

    return (
        <div className="regPage">
            <HighlightLogo />
            <form className="registrationBlock">
                <h1 className="regHeader">{appInfo.app_name}</h1>
                <h2 className="regSecondHeader">После заполнения анкеты участника ниже Вы сможете начать работу</h2>
                <div className="regInputs">
                    <input
                        className="input regInput"
                        placeholder="Фамилия"
                        type="text"
                        autoComplete="off"
                        value={surname}
                        onChange={(e) => setSurname(e.target.value)}
                    />
                    <input
                        className="input regInput"
                        placeholder="Имя"
                        type="text"
                        autoComplete="off"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <input
                        className="input regInput"
                        placeholder="Отчество (при наличии)"
                        type="text"
                        autoComplete="off"
                        value={secondName}
                        onChange={(e) => setSecondName(e.target.value)}
                    />
                    <input
                        className="input regInput"
                        style={{filter: "brightness(0.8)", cursor: 'default'}}
                        placeholder="email"
                        type="email"
                        autoComplete="off"
                        value={email}
                        disabled={email !== 'email'}
                    />
                </div>

                <div className="regSelects">
                    {fields.map((field, index) => {
                        let width

                        if ((index-1) % 4 === 0 || (index-2) % 4 === 0) {
                            width = "26.4vw"
                        } else {
                            width = "18.8vw"
                        }

                        return <Select
                            key={field.name}
                            width={width}
                            name={field.name}
                            values={field.values}
                            index={index}
                            setValue={setValue}
                        />
                    })}
                </div>
                <hr style={{position: 'relative', zIndex: 1, color: '#DCDCDC', marginTop: 16}}/>

                <GeneratePassword
                    generatePassword={generatePassword}
                    checks={checks}
                    forRestore={false}
                />
                <div className="regPasswordContainer">
                    <div className="input password regPassInput">
                        <input
                            className="input-password input-password-reg"
                            placeholder="Пароль"
                            type={isFirstHidden ? "password" : "text"}
                            value={password}
                            onChange={(e) => {
                                setPassword(e.target.value)
                                checkValidation(e.target.value, setChecks)
                            }}
                        />
                        <div
                            className="eye-container"
                            onMouseDown={() => setIsFirstHidden(false)}
                            onMouseUp={() => setIsFirstHidden(true)}
                            onTouchStart={() => setIsFirstHidden(false)}
                            onTouchEnd={() => setIsFirstHidden(true)}
                        >
                            <EyeIcon />
                        </div>
                    </div>
                    <PasswordArrowIcon />
                    <div className="input password regPassInput">
                        <input
                            className="input-password input-password-reg"
                            placeholder="Повторите пароль"
                            type={isSecondHidden ? "password" : "text"}
                            value={repeatedPassword}
                            onChange={(e) => {
                                setRepeatedPassword(e.target.value)
                            }}
                        />
                        <div
                            className="eye-container"
                            onMouseDown={() => setIsSecondHidden(false)}
                            onMouseUp={() => setIsSecondHidden(true)}
                            onTouchStart={() => setIsSecondHidden(false)}
                            onTouchEnd={() => setIsSecondHidden(true)}
                        >
                            <EyeIcon />
                        </div>
                    </div>
                </div>
                <div className="error_container" style={{paddingTop: "3vh"}}>
                    {error && (
                        <p className="error"><ErrorIcon /><span>{error}</span></p>
                    )}
                </div>
                <button className="submitButton regButton" type="submit" onClick={submitHandler}>
                    {buttonWord}
                </button>
                <a href="mailto:support@hl.expert" className="support regSupport">{appInfo.support_mail}</a>
            </form>
        </div>
    );
};

export default RegistrationPage;