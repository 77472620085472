import React, {useEffect, useState} from 'react';
// @ts-ignore
import styles from "../../../css/admin/ThemePanel.module.css"
// @ts-ignore
import commonStyles from "../../../css/admin/Admin.module.css"
import FunctionalHeader from "../common/FunctionalHeader";
import DownloadIcon from "../../icons/admin/DownloadIcon";
import CustomCheckbox from "../../common/CustomCheckbox";
import EyeIcon from "../../icons/admin/EyeIcon";
import EditIcon from "../../icons/admin/EditIcon";
import ThemeTrashIcon from "../../icons/admin/ThemeTrashIcon";
import GreyPlusIcon from "../../icons/admin/GreyPlusIcon";
import SortIcon from "../../icons/admin/SortIcon";
import {getTopicsByClusterId} from "../../../http/admin/themeRequests";
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import {ISubtopic, ITopic} from "../../../types/admin/http/userRequests";
import Preloader from "../../common/Preloader";
import classNames from "classnames";
import EditSubtopicModal from "./EditSubtopicModal";
import AddTopicModal from "./AddTopicModal";
import AddSubtopicModal from "./AddSubtopicModal";
import DeleteModal from "./DeleteModal";
import {IOption} from "../../../types/admin/props/adminDropDown";
import {ISubtopicSortType, subtopicSortType} from "../../../types/admin/entities/subtopicSortTypes";
import {subtopicSort} from "../../../utils/userSort";
import {MouseHintActionTypes} from "../../../types/reducers/mouseHint";
import {useDispatch} from "react-redux";
import {downloadFileFromServer} from "../../../utils/downloadFileFromServer";

//TODO Вынести компонент таблицы

const eye_hint = 'Посмотреть информацию о созданных заданиях по данной подтеме'
const edit_hint = 'Редактировать подтему'
const delete_hint = 'Удалить подтему'

const ThemePanel = () => {
    const currentCluster = useTypedSelector(state => state.clusters.current_cluster)
    const max_task_on_subtopic = useTypedSelector(state => state.app.max_tasks_on_subtopic)
    const max_experts_on_subtopic = useTypedSelector(state => state.app.max_experts_on_subtopic)
    const [isTableLoaded, setIsTableLoaded] = useState(false)
    const [topics, setTopics] = useState<ITopic[]>([])
    const dispatch = useDispatch()
    const [searchValue, setSearchValue] = useState('')

    const [currentSort, setCurrentSort] = useState<ISubtopicSortType>({isDescending: true, sortType: "user_counter"})
    const setSort = (sortType: subtopicSortType) => {
        if (currentSort.sortType !== sortType) {
            setCurrentSort({sortType: sortType, isDescending: true})
        } else {
            setCurrentSort({...currentSort, isDescending: !currentSort.isDescending})
        }
    }

    const [pickedSubtopics, setPickedSubtopics] = useState<ISubtopic[]>([])
    const clearPickedSubtopics = () => {
        setPickedSubtopics([])
    }

    const [currentSubtopic, setCurrentSubtopic] = useState<ISubtopic>()
    const [currentTopic, setCurrentTopic] = useState<ITopic>()

    const [reload, setReload] = useState(false)
    const reloadThemePanel = () => {
        setReload(!reload)
    }
    
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
    const [isAddSubtopicOpen, setIsAddSubtopicOpen] = useState(false)
    const [isAddTopicOpen, setIsAddTopicOpen] = useState(false)
    const [isEditSubtopicOpen, setIsEditSubtopicOpen] = useState(false)

    useEffect(() => {
        setIsTableLoaded(false)
        getTopicsByClusterId(currentCluster.id).then(data => {
            setIsTableLoaded(true)
            console.log(data)
            setTopics(data)
        })
    }, [currentCluster, reload])

    const isSubtopicPicked = (id: number) => {
        for (let i = 0; i < pickedSubtopics.length; i++) {
            if (pickedSubtopics[i].id === id) {
                return true
            }
        }

        return false
    }

    const addOrDeletePickedSubtopic = (currentSubtopic: ISubtopic) => {
        if (isSubtopicPicked(currentSubtopic.id)) {
            setPickedSubtopics(pickedSubtopics.filter(subtopic => subtopic.id !== currentSubtopic.id))
        } else {
            setPickedSubtopics([...pickedSubtopics, currentSubtopic])
        }
    }

    const showHint = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, text: string) => {
        dispatch({type: MouseHintActionTypes.SHOW_HINT, payload: {
                x: e.clientX + 15,
                y: e.pageY,
                isShown: true,
                hint_text: text
            }})
    }

    const hideHint = () => {
        dispatch({type: MouseHintActionTypes.HIDE_HINT})
    }

    const filterTopicsFunc = (topics: ITopic[]) => {
        return topics.filter(topic => {
            for (let i = 0; i < topic.subtopics.length; i++) {
                if (topic.subtopics[i].name.toLowerCase().includes(searchValue.toLowerCase())) return true
            }
        })
    }
    const filterSubtopic = (subtopic: ISubtopic) => subtopic.name.toLowerCase().includes(searchValue.toLowerCase())

    const getSubtopicList = (): IOption[] => {
        let subtopicList: IOption[] = []

        for (let i = 0; i < topics.length; i++) {
            for (let j = 0; j < topics[i].subtopics.length; j++) {
                subtopicList.push({id: topics[i].subtopics[j].id, name: topics[i].subtopics[j].name})
            }
        }

        subtopicList = subtopicList.filter(subtopic => {
            let isSubtopicInPicked = false

            pickedSubtopics.forEach(pickedSubtopic => {
                if (subtopic.id === pickedSubtopic.id) {
                    isSubtopicInPicked = true
                }
            })

            return !isSubtopicInPicked
        })

        return subtopicList
    }

    return (
        <div className={commonStyles.mainPanel}>
            {isEditSubtopicOpen && currentSubtopic && <EditSubtopicModal
                currentSubtopic={currentSubtopic}
                closeModal={() => setIsEditSubtopicOpen(false)}
                reload={reloadThemePanel}
            />}

            {isAddTopicOpen && <AddTopicModal
                closeModal={() => setIsAddTopicOpen(false)}
                reload={reloadThemePanel}
            />}

            {isAddSubtopicOpen && currentTopic && <AddSubtopicModal
                closeModal={() => setIsAddSubtopicOpen(false)}
                topic={currentTopic}
                reload={reloadThemePanel}
            />}

            {isDeleteModalOpen && <DeleteModal
                closeModal={() => setIsDeleteModalOpen(false)}
                reload={reloadThemePanel}
                allSubtopics={getSubtopicList()}
                subtopics={pickedSubtopics}
                clear={clearPickedSubtopics}
            />}

            <div className={commonStyles.headerControls}>
                <FunctionalHeader header={"Темы"} searchValue={searchValue} searchValueSetter={setSearchValue}/>
                <div className={commonStyles.controls}>
                    <div className={commonStyles.control} onClick={() => downloadFileFromServer(
                        'admin/themes/download_xlsx',
                        'theme_statistic.xlsx'
                    )}>
                        <DownloadIcon/>
                        <span>Скачать статистику</span>
                    </div>
                    <div className={commonStyles.control} onClick={() => downloadFileFromServer(
                        'dashboard/full_feedback?subtopic_id=0',
                        'all_tasks.xlsx'
                    )}>
                        <DownloadIcon/>
                        <span>Скачать полную выгрузку</span>
                    </div>
                </div>
            </div>

            {isTableLoaded && topics.length !== 0 ? <div className={styles.userTable}>
                <div className={styles.tableHeader}>
                    <div className={styles.topicHeader}>
                        <p>Тема</p>
                    </div>
                    <div className={styles.subtopicHeader}>
                        <p>Подтема</p>
                    </div>
                    <div className={styles.counterHeader} onClick={() => setSort('user_counter')}>
                        <p>Выбрали подтему</p>
                        <SortIcon />
                    </div>
                    <div className={styles.counterHeader} onClick={() => setSort('task_counter')}>
                        <p>Количество заданий</p>
                        <SortIcon />
                    </div>
                </div>

                <div className={styles.table}>
                    {filterTopicsFunc(topics).map(topic => {
                        return <div className={styles.topicRow} key={topic.id}>
                            <div className={styles.topic}>
                                <p>
                                    {topic.name}
                                </p>
                            </div>
                            <div className={styles.subtopics}>
                                {subtopicSort(topic.subtopics, currentSort).filter(filterSubtopic).map((subtopic) => {
                                    return <div className={styles.subtopic} key={subtopic.id}>
                                        <div className={styles.subtopicName} onClick={() => addOrDeletePickedSubtopic(subtopic)}>
                                            <CustomCheckbox isChecked={isSubtopicPicked(subtopic.id)} isMinimize={true}/>
                                            <p>{subtopic.name}</p>
                                        </div>
                                        <div className={styles.userCounter}>
                                            <p>{subtopic.user_counter}/<span>{max_experts_on_subtopic}</span></p>
                                        </div>
                                        <div className={styles.taskCounter}>
                                            <p>{subtopic.task_counter}/<span>{Number(subtopic.user_counter) * max_task_on_subtopic}</span></p>
                                        </div>
                                        <div className={styles.subtopicControls}>
                                            <div className={styles.controlsContainer}>
                                                <div
                                                  onMouseMove={(e) => showHint(e, eye_hint)}
                                                  onMouseLeave={() => hideHint()}
                                                  onClick={() => downloadFileFromServer(
                                                      `dashboard/full_feedback?subtopic_id=${subtopic.id}`,
                                                      `subtopic${subtopic.id}_tasks.xlsx`
                                                  )}
                                                >
                                                    <EyeIcon />
                                                </div>
                                                <div
                                                  onClick={() => {
                                                    setIsEditSubtopicOpen(true)
                                                    setCurrentSubtopic(subtopic)
                                                  }}
                                                  onMouseMove={(e) => showHint(e, edit_hint)}
                                                  onMouseLeave={() => hideHint()}
                                                >
                                                    <EditIcon />
                                                </div>
                                                <div
                                                  onClick={() => {
                                                      setIsDeleteModalOpen(true)
                                                      setPickedSubtopics([subtopic])
                                                  }}
                                                  onMouseMove={(e) => showHint(e, delete_hint)}
                                                  onMouseLeave={() => hideHint()}
                                                >
                                                    <ThemeTrashIcon />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                })}

                                <div className={styles.addSubtopicRow}>
                                    <div
                                        className={styles.addSubtopicControl}
                                        onClick={() => {
                                            setIsAddSubtopicOpen(true)
                                            setCurrentTopic(topic)
                                        }}
                                    >
                                        <GreyPlusIcon />
                                        <span>Добавить подтему</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    })}
                    <div className={classNames(styles.addSubtopicRow, styles.addTopicRow)}>
                        <div className={styles.addSubtopicControl} onClick={() => setIsAddTopicOpen(true)}>
                            <GreyPlusIcon />
                            <span>Добавить тему</span>
                        </div>
                    </div>
                </div>

                {pickedSubtopics.length !== 0 && <div className={styles.blockControl} onClick={() => setIsDeleteModalOpen(true)}>
                    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7 9V15M11 9V15M1 5H17M16 5L15.133 17.142C15.0971 17.6466 14.8713 18.1188 14.5011 18.4636C14.1309 18.8083 13.6439 19 13.138 19H4.862C4.35614 19 3.86907 18.8083 3.49889 18.4636C3.1287 18.1188 2.90292 17.6466 2.867 17.142L2 5H16ZM12 5V2C12 1.73478 11.8946 1.48043 11.7071 1.29289C11.5196 1.10536 11.2652 1 11 1H7C6.73478 1 6.48043 1.10536 6.29289 1.29289C6.10536 1.48043 6 1.73478 6 2V5H12Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    <p>Удалить подтемы</p>
                </div>}
            </div> : topics.length !== 0 || !isTableLoaded ? <Preloader /> : <p>Пусто =)</p>}
        </div>
    );
};

export default ThemePanel;