import React, {useEffect, useState} from 'react';
import "../../css/loginPage.css";
import SignUpEmail from "./SignUpEmail";
import HighlightLogo from "../icons/HighlightLogo";
import SignIn from "./SignIn";
import ForgotForm from "./ForgotForm";
import {useTypedSelector} from "../../hooks/useTypedSelector";

const AuthPage = () => {
    const [form, setForm] = useState('Registration')
    const appInfo = useTypedSelector(state => state.app)

    useEffect(() => {
        if (appInfo.current_stage === 1) {
            setForm('Registration')
        } else {
            setForm('Login')
        }
    }, [])

    const getRegistration = () => {
        if (appInfo.current_stage > 1) {
            alert("К сожалению, срок регистрации закончился.")
        } else {
            setForm('Registration')
        }
    }

    const getLogin = () => {
        setForm('Login')
    }

    const getForget = () => {
        setForm('Forget')
    }

    return (
        <>
            <HighlightLogo />
            <div className="loginBlock">
                <h2 className="login-greeting">{appInfo.app_name}</h2>
                <p className="login-p">{appInfo.app_name_description}</p>
                {form === 'Login' && (
                    <SignIn getRegistration={getRegistration} getForget={getForget}/>
                )}
                {form === 'Registration' && (
                    <SignUpEmail getLogin={getLogin}/>
                )}
                {form === 'Forget' && (
                    <ForgotForm getLogin={getLogin}/>
                )}
                <a href={"mailto:" + appInfo.support_mail} className="support">{appInfo.support_mail}</a>
            </div>
        </>
    );
};

export default AuthPage;