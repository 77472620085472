import React, {useState} from 'react';
// @ts-ignore
import styles from "../../../css/admin/MailPanel.module.css";
import TextEditor from "./TextEditor";
import RecipientIcon from "../../icons/admin/RecipientIcon";
import CrossIcon from "../../icons/admin/CrossIcon";
import CustomCheckbox from "../../common/CustomCheckbox";
import Button from "../../common/Button";
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import {useDispatch} from "react-redux";
import {RecipientsActionTypes} from "../../../types/reducers/recipients";
import {useNavigate} from "react-router-dom";
import {saveCustomMailList} from "../../../http/admin/mailListsRequests";
import AlertModal from "../common/AlertModal";
import {isValidDate} from "../../../utils/isValidDate";
import {MailEditorActionTypes} from "../../../types/reducers/mailEditor";
import {reloadMailLists} from "../../../utils/reloadMailLists";
import ConfirmModal from "../common/ConfirmModal";

const MailListEditor = () => {
    const [buttonText, setButtonText] = useState('Сохранить')

    const mailListData = useTypedSelector(state => state.mailEditor)

    const recipients = useTypedSelector(state => state.recipients)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [isAlertModalOpen, setIsAlertModalOpen] = useState(false)
    const [alertText, setAlertText] = useState('')
    const [confirmEmpty, setConfirmEmpty] = useState(false)

    const deleteRecipient = (id: number) => {
        dispatch({type: RecipientsActionTypes.REMOVE_RECIPIENT, payload: id})
    }

    const setText = (text: string) => {
        dispatch({type: MailEditorActionTypes.SET_TEXT, payload: text})
    }

  const clearMailList = () => {
        dispatch({type: MailEditorActionTypes.SET_DEFAULT_DATA})
        dispatch({type: RecipientsActionTypes.CLEAR_RECIPIENTS})
        setConfirmEmpty(false)
  }

    const saveMailList = () => {
        if (buttonText !== 'Подождите...') {
            if (recipients.length && mailListData.text && mailListData.text.length <= 1200 && mailListData.mailListName && (isValidDate(mailListData.date) || mailListData.isNowChecked)) {
                setButtonText('Подождите...')

                const mailListIn = {
                    name: mailListData.mailListName,
                    date: mailListData.isNowChecked ? 'now' : mailListData.date,
                    text: mailListData.text,
                    recipients: recipients.map(recipient => recipient.id)
                }

                saveCustomMailList(mailListIn).then(() => {
                    dispatch({type: RecipientsActionTypes.CLEAR_RECIPIENTS})
                    dispatch({type: MailEditorActionTypes.SET_DEFAULT_DATA})
                    setButtonText('Сохранить')
                    reloadMailLists(navigate)
                })
            } else {
                if (recipients.length === 0) {
                    setAlertText('Не выбраны получатели!')
                    setIsAlertModalOpen(true)
                } else if (!(mailListData.text && (mailListData.date || mailListData.isNowChecked) && mailListData.mailListName)) {
                    setAlertText('Не все данные заполнены!')
                    setIsAlertModalOpen(true)
                } else if (mailListData.text.length > 1200) {
                  setAlertText('Длина текста рассылки не должна превышать 1200 символов!')
                  setIsAlertModalOpen(true)
                } else {
                    setAlertText('Некорректная дата!')
                    setIsAlertModalOpen(true)
                }
            }
        }
    }

    return (
        <div className={styles.mailList} style={{overflow: 'visible'}}>
            {isAlertModalOpen && <AlertModal closeModal={() => setIsAlertModalOpen(false)} text={alertText}/>}
            {confirmEmpty && <ConfirmModal
                text="Уверены, что хотите очистить рассылку?"
                closeModal={() => setConfirmEmpty(false)}
                submit={clearMailList}
            />}

            <div className={styles.mailListEditorHeader}>
              <input
                value={mailListData.mailListName}
                onChange={(e) => dispatch({type: MailEditorActionTypes.SET_MAIL_LIST_NAME, payload: e.target.value})}
                type="text"
                className={styles.mailListNameInput}
                placeholder={"Название рассылки"}
              />
              <span className={styles.hideEditorButton} onClick={() => {
                  dispatch({type: MailEditorActionTypes.CLOSE_EDITOR})
              }}>Скрыть</span>
            </div>
            <div className={styles.addRecipientsControl} onClick={() => navigate('/admin/users')}>
                <RecipientIcon />
                <span>Выбрать получателей</span>
            </div>

            <div className={styles.recipients}>
                {recipients.map(recipient => {
                    return <div key={recipient.id} className={styles.recipient}>
                        <span>{recipient.email}</span>
                        <div onClick={() => deleteRecipient(recipient.id)}>
                            <CrossIcon />
                        </div>
                    </div>
                })}
            </div>

            <TextEditor setText={setText} text={mailListData.text} isForEdit={true}/>

            <div className={styles.datePicker}>
                <label htmlFor="date">Выберите дату рассылки (ДД.ММ.ГГГГ):</label>
                <input
                    type="text"
                    id={'date'}
                    disabled={mailListData.isNowChecked}
                    value={mailListData.isNowChecked ? '' : mailListData.date}
                    onChange={(e) => dispatch({type: MailEditorActionTypes.SET_DATE, payload: e.target.value})}
                />
            </div>

            <div className={styles.subfilter} onClick={() => dispatch({type: MailEditorActionTypes.TOGGLE_IS_NOW_CHECKED})}>
                <CustomCheckbox isChecked={mailListData.isNowChecked} isMinimize={true}/>
                <span>Отправить сейчас</span>
            </div>

          <div className={styles.mailListEditorButtons}>
            <Button
              width={"170px"}
              height={"45px"}
              fontSize={"16px"}
              variant={"primary"}
              submit={() => setConfirmEmpty(true)}
            >
              Очистить рассылку
            </Button>
            <Button
              width={"150px"}
              height={"45px"}
              fontSize={"16px"}
              variant={"primary"}
              submit={() => saveMailList()}
            >
              {mailListData.isNowChecked && buttonText !== "Подождите..." ? mailListData.id ? "Редактировать" : "Отправить" : buttonText}
            </Button>
          </div>
        </div>
    );
};

export default MailListEditor;