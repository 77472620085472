import React, {ChangeEvent, useEffect, useState} from 'react';
// @ts-ignore
import styles from "../../../../css/admin/Script.module.css";
import {WizardStages, WizardStagesNames} from "../../../../types/entities/status";
import WizardInput from "../UI/WizardInput";
import IconButton from "../UI/IconButton";
import Button from "../../../common/Button";
import {useDispatch} from "react-redux";
import {useTypedSelector} from "../../../../hooks/useTypedSelector";
import {ProjectDescription, WizardActionTypes} from "../../../../types/reducers/wizard";
import {
    getFirstStepWizardData,
    getProjectDescriptionData,
    setProjectDescriptionData, uploadRulesFile,
    uploadTopicTemplate
} from "../../../../http/admin/wizardRequest";
import AlertModal from "../../common/AlertModal";
import {useNavigate} from "react-router-dom";
import {StatusActionTypes} from "../../../../types/reducers/status";
import UploadIcon from "../../../icons/admin/UploadIcon";
import {downloadFileFromServer} from "../../../../utils/downloadFileFromServer";

const errorHandler = (projectDescription: ProjectDescription) => {
    if (projectDescription.project_name === '') return 'Вы не ввели название!'
    if (projectDescription.description === '') return 'Вы не ввели краткое описание!'
    if (projectDescription.support_mail === '') return 'Вы не ввели электронный адрес поддержки!'

    for (let i = 0; i < projectDescription.mail_domains.length; i++)
        if (projectDescription.mail_domains[i] === '') return 'Не все поля допустимых доменов заполнены!'
    return ''
}

const ProjectDescriptionComponent = () => {
    const isWizardConfigured = useTypedSelector(state => state.status.wizard_completed)
    const projectDescriptionData = useTypedSelector(state => state.wizard.project_description)
    const dispatch = useDispatch()
    const [buttonText, setButtonText] = useState('Сохранить')
    const [isModalErrorOpened, setIsModalErrorOpened] = useState(false)
    const [uploadSuccessAlert, setUploadSuccessAlert] = useState(false)
    const [uploadButtonText, setUploadButtonText] = useState('Загрузить файл')
    const [errorText, setErrorText] = useState('')
    const navigate = useNavigate()

    useEffect(() => {
        if (isWizardConfigured) {
            getProjectDescriptionData().then(data => {
                dispatch({ type: WizardActionTypes.SET_WIZARD_PROJECT_DESCRIPTION, payload: data })
            })
        }
    }, [])

    const setProjectName = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch({ type: WizardActionTypes.SET_PROJECT_NAME, payload: e.target.value })
    }

    const setDescription = (e: ChangeEvent<HTMLTextAreaElement>) => {
        dispatch({ type: WizardActionTypes.SET_DESCRIPTION, payload: e.target.value })
    }

    const setSupportMail = (e: any) => {
        dispatch({ type: WizardActionTypes.SET_SUPPORT_MAIL, payload: e.target.value })
    }

    const setEmailDomainByIndex = (e: any, index: number) => {
        dispatch({
            type: WizardActionTypes.SET_MAIL_DOMAIN_BY_INDEX,
            payload: {
                index,
                domain: e.target.value,
            }
        })
    }

    const deleteEmailDomain = () => {
        dispatch({ type: WizardActionTypes.DELETE_MAIL_DOMAIN })
    }

    const addEmailDomain = () => {
        dispatch({ type: WizardActionTypes.ADD_MAIL_DOMAIN })
    }

    const uploadHandler = (e: any) => {
        if (uploadButtonText !== 'Подождите...') {
            setUploadButtonText('Подождите...')
            uploadRulesFile(e.target.files[0]).then(() => {
                e.target.value = null
                setUploadSuccessAlert(true)
                setUploadButtonText('Загрузить файл')
            })
        }
    }

    const submit = () => {
        if (buttonText !== 'Подождите...') {
            const error = errorHandler(projectDescriptionData)

            if (error) {
                setErrorText(error)
                setIsModalErrorOpened(true)
            } else {
                setButtonText('Подождите...')
                setProjectDescriptionData(projectDescriptionData).then(() => {
                    if (isWizardConfigured) {
                        navigate('/admin/script')
                    } else {
                        dispatch({ type: StatusActionTypes.SET_NEXT_WIZARD_STAGE })
                    }
                })
            }
        }
    }

    return (
        <div className={styles.wizardStageComponent}>
            {isModalErrorOpened && <AlertModal closeModal={() => setIsModalErrorOpened(false)} text={errorText}/>}
            {uploadSuccessAlert && <AlertModal closeModal={() => setUploadSuccessAlert(false)} text="Файл успешно загружен."/>}

            <h2 className={styles.wizardStageHeader}>{WizardStagesNames[WizardStages.PROJECT_DESCRIPTION]}</h2>
            <div className={styles.projectDescriptionContent}>
                <span className={styles.wizardInputLabel}>Установите файл правил конкурса (pdf)</span>
                <a
                    href="#"
                    onClick={() => downloadFileFromServer(
                    'wizard/get_rules',
                    'rules.pdf'
                    )}
                >
                    Скачать текущие правила конкурса
                </a>
                <div className={styles.uploadButtonContainer} style={{ width: '180px' }}>
                    <div className={styles.inputWrapper}>
                        <input
                            name="file"
                            type="file"
                            id="input__file"
                            className={styles.inputFile}
                            accept=".pdf"
                            onChange={uploadHandler}
                        />
                        <label htmlFor="input__file" className={styles.inputFileButton}>
                            <span className={styles.inputIconWrapper}>
                                <UploadIcon />
                            </span>
                            <span className={styles.inputFileButtonText}>{uploadButtonText}</span>
                        </label>
                    </div>
                </div>
                <WizardInput
                    onChange={setProjectName}
                    value={projectDescriptionData.project_name}
                    placeholder="Текст..."
                    label="Название"
                />
                <WizardInput
                    onChange={setDescription}
                    value={projectDescriptionData.description}
                    placeholder="Текст..."
                    label="Краткое описание"
                    isTextarea={true}
                    height={100}
                />
                <div>
                    <span className={styles.wizardInputLabel}>Почтовые домены, допущенные к регистрации</span>
                    <div className={styles.domainsContainer}>
                        {projectDescriptionData.mail_domains.map((mail_domain, index) => {
                            return <WizardInput
                                key={index}
                                onChange={(e) => setEmailDomainByIndex(e, index)}
                                value={mail_domain}
                                placeholder="@gmail.com"
                                width={130}
                            />
                        })}
                        <IconButton onClick={deleteEmailDomain} state="minus"/>
                        <IconButton onClick={addEmailDomain} state="add"/>
                    </div>
                </div>
                <WizardInput
                    onChange={setSupportMail}
                    value={projectDescriptionData.support_mail}
                    placeholder="Текст..."
                    label="Почтовый адрес поддержки"
                />
            </div>
            <Button
                width={"150px"}
                height={"45px"}
                fontSize={"16px"}
                variant={"primary"}
                margin="50px 0 0 770px"
                submit={submit}
            >
                {buttonText}
            </Button>
        </div>
    );
};

export default ProjectDescriptionComponent;