import React from 'react';
import {Page, Text, Image, Document, StyleSheet, Font, View} from "@react-pdf/renderer";
// @ts-ignore
import background from '../../../../../../img/background.jpg'
// @ts-ignore
import Arimo from '../../../../../../fonts/Arimo-Regular.ttf'

Font.register({
    family: "Arimo",
    src: Arimo
});

const styles = StyleSheet.create({
    header: {
        fontFamily : "Arimo",
        fontSize: 24,
        textAlign: "center",
        marginTop: 219,
        fontWeight: 400
    },

    firstRow: {
        marginTop: 52,
        fontFamily : "Arimo",
        fontSize: 14,
        textAlign: "center",
    },

    name: {
        marginTop: 4,
        fontFamily : "Arimo",
        fontSize: 14,
        textAlign: "center",
        textDecoration: "underline"
    },

    description: {
        marginTop: 4,
        fontFamily : "Arimo",
        fontSize: 14,
        textAlign: "center",
    },

    quality: {
        marginTop: 28,
        fontFamily : "Arimo",
        fontSize: 14,
        textAlign: "center",
    },

    date: {
        fontFamily: "Arimo",
        textAlign: "center",
        width: "100%",
        position: "absolute",
        bottom: "55px",
        color: "rgba(112, 112, 112, 1)",
        fontSize: 12
    }
})

const ApiStyles = StyleSheet.create({
    image: {
        objectFit: 'cover',
        position: "absolute",
        width: "594",
        height: "841"
    },
});

const PdfCertificate = (props: {name: string, place: number, qualityCounter: number, cluster: string}) => {
    return (
        <Document>
            <Page size={"A4"}>
                <Image src={background} style={ApiStyles.image}/>
                <Text style={styles.header}>{Number(props.place) <= -1 ? "Диплом" : "Сертификат участника"}</Text>
                <Text style={styles.firstRow}>Настоящий диплом подтверждает, что</Text>
                <Text style={styles.name}>{props.name}</Text>
                <Text style={styles.description}>принял(а) участие в конкурсе разработчиков</Text>
                <Text style={styles.description}>тестовых заданий «Экспертная платформа».</Text>
                <Text style={styles.quality}>Разработано {props.qualityCounter} качественных тестовых заданий.</Text>
                {Number(props.place) <= -1 && <><Text style={styles.description}>По итогам конкурса {props.name}</Text>
                <Text style={styles.description}>занял(а) {props.place} место среди сотрудников</Text>
                <Text style={styles.description}>профессионального кластера</Text>
                <Text style={styles.name}>{props.cluster}</Text></>}
                <Text style={styles.date}>{new Date().getFullYear()} год</Text>
            </Page>
        </Document>
    );
};

export default PdfCertificate;