import React, {PropsWithChildren, useState} from 'react';
// @ts-ignore
import styles from "../../../css/Editor.module.css";
import classNames from "classnames";

const QuestionCard = (props: PropsWithChildren<any>) => {
    const isMobile = useState(document.documentElement.clientWidth <= 900)[0]

    return (<>
        {!isMobile ? <div className={styles.questionCard} style={{position: "relative", top: 10}}>
                        <div className={styles.questionCard} style={{position: "relative", left: 7, bottom: 5}}>
                            <div
                                className={classNames(styles.questionCard, styles.questionCardInner)}
                                style={{position: "relative", left: 7, bottom: 5}}
                            >
                                {props.children}
                            </div>
                        </div>
                    </div>
                    :
                    <div className={classNames(styles.questionCard, styles.questionCardInner)}>
                        {props.children}
                    </div>
        }
    </>
    );
};

export default QuestionCard;