import {useTypedSelector} from "./useTypedSelector";
import {StageStatus, WizardStages} from "../types/entities/status";
import {getWizardStageOrderNumber} from "../utils/getWizardStageOrderNumber";

export const useWizardStageStatus = (stage: WizardStages): StageStatus => {
    const currentStage = useTypedSelector(state => state.status.current_wizard_stage)

    if (getWizardStageOrderNumber(currentStage) === getWizardStageOrderNumber(stage)) return StageStatus.ACTUAL
    else if (getWizardStageOrderNumber(currentStage) > getWizardStageOrderNumber(stage)) return StageStatus.PAST
    else return StageStatus.COMING
}