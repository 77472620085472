import React from 'react';
// @ts-ignore
import styles from '../../css/PersonalArea.module.css'
import classNames from "classnames";

const AnotherButton = (props: {children: string, width: number, height: number, onClick?: () => void}) => {
  return (
    <button
      className={classNames(styles.button, styles.modalButton)}
      style={{width: props.width, height: props.height}}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
};

export default AnotherButton;