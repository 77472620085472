import {IModalConfig, ModalAction, ModalActionTypes} from "../../types/reducers/modal";

const initialState: IModalConfig = false

export const modalReducer = (state=initialState, action: ModalAction): IModalConfig => {
  switch (action.type) {
    case ModalActionTypes.SET_MODAL_STATUS:
      return action.payload
    default:
      return false
  }
}