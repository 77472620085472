import {Wizard, WizardAction, WizardActionTypes, WizardInitState} from "../../types/reducers/wizard";

const initialState: Wizard = WizardInitState

export const wizardReducer = (state=initialState, action: WizardAction): Wizard => {
    switch (action.type) {
        case WizardActionTypes.SET_DESCRIPTION:
            return {
                ...state,
                project_description: {
                    ...state.project_description,
                    description: action.payload
                }
            }
        case WizardActionTypes.SET_SUPPORT_MAIL:
            return {
                ...state,
                project_description: {
                    ...state.project_description,
                    support_mail: action.payload
                }
            }
        case WizardActionTypes.SET_WIZARD_PROJECT_DESCRIPTION:
            return {
                ...state,
                project_description: action.payload
            }
        case WizardActionTypes.SET_PROJECT_NAME:
            return {
                ...state,
                project_description: {
                    ...state.project_description,
                    project_name: action.payload
                }
            }
        case WizardActionTypes.SET_WIZARD_VARIABLES:
            return {
                ...state,
                variables: action.payload
            }
        case WizardActionTypes.ADD_MAIL_DOMAIN:
            const new_domains = [...state.project_description.mail_domains]
            new_domains.push('')

            return {
                ...state,
                project_description: {
                    ...state.project_description,
                    mail_domains: new_domains
                }
            }
        case WizardActionTypes.DELETE_MAIL_DOMAIN:
            if (state.project_description.mail_domains.length === 1) return state

            const current_domains = [...state.project_description.mail_domains]
            current_domains.pop()

            return {
                ...state,
                project_description: {
                    ...state.project_description,
                    mail_domains: current_domains
                }
            }
        case WizardActionTypes.SET_REGISTER_FIELD_NAME_BY_INDEX:
            const registerFields = [...state.register]
            registerFields[action.payload.index].name = action.payload.name

            return {
                ...state,
                register: registerFields
            }
        case WizardActionTypes.SET_REGISTER_SUBFIELDS_BY_INDEX:
            const newRegisterFields = [...state.register]
            newRegisterFields[action.payload.index].subfields = action.payload.subfields

            return {
                ...state,
                register: newRegisterFields
            }
        case WizardActionTypes.ADD_REGISTER_FIELD:
            const registerFieldsForAdd = [...state.register]
            registerFieldsForAdd.push({
                name: '',
                subfields: '',
                isChecked: false
            })

            return {
                ...state,
                register: registerFieldsForAdd
            }
        case WizardActionTypes.REMOVE_REGISTER_FIELD:
            if (state.register.length === 1) return state

            const registerFieldsForRemove = [...state.register]
            registerFieldsForRemove.pop()

            return {
                ...state,
                register: registerFieldsForRemove
            }
        case WizardActionTypes.CHECK_REGISTER_FIELD:
            const registerFieldsForCheck = [...state.register].map((registerField, index) => {
                registerField.isChecked = index === action.payload;
                return registerField
            })

            return {
                ...state,
                register: registerFieldsForCheck
            }
        case WizardActionTypes.SET_REGISTER_FIELDS:
            return {
                ...state,
                register: action.payload
            }
        case WizardActionTypes.SET_WAITING_INSTRUCTION:
            return {
                ...state,
                waiting_stage: {
                    ...state.waiting_stage,
                    instruction: action.payload
                }
            }
        case WizardActionTypes.SET_EMBEDDED_LINK:
            return {
                ...state,
                waiting_stage: {
                    ...state.waiting_stage,
                    embedded_link: action.payload
                }
            }
        case WizardActionTypes.SET_WAITING_INFO:
            return {
                ...state,
                waiting_stage: action.payload
            }
        case WizardActionTypes.SET_OPTIONS_COUNTER:
            if (isNaN(+action.payload)) return state

            return {
                ...state,
                second_step: {
                    ...state.second_step,
                    option_counter: action.payload
                }
            }
        case WizardActionTypes.SET_TASK_COUNTER:
            if (isNaN(+action.payload)) return state

            return {
                ...state,
                second_step: {
                    ...state.second_step,
                    task_counter: action.payload
                }
            }
        case WizardActionTypes.SET_MAX_SYMBOLS_FOR_OPTION:
            if (isNaN(+action.payload)) return state

            return {
                ...state,
                second_step: {
                    ...state.second_step,
                    max_symbols_for_option: action.payload
                }
            }
        case WizardActionTypes.SET_MAX_SYMBOLS_FOR_QUESTION:
            if (isNaN(+action.payload)) return state

            return {
                ...state,
                second_step: {
                    ...state.second_step,
                    max_symbols_for_question: action.payload
                }
            }
        case WizardActionTypes.SET_SECOND_STEP_INSTRUCTION:
            return {
                ...state,
                second_step: {
                    ...state.second_step,
                    instruction: action.payload
                }
            }
        case WizardActionTypes.SET_SECOND_STEP_START_DATE:
            return {
                ...state,
                second_step: {
                    ...state.second_step,
                    start_date: action.payload
                }
            }
        case WizardActionTypes.SET_SECOND_STEP_INFO:
            return {
                ...state,
                second_step: action.payload
            }
        case WizardActionTypes.SET_FIRST_STEP_INSTRUCTION:
            return {
                ...state,
                first_step: {
                    ...state.first_step,
                    instruction: action.payload
                }
            }
        case WizardActionTypes.SET_FIRST_STEP_DATE:
            return {
                ...state,
                first_step: {
                    ...state.first_step,
                    start_date: action.payload
                }
            }
        case WizardActionTypes.SET_DEFAULT_LIMIT:
            if (isNaN(+action.payload)) return state

            return {
                ...state,
                first_step: {
                    ...state.first_step,
                    subtopic_limit: action.payload
                }
            }

        case WizardActionTypes.SET_MAX_PICKED_SUBTOPICS:
            if (isNaN(+action.payload)) return state

            return {
                ...state,
                first_step: {
                    ...state.first_step,
                    max_picked_subtopics: action.payload
                }
            }
        case WizardActionTypes.SET_SUBTOPICS:
            return {
                ...state,
                first_step: {
                    ...state.first_step,
                    subtopic_data: action.payload.subtopic_data,
                    clusters: action.payload.clusters
                }
            }
        case WizardActionTypes.SET_FIRST_STEP_DATA:
            return {
                ...state,
                first_step: action.payload
            }
        case WizardActionTypes.SET_THIRD_STEP_INSTRUCTION:
            return {
                ...state,
                third_step: {
                    ...state.third_step,
                    instruction: action.payload
                }
            }
        case WizardActionTypes.SET_THIRD_STEP_START_DATE:
            return {
                ...state,
                third_step: {
                    ...state.third_step,
                    start_date: action.payload
                }
            }
        case WizardActionTypes.SET_THIRD_STEP_END_DATE:
            return {
                ...state,
                third_step: {
                    ...state.third_step,
                    end_date: action.payload
                }
            }
        case WizardActionTypes.SET_COLLECT_WAY:
            return {
                ...state,
                third_step: {
                    ...state.third_step,
                    is_collection_by_subtopic: action.payload
                }
            }
        case WizardActionTypes.SET_THIRD_STAGE_DATA:
            return {
                ...state,
                third_step: action.payload
            }
        case WizardActionTypes.SET_SCRIPT_DATA:
            return {
                ...state,
                scriptData: action.payload
            }
        case WizardActionTypes.SET_EXPERT_TASK_LIMIT:
            if (isNaN(+action.payload)) return state

            return {
                ...state,
                third_step: {
                    ...state.third_step,
                    task_limit_for_expert: action.payload
                }
            }
        case WizardActionTypes.SET_DASHBOARD_FLAG:
            return {
                ...state,
                third_step: {
                    ...state.third_step,
                    is_dashboard_show: action.payload
                }
            }
        case WizardActionTypes.SET_GRADE_ACTUALITY_DESCRIPTION_BY_INDEX:
            const newOriginality = [...state.third_step.originality]
            newOriginality[action.payload.index] = action.payload.description

            return {
                ...state,
                third_step: {
                    ...state.third_step,
                    originality: newOriginality
                }
            }
        case WizardActionTypes.SET_GRADE_QUALITY_DESCRIPTION_BY_INDEX:
            const newQuality = [...state.third_step.quality]
            newQuality[action.payload.index] = action.payload.description

            return {
                ...state,
                third_step: {
                    ...state.third_step,
                    quality: newQuality
                }
            }
        case WizardActionTypes.SET_MAIL_DOMAIN_BY_INDEX:
            const domains_for_mutate = [...state.project_description.mail_domains]

            domains_for_mutate[action.payload.index] = action.payload.domain

            return {
                ...state,
                project_description: {
                    ...state.project_description,
                    mail_domains: domains_for_mutate
                }
            }
        default:
            return state
    }
}