import React, {MouseEventHandler, useEffect, useState} from 'react';
// @ts-ignore
import styles from "../../css/Common.module.css"
import {IButtonProps} from "../../types/components/common";

const Button = ({
    width,
    height,
    fontSize,
    margin,
    variant,
    submit,
    display,
    children,
    disabled = false
}: IButtonProps) => {
    const [currentVariant, setCurrentVariant] = useState(styles.button)

    useEffect(() => {
        if (variant === "primary") {
            setCurrentVariant(styles.button)
        } else {
            setCurrentVariant(styles.secondary)
        }
    }, [])

    return (
        <button className={currentVariant} type="submit" disabled={disabled} onClick={submit} style={{
            width: width,
            height: height,
            fontSize: fontSize,
            margin: margin ? margin : "0",
            display: display,
            opacity: disabled ? '0.3' : '1'
        }}>
            {children}
        </button>
    );
};

export default Button;