import React, {FC} from 'react';
// @ts-ignore
import styles from "../../../css/admin/Script.module.css";
import {StageStatus, WizardStages, WizardStagesNames} from "../../../types/entities/status";
import {useWizardStageStatus} from "../../../hooks/useWizardStageStatus";

interface WizardNavigationStageProps {
    stage: WizardStages
}

const getCircleStyles = (status: StageStatus) => {
    const backgroundColor = status === StageStatus.ACTUAL ?
        'rgba(255, 213, 86, 1)'
        :
        status === StageStatus.PAST ? 'rgba(186, 186, 186, 1)' : 'white'
    const borderColor = status === StageStatus.ACTUAL ? 'rgba(255, 213, 86, 1)' : 'rgba(186, 186, 186, 1)'

    return { backgroundColor, borderColor }
}

const getTextColor = (status: StageStatus) => {
    return status === StageStatus.ACTUAL ?
        'rgba(255, 213, 86, 1)'
        :
        status === StageStatus.PAST ? 'rgba(186, 186, 186, 1)' : 'rgba(50, 50, 50, 1)'
}

const WizardNavigationStage:FC<WizardNavigationStageProps> = (props) => {
    const { stage } = props
    const stageStatus = useWizardStageStatus(stage)

    const circleStyles = getCircleStyles(stageStatus)

    return (
        <>
            <div className={styles.wizardNavigationPoint}>
                <span className={styles.wizardNavigationCircle} style={circleStyles}/>
                <span className={styles.wizardNavigationHeader} style={{color: getTextColor(stageStatus)}}>
                    {WizardStagesNames[stage]}
                </span>
            </div>
            {stage !== WizardStages.THIRD_STEP && <div className={styles.divider} />}
        </>
    );
};

export default WizardNavigationStage;