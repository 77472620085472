import {IThemeList} from "../components/themes";

export enum ThemeListActionTypes {
    SET_THEME_LIST = "SET_THEME_LIST"
}

export interface ISetThemeListAction {
    type: ThemeListActionTypes.SET_THEME_LIST
    payload: IThemeList
}

export type ThemeListAction = ISetThemeListAction