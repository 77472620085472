import React, {useEffect} from 'react';
import Header from "../common/Header/Header";
import {useParams} from "react-router-dom";
// @ts-ignore
import styles from "../../css/admin/Admin.module.css"
import NavPanel from "./NavPanel";
import ScriptPanel from "./wizard/ScriptPanel/ScriptPanel";
import UserPanel from "./user/UserPanel";
import ThemePanel from "./theme/ThemePanel";
import MailListsPanel from "./mailLists/MailListsPanel";
import {getClusters} from "../../http/admin/themeRequests";
import {useDispatch} from "react-redux";
import {ClustersActionTypes} from "../../types/reducers/clusters";

const Admin = () => {
    const panelType = useParams().panel_type
    const dispatch = useDispatch()

    useEffect(() => {
        getClusters().then(data => {
            dispatch({type: ClustersActionTypes.SET_CLUSTERS, payload: data})
        })
    }, [])

    return (
        <>
            <Header />
            <div className={styles.adminPanel}>
                <NavPanel panelType={panelType}/>

                <div className={styles.panel} id="admin-panel">
                    {panelType === "script" && <ScriptPanel />}
                    {panelType === "users" && <UserPanel />}
                    {panelType === "themes" && <ThemePanel />}
                    {panelType === "mail_lists" && <MailListsPanel />}
                </div>
            </div>
        </>
    );
};

export default Admin;