import {$authHost, $host} from "../index";
import {IDashboard, IUser, IUserSubtopics, UserToUpload} from "../../types/admin/http/userRequests";
import {isClientDemo} from "../../utils/demo/demo";
import {getDashboardDataDemo} from "../../utils/demo/mocks";

export const getUsersByCluster = async (id: number): Promise<IUser[]> => {
    const response = await $authHost.get(`admin/user/stats/?cluster_id=${id}`)
    return response.data
}

export const getUserSubtopics = async (id: number): Promise<IUserSubtopics> => {
    const response = await $authHost.get(`admin/user/subtopics/?user_id=${id}`)
    return response.data
}

export const updateUserSubtopics = async (user_id: number, subtopic_ids: number[]): Promise<any> => {
    const response = await $authHost.put(`admin/user/subtopics/?user_id=${user_id}`, subtopic_ids)
    return response.data
}

export const deleteUser = async (id: number): Promise<IUser[]> => {
    const response = await $authHost.delete(`admin/user/?user_id=${id}`)
    return response.data
}

export const exportAllUsers = async (): Promise<any> => {
    const response = await $authHost.get(`admin/export/users/`, {responseType: "blob"})
    return response.data
}

export const getDashboardData = async (id: number): Promise<IDashboard> => {
    const response = isClientDemo() ? getDashboardDataDemo() : await $authHost.get(`/dashboard/?user=${id}`)
    return response.data
}

export const getFeedback = async (id: string | undefined): Promise<any> => {
    const response = await $authHost.get(`dashboard/feedback?user_id=${id}`, {responseType: "blob"})
    return response.data
}

export const createUser = async (user: UserToUpload) => {
    const response = await $authHost.post(`admin/user/create_user/`, user)
    return response.data
}