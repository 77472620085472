import React from 'react';
import InfoBlock from "../../../InfoBlock/InfoBlock";
import QuestionCard from "../../../../editors/expertise/QuestionCard";
// @ts-ignore
import expertiseStyles from "../../../../../css/Editor.module.css";
import CustomRadio from "../../../../common/CustomRadio";

const StepTwo = () => {
    return (
        <>
            <InfoBlock>
                <p>Для каждого задания <b>укажите ответ</b>, который Вы считаете верным.</p>
                <br/>
                <p>Это нужно не для оценки Ваших знаний, а для того, чтобы убедится, что задание имеет однозначный ответ.</p>
            </InfoBlock>
            <div style={{width: 320, margin: '23px auto'}}>
                <QuestionCard>
            <span className={expertiseStyles.exerciseNumber}>
                1
            </span>
                    <div className={expertiseStyles.exercise}>
                        <p className={expertiseStyles.exerciseQuestion}>Сколько будет 2х2?</p>
                        {[1, 2, 3, 4].map(num => {
                            return <div className={expertiseStyles.exerciseOption} key={num}>
                                <CustomRadio isChecked={num === 4}/>
                                <p className={expertiseStyles.exerciseOptionText}>{num}</p>
                            </div>
                        })}
                    </div>
                </QuestionCard>
            </div>
        </>
    );
};

export default StepTwo;