import React, {useState} from 'react';
import Modal from "../../../common/Modal";
// @ts-ignore
import styles from "../../../../../css/admin/UserPanel.module.css";
import AdminDropDown from "../../../common/AdminDropDown";
import {useTypedSelector} from "../../../../../hooks/useTypedSelector";
import CrossIcon from "../../../../icons/admin/CrossIcon";
import ToggleSetting from "./components/ToggleSetting";
import Button from "../../../../common/Button";

const SettingsPanel = (props: {closeModal: () => void}) => {
    const clusters = useTypedSelector(state => state.clusters.clusters)

    const [isChecked, setIsChecked] = useState(false)

    return (
        <Modal>
            <div className={styles.dashboardSettings}>
                <div className={styles.settingsHeader}>
                    <div className={styles.clusterPicker}>
                        <h2>Настройка дашборда участников</h2>
                        <AdminDropDown maxHeight={130} width={200} defaultValue={clusters[0]} optionList={clusters}/>
                    </div>
                    <div onClick={props.closeModal}>
                        <CrossIcon />
                    </div>
                </div>

                <div className={styles.mainSettings}>
                    <div className={styles.firstSettingsBlock}>
                        <div className={styles.settingPart}>
                            <h3>Общий балл</h3>
                            <ToggleSetting isChecked={isChecked} settingName={'Показывать участнику'}/>
                        </div>
                        <div className={styles.settingPart}>
                            <h3>Место в рейтинге авторов заданий</h3>
                            <ToggleSetting isChecked={isChecked} settingName={'Показывать участнику'}/>
                            <div className={styles.formulaBlock}>
                                <p>Строить рейтинг по</p>
                                <AdminDropDown maxHeight={125} width={320} defaultValue={clusters[0]} optionList={clusters}/>
                            </div>
                        </div>
                        <div className={styles.settingPart}>
                            <h3>Место в рейтинге экспертов</h3>
                            <ToggleSetting isChecked={isChecked} settingName={'Показывать участнику'}/>
                            <div className={styles.formulaBlock}>
                                <p>Строить рейтинг по</p>
                                <AdminDropDown maxHeight={125} width={320} defaultValue={clusters[0]} optionList={clusters}/>
                            </div>
                        </div>
                        <div className={styles.settingPart}>
                            <h3>Место в рейтинге знатоков</h3>
                            <ToggleSetting isChecked={isChecked} settingName={'Показывать участнику'}/>
                            <div className={styles.formulaBlock}>
                                <p>Строить рейтинг по</p>
                                <AdminDropDown maxHeight={125} width={320} defaultValue={clusters[0]} optionList={clusters}/>
                            </div>
                        </div>
                    </div>
                    <div className={styles.secondSettingsBlock}>
                        <div className={styles.settingPart}>
                            <h3>Обратная связь</h3>
                            <ToggleSetting isChecked={isChecked} settingName={'Показывать участнику'}/>
                            <br/>
                            <ToggleSetting isChecked={isChecked} settingName={'Возможность скачать обратную связь'}/>
                            <br/>
                            <ToggleSetting isChecked={isChecked} settingName={'Показывать сертификат'}/>
                            <br/>
                            <ToggleSetting isChecked={isChecked} settingName={'Показывать комментарии к заданиям'}/>
                            <br/>
                            <ToggleSetting isChecked={isChecked} settingName={'Показывать коэффицент трудности у каждого задания'}/>
                            <br/>
                            <ToggleSetting isChecked={isChecked} settingName={'Показывать коэффицент дискриминативности у каждого задания'}/>
                            <br/>
                            <ToggleSetting isChecked={isChecked} settingName={'Показывать балл оригинальности у каждого задания'}/>
                            <br/>
                            <ToggleSetting isChecked={isChecked} settingName={'Показывать качества у каждого задания'}/>
                            <br/>
                            <ToggleSetting isChecked={isChecked} settingName={'Показывать число оценивших у каждого задания'}/>
                        </div>
                    </div>
                </div>
                <Button
                    width={"180px"}
                    height={"50px"}
                    variant={"primary"}
                    fontSize={"16px"}
                    margin={"0 0 0 770px"}
                    submit={props.closeModal}
                >
                    Сохранить
                </Button>
            </div>
        </Modal>
    );
};

export default SettingsPanel;