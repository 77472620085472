import {IMouseHintState, MouseHintAction, MouseHintActionTypes} from "../../types/reducers/mouseHint";

const initialState: IMouseHintState = {
    x: 0,
    y: 0,
    hint_text: '',
    isShown: false
}

export const mouseHintReducer = (state=initialState, action: MouseHintAction): IMouseHintState => {
    switch (action.type) {
        case MouseHintActionTypes.SHOW_HINT:
            return action.payload
        case MouseHintActionTypes.HIDE_HINT:
            return initialState
        default:
            return state
    }
}