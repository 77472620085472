import React, {useEffect, useState} from 'react';
// @ts-ignore
import commonStyles from "../../../css/admin/Admin.module.css"
// @ts-ignore
import styles from "../../../css/admin/MailPanel.module.css"
import MailNav from "./MailNav";
import MailList from "./MailList";
import InfoBlock from "./InfoBlock";
import {useParams} from "react-router-dom";
import GreyPlusIcon from "../../icons/admin/GreyPlusIcon";
import MailListEditor from "./MailListEditor";
import {IMailListData} from "../../../types/admin/http/mailListsRequests";
import {getMailListsData, getVariable} from "../../../http/admin/mailListsRequests";
import {useDispatch} from "react-redux";
import {VariablesActionTypes} from "../../../types/reducers/variables";
import Preloader from "../../common/Preloader";
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import {MailEditorActionTypes} from "../../../types/reducers/mailEditor";

const MailListsPanel = () => {
    const mailListGroup = useParams().mail_list_group

    const [isDataLoaded, setIsDataLoaded] = useState(false)
    const isEditorOpen = useTypedSelector(state => state.mailEditor.isEditorOpened)
    const [mailListsData, setMailListsData] = useState<IMailListData>({status: '', deadline: '', mail_list: []})
    const dispatch = useDispatch()

    useEffect(() => {
        if (mailListGroup) {
            setIsDataLoaded(false)
            getMailListsData(mailListGroup).then(data => {
                console.log(data)
                setMailListsData(data)
            }).then(() => {
                getVariable().then(data => {
                    dispatch({type: VariablesActionTypes.SET_VARS, payload: data})
                }).then(() => {
                    setIsDataLoaded(true)
                })
            })
        }
    }, [mailListGroup])

    return (
        <div className={commonStyles.mainPanel}>
            <h1 className={commonStyles.panelHeader}>Рассылки</h1>
            <MailNav />

            {isDataLoaded ? <>
                    {mailListGroup === "custom" && isEditorOpen && <MailListEditor />}

                    {mailListGroup === "custom" && !isEditorOpen && <div
                        className={styles.infoBlock}
                        onClick={() => { dispatch({type: MailEditorActionTypes.OPEN_EDITOR}) }}
                    >
                        <div className={styles.addThemeButton}>
                            <GreyPlusIcon/>
                            <span>Добавить рассылку</span>
                        </div>
                    </div>}

                    {mailListGroup !== "custom" && <InfoBlock date={mailListsData.deadline} status={mailListsData.status}/>}

                    {mailListsData.mail_list.map(mailList => {
                        return <MailList key={mailList.id} mailList={mailList}/>
                    })}
                </>
                :
                <Preloader />
            }
        </div>
    );
};

export default MailListsPanel;