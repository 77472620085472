import React from 'react';

const EyeIcon = () => {
    return (
        <svg className="eye" width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.543 8C13.543 8.79565 13.2269 9.55871 12.6643 10.1213C12.1017 10.6839 11.3386 11 10.543 11C9.74732 11 8.98426 10.6839 8.42165 10.1213C7.85904 9.55871 7.54297 8.79565 7.54297 8C7.54297 7.20435 7.85904 6.44129 8.42165 5.87868C8.98426 5.31607 9.74732 5 10.543 5C11.3386 5 12.1017 5.31607 12.6643 5.87868C13.2269 6.44129 13.543 7.20435 13.543 8V8Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M1 8C2.274 3.943 6.065 1 10.542 1C15.02 1 18.81 3.943 20.084 8C18.81 12.057 15.02 15 10.542 15C6.065 15 2.274 12.057 1 8Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};

export default EyeIcon;