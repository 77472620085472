import React, {useEffect, useState} from 'react';
// @ts-ignore
import styles from '../../../css/PersonalArea.module.css'
import AnotherButton from "../AnotherButton";
import {IModalConfig, ModalActionTypes} from "../../../types/reducers/modal";
import {useDispatch} from "react-redux";
import {clearPickedSubtopics, saveThemes} from "../../../http/playerRequests";
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import {useNavigate} from "react-router-dom";
import {ThemeListActionTypes} from "../../../types/reducers/themeList";
import {IThemeList} from "../../../types/components/themes";
import {ThemeActionTypes} from "../../../types/reducers/theme";

const LogoutModal = (props: {config: IModalConfig}) => {
  const isMobile = useState(document.documentElement.clientWidth <= 900)[0]
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const set = useTypedSelector(state => state.theme)
  const [question, setQuestion] = useState('')
  const [action, setAction] = useState('')
  const [cancel, setCancel] = useState('Назад')
  const [themes, setThemes] = useState<IThemeList>()

  useEffect(() => {
    if (props.config === 'logout') {
      setQuestion("Вы уверены, что хотите выйти?")
      setAction('Выйти')
    } else if (props.config === 'save') {
      setQuestion("Ваш выбор тем будет сохранен.")
      setAction('Подтвердить')
      setCancel('Отмена')
    } else {
      setQuestion("Хотите вернуться и перевыбрать?")
      setAction('Перевыбрать')
      setCancel("Нет")
    }
  }, [])

  const closeModal = () => {
    dispatch({type: ModalActionTypes.SET_MODAL_STATUS, payload: false})
    if (themes) {
      const newSet = new Set(set)

      themes.forEach(theme => {
        theme.values.forEach(subtopic => {
          if (subtopic.is_locked) {
            newSet.delete(subtopic.id)
          }
        })
      })

      dispatch({type: ThemeActionTypes.SET_THEMES, payload: newSet})
      dispatch({type: ThemeListActionTypes.SET_THEME_LIST, payload: themes})
    }
  }

  const logout = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('demo')
    navigate('/')
    window.location.reload()
  }

  const save = () => {
    if (set.size === 0) {
      setQuestion("Вы не выбрали ни одной темы.")
    } else {
      setQuestion('Подождите...')
      saveThemes(Array.from(set)).then((data) => {
        if (data[0]) {
          setQuestion(`Некоторые подтемы уже заняты`)
          setAction('')
          setCancel('Понятно')
          setThemes(data)
        } else {
          navigate('/')
          window.location.reload()
        }
      }).catch(error => error.message)
    }
  }

  const back = () => {
    clearPickedSubtopics().then(() => window.location.reload())
  }

  return (
    <div className={styles.logoutModal}>
      <div className={styles.logoutModalContent}>
        <h2 className={styles.logoutQuestion}>{question}</h2>
        <div className={styles.logoutControls}>
          <AnotherButton width={isMobile ? 85 : 155} height={isMobile ? 48 : 58} onClick={closeModal}>{cancel}</AnotherButton>
          {question !== 'Вы не выбрали ни одной темы.' && action !== '' && <AnotherButton
              width={isMobile ? 130 : 155}
              height={isMobile ? 48 : 58}
              onClick={props.config === 'logout' ? logout : props.config === 'save' ? save : back}>
            {action}
          </AnotherButton>}
        </div>
      </div>
    </div>
  );
};

export default LogoutModal;