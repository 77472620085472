enum UserActionTypes {
    SET_USER = 'SET_USER',
    UNSET_USER = 'UNSET_USER'
}

interface IUser {
    isAuth: boolean | null
    id: number | null
    name: string | null
    role: 1 | 2 | 3
}

interface ISetUserAction {
    type: UserActionTypes.SET_USER
    payload: IUser
}

interface IUnsetUserAction {
    type: UserActionTypes.UNSET_USER
}

type UserAction = ISetUserAction | IUnsetUserAction

export {UserActionTypes}
export type {UserAction, IUser}