import React, { FC, useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import Modal from "../common/Modal";
// @ts-ignore
import styles from "../../../css/admin/UserPanel.module.css";
import { getFields } from "../../../http/authRequests";
import Button from "../../common/Button";
import { createUser } from "../../../http/admin/userRequests";
import { IUserPopupsField, UserToUpload } from "../../../types/admin/http/userRequests";
import HeadingDescriptionComponent from "../wizard/UI/HeadingDescriptionComponent";
import UploadIcon from "../../icons/admin/UploadIcon";

interface UploadUsersModalProps {
    onCancel: () => void;
}

export const UploadUsersModal: FC<UploadUsersModalProps> = ({ onCancel }) => {
    const [headers, setHeaders] = useState<string[]>(["Фамилия", "Имя", "Отчество", "email", "password"]);
    const [fields, setFields] = useState<IUserPopupsField[]>([]);
    const [usersToUpload, setUsersToUpload] = useState<UserToUpload[]>([]);
    const [downloadUrl, setDownloadUrl] = useState<string | null>(null);

    useEffect(() => {
        getFields().then(data => {
            setFields(data)
            generateTemplateFile(data)
        })
    }, []);

    const generateTemplateFile = (fields: IUserPopupsField[]) => {
        const extraHeaders: string[] = []
        fields.forEach(field => {
            extraHeaders.push(field.name + ' (ID)');
        });

        fields.forEach(field => {
            extraHeaders.push(""); // Два пустых столбца для отступа
            extraHeaders.push(`ID поля ${field.name}`, `Значение поля ${field.name}`);
        });

        const allHeaders = [...headers, ...extraHeaders];
        console.log(extraHeaders)
        setHeaders(allHeaders)

        // Подготавливаем строки для значений `id` и `value` каждого поля
        const rows: (string | number)[][] = [];

        console.log(allHeaders)

        fields.forEach(field => {
            field.values.forEach(value => {
                const row = Array(allHeaders.length).fill(""); // Инициализируем строку пустыми значениями
                // Найдем индексы для текущего поля и заполним их
                const idIndex = allHeaders.indexOf(`ID поля ${field.name}`);
                const valueIndex = allHeaders.indexOf(`Значение поля ${field.name}`);
                row[idIndex] = value.id;
                row[valueIndex] = value.value;
                rows.push(row);
            });
        });

        // Создаем рабочий лист и добавляем данные
        const worksheet = XLSX.utils.aoa_to_sheet([allHeaders, ...rows]);

        // Устанавливаем стиль для всех ячеек, чтобы включить перенос текста
        const wrapTextStyle = {
            alignment: {
                wrapText: true, // Включаем перенос текста
            },
        };

        // Применяем стиль ко всем ячейкам
        for (let rowIndex = 0; rowIndex <= rows.length; rowIndex++) {
            for (let colIndex = 0; colIndex < allHeaders.length; colIndex++) {
                const cellAddress = XLSX.utils.encode_cell({ r: rowIndex, c: colIndex });
                if (!worksheet[cellAddress]) {
                    worksheet[cellAddress] = {}; // Если ячейка не существует, создаем ее
                }
                worksheet[cellAddress].s = { ...wrapTextStyle }; // Применяем стиль
            }
        }

        // Настройка ширины столбцов
        worksheet["!cols"] = allHeaders.map(header => ({ width: Math.max(header.length * 1.2, 10) })); // Устанавливаем ширину столбцов

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Template");

        // Генерация Blob для скачивания
        const workbookBlob = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
        const blob = new Blob([workbookBlob], { type: "application/octet-stream" });
        setDownloadUrl(URL.createObjectURL(blob));
    };

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (!file) return;

        const data = await file.arrayBuffer(); // Читаем файл как ArrayBuffer
        const workbook = XLSX.read(data); // Читаем книгу
        const sheetName = workbook.SheetNames[0]; // Предполагаем, что мы берем первый лист
        const worksheet = workbook.Sheets[sheetName]; // Получаем лист
        const jsonData: string[][] = XLSX.utils.sheet_to_json(worksheet, { header: 1 }); // Конвертируем в JSON

        const newUsers: UserToUpload[] = jsonData.slice(1).map(row => {
            const user: UserToUpload = {
                first_name: row[1],
                second_name: row[2],
                last_name: row[0],
                email: row[3],
                password: row[4],
                popup_ids: row.slice(5, 5 + fields.length).map(element => Number(element))
            };
            return user;
        });

        setUsersToUpload(newUsers); // Заполняем состояние usersToUpload
    };

    const submitHandler = async () => {
        try {
            const createUserRequests = usersToUpload.map(user => createUser(user));
            await Promise.all(createUserRequests);
            onCancel()
        } catch (error) {
            console.error("Error creating users:", error);
        }
    };

    return (
        <Modal>
            <div className={styles.uploadUsersModal}>
                {downloadUrl && (
                    <div className={styles.uploadHeading}>
                        <HeadingDescriptionComponent heading="Загрузка пользователей">
                            Вы можете <a href={downloadUrl} download="UserUploadTemplate.xlsx">скачать шаблон для загрузки пользователей</a>, заполнить его и загрузить
                            обратно в систему. Далее вы сможете отсмотреть корректность данных в таблице, которая появится после того, как вы загрузите заполненный шаблон.
                        </HeadingDescriptionComponent>
                        <div className={styles.uploadButtonContainer}>
                            <div className={styles.inputWrapper}>
                                <input
                                    name="file"
                                    type="file"
                                    id="input__file"
                                    className={styles.inputFile}
                                    accept=".xls, .xlsx, .csv"
                                    onChange={handleFileChange} // Добавляем обработчик изменения файла
                                />
                                <label htmlFor="input__file" className={styles.inputFileButton}>
                                    <span className={styles.inputIconWrapper}>
                                        <UploadIcon />
                                    </span>
                                    <span className={styles.inputFileButtonText}>Выберите файл</span>
                                </label>
                            </div>
                        </div>
                    </div>
                )}
                {usersToUpload.length > 0 && (
                    <div>
                        <h3>Загруженные пользователи:</h3>
                        <table>
                            <thead>
                            <tr>
                                <th>Имя</th>
                                <th>Фамилия</th>
                                <th>Отчество</th>
                                <th>Email</th>
                                <th>Пароль</th>
                                {fields.map(field => (
                                    <React.Fragment key={field.name}>
                                        <th>{field.name} (ID)</th>
                                        <th>{field.name} (Значение)</th>
                                    </React.Fragment>
                                ))}
                            </tr>
                            </thead>
                            <tbody>
                            {usersToUpload.map((user, index) => (
                                <tr key={index}>
                                    <td>{user.first_name}</td>
                                    <td>{user.second_name}</td>
                                    <td>{user.last_name}</td>
                                    <td>{user.email}</td>
                                    <td>{user.password}</td>
                                    {fields.map(field => {
                                        const index = headers.indexOf(field.name + ' (ID)');
                                        return (
                                            <React.Fragment key={field.name}>
                                                <td>{user.popup_ids[index] || ''}</td>
                                                <td>{user.popup_ids[index] ? field.values.find(v => v.id === user.popup_ids[index])?.value : ''}</td>
                                            </React.Fragment>
                                        );
                                    })}
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                )}
                <div className={styles.uploadUsersButtons}>
                    <Button width="100px" height="40px" fontSize="16px" variant="primary" submit={onCancel}>
                        Отмена
                    </Button>
                    {usersToUpload.length !== 0 && (
                        <Button width="150px" height="40px" fontSize="16px" variant="primary" submit={submitHandler}>
                            Подтвердить
                        </Button>
                    )}
                </div>
            </div>
        </Modal>
    );
};
