import {IThemeList} from "../../../types/components/themes";

export const filterPickedThemes = (themes: IThemeList, set: Set<number>) => {
    let pickedThemes: IThemeList = JSON.parse(JSON.stringify(themes))

    pickedThemes.forEach((theme, i) => {
        pickedThemes[i].values = theme.values.filter(subtopic => {
            return set.has(subtopic.id)
        })
    })

    return pickedThemes.filter(theme => {
        return theme.values.length !== 0
    })
}