import {IExercise, IExerciseErrors} from "../entities/exercise";

export enum ExerciseActionTypes {
    SET_EXERCISE = 'SET_EXERCISE',
    SET_ANSWERS = 'SET_ANSWERS',
    SET_ACTUALITY_ASSESSMENT = 'SET_ACTUALITY_ASSESSMENT',
    SET_QUALITY_ASSESSMENT = 'SET_QUALITY_ASSESSMENT',
    SET_COMMENT = 'SET_COMMENT',
    SET_ERRORS = 'SET_ERRORS'
}

interface ISetExerciseAction {
    type: ExerciseActionTypes.SET_EXERCISE
    payload: IExercise
}

interface ISetAnswersAction {
    type: ExerciseActionTypes.SET_ANSWERS
    payload: string
}

interface ISetActualityAssessment {
    type: ExerciseActionTypes.SET_ACTUALITY_ASSESSMENT
    payload: number | null
}

interface ISetQualityAssessment {
    type: ExerciseActionTypes.SET_QUALITY_ASSESSMENT
    payload: number | null
}

interface ISetCommentAssessment {
    type: ExerciseActionTypes.SET_COMMENT
    payload: string
}

interface ISetErrorsAction {
    type: ExerciseActionTypes.SET_ERRORS
    payload: IExerciseErrors
}

export type ExerciseAction =
    ISetExerciseAction |
    ISetAnswersAction |
    ISetActualityAssessment |
    ISetQualityAssessment |
    ISetCommentAssessment |
    ISetErrorsAction