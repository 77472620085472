import React, {useState} from 'react';
import Modal from "../common/Modal";
// @ts-ignore
import styles from "../../../css/admin/MailPanel.module.css";
import TextEditor from "./TextEditor";
import Button from "../../common/Button";
import ConfirmModal from "../common/ConfirmModal";
import {editCommonMailList} from "../../../http/admin/mailListsRequests";
import {useNavigate} from "react-router-dom";
import {reloadMailLists} from "../../../utils/reloadMailLists";

const ModalEditor = (props: {name: string, text: string, id: number, closeModal: () => void}) => {
    const navigate = useNavigate()
    const [text, setText] = useState(props.text)
    const [name, setName] = useState(props.name)
    const [isConfirmModalOpened, setIsConfirmModalOpened] = useState(false)
    const [alertText, setAlertText] = useState("Уверены, что хотите отредактировать рассылку?")

    const closeConfirmModal = () => {
        setIsConfirmModalOpened(false)
    }

    const submitEdit = () => {
        if (alertText !== 'Подождите...') {
            setAlertText(' Подождите...')
            editCommonMailList(props.id, name, text).then(() => {
                setAlertText('Уверены, что хотите отредактировать рассылку?')
                setIsConfirmModalOpened(false)
                props.closeModal()
                reloadMailLists(navigate)
            })
        }
    }

    return (
        <Modal>
            <div className={styles.mailList} style={{overflow: 'visible'}}>
                {isConfirmModalOpened && <ConfirmModal
                    submit={() => submitEdit()}
                    closeModal={closeConfirmModal}
                    text={alertText}
                />}

                <div className={styles.mailListEditorHeader}>
                    <input
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        type="text" className={styles.mailListNameInput}
                        placeholder={"Название рассылки"}
                    />
                    <span className={styles.hideEditorButton} onClick={() => props.closeModal()}>Скрыть</span>
                </div>
                <TextEditor setText={setText} text={text} isForEdit={true}/>
                <Button
                    width={"170px"}
                    height={"45px"}
                    fontSize={"16px"}
                    variant={"primary"}
                    margin={"20px 0 0 975px"}
                    submit={() => setIsConfirmModalOpened(true)}
                >
                    Изменить
                </Button>
            </div>
        </Modal>
    );
};

export default ModalEditor;