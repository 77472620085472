enum ThemeActionTypes {
    ADD_THEME = 'ADD_THEME',
    DELETE_THEME = 'DELETE_THEME',
    SET_THEMES = 'SET_THEMES'
}

type IThemes = Set<number>

interface IAddThemeAction {
    type: ThemeActionTypes.ADD_THEME
    payload: number
}

interface ISetThemesAction {
    type: ThemeActionTypes.SET_THEMES
    payload: IThemes
}

interface IDeleteThemeAction {
    type: ThemeActionTypes.DELETE_THEME
    payload: number
}

type ThemeAction = IAddThemeAction | IDeleteThemeAction | ISetThemesAction

export {ThemeActionTypes}
export type {ThemeAction, IThemes}