enum MailEditorActionTypes {
    SET_MAIL_LIST_NAME = 'SET_MAIL_LIST_NAME',
    SET_TEXT = 'SET_TEXT',
    SET_DATE = 'SET_DATE',
    SET_DEFAULT_DATA = 'SET_DEFAULT_DATA',
    TOGGLE_IS_NOW_CHECKED = 'TOGGLE_IS_NOW_CHECKED',
    SET_FULL_MAIL_LIST = 'SET_FUL_MAIL_LIST',
    CLOSE_EDITOR = 'CLOSE_EDITOR',
    OPEN_EDITOR = 'OPEN_EDITOR'
}

interface IMailEditorData {
    id?: number
    mailListName: string
    text: string
    date: string
    isNowChecked: boolean
    isEditorOpened: boolean
}

interface ICloseEditorAction {
    type: MailEditorActionTypes.CLOSE_EDITOR
}

interface IOpenEditorAction {
    type: MailEditorActionTypes.OPEN_EDITOR
}

interface ISetFullMailListAction {
    type: MailEditorActionTypes.SET_FULL_MAIL_LIST
    payload: IMailEditorData
}

interface ISetMailListNameAction {
    type: MailEditorActionTypes.SET_MAIL_LIST_NAME
    payload: string
}

interface ISetTextAction {
    type: MailEditorActionTypes.SET_TEXT
    payload: string
}

interface ISetDateAction {
    type: MailEditorActionTypes.SET_DATE
    payload: string
}

interface IToggleIsNowCheckedAction {
    type: MailEditorActionTypes.TOGGLE_IS_NOW_CHECKED
}

interface ISetDefaultDataAction {
    type: MailEditorActionTypes.SET_DEFAULT_DATA
}

type MailEditorAction =
  ISetDateAction |
  ISetTextAction |
  ISetMailListNameAction |
  IToggleIsNowCheckedAction |
  ISetDefaultDataAction |
  ISetFullMailListAction |
  ICloseEditorAction |
  IOpenEditorAction

export {MailEditorActionTypes}
export type {MailEditorAction, IMailEditorData}