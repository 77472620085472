import React, {useEffect, useState} from 'react';
// @ts-ignore
import styles from "../../../../css/admin/Script.module.css";
import AlertModal from "../../common/AlertModal";
import {WizardStages, WizardStagesNames} from "../../../../types/entities/status";
import IconButton from "../UI/IconButton";
import Button from "../../../common/Button";
import WizardInput from "../UI/WizardInput";
import CustomRadio from "../../../common/CustomRadio";
import {useTypedSelector} from "../../../../hooks/useTypedSelector";
import {useDispatch} from "react-redux";
import {Register, WizardActionTypes} from "../../../../types/reducers/wizard";
import {
    getRegistrationFieldsData,
    setRegistrationFieldsData
} from "../../../../http/admin/wizardRequest";
import {StatusActionTypes} from "../../../../types/reducers/status";
import {useNavigate} from "react-router-dom";

const errorHandler = (register: Register) => {
    let isSomethingChecked = 'Не выбрано ни одного поля для разбиения на треки!'
    for (let i = 0; i < register.length; i++) {
        if (register[i].isChecked) isSomethingChecked = ''
        if (register[i].name === '' || register[i].subfields === '') return 'Не все поля допустимых доменов заполнены!'
    }
    return isSomethingChecked
}

const RegistrationComponent = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const isWizardConfigured = useTypedSelector(state => state.status.wizard_completed)
    const registerFields = useTypedSelector(state => state.wizard.register)
    const [buttonText, setButtonText] = useState('Сохранить')
    const [errorText, setErrorText] = useState('')
    const [isModalErrorOpened, setIsModalErrorOpened] = useState(false)

    useEffect(() => {
        if (isWizardConfigured) {
            getRegistrationFieldsData().then(data => {
                dispatch({ type: WizardActionTypes.SET_REGISTER_FIELDS, payload: data })
            })
        }
    }, [])

    const setRegisterFieldName = (e: any, index: number) => {
        dispatch({
            type: WizardActionTypes.SET_REGISTER_FIELD_NAME_BY_INDEX,
            payload: {
                index,
                name: e.target.value,
            }
        })
    }

    const setRegisterSubfields = (e: any, index: number) => {
        dispatch({
            type: WizardActionTypes.SET_REGISTER_SUBFIELDS_BY_INDEX,
            payload: {
                index,
                subfields: e.target.value,
            }
        })
    }

    const checkRegisterField = (index: number) => {
        dispatch({
            type: WizardActionTypes.CHECK_REGISTER_FIELD,
            payload: index
        })
    }

    const removeRegisterField = () => {
        dispatch({ type: WizardActionTypes.REMOVE_REGISTER_FIELD })
    }

    const addRegisterField = () => {
        dispatch({ type: WizardActionTypes.ADD_REGISTER_FIELD })
    }

    const submit = () => {
        if (buttonText !== 'Подождите...') {
            const error = errorHandler(registerFields)

            if (error) {
                setErrorText(error)
                setIsModalErrorOpened(true)
            } else {
                setButtonText('Подождите...')
                setRegistrationFieldsData(registerFields).then(() => {
                    if (isWizardConfigured) {
                        navigate('/admin/script')
                    } else {
                        dispatch({ type: StatusActionTypes.SET_NEXT_WIZARD_STAGE })
                    }
                })
            }
        }
    }

    return (
        <div className={styles.wizardStageComponent}>
            {isModalErrorOpened && <AlertModal closeModal={() => setIsModalErrorOpened(false)} text={errorText}/>}
            <h2 className={styles.wizardStageHeader}>{WizardStagesNames[WizardStages.REGISTER]}</h2>
            <div className={styles.registerStageContent}>
                <span className={styles.registerComponentInputLabel}>
                    Укажите поля, которые участнику предстоит заполнить при регистрации.
                    “Имя”, “Фамилия”, “Отчество” указывать не надо, они предусмотрены автоматически.
                </span>
                {registerFields.map((registerField, index) => {
                    return <div className={styles.registerFieldSubform} key={index}>
                        <div className={styles.registerFieldHeading}>
                            <h3 className={styles.registerFieldHeader}>Название</h3>
                            <WizardInput
                                onChange={(e) => setRegisterFieldName(e, index)}
                                placeholder="Название поля"
                                value={registerField.name}
                                width={770}
                            />
                        </div>
                        <div className={styles.registerAnswerType}>
                            <h3 className={styles.registerFieldHeader}>Тип ответа</h3>
                            <p>Выпадающий список</p>
                        </div>
                        <WizardInput
                            onChange={(e) => setRegisterSubfields(e, index)}
                            placeholder="Введите в столбик, каждый вариант в новой строке..."
                            value={registerField.subfields}
                            height={200}
                            label="Варианты заполнения"
                            isTextarea={true}
                        />
                        <div className={styles.registerCheckbox} onClick={() => checkRegisterField(index)}>
                            <CustomRadio isChecked={registerField.isChecked}/>
                            <p>Разделить на треки по данному полю</p>
                        </div>
                    </div>
                })}
                <div className={styles.registerButtons}>
                    <IconButton onClick={removeRegisterField} state="minus" label="Убрать поле"/>
                    <IconButton onClick={addRegisterField} state="add" label="Добавить поле"/>
                </div>
            </div>
            <Button
                width={"150px"}
                height={"45px"}
                fontSize={"16px"}
                variant={"primary"}
                margin="50px 0 0 770px"
                submit={submit}
            >
                {buttonText}
            </Button>
        </div>
    );
};

export default RegistrationComponent;