import React from 'react';

const HighlightLogo = () => {
    return (
        <svg width="91" height="22" viewBox="0 0 91 22" fill="none" xmlns="http://www.w3.org/2000/svg" className="logo">
            <path d="M10.7313 9.56052V16.4207H7.3768V10.0527C7.40513 9.7864 7.37502 9.51711 7.28865 9.26361C7.20229 9.01011 7.06167 8.77848 6.87664 8.58486C6.69162 8.39124 6.46663 8.24028 6.21731 8.1425C5.96798 8.04472 5.70037 8.00247 5.43304 8.01869C4.20348 8.01869 3.35445 8.73349 3.35445 10.3205V16.4195H0V0.777344H3.35445V6.29634C3.74165 5.83288 4.23318 5.46772 4.78867 5.23079C5.34417 4.99385 5.94783 4.89187 6.55033 4.9332C8.80879 4.93442 10.7313 6.54395 10.7313 9.56052Z" fill="#323232"/>
            <path d="M12.6973 2.25095C12.6973 1.85288 12.8153 1.46375 13.0365 1.13277C13.2576 0.801789 13.572 0.543823 13.9398 0.391488C14.3075 0.239154 14.7122 0.199296 15.1026 0.276955C15.493 0.354614 15.8517 0.546302 16.1332 0.827779C16.4146 1.10926 16.6063 1.46788 16.684 1.8583C16.7616 2.24872 16.7218 2.6534 16.5694 3.02117C16.4171 3.38894 16.1591 3.70327 15.8281 3.92443C15.4972 4.14558 15.108 4.26363 14.71 4.26363C14.1777 4.25869 13.6686 4.04506 13.2922 3.66868C12.9158 3.29229 12.7022 2.78322 12.6973 2.25095ZM13.0321 5.24618H16.3866V16.4208H13.0321V5.24618Z" fill="#323232"/>
            <path d="M30.3089 5.24832V15.8642C30.3089 19.6194 27.3813 21.2057 24.4087 21.2057C22.0844 21.2057 20.1394 20.3342 19.1117 18.5015L21.9721 16.8474C22.2029 17.3137 22.5705 17.6985 23.0259 17.9501C23.4813 18.2018 24.0027 18.3084 24.5203 18.2557C26.0847 18.2557 27.0459 17.4512 27.0459 15.8642V14.8359C26.6532 15.316 26.1536 15.6975 25.5869 15.9496C25.0203 16.2018 24.4024 16.3177 23.7829 16.2881C22.3095 16.2398 20.9126 15.6206 19.8874 14.5612C18.8622 13.5018 18.2891 12.0854 18.2891 10.6112C18.2891 9.13696 18.8622 7.7205 19.8874 6.66114C20.9126 5.60178 22.3095 4.98251 23.7829 4.93421C24.4025 4.90461 25.0204 5.02062 25.587 5.27291C26.1537 5.5252 26.6533 5.90676 27.0459 6.38699V5.24832H30.3089ZM27.0459 10.6124C27.0688 10.0726 26.9297 9.5384 26.6464 9.0784C26.3631 8.6184 25.9486 8.25369 25.4564 8.0312C24.9641 7.80871 24.4165 7.73862 23.884 7.82994C23.3516 7.92127 22.8587 8.16983 22.4687 8.54366C22.0786 8.91748 21.8094 9.39945 21.6956 9.92756C21.5818 10.4557 21.6287 11.0057 21.8301 11.507C22.0315 12.0083 22.3784 12.4378 22.8259 12.7403C23.2735 13.0428 23.8014 13.2045 24.3416 13.2045C24.6937 13.2291 25.047 13.1798 25.3789 13.0597C25.7107 12.9397 26.0138 12.7515 26.2686 12.5073C26.5234 12.2632 26.7243 11.9684 26.8584 11.6419C26.9925 11.3155 27.0568 10.9646 27.0472 10.6118L27.0459 10.6124Z" fill="#323232"/>
            <path d="M43.4916 9.56052V16.4207H40.1372V10.0527C40.1654 9.78637 40.1352 9.51707 40.0488 9.26357C39.9623 9.01007 39.8216 8.77846 39.6366 8.58485C39.4515 8.39124 39.2265 8.24029 38.9771 8.14252C38.7278 8.04474 38.4601 8.00249 38.1927 8.01869C36.9632 8.01869 36.1142 8.73349 36.1142 10.3205V16.4195H32.7598V0.777344H36.1142V6.29634C36.5014 5.83284 36.9929 5.46766 37.5484 5.23072C38.1039 4.99378 38.7076 4.89183 39.3101 4.9332C41.5673 4.93442 43.4916 6.54395 43.4916 9.56052Z" fill="#323232"/>
            <path d="M45.7939 0.107422H49.1484V16.4229H45.7939V0.107422Z" fill="#323232"/>
            <path d="M51.2686 2.25095C51.2686 1.85288 51.3866 1.46375 51.6078 1.13277C51.8289 0.801789 52.1433 0.543823 52.5111 0.391488C52.8788 0.239154 53.2835 0.199296 53.6739 0.276955C54.0643 0.354614 54.423 0.546302 54.7045 0.827779C54.9859 1.10926 55.1776 1.46788 55.2552 1.8583C55.3329 2.24872 55.2931 2.6534 55.1407 3.02117C54.9884 3.38894 54.7304 3.70327 54.3994 3.92443C54.0685 4.14558 53.6793 4.26363 53.2813 4.26363C52.749 4.25854 52.2401 4.04486 51.8637 3.6685C51.4874 3.29215 51.2736 2.78317 51.2686 2.25095ZM51.604 5.24618H54.9585V16.4208H51.604V5.24618Z" fill="#323232"/>
            <path d="M68.8818 5.24833V15.8643C68.8818 19.6194 65.9543 21.2058 62.9816 21.2058C60.6573 21.2058 58.7123 20.3342 57.6846 18.5015L60.5457 16.8474C60.7763 17.3137 61.1438 17.6985 61.5991 17.9502C62.0544 18.2019 62.5757 18.3085 63.0932 18.2557C64.657 18.2557 65.6188 17.4512 65.6188 15.8643V14.836C65.2261 15.3161 64.7263 15.6975 64.1595 15.9497C63.5928 16.2019 62.9749 16.3178 62.3553 16.2881C60.8819 16.2398 59.4849 15.6206 58.4597 14.5612C57.4345 13.5018 56.8613 12.0854 56.8613 10.6112C56.8613 9.13698 57.4345 7.72052 58.4597 6.66115C59.4849 5.60179 60.8819 4.98252 62.3553 4.93422C62.9749 4.90458 63.5929 5.02056 64.1597 5.27285C64.7264 5.52514 65.2262 5.90673 65.6188 6.38701V5.24833H68.8818ZM65.6188 10.6124C65.6417 10.0727 65.5025 9.53841 65.2192 9.07841C64.936 8.61841 64.5216 8.25371 64.0293 8.03122C63.537 7.80873 62.9893 7.73863 62.4569 7.82996C61.9244 7.92128 61.4315 8.16985 61.0415 8.54367C60.6515 8.91749 60.3823 9.39946 60.2685 9.92757C60.1547 10.4557 60.2015 11.0058 60.403 11.507C60.6044 12.0083 60.9512 12.4378 61.3988 12.7403C61.8464 13.0428 62.3743 13.2045 62.9145 13.2045C63.2664 13.2288 63.6194 13.1792 63.951 13.059C64.2825 12.9388 64.5854 12.7505 64.8399 12.5064C65.0945 12.2623 65.2951 11.9676 65.429 11.6413C65.563 11.315 65.6272 10.9644 65.6176 10.6118L65.6188 10.6124Z" fill="#323232"/>
            <path d="M82.0619 9.56052V16.4207H78.7075V10.0527C78.7357 9.78637 78.7055 9.51707 78.6191 9.26357C78.5327 9.01007 78.392 8.77846 78.2069 8.58485C78.0219 8.39124 77.7968 8.24029 77.5475 8.14252C77.2981 8.04474 77.0305 8.00249 76.7631 8.01869C75.5336 8.01869 74.6845 8.73349 74.6845 10.3205V16.4195H71.3301V0.777344H74.6845V6.29634C75.0717 5.8328 75.5632 5.46759 76.1187 5.23065C76.6742 4.99371 77.2779 4.89178 77.8804 4.9332C80.1425 4.93442 82.0619 6.54395 82.0619 9.56052Z" fill="#323232"/>
            <path d="M88.6119 8.46589V12.4217C88.6119 13.3829 89.4389 13.472 90.9143 13.3829V16.4227C86.5334 16.8697 85.2593 15.5511 85.2593 12.4223V8.4665H83.4717V5.24989H85.2593V3.12682L88.6138 2.12109V5.24989H90.9162V8.46772L88.6119 8.46589Z" fill="#323232"/>
        </svg>

    );
};

export default HighlightLogo;