import React from 'react';
import StepFourSVG from "./vectorImages/StepFourSVG";

const DesktopStepFour = () => {
    return (
        <>
            <p>
                По возможности, <b>поясните свою оценку в письменном комментарии</b>.
            </p>

            <br/>

            <p>
                Например, если вы обнаружили опечатку, то укажите, в каком слове она допущена
            </p>

            <div style={{margin: "50px auto", width: 540}}>
                <StepFourSVG />
            </div>
        </>
    );
};

export default DesktopStepFour;