export const timestempToDate = (date: string, isShort: boolean = false): string => {
    const year = date.slice(0, 4)
    const monthNumber = date.slice(5, 7)
    const day = Number(date.slice(8, 10))

    if (isShort)
        return day + '.' + monthNumber + '.' + year

    let month: string
    switch (monthNumber) {
        case '01':
            month = 'января'
            break
        case '02':
            month = 'февраля'
            break
        case '03':
            month = 'марта'
            break
        case '04':
            month = 'апреля'
            break
        case '05':
            month = 'мая'
            break
        case '06':
            month = 'июня'
            break
        case '07':
            month = 'июля'
            break
        case '08':
            month = 'августа'
            break
        case '09':
            month = 'сентября'
            break
        case '10':
            month = 'октября'
            break
        case '11':
            month = 'ноября'
            break
        case '12':
            month = 'декабря'
            break
        default:
            month = 'января'
    }

    return day + ' ' + month + ' ' + year + ' года'
}

export const isCustomMailSent = (date: string | undefined, isCustom = true) => {
    if (date) {
        const day = +date.slice(0, 2)
        const month = +date.slice(3, 5)
        const year = +date.slice(8, 10)

        const currentDate = new Date()

        const currentDay = currentDate.getDate()
        const currentMonth = currentDate.getUTCMonth() + 1
        const currentYear = +String(currentDate.getFullYear()).slice(2, 5)

        if (year < currentYear) {
            return true
        } else if (year > currentYear) {
            return false
        } else {
            if (month < currentMonth) {
                return true
            } else if (month > currentMonth) {
                return false
            } else {
                return day <= currentDay;
            }
        }
    }

    return isCustom
}