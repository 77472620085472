import React, {useEffect, useState} from 'react';
// @ts-ignore
import styles from "../../css/Player.module.css"
import Button from "../common/Button";
import {IThemeList} from "../../types/components/themes";
import {useTypedSelector} from "../../hooks/useTypedSelector";
import {filterPickedThemes} from "./themeList/filterFunction";
import {useDispatch} from "react-redux";
import {ModalActionTypes} from "../../types/reducers/modal";
import {getPickedThemes} from "../../http/playerRequests";

const ThemeBlock = (props: {themes: IThemeList, isForWaiting: boolean}) => {
    const isMobile = useState(document.documentElement.clientWidth <= 900)[0]
    const set = useTypedSelector(state => state.theme)

    const dispatch = useDispatch()

    const [pickedThemes, setPickedThemes] = useState([
        {
            "name": "Заголовок",
            "values": [
                {
                    "value": "Первая тема",
                    "id": 1,
                    "subtopic_name_id": 1
                },
                {
                    "value": "Вторая тема",
                    "id": 2,
                    "subtopic_name_id": 1
                },
                {
                    "value": "Третья тема",
                    "id": 3,
                    "subtopic_name_id": 1
                }
            ]
        },
    ])

    useEffect(() => {
        if (props.isForWaiting) {
            getPickedThemes().then(data => {
                setPickedThemes(data)
            })
        } else {
            setPickedThemes(filterPickedThemes(props.themes, set))
        }
    }, [set, props.themes])

    const saveTopics = () => {
        if (props.isForWaiting) {
            dispatch({type: ModalActionTypes.SET_MODAL_STATUS, payload: 'await'})
        } else {
            dispatch({type: ModalActionTypes.SET_MODAL_STATUS, payload: 'save'})
        }
    }

    return (
        <div className={styles.themeBlockContainer} style={{display: (!isMobile || props.isForWaiting) ? 'block' : 'none'}}>
            {pickedThemes.length !== 0 && <div className={styles.themeBlock}>
                <h3 className={styles.themeBlockHeader}>Вы выбрали:</h3>
                <ul className={styles.themeBlockList}>
                    {pickedThemes.map((theme) => {
                        return <li key={theme.name} className={styles.themeBlockTopic}>
                            <h4 className={styles.subtopicBlockHeader}>{theme.name}</h4>
                            <ul className={styles.subtopicBlockList}>
                                {theme.values.map((subtopic) => {
                                    return <li key={subtopic.id} className={styles.themeBlockSubtopic}>{subtopic.value}</li>
                                })}
                            </ul>
                        </li>
                    })}
                </ul>
                <div className={styles.themeBlockButtonContainer}>
                    <Button width="6vw" height="2.2vw" fontSize="0.9vw" variant="secondary" submit={saveTopics}>
                        {props.isForWaiting ? "Изменить" : "Сохранить"}
                    </Button>
                </div>
            </div>}
        </div>
    );
};

export default ThemeBlock;