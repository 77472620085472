import React, {useEffect, useRef, useState} from 'react';
// @ts-ignore
import styles from "../../../css/Player.module.css";
import ArrowIcon from "../../icons/ArrowIcon";
import Subtopic from "./Subtopic";
import {ITheme, IThemeList, IThemeListVariant} from "../../../types/components/themes";
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import {filterPickedThemes} from "./filterFunction";

const Theme = (props: {themeListVariant: IThemeListVariant, theme: ITheme, themes: IThemeList}) => {
    const set = useTypedSelector(state => state.theme)
    const [chosenCounter, setChosenCounter] = useState(0)
    const [isHidden, setIsHidden] = useState(false)
    const [blockHeight, setBlockHeight] = useState('auto')
    const topicsDiv = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const filteredThemes = filterPickedThemes(props.themes, set)
        if (filteredThemes.length === 0) {
            setChosenCounter(0)
        } else {
            for (let i = 0; i < filteredThemes.length; i++) {
                if (filteredThemes[i].name === props.theme.name) {
                    setChosenCounter(filteredThemes[i].values.length)
                    break
                }
                setChosenCounter(0)
            }
        }
    }, [set])

    return (
        <div className={styles.theme}>
            <div className={styles.themeHeading} onClick={() => {
                if (blockHeight === "auto") {
                    setBlockHeight(topicsDiv.current?.offsetHeight.toString() + 'px')
                    setIsHidden(!isHidden)
                }
                setIsHidden(!isHidden)
            }}>
                <ArrowIcon isOpened={!isHidden}/>
                <h2 className={styles.themeHeader}>{props.theme.name}</h2>
                {props.themeListVariant === "chooseThemes" && chosenCounter !== 0 && <div className={styles.themeCounter}>{chosenCounter}</div>}
            </div>
            <div ref={topicsDiv} className={styles.topics} style={{height: isHidden ? "0" : blockHeight}}>
                {props.theme.values.map(subtopic => {
                    return <Subtopic key={subtopic.id} themeListVariant={props.themeListVariant} subtopic={subtopic}/>
                })}
            </div>
        </div>
    );
};

export default Theme;