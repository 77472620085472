export function isValidDate(date: string) {
    if (date === 'now')
        return true

    let [day, month, year] = date.split('.')

    let D = Number(day)
    let M = Number(month)
    let Y = Number(year)

    let d = new Date(Y, --M, D);
    return Y === d.getFullYear() && M === d.getMonth() && D === d.getDate();
}