import React, {useEffect, useState} from 'react';
// @ts-ignore
import styles from "../../css/Player.module.css";
import InfoBlock from "./InfoBlock/InfoBlock";
import ThemeBlock from "./ThemeBlock";
import ThemeList from "./themeList/ThemeList";
import {getPickedThemes, getThemes} from "../../http/playerRequests";
import StepNav from "./StepNav";
import Header from "../common/Header/Header";
import Footer from "../common/Footer/Footer";
import {useDispatch} from "react-redux";
import {ThemeActionTypes} from "../../types/reducers/theme";
import {useTypedSelector} from "../../hooks/useTypedSelector";
import {ITheme} from "../../types/components/themes";
import {ThemeListActionTypes} from "../../types/reducers/themeList";
import {getTexts} from "../../http/admin/wizardRequest";
import {WizardStages} from "../../types/entities/status";

const FirstStepForm = () => {
    const dispatch = useDispatch()
    const themes = useTypedSelector(state => state.themeList)
    const [text, setText] = useState('Загрузка...')

    useEffect(() => {
        getTexts('first_stage_instruction').then(data => {
            // @ts-ignore
            setText(data)
        })
        getPickedThemes().then(data => {
            let ids: number[] = []
            data.forEach((topic: ITheme) => {
                topic.values.forEach(subtopic => {
                    ids.push(subtopic.id)
                })
            })
            dispatch({type: ThemeActionTypes.SET_THEMES, payload: new Set(ids)})
        })
        getThemes().then(data => {
            dispatch({type: ThemeListActionTypes.SET_THEME_LIST, payload: data})
        })
    }, [])

    return (
        <>
            <Header />
            <div className={styles.playerWindow}>
                <StepNav steps={3} currentStep={1}/>
                <h1 className={styles.stepHeader}>1 этап</h1>
                <InfoBlock>
                    {text}
                </InfoBlock>
                <ThemeList themeListVariant="chooseThemes" themes={themes}/>
                <ThemeBlock themes={themes} isForWaiting={false}/>
            </div>
            <Footer />
        </>
    );
};

export default FirstStepForm;