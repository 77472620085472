import React, {useState} from 'react';
import Modal from "../common/Modal";
// @ts-ignore
import styles from "../../../css/admin/ThemePanel.module.css"
import Button from "../../common/Button";
import CrossIcon from "../../icons/admin/CrossIcon";
import AdminDropDown from "../common/AdminDropDown";
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import {ITopic} from "../../../types/admin/http/userRequests";
import {ICluster} from "../../../types/admin/http/themeRequests";
import {addSubtopic} from "../../../http/admin/themeRequests";

const AddSubtopicModal = (props: {closeModal: () => void, topic: ITopic, reload: () => void}) => {
    const [submitText, setSubmitText] = useState('Сохранить')
    const [subtopic, setSubtopic] = useState('')
    const [limit, setLimit] = useState(5)
    const [description, setDescription] = useState('')
    const clusters = useTypedSelector(state => state.clusters)
    const [currentCluster, setCurrentCluster] = useState(clusters.current_cluster)

    const setter = (cluster: ICluster) => {
        setCurrentCluster(cluster)
    }

    const submit = () => {
        if (currentCluster.id === 0) {
            alert('Вы не выбрали кластер!')
        } else if (subtopic === '') {
            alert('Вы не дали название подтеме!')
        } else if (submitText !== "Подождите...") {
            setSubmitText('Подождите...')
            addSubtopic({
                topic_id: props.topic.id,
                limit: limit,
                description: description,
                cluster_id: currentCluster.id,
                subtopic_name: subtopic
            }).then(() => {
                props.closeModal()
                setSubmitText('Сохранить')
                props.reload()
            })
        }
    }

    return (
        <Modal>
            <div className={styles.editMenu}>
                <div className={styles.topicModalHeading}>
                    <h2>Добавить подтему</h2>
                    <div onClick={props.closeModal}>
                        <CrossIcon />
                    </div>
                </div>
                <div className={styles.editControls}>
                    <div className={styles.subtopicEditControl}>
                        <h3>Тема</h3>
                        <p>
                            {props.topic.name}
                        </p>
                    </div>
                    <div className={styles.subtopicEditControl}>
                        <h3>Название подтемы</h3>
                        <textarea
                            name="subtopic"
                            id="subtopic"
                            value={subtopic}
                            onChange={(e) => setSubtopic(e.target.value)}
                        />
                    </div>
                    <div className={styles.subtopicEditControl}>
                        <h3>Описание</h3>
                        <textarea
                            name="subtopic"
                            id="subtopic"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </div>
                    <div className={styles.counterControl}>
                        <h3>Лимит участников, которые могут выбрать тему</h3>
                        <input type="text" value={limit} onChange={(e) => {
                            if (!isNaN(Number(e.target.value)))
                                setLimit(Number(e.target.value))
                        }}/>
                    </div>
                    <div className={styles.clusterControl}>
                        <h3>Кластер</h3>
                        <AdminDropDown
                            width={150}
                            maxHeight={150}
                            optionList={clusters.clusters}
                            defaultValue={clusters.current_cluster}
                            setter={setter}
                        />
                    </div>
                </div>
                <Button
                    width={"150px"}
                    height={"40px"}
                    variant={"primary"}
                    fontSize={"16px"}
                    margin={"20px 0 0 350px"}
                    submit={submit}
                >
                    {submitText}
                </Button>
            </div>
        </Modal>
    );
};

export default AddSubtopicModal;