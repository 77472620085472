import React from 'react';
import HighlightLogo from "../icons/HighlightLogo";
import {useTypedSelector} from "../../hooks/useTypedSelector";
import ExpertiseIcon from "../icons/ExpertiseIcon";

const EndExpertisePage = () => {
    const supportMail = useTypedSelector(state => state.app.support_mail)

    return (
        <>
            <HighlightLogo />
            <div className="instructionBlock">
                <ExpertiseIcon />
                <div className="instructionText">
                    <p className="word">Спасибо!</p>
                    <p className="text">Вы выполнили экспертизу. Дождитесь выполнения третьего этапа конкурса всеми участниками, чтобы узнать свой результат.</p>
                </div>
            </div>
            <a href={"mailto" + supportMail} className="support instructionSupport">{supportMail}</a>
        </>
    );
};

export default EndExpertisePage;