import React from 'react';
// @ts-ignore
import styles from "../../css/admin/Admin.module.css";
import {useNavigate} from "react-router-dom";

// TODO Вывести вкладки в компоненты

const NavPanel = (props: {panelType: string | undefined}) => {
    const navigate = useNavigate()

    return (
        <div className={styles.navPanel}>
            <div
                className={styles.tab}
                style={{borderRight: props.panelType === "script" ? "10px solid #323232" : "none"}}
                onClick={() => navigate('/admin/script')}
            >
                <p>Сценарий проекта</p>
            </div>
            <div
                className={styles.tab}
                style={{borderRight: props.panelType === "users" ? "10px solid #323232" : "none"}}
                onClick={() => navigate('/admin/users')}
            >
                <p>Пользователи</p>
            </div>
            <div
                className={styles.tab}
                style={{borderRight: props.panelType === "themes" ? "10px solid #323232" : "none"}}
                onClick={() => navigate('/admin/themes')}
            >
                <p>Темы</p>
            </div>
            <div
                className={styles.tab}
                style={{borderRight: props.panelType === "mail_lists" ? "10px solid #323232" : "none"}}
                onClick={() => navigate('/admin/mail_lists/register')}
            >
                <p>Рассылки</p>
            </div>
        </div>
    );
};

export default NavPanel;