import React, {useState} from 'react';
// @ts-ignore
import styles from "../../../css/Editor.module.css";
import InfoBlock from "../../steps/InfoBlock/InfoBlock";
import Arrow from "../../common/Arrow";
import {useNavigate} from "react-router-dom";

const ExerciseThemeInfo = (props: {topic: string, subtopic: string}) => {
    const navigate = useNavigate()
    const isMobile = useState(document.documentElement.clientWidth <= 900)[0]

    return (
        <div className={styles.expertiseThemeInfo}>
            {isMobile ? <InfoBlock>
                <h1 className={styles.header}>{props.topic}</h1>
                <p className={styles.theme}>{props.subtopic}</p>
            </InfoBlock> : <>
                <div className={styles.arrowRelativeContainer}>
                    <div className={styles.arrowAbsoluteContainer}>
                        <Arrow isComplete={false} word={"К темам"} direction={"left"} onClick={() => navigate('/third_step')}/>
                    </div>
                </div>
                <h1 className={styles.expertiseTopic}>{props.topic}</h1>
                <h2 className={styles.expertiseSubtopic}>{props.subtopic}</h2>
            </>}
        </div>
    );
};

export default ExerciseThemeInfo;