import React, {useEffect, useRef, useState} from 'react';
// @ts-ignore
import styles from "../../../../css/Player.module.css";
// @ts-ignore
import expertiseStyles from "../../../../css/Editor.module.css";
import {fadeInAnimation} from "../../../../utils/saveAndChangeExercise";
import StepThree from "./components/stepThree";
import StepTwo from "./components/StepTwo";
import StepOne from "./components/StepOne";
import StepFour from "./components/StepFour";
import Button from "../../../common/Button";
import ChangeTaskControl from "../ChangeTaskControl";
import CrossIcon from "../../../icons/admin/CrossIcon";

const ExpertiseMobileInstruction = (props: {closeInstruction: () => void}) => {
    const [step, setStep] = useState(1)
    const container = useRef<HTMLDivElement>(null)

    useEffect(() => {
        fadeInAnimation(container)
    }, [])

    const getNext = () => {
        setStep(step + 1)
    }

    const getPrev = () => {
        setStep(step - 1)
    }

    const setAnyStep = (step: number) => {
        setStep(step)
    }

    return (
        <div className={styles.instruction}>
            <div className={styles.closeInstruction} onClick={props.closeInstruction}>
                <CrossIcon/>
            </div>

            {step !== 5 && <>
                <div className={styles.stepContent} ref={container}>
                    {step === 1 && <StepOne />}
                    {step === 2 && <StepTwo />}
                    {step === 3 && <StepThree />}
                    {step === 4 && <StepFour />}
                </div>
                <ChangeTaskControl container={container} step={step} getNext={getNext} getPrev={getPrev} setStep={setAnyStep}/>
            </>}

            {step === 5 && <div className={styles.goodLuck}>
                <h1>Желаем удачи!</h1>
                <Button
                    width={"200px"}
                    height={"50px"}
                    fontSize={"15px"}
                    variant={"primary"}
                    submit={props.closeInstruction}
                    margin={"80vh auto 0 auto"}
                >
                    Начать экспертизу
                </Button>
            </div>}
        </div>
    );
};

export default ExpertiseMobileInstruction;