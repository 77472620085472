import React, {useEffect, useRef, useState} from 'react';
import ErrorIcon from "../icons/ErrorIcon";
import {sentEmailForRestore} from "../../http/authRequests";
import {useNavigate} from "react-router-dom";

const ForgotForm = ({getLogin}: {getLogin: any}) => {
    const navigate = useNavigate()
    const [bottomText, setBottomText] = useState('Подтвердить')
    const [email, setEmail] = useState('')
    const [error, setError] = useState('')
    const current_block = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        if (current_block.current)
            current_block.current.style.opacity = "1"
    }, [])

    const validateEmail = (email: string) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const submit = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        if (email === '') {
            setError('Не введен email')
        } else if (!validateEmail(email)) {
            setError('Email введен неверно')
        }  else {
            setBottomText('Подождите...')
            sentEmailForRestore(email).then(() => {
                navigate('/password_instruction')
            }).catch((error) => {
                if (error.message === 'Request failed with status code 404') {
                    setError('Такого пользователя не существует')
                } else if (error.message === 'Request failed with status code 403') {
                    setError('Пользователь заблокирован или не активен')
                } else {
                    alert('Что-то пошло не так, попробуйте перезагрузить страницу')
                }
                setBottomText('Подтвердить')
            })
        }
    }

    return (
        <div className="forgotBlock" ref={current_block}>
            <div className="formWrapper">
                <h2 className="forgot-h2">Забыли пароль?</h2>
                <h3 className="forgot-h3">Ничего страшного!</h3>
                <p className="instruction">
                    Введите вашу электронную почту и мы пришлем вам инструкцию по восстановлению пароля.
                </p>
                <form className="logInForm">
                    <input
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="input"
                        placeholder="you@example.com"
                        type="email"
                        id="email"
                    />
                    <div className="error_container">
                        {error && (
                            <label className="error" htmlFor="email"><ErrorIcon /><span>{error}</span></label>
                        )}
                    </div>

                    <button className="submitButton forgot" type="submit" onClick={submit}>
                        {bottomText}
                    </button>
                </form>
                <div className="controls back-to-reg">
                    <div className="control back-to-reg-control" onClick={getLogin}>Назад к авторизации</div>
                </div>
            </div>
        </div>
    );
};

export default ForgotForm;