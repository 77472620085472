import React, {useEffect, useState} from 'react';
// @ts-ignore
import styles from "../../css/Editor.module.css";
import classNames from "classnames";
import {ITaskProps} from "../../types/components/editor";
import {dragEnd, dragMove} from "../../utils/dragMobileFuncs";
import {IOption} from "../../types/entities/task";

const SequenceDraggableOptions = (props: ITaskProps) => {
    const [isDraggable, setIsDraggable] = useState(false)
    const [order, setOrder] = useState<{ order: number; option: IOption; }[]>()
    const [currentOption, setCurrentOption] = useState<{ order: number; option: IOption; }>()

    useEffect(() => {
        let newOrder: { order: number; option: IOption }[] = []
        let answers = JSON.parse(props.task.answers)
        for (let i = 0; i < answers.length; i++) {
            props.task.task_content.options.forEach((option) => {
                if (option.id === answers[i]) {
                    newOrder.push({order: i+1, option: option})
                }
            })
        }
        setOrder(newOrder)
    }, [])

    const dragStart = (e: React.DragEvent<HTMLDivElement>) => {
        order?.forEach((option) => {
            if (option.option.id === Number(e.currentTarget.id)) {
                setCurrentOption(option)
            }
        })
    }

    const dragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        e.currentTarget.style.filter = 'brightness(0.8)'
    }

    const dragLeave = (e: React.DragEvent<HTMLDivElement>) => {
        e.currentTarget.style.filter = 'brightness(1)'
    }

    const drop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        e.currentTarget.style.filter = 'brightness(1)'

        let finalOption: { order: number; option: IOption; }
        order?.forEach((option) => {
            if (option.option.id === Number(e.currentTarget.id)) {
                finalOption = option
            }
        })

        let newOrder = order?.map((option) => {
            if (currentOption && option.option.id === finalOption.option.id) {
                return {...option, order: currentOption.order}
            }

            if (currentOption && option.option.id === currentOption.option.id) {
                return {...option, order: finalOption.order}
            }

            return option
        })

        let newAnswers: number[] = []
        newOrder?.forEach((option) => {
            newAnswers[option.order - 1] = option.option.id
        })

        props.setAnswers(props.task.id, newAnswers)
        setOrder(newOrder)
    }

    const sortByOrder = (oneOption: { order: number; option: IOption; }, twoOption: { order: number; option: IOption; }) => {
        return oneOption.order - twoOption.order
    }

    return (
        <div className={styles.optionsRange}>
            <div className={styles.positions}>
                {props.task.task_content.options.map((value, index) => {
                    return <span key={index}>{index + 1}</span>
                })}
            </div>
            <div className={styles.dragOptions + ' dragOptionsContainer'}>
                {order?.sort(sortByOrder).map((ordered => {
                    return <div
                        key={ordered.option.id}
                        className={styles.dragContainer + ' dragContainer'}
                    >
                        <div
                            onDragStart={(e) => dragStart(e)}
                            onDragOver={(e) => dragOver(e)}
                            onDragLeave={(e) => dragLeave(e)}
                            onDrop={(e) => drop(e)}
                            draggable={isDraggable}
                            className={classNames(styles.option, styles.dragOption) + ' dragElement'}
                            id={ordered.option.id.toString()}
                        >
                            <textarea
                                className={props.errors.includes(ordered.option.id) && !props.fixedErrors?.includes(ordered.option.id) ?
                                    classNames(styles.optionTextarea, styles.optionTextareaError, styles.optionTextareaDrag)
                                    :
                                    classNames(styles.optionTextarea, styles.optionTextareaDrag)}
                                placeholder="Вариант ответа..."
                                defaultValue={ordered.option.text}
                                onChange={(e) => {
                                    if (!props.fixedErrors?.includes(ordered.option.id)) {
                                        if (props.setFixedErrors && props.fixedErrors)
                                            props.setFixedErrors([...props.fixedErrors, ordered.option.id])
                                    }
                                    props.setOption(props.task.id, ordered.option.id, e.target.value)
                                }}
                                onBlur={() => props.saveCurrentTasks()}
                            />
                            <div className={styles.dragBlock} />
                            <div
                                className={styles.dragControl}
                                onTouchMove={(e) => dragMove(e)}
                                onTouchEnd={() => dragEnd(props.task.id, props.setAnswers)}
                                onMouseDown={() => setIsDraggable(true)}
                                onMouseUp={() => setIsDraggable(false)}
                            >
                                <div className={styles.dots}>
                                    <span className={styles.dot}/>
                                    <span className={styles.dot}/>
                                    <span className={styles.dot}/>
                                </div>
                            </div>
                        </div>
                    </div>
                }))}
            </div>
        </div>
    );
};

export default SequenceDraggableOptions;