import React from 'react';
import StepThreeSVG from "./vectorImages/StepThreeSVG";

const DesktopStepThree = () => {
    return (
        <>
            <p>
                <b>Оцените качество</b> тестового задания и его <b>актуальность</b>.
            </p>

            <div style={{margin: "50px auto", width: 480}}>
                <StepThreeSVG />
            </div>
        </>
    );
};

export default DesktopStepThree;