import React, {useEffect, useMemo} from 'react';
import {createPortal} from "react-dom";
// @ts-ignore
import commonStyles from "../../css/Common.module.css";
import WhiteQuestionIcon from "../icons/admin/WhiteQuestionIcon";

const mouseHintRootElement = document.querySelector('#mouseHint')

const MouseHint = (props: {x: number, y: number, text: string}) => {
    const element = useMemo(() => {
        const element = document.createElement('div')
        return element
    }, [])

    useEffect(() => {
        if (mouseHintRootElement)
            mouseHintRootElement.appendChild(element)

        return () => {
            if (mouseHintRootElement)
                mouseHintRootElement.removeChild(element)
        }
    }, [])

    return createPortal(
        <div className={commonStyles.mouseHint} style={{top: props.y, left: props.x}}>
            <WhiteQuestionIcon />
            <p>{props.text}</p>
        </div>,
        element
    );
};

export default MouseHint;