import React from 'react';

const MinusIcon = () => {
    return (
        <svg width="18" height="2" viewBox="0 0 18 2" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.3307 1H1.66406" stroke="rgba(50, 50, 50, 0.2)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};

export default MinusIcon;