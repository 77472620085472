import {initialThemeValue, IThemeList} from "../components/themes";

export interface IStatus {
    max_experts_on_subtopic: number | null
    max_picked_subtopics: number | null
    stage_deadline: string
    first_stage_deadline: string
    second_stage_deadline: string
    third_stage_deadline: string
    third_stage_deadline_ts: number
    stage_number: number
    dashboard_allowed_by_admin: boolean
    subtopics: IThemeList
    wizard_completed: boolean | undefined
    current_wizard_stage: WizardStages
}

export enum WizardStages {
    PROJECT_DESCRIPTION = 'project_description',
    REGISTER = 'register',
    FIRST_STEP = 'first_step',
    WAITING_FOR_SECOND_STEP = 'waiting_for_second_step',
    SECOND_STEP = 'second_step',
    THIRD_STEP = 'third_step'
}

export enum StageStatus {
    ACTUAL = 'actual',
    PAST = 'past',
    COMING = 'coming'
}

export const WizardStagesNames = {
    [WizardStages.PROJECT_DESCRIPTION]: 'Описание проекта',
    [WizardStages.REGISTER]: 'Регистрация участников',
    [WizardStages.FIRST_STEP]: '1 этап',
    [WizardStages.WAITING_FOR_SECOND_STEP]: 'Ожидание 2 этапа',
    [WizardStages.SECOND_STEP]: '2 этап',
    [WizardStages.THIRD_STEP]: '3 этап'
}

export const initialStatus: IStatus = {
    max_experts_on_subtopic: 0,
    max_picked_subtopics: 0,
    stage_deadline: '',
    first_stage_deadline: '',
    second_stage_deadline: '',
    third_stage_deadline: '',
    third_stage_deadline_ts: 0,
    dashboard_allowed_by_admin: false,
    stage_number: 0,
    subtopics: initialThemeValue,
    wizard_completed: undefined,
    current_wizard_stage: WizardStages.PROJECT_DESCRIPTION
}