import React, {useEffect, useRef, useState} from 'react';
// @ts-ignore
import styles from "../../../../css/Player.module.css";
import Modal from "../../../admin/common/Modal";
import {fadeInAnimation} from "../../../../utils/saveAndChangeExercise";
import DesktopStepOne from "./components/DesktopStepOne";
import DesktopStepTwo from "./components/DesktopStepTwo";
import DesktopStepFour from "./components/DesktopStepFour";
import DesktopStepThree from "./components/DesktopStepThree";
import Button from "../../../common/Button";
import ChangeTaskControl from "../ChangeTaskControl";
import CrossIcon from "../../../icons/admin/CrossIcon";

//TODO Порефакторить инструкцию (чтоб не было дупликода)

const ExpertiseInstruction = (props: {closeInstruction: () => void}) => {
    const [step, setStep] = useState(1)
    const container = useRef<HTMLDivElement>(null)

    useEffect(() => {
        fadeInAnimation(container)
    }, [])

    const getNext = () => {
        setStep(step + 1)
    }

    const getPrev = () => {
        setStep(step - 1)
    }

    const setAnyStep = (step: number) => {
        setStep(step)
    }

    return (
        <Modal>
            <div className={styles.expertiseInstruction}>
                <div className={styles.closeInstruction} onClick={props.closeInstruction}>
                    <CrossIcon/>
                </div>

                {step !== 5 && <>
                    <div className={styles.stepContent} ref={container}>
                        {step === 1 && <DesktopStepOne />}
                        {step === 2 && <DesktopStepTwo />}
                        {step === 3 && <DesktopStepThree />}
                        {step === 4 && <DesktopStepFour />}
                    </div>

                    <ChangeTaskControl container={container} step={step} getNext={getNext} getPrev={getPrev} setStep={setAnyStep}/>
                </>}

                {step === 5 && <div className={styles.goodLuck}>
                    <h1>Желаем удачи!</h1>
                    <Button
                        width={"200px"}
                        height={"50px"}
                        fontSize={"15px"}
                        variant={"primary"}
                        submit={props.closeInstruction}
                        margin={"160px auto 0 auto"}
                    >
                        Начать экспертизу
                    </Button>
                </div>}
            </div>
        </Modal>
    );
};

export default ExpertiseInstruction;