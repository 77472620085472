import React from 'react';
// @ts-ignore
import styles from "../../../../css/admin/Script.module.css";
import GreyPlusIcon from "../../../icons/admin/GreyPlusIcon";
import UploadIcon from "../../../icons/admin/UploadIcon";
import MinusIcon from "../../../icons/admin/MinusIcon";

const IconButton = (props: {label?: string, margin?: string, state?: 'add' | 'upload' | 'minus', onClick: () => void}) => {
    const { label, margin, state = 'add', onClick } = props

    return (
        <div className={styles.addButton} style={{ margin }} onClick={onClick}>
            {state === 'add' && <GreyPlusIcon stroke="rgba(50, 50, 50, 0.2)"/>}
            {state === 'upload' && <UploadIcon />}
            {state === 'minus' && <MinusIcon />}
            {label && <span style={{ color: state === 'upload' ? 'black' : undefined }}>{label}</span>}
        </div>
    );
};

export default IconButton;