import {isClientDemo, removeAdminFlag, setAdminFlag} from "./demo/demo";

export const checkAdminMode = () => {
  let adminCurrentRole

  if (localStorage.getItem('currentMode') === null) {
    localStorage.setItem('currentMode', '1')
    adminCurrentRole = 1
  } else {
    adminCurrentRole = Number(localStorage.getItem('currentMode'))
  }

  if (adminCurrentRole === 1 && isClientDemo()) {
    setAdminFlag()
  }

  return adminCurrentRole
}