import React, {useEffect, useRef, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import Modal from "../Modal";
// @ts-ignore
import styles from "../../../css/Editor.module.css";
import BigReadyIcon from "../../icons/BigReadyIcon";
import AnotherButton from "../../common/AnotherButton";
import Header from "../../common/Header/Header";
import Arrow from "../../common/Arrow";
import Exercise from "./Exercise";
import {fadeInAnimation, saveAndChangeExercise} from "../../../utils/saveAndChangeExercise";
import {getExercise, getSubtopicData, saveExercise} from "../../../http/editorRequests";
import ExerciseThemeInfo from "./ExerciseThemeInfo";
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import {useDispatch} from "react-redux";
import { ExerciseActionTypes } from '../../../types/reducers/exercise';
import {IExerciseErrors} from "../../../types/entities/exercise";
import AlertModal from "../../admin/common/AlertModal";
import ExpertiseMobileInstruction from "../../steps/ThirdStepInstruction/mobileInstruction/ExpertiseMobileInstruction";
import ExpertiseInstruction from "../../steps/ThirdStepInstruction/instruction/ExpertiseInstruction";
import {isClientDemo} from "../../../utils/demo/demo";

const ExpertisePlayer = () => {
    const id = Number(useParams().subtopic_id)
    const navigate = useNavigate()
    const taskContainer = useRef<HTMLDivElement>(null)
    const dispatch = useDispatch()
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [subtopicData, setSubtopicData] = useState({
        topic: "Загрузка...",
        subtopic: "Загрузка..."
    })

    const isMobile = useState(document.documentElement.clientWidth <= 900)[0]
    const [isAlertModalOpen, setIsAlertModalOpen] = useState(true)
    const [isInstructionOpen, setIsInstructionOpen] = useState(false)

    const exercise = useTypedSelector(state => state.exercise)

    useEffect(() => {
        getSubtopicData(id).then(data => {
            setSubtopicData(data)
        })

        getExercise(id).then(data => {
            if (data.answer === "") {
                data.answer = "[]"
            }

            if (!data.comment) {
                data.comment = ''
            }

            fadeInAnimation(taskContainer)
            dispatch({type: ExerciseActionTypes.SET_EXERCISE, payload: data})
        }).catch(error => {
            if (error.message === 'Request failed with status code 403') {
                navigate('/error/closed')
            }
        })
    }, [])

    return (
        <>
            {isModalOpen && <Modal>
                <div className={styles.readyModalContent}>
                    <BigReadyIcon />
                    <h2 className={styles.modalHeader}>Ваши экспертные оценки сохранены</h2>
                    <AnotherButton width={181} height={54} onClick={() => navigate('/third_step')}>К списку тем</AnotherButton>
                </div>
            </Modal>}
            <Header />

            {isAlertModalOpen && <AlertModal
                text={"Перед началом экспертизы вас ожидает краткая инструкция"}
                closeModal={() => {
                    setIsAlertModalOpen(false)
                    setIsInstructionOpen(true)
                }}
            />}

            {isInstructionOpen ? isMobile ?
                <ExpertiseMobileInstruction closeInstruction={() => setIsInstructionOpen(false)}/>
                :
                <ExpertiseInstruction closeInstruction={() => setIsInstructionOpen(false)}/>
            : <></>}

            <div className={styles.playerWindow} id="playerWindow">
                <ExerciseThemeInfo topic={subtopicData.topic} subtopic={subtopicData.subtopic}/>

                <div id="taskContainer" className={styles.exerciseTaskContainer} ref={taskContainer}>
                    <Exercise exercise={exercise}/>
                </div>

                <div className={styles.changeTaskControl}>
                    <div className={styles.invisibleElementForFlex} style={{width: exercise.done + 1 === exercise.total ? 119 : 86}} />
                    <span className={styles.totalScore}>{exercise.done + 1}/{exercise.total}</span>
                    <Arrow word={"Далее"} direction={"right"} isComplete={exercise.done + 1 === exercise.total} onClick={() => {
                        if (taskContainer.current?.style.right[0] === '0') {
                            if (!exercise.actuality_assessment || !exercise.quality_assessment || exercise.answer === "[]") {
                                let errors: IExerciseErrors = {
                                    answer_error: false,
                                    quality_error: false,
                                    actuality_error: false
                                }

                                if (!exercise.actuality_assessment) {
                                    errors = {...errors, actuality_error: true}
                                }

                                if (!exercise.quality_assessment) {
                                    errors = {...errors, quality_error: true}
                                }

                                if (exercise.answer === "[]") {
                                    errors = {...errors, answer_error: true}
                                }

                                dispatch({type: ExerciseActionTypes.SET_ERRORS, payload: errors})
                            } else {
                                if (exercise.done + 1 === exercise.total) {
                                    taskContainer.current.style.right = "1px"
                                    saveExercise(exercise).then(() => {
                                        setIsModalOpen(true)
                                    })
                                } else {
                                    if (taskContainer.current) {
                                        taskContainer.current.style.right = "0"
                                        taskContainer.current.style.opacity = "0"
                                        saveAndChangeExercise(taskContainer, dispatch, exercise, id)
                                    }
                                }
                            }
                        }
                    }}/>
                </div>
            </div>
        </>
    );
};

export default ExpertisePlayer;