import React, {useEffect, useState} from 'react';
// @ts-ignore
import styles from "../../css/Player.module.css"

const StepNav = (props: {currentStep: number; steps: number}) => {
    const [steps, setSteps] = useState([<div className={styles.currentStep}>1</div>])

    useEffect(() => {
        let steps = []
        for (let i = 0; i < props.steps; i++) {
            let stepJSX
            if (i === props.currentStep - 1) {
                if (i === props.steps - 1) {
                    stepJSX = <div key={i} className={styles.currentStep}><span>{i + 1}</span></div>
                } else {
                    stepJSX = <>
                        <div key={i} className={styles.currentStep}><span>{i + 1}</span></div>
                        <hr className={styles.navLine} />
                    </>
                }
            } else {
                if (i === props.steps - 1) {
                    stepJSX = <div key={i} className={styles.step}><span>{i + 1}</span></div>
                } else {
                    stepJSX = <>
                        <div key={i} className={styles.step}><span>{i + 1}</span></div>
                        <hr className={styles.navLine} />
                    </>
                }
            }
            steps.push(stepJSX)
        }
        setSteps(steps)
    }, [])

    return (
        <div className={styles.stepNav}>
            {steps.map((step) => step)}
        </div>
    );
};

export default StepNav;